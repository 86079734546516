<template>
    <div ref="printable">
        <div class="container-paystub">

           <div v-if="paycheck.is_void_check" class="mb-2">
                <h3>VOID CHECK</h3>
            </div>

            <section>
                <div class="row mb-2">
                    <div class="col">
                        <div class="mb-1 border-bottom">
                            <h3>Company</h3>
                        </div>
                        <p class="text-uppercase mb-2">
                            <span>{{ paycheck.employee.company.name }}</span><br> 
                            <span>{{ paycheck.employee.company.address_1 }}</span><br> 
                            <span v-if="paycheck.employee.company.address_2">{{ paycheck.employee.company.address_2 }}<br></span>
                            {{ paycheck.employee.company.city }}, 
                            {{ paycheck.employee.company.state }} {{ paycheck.employee.company.postal_code }}
                        </p>
                    </div>
                </div>
                
                <div class="row mb-4">
                    <div class="col">
                        <div class="mb-1 border-bottom">
                            <h3>Employee</h3>
                        </div>
                        <p class="text-uppercase mb-2">
                            <span>{{ paycheck.employee.full_name }}</span><br> 
                            <span>{{ paycheck.employee.address_1 }}</span><br> 
                            <span v-if="paycheck.employee.address_2">{{ paycheck.employee.address_2 }}<br></span>
                            {{ paycheck.employee.city }}, {{ paycheck.employee.state }} {{ paycheck.employee.postal_code }}
                        </p>
                    </div>
                </div>
            </section>
            
            
            <div class="mb-1 border-bottom">
                <h3>Current Statement</h3>
            </div>
                    
            <section class="mb-3">
                <div class="form-row py-qt">
                    <div class="col-5 col-rs-4 col-sm-5">
                        <span class="text-semibold">Check Date:</span>
                    </div>
                    <div class="col-7 col-rs-8 col-sm-7 text-right">
                        <span>{{ paycheck.check_date|date('dddd MM/DD/YYYY') }}</span>
                    </div> 
                </div>
                <div class="form-row py-qt">
                    <div class="col-5 col-rs-4 col-sm-5">
                        <span class="text-semibold">Check Number:</span>
                    </div>
                    <div class="col-7 col-rs-8 col-sm-7 text-right">
                        <span>{{ paycheck.check_number }}</span>
                    </div> 
                </div>
                <div class="form-row py-qt">
                    <div class="col-5 col-rs-4 col-sm-5">
                        <span class="text-semibold">Pay Period:</span>
                    </div>
                    <div class="col-7 col-rs-8 col-sm-7 text-right">
                        <span class="text-nowrap">{{ paycheck.check_data.periodBeginDate|date('dddd MM/DD/YYYY') }}</span> –
                        <span class="text-nowrap">{{ paycheck.check_data.periodEndDate|date('dddd MM/DD/YYYY') }}</span>
                    </div> 
                </div>
            </section>
            
            
            <!-- Current Check Section -->
            
            <section class="mb-2">
                <div class="mb-1 border-bottom">
                    <h5>Current Earnings</h5>
                </div>
                <!-- Smaller than SM -->
                <div class="table-wrapper d-sm-none d-print-none">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody v-for="line in paycheck.pay_details.earnings_by_rate">
                                <tr>
                                  <td>{{ line.label_sm }}</td>
                                  <td>${{ line.dollars || '0.00' }}</td>
                                </tr>
                                <tr class="child currEarnDetails" v-if="line.rate">
                                    <td class="pl-4">Rate</td>
                                    <td><span v-if="line.rate">$</span>{{ line.rate }}</td>
                                </tr>
                                <tr class="child currEarnDetails" v-if="line.hours">
                                    <td class="pl-4">Hours</td>
                                    <td>{{ line.hours }}</td>
                                </tr>
                                <!--
                                    <tr class="child currEarnDetails" v-if="line.units">
                                        <td class="pl-4">Units</td>
                                        <td>{{ line.units }}</td>
                                    </tr>
                                -->
                            </tbody>
                            <tbody>
                                <tr class="totals">
                                  <td>Total</td>
                                  <td>${{ paycheck.pay_details.earnings_total_dollars }}</td>
                                </tr>
                                <tr class="child totals currEarnDetails" v-if="paycheck.pay_details.earnings_total_hours">
                                    <td class="pl-4">Hours</td>
                                    <td>{{ paycheck.pay_details.earnings_total_hours }}</td>
                                </tr>
                                <!--
                                    <tr class="child totals currEarnDetails" v-if="paycheck.pay_details.earnings_total_units">
                                        <td class="pl-4">Units</td>
                                        <td>{{ paycheck.pay_details.earnings_total_units }}</td>
                                    </tr>
                                -->
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- SM and up -->
                <div class="table-wrapper d-none d-sm-block d-print-block">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Rate</th>
                                    <th>Hours</th>
                                    <!-- <th>Units</th> -->
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="line in paycheck.pay_details.earnings_by_rate" :class="{'secondary-line': !line.is_first_line}">
                                    <td>{{ line.label }}</td>
                                    <td><span v-if="line.rate">$</span>{{ line.rate }}</td>
                                    <td>{{ line.hours }}</td>
                                    <!-- <td>{{ line.units }}</td> -->
                                    <td>${{ line.dollars || '0.00' }}</td>
                                </tr>
                                <tr class="totals">
                                    <td colspan="2">Total</td>
                                    <td>{{ paycheck.pay_details.earnings_total_hours }}</td>
                                    <!-- <td>{{ paycheck.pay_details.earnings_total_units }}</td> -->
                                    <td>${{ paycheck.pay_details.earnings_total_dollars || '0.00' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
    
            <section class="mb-2" v-if="paycheck.pay_details.reimbursements.length">
                <div class="mb-1 border-bottom">
                    <h5>Current Reimbursements</h5>
                </div>
                <!-- Smaller than SM -->
                <div class="table-wrapper d-sm-none d-print-none">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody v-for="line in paycheck.pay_details.reimbursements">
                                <tr>
                                  <td>{{ line.label }}</td>
                                  <td>${{ line.dollars || '0.00' }}</td>
                                </tr>
                                <tr class="child currEarnDetails" v-if="line.rate">
                                    <td class="pl-4">Rate</td>
                                    <td><span v-if="line.rate">$</span>{{ line.rate }}</td>
                                </tr>
                                <tr class="child currEarnDetails" v-if="line.hours">
                                    <td class="pl-4">Hours</td>
                                    <td>{{ line.hours }}</td>
                                </tr>
                                <!--
                                    <tr class="child currEarnDetails">
                                        <td class="pl-4">Units</td>
                                        <td>{{ line.units }}</td>
                                    </tr>
                                -->
                            </tbody>
                            <tbody>
                                <tr class="totals">
                                  <td>Total</td>
                                  <td>${{ paycheck.pay_details.reimbursements_total_dollars || '0.00' }}</td>
                                </tr>
                                <tr class="child totals currEarnDetails" v-if="paycheck.pay_details.reimbursements_total_hours">
                                    <td class="pl-4">Hours</td>
                                    <td>{{ paycheck.pay_details.reimbursements_total_hours }}</td>
                                </tr>
                                <!--
                                    <tr class="child totals currEarnDetails">
                                        <td class="pl-4">Units</td>
                                        <td>{{ paycheck.pay_details.reimbursements_total_units }}</td>
                                    </tr>
                                -->
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- SM and up -->
                <div class="table-wrapper d-none d-sm-block d-print-block">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Rate</th>
                                    <th>Hours</th>
                                    <!-- <th>Units</th> -->
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="line in paycheck.pay_details.reimbursements">
                                    <td>{{ line.label }}</td>
                                    <td><span v-if="line.rate">$</span>{{ line.rate }}</td>
                                    <td>{{ line.hours }}</td>
                                    <!-- <td>{{ line.units }}</td> -->
                                    <td>${{ line.dollars || '0.00' }}</td>
                                </tr>
                                <tr class="totals">
                                    <td colspan="2">Total</td>
                                    <td>{{ paycheck.pay_details.reimbursements_total_hours }}</td>
                                    <!-- <td>{{ paycheck.pay_details.reimbursements_total_units }}</td> -->
                                    <td>${{ paycheck.pay_details.reimbursements_total_dollars || '0.00' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            
            <section class="mb-2">
                <div class="mb-1 border-bottom">
                    <h5>Current Taxes</h5>
                </div>
                <div class="table-wrapper">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="line in paycheck.pay_details.taxes">
                                    <td>{{ line.label }}</td>
                                    <td>${{ line.dollars || '0.00' }}</td>
                                </tr>
                                <tr class="totals">
                                    <td>Total</td>
                                    <td>${{ paycheck.pay_details.taxes_total_dollars || '0.00' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            
            <section class="mb-2">
                <div class="mb-1 border-bottom">
                    <h5>Current Deductions</h5>
                </div>
                <div class="table-wrapper">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="line in paycheck.pay_details.deductions">
                                    <td>{{ line.label }}</td>
                                    <td>${{ line.dollars || '0.00' }}</td>
                                </tr>
                                <tr class="totals">
                                    <td>Total</td>
                                    <td>${{ paycheck.pay_details.deductions_total_dollars || '0.00' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

            <section class="mb-2" v-if="paycheck.pay_details.memos.length">
                <div class="mb-1 border-bottom">
                    <h5>Current Memos</h5>
                </div>
                <!-- Smaller than SM -->
                <div class="table-wrapper d-sm-none d-print-none">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody v-for="line in paycheck.pay_details.memos">
                                <tr>
                                  <td>{{ line.label }}</td>
                                  <td>${{ line.dollars || '0.00' }}</td>
                                </tr>
                                <tr class="child currEarnDetails" v-if="line.rate">
                                    <td class="pl-4">Rate</td>
                                    <td><span v-if="line.rate">$</span>{{ line.rate }}</td>
                                </tr>
                                <tr class="child currEarnDetails" v-if="line.hours">
                                    <td class="pl-4">Hours</td>
                                    <td>{{ line.hours }}</td>
                                </tr>
                                <!--
                                    <tr class="child currEarnDetails">
                                        <td class="pl-4">Units</td>
                                        <td>{{ line.units }}</td>
                                    </tr>
                                -->
                            </tbody>
                            <tbody>
                                <tr class="totals">
                                  <td>Total</td>
                                  <td>${{ paycheck.pay_details.memos_total_dollars || '0.00' }}</td>
                                </tr>
                                <tr class="child totals currEarnDetails" v-if="paycheck.pay_details.memos_total_hours">
                                    <td class="pl-4">Hours</td>
                                    <td>{{ paycheck.pay_details.memos_total_hours }}</td>
                                </tr>
                                <!--
                                    <tr class="child totals currEarnDetails">
                                        <td class="pl-4">Units</td>
                                        <td>{{ paycheck.pay_details.memos_total_units }}</td>
                                    </tr>
                                -->
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- SM and up -->
                <div class="table-wrapper d-none d-sm-block d-print-block">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Rate</th>
                                    <th>Hours</th>
                                    <!-- <th>Units</th> -->
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="line in paycheck.pay_details.memos">
                                    <td>{{ line.label }}</td>
                                    <td><span v-if="line.rate">$</span>{{ line.rate }}</td>
                                    <td>{{ line.hours }}</td>
                                    <!-- <td>{{ line.units }}</td> -->
                                    <td>${{ line.dollars || '0.00' }}</td>
                                </tr>
                                <tr class="totals">
                                    <td colspan="2">Total</td>
                                    <td>{{ paycheck.pay_details.memos_total_hours }}</td>
                                    <!-- <td>{{ paycheck.pay_details.memos_total_units }}</td> -->
                                    <td>${{ paycheck.pay_details.memos_total_dollars || '0.00' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            
            <section class="pb-2 mb-4">
                <div class="table-wrapper grand-totals-divider px-1 py-hf">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <tbody>
                                <tr class="totals">
                                    <td><h3 class="m-0">Net Pay</h3></td>
                                    <td>
                                      <h3 class="m-0">${{ paycheck.net_pay || '0.00' }}</h3>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            
                    
            <!-- Year-to-Date Section -->
            
            <div class="mb-3 border-bottom">
                <h3>Year to Date</h3>
            </div>
            
            <section class="mb-2">
                <div class="mb-1 border-bottom">
                    <h5>Year-to-Date Earnings</h5>
                </div>
                <!-- Smaller than SM -->
                <div class="table-wrapper d-sm-none d-print-none">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody v-for="line in paycheck.pay_details.earnings">
                                <tr>
                                  <td>{{ line.label }}</td>
                                  <td>${{ line.ytd_dollars || '0.00' }}</td>
                                </tr>
                                <tr class="child ytdEarnDetails" v-if="line.ytd_hours">
                                    <td class="pl-4">Hours</td>
                                    <td>{{ line.ytd_hours }}</td>
                                </tr>
                                <!--
                                    <tr class="child ytdEarnDetails">
                                        <td class="pl-4">Units</td>
                                        <td>{{ line.ytd_units }}</td>
                                    </tr>
                                -->
                            </tbody>
                            <tbody>
                                <tr class="totals">
                                  <td>Total</td>
                                  <td>${{ paycheck.pay_details.ytd_earnings_total_dollars || '0.00' }}</td>
                                </tr>
                                <tr class="child totals ytdEarnDetails" v-if="paycheck.pay_details.ytd_earnings_total_hours">
                                    <td class="pl-4">Hours</td>
                                    <td>{{ paycheck.pay_details.ytd_earnings_total_hours }}</td>
                                </tr>
                                <!--
                                    <tr class="child totals ytdEarnDetails">
                                        <td class="pl-4">Units</td>
                                        <td>{{ paycheck.pay_details.ytd_earnings_total_units }}</td>
                                    </tr>
                                -->
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- SM and up -->
                <div class="table-wrapper d-none d-sm-block d-print-block">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Hours</th>
                                    <!-- <th>Units</th> -->
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="line in paycheck.pay_details.earnings">
                                    <td>{{ line.label }}</td>
                                    <td>{{ line.ytd_hours }}</td>
                                    <!-- <td>{{ line.ytd_units }}</td> -->
                                    <td>${{ line.ytd_dollars || '0.00' }}</td>
                                </tr>
                                <tr class="totals">
                                    <td>Total</td>
                                    <td>{{ paycheck.pay_details.ytd_earnings_total_hours }}</td>
                                    <!-- <td>{{ paycheck.pay_details.ytd_earnings_total_units }}</td> -->
                                    <td>${{ paycheck.pay_details.ytd_earnings_total_dollars || '0.00' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

            <section class="mb-2" v-if="paycheck.pay_details.reimbursements.length">
                <div class="mb-1 border-bottom">
                    <h5>Year-to-Date Reimbursements</h5>
                </div>
                <!-- Smaller than SM -->
                <div class="table-wrapper d-sm-none d-print-none">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody v-for="line in paycheck.pay_details.reimbursements">
                                <tr>
                                  <td>{{ line.label }}</td>
                                  <td>${{ line.ytd_dollars || '0.00' }}</td>
                                </tr>
                                <tr class="child ytdEarnDetails" v-if="line.ytd_hours">
                                    <td class="pl-4">Hours</td>
                                    <td>{{ line.ytd_hours }}</td>
                                </tr>
                                <!--
                                    <tr class="child ytdEarnDetails">
                                        <td class="pl-4">Units</td>
                                        <td>{{ line.ytd_units }}</td>
                                    </tr>
                                -->
                            </tbody>
                            <tbody>
                                <tr class="totals">
                                  <td>Total</td>
                                  <td>${{ paycheck.pay_details.ytd_reimbursements_total_dollars || '0.00' }}</td>
                                </tr>
                                <tr class="child totals ytdEarnDetails" v-if="paycheck.pay_details.ytd_reimbursements_total_hours">
                                    <td class="pl-4">Hours</td>
                                    <td>{{ paycheck.pay_details.ytd_reimbursements_total_hours }}</td>
                                </tr>
                                <!--
                                    <tr class="child totals ytdEarnDetails">
                                        <td class="pl-4">Units</td>
                                        <td>{{ paycheck.pay_details.ytd_reimbursements_total_units }}</td>
                                    </tr>
                                -->
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- SM and up -->
                <div class="table-wrapper d-none d-sm-block d-print-block">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Hours</th>
                                    <!-- <th>Units</th> -->
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="line in paycheck.pay_details.reimbursements">
                                    <td>{{ line.label }}</td>
                                    <td>{{ line.ytd_hours }}</td>
                                    <!-- <td>{{ line.ytd_units }}</td> -->
                                    <td>${{ line.ytd_dollars || '0.00' }}</td>
                                </tr>
                                <tr class="totals">
                                    <td>Total</td>
                                    <td>{{ paycheck.pay_details.ytd_reimbursements_total_hours }}</td>
                                    <!-- <td>{{ paycheck.pay_details.ytd_reimbursements_total_units }}</td> -->
                                    <td>${{ paycheck.pay_details.ytd_reimbursements_total_dollars || '0.00' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            
            <section class="mb-2">
                <div class="mb-1 border-bottom">
                    <h5>Year-to-Date Taxes</h5>
                </div>
                <div class="table-wrapper">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="line in paycheck.pay_details.taxes">
                                    <td>{{ line.label }}</td>
                                    <td>${{ line.ytd_dollars || '0.00' }}</td>
                                </tr>
                                <tr class="totals">
                                    <td>Total</td>
                                    <td>${{ paycheck.pay_details.ytd_taxes_total_dollars || '0.00' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            
            <section class="mb-2">
                <div class="mb-1 border-bottom">
                    <h5>Year-to-Date Deductions</h5>
                </div>
                <div class="table-wrapper">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="line in paycheck.pay_details.deductions">
                                    <td>{{ line.label }}</td>
                                    <td>${{ line.ytd_dollars || '0.00' }}</td>
                                </tr>
                                <tr class="totals">
                                    <td>Total</td>
                                    <td>${{ paycheck.pay_details.ytd_deductions_total_dollars || '0.00' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

            <section class="mb-2" v-if="paycheck.pay_details.memos.length">
                <div class="mb-1 border-bottom">
                    <h5>Year-to-Date Memos</h5>
                </div>
                <!-- Smaller than SM -->
                <div class="table-wrapper d-sm-none d-print-none">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody v-for="line in paycheck.pay_details.memos">
                                <tr>
                                  <td>{{ line.label }}</td>
                                  <td>${{ line.ytd_dollars || '0.00' }}</td>
                                </tr>
                                <tr class="child ytdEarnDetails" v-if="line.ytd_hours">
                                    <td class="pl-4">Hours</td>
                                    <td>{{ line.ytd_hours }}</td>
                                </tr>
                                <!--
                                    <tr class="child ytdEarnDetails">
                                        <td class="pl-4">Units</td>
                                        <td>{{ line.ytd_units }}</td>
                                    </tr>
                                -->
                            </tbody>
                            <tbody>
                                <tr class="totals">
                                  <td>Total</td>
                                  <td>${{ paycheck.pay_details.ytd_memos_total_dollars || '0.00' }}</td>
                                </tr>
                                <tr class="child totals ytdEarnDetails" v-if="paycheck.pay_details.ytd_memos_total_hours">
                                    <td class="pl-4">Hours</td>
                                    <td>{{ paycheck.pay_details.ytd_memos_total_hours }}</td>
                                </tr>
                                <!--
                                    <tr class="child totals ytdEarnDetails">
                                        <td class="pl-4">Units</td>
                                        <td>{{ paycheck.pay_details.ytd_memos_total_units }}</td>
                                    </tr>
                                -->
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- SM and up -->
                <div class="table-wrapper d-none d-sm-block d-print-block">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Hours</th>
                                    <!-- <th>Units</th> -->
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="line in paycheck.pay_details.memos">
                                    <td>{{ line.label }}</td>
                                    <td>{{ line.ytd_hours }}</td>
                                    <!-- <td>{{ line.ytd_units }}</td> -->
                                    <td>${{ line.ytd_dollars || '0.00' }}</td>
                                </tr>
                                <tr class="totals">
                                    <td>Total</td>
                                    <td>{{ paycheck.pay_details.ytd_memos_total_hours }}</td>
                                    <!-- <td>{{ paycheck.pay_details.ytd_memos_total_units }}</td> -->
                                    <td>${{ paycheck.pay_details.ytd_memos_total_dollars || '0.00' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            <!-- Accruals Section -->
            
            <section class="mb-2 mt-2" v-if="paycheck.pay_details.accruals.length">
                <div class="mb-1 border-bottom">
                    <h3>Accruals</h3>
                </div>
                <!-- Smaller than SM -->
                <div class="table-wrapper d-sm-none d-print-none">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody v-for="accrual in paycheck.pay_details.accruals" :key="accrual.id">
                                <tr>
                                  <td>{{ accrual.name }}</td>
                                  <td>{{ accrual.ending_balance }}</td>
                                </tr>
                                <tr class="child accrualDetails">
                                    <td class="pl-4">Accrued</td>
                                    <td>{{ accrual.current_accrual }}</td>
                                </tr>
                                <tr class="child accrualDetails">
                                    <td class="pl-4">Taken</td>
                                    <td>{{ accrual.current_taken }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- SM and up -->
                <div class="table-wrapper d-none d-sm-block d-print-block">
                    <div class="table-responsive">
                        <table class="table table-reduce-x table-flush-x table-paycheck-grid">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Accrued</th>
                                    <th>Taken</th>
                                    <th>Available Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="accrual in paycheck.pay_details.accruals" :key="accrual.id">
                                    <td>{{ accrual.name }}</td>
                                    <td>{{ accrual.current_accrual }}</td>
                                    <td>{{ accrual.current_taken }}</td>
                                    <td>{{ accrual.ending_balance }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            
            <!-- Check Message section -->
            
            <section class="mb-2" v-if="paycheck.message">
                <div class="mb-1 border-bottom">
                    <h3>Check Message</h3>
                </div>
                <div>
                    <span>{{ paycheck.message }}</span>
                </div>
            </section>

        </div>
    </div>
</template>

<script>
import PrintableMixin from '@/mixins/Printable'

export default {
    props: ['paycheck', ],
    mixins: [PrintableMixin],
    data() {
        return {
            'printableItemType': 'paycheck',
            'printableItemId': this.paycheck.id,
        }
    }

}
</script>
