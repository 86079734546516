<template>
    <div id="app" class="flex-container" :class="{'is-in-app': state.isInApp}" @click="triggerRecentActivity" @keydown="triggerRecentActivity">
        <!-- Loading Spinner -->
        <div v-show="loading" class="loader">
            <div class="spinner">
              <div class="lds-grid">
                <div></div><div></div><div></div>
                <div></div><div></div><div></div>
                <div></div><div></div><div></div>
              </div>
            </div>
        </div>
        <!-- System Info Message -->
        <div class="flex-header">
            <nav class="navbar navbar-dark system-info-message" v-if="state.system_status_messages && state.system_status_messages.length">
                <div class="w-100 px-1" v-for="msg in state.system_status_messages">
                    {{ msg.short_message }}
                    <span v-if="msg.long_message">
                        <a href @click.prevent="system_status_message_expanded = !system_status_message_expanded">
                            <span v-if="system_status_message_expanded">Show less</span>
                            <span v-else="system_status_message_expanded">Show more</span>
                        </a>
                    </span>
                    <div class="system-info-message__details" v-if="msg.long_message && system_status_message_expanded">
                        <strong class="pr-hf">Details: </strong>
                        {{ msg.long_message }}
                    </div>
                </div>
            </nav>

            <!-- Main Navbar -->
            <main-navbar v-if="state.user" />

            <nav class="navbar navbar-expand-md navbar-light bg-white" v-if="!state.user">
                <div class="mx-auto">
                    <h1 class="h3 m-0 text-primary text-regular">
                        <router-link to="/">
                            <span class="navbar-logo">
                                <img class="navbar-logo-icon" src="@/assets/img/gmps-logo-icon-opt.svg" alt="GetMyPayStub Logo" />
                                <img class="navbar-logo-text" src="@/assets/img/gmps-logo-text-opt.svg" alt="GetMyPayStub Logo" />
                            </span>
                        </router-link>
                    </h1>
                </div>
            </nav>
        </div>
        <transition name="fade" mode="out-in">
            <router-view class="flex-page-wrapper flex-body flex-container view" :key="$route.fullPath" />
        </transition>

        <session-expiring-modal ref="sessionExpiringModal" v-if="show_session_timeout_warning" />
        <logged-out-modal ref="loggedOutModal" />
        <no-access-modal ref="noAccessModal" />
        <time-mismatch-modal ref="timeMismatchModal" />

        <portal-target name="globalModal" slim></portal-target>
        <portal-target name="specialModal" slim></portal-target>
    </div>
</template>

<script>
import MainNavbar from '@/components/MainNavbar.vue'
import LoggedOutModal from '@/components/LoggedOutModal'
import SessionExpiringModal from '@/components/SessionExpiringModal'
import NoAccessModal from '@/components/NoAccessModal'
import TimeMismatchModal from '@/components/TimeMismatchModal'
import moment from 'moment'

export default {
    name: 'app',
    components: {MainNavbar, LoggedOutModal, NoAccessModal, SessionExpiringModal, TimeMismatchModal},
    data() {
        return {
            has_recent_activity: false,
            timer: null,
            time_counter: 0,
            system_status_message_expanded: false,
        }
    },
    computed: {
        state() {return this.$store.state},
        loading() {return this.$store.state.loading},
        show_session_timeout_warning() {
            if (! this.$store.state.user) {
                return false
            }
            if (! this.$store.state.session_expiry) {
                return false
            }
            // this is just to trigger the recompute, moment will get the current time
            let a = this.time_counter
            let m = moment.utc(this.$store.state.session_expiry, moment.ISO_8601, true)
            // pop up the warning 10 minutes before the session is about to end
            let res = m.isBefore(moment.utc().add(10, 'minutes'))
            return res
        },
    },
    destroyed() {
        clearInterval(this.timer)
    },
    methods: {
        triggerRecentActivity() {
            this.has_recent_activity = true
        }
    },
    mounted() {
        this.$store.dispatch('STOP_LOADING')
        this.$store.dispatch('CLOSE_LEFT_DRAWER')
        this.$store.dispatch('GET_SECURITY_ROLES')
        this.$store.dispatch('GET_SYSTEM_STATUS')
        this.$store.dispatch('GET_SYSTEM_FLAGS')

        window.onbeforeunload = (evt) => {
            if (!this.$store.state.isCloseable) {
                evt.preventDefault()
                evt.returnValue = 'Changes you made may not be saved.'
                return 'Changes you made may not be saved.'
            }
        }

        if (!this.$store.state.session) {
            if (this.$route.meta.requiresAuth) {
                this.$store.dispatch('LOGOUT', true)
                this.$router.push({name: 'login'})
            }
        }

        this.$bus.$on('no-access', () => {
            this.$refs.noAccessModal.open()
        })

        this.$bus.$on('logged-out', () => {
            this.$refs.loggedOutModal.open()
        })

        this.$bus.$on('navigation', () => {
            if (this.$refs && this.$refs.noAccessModal) {
                this.$refs.noAccessModal.close()
            }

            if (this.$refs && this.$refs.loggedOutModal) {
                this.$refs.loggedOutModal.close(true)
            }

            if (this.$refs && this.$refs.sessionExpiringModal) {
                this.$refs.sessionExpiringModal.close()
            }

            if (this.$store.state.appVersionClient != this.$store.state.appVersionServer) {
                this.$nextTick(() => {
                    // console.log('Refreshing to get new version')
                    window.location.reload()
                })
            }
        })

        // update every 30 seconds - we're really just trying to update the minutes, don't need seconds
        const heartbeatInterval = (process.env.NODE_ENV === 'development') ? 30 * 1000 * 1000 : 30 * 1000;
        this.timer = setInterval(() => {
            this.time_counter += 1

            // Send a heartbeat - check for session (but make sure to set ignore_for_session_expiry=True
            // if we don't have recent activity,  so it doesn't result in indefinitely extending it).
            // Just a GET should be fine, don't need to do anything with the results since the response
            // parsers will pick up what they need from the headers.
            this.$api.get(`/heartbeat`, !this.has_recent_activity).then(resp => {
                // if the system status was updated, grab what's going on
                if (resp['system_status_message_updated'] && resp['system_status_message_updated'] != this.$store.state.system_status_message_updated) {
                    this.$store.dispatch('GET_SYSTEM_STATUS')
                }

                if (resp.time) {
                    let serverTime = resp.time.substring(0, 16);
                    let localTime = (moment.utc().toISOString().split(".")[0] + "Z").substring(0, 16);
                    let timeMismatch = (serverTime != localTime);
                    if (timeMismatch) {
                        console.error("Your computer clock or timezone is not correct, which may cause problems logging in to GetMyPayStub.")
                        // this.$refs.timeMismatchModal.open()
                    }
                }
            })

            // reset 'recent activity' counter
            this.has_recent_activity = false

            // if the app is more than 10 minutes out of date, let them know
            if (this.$store.state.appVersionStaleSince && this.$store.state.appVersionStaleSince.isBefore(moment().add(10, 'minutes'))) {
                this.$bus.showUpdatePrompt()
            }
        }, heartbeatInterval);
    },
}
</script>
