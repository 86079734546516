<template>
    <modal size="lg" @close="$emit('close')" ref="modal" :autoshow="true">
        <template slot="title">{{ docName }}</template>

        <template slot="headerbuttons">
            <div v-if="src">
                <button class="btn btn-outline-primary btn-collapse-xl" :disabled="zoom >= 400" @click.prevent="zoom += 50">
                    <i class="far fa-fw fa-search-plus"></i>
                    <span class="sr-only">Zoom In</span>
                </button>
                <button class="btn btn-outline-primary btn-collapse-xl ml-1" :disabled="zoom <= 100" @click.prevent="zoom -= 50">
                    <i class="far fa-fw fa-search-minus"></i>
                    <span class="sr-only">Zoom Out</span>
                </button>
            </div>
        </template>

        <slot>
            <div v-if="src" class="pdf-viewer-wrapper" :class="{'zoom-active': zoom > 100}">
                <pdf ref="pdf" v-show="isLoaded" :src="src" v-for="i in numPages" :key="i" :page="i" :style="`width: ${zoom}%; border: 1px solid #999; margin-bottom: 1em;`"/>
            </div>
            <custom-form-survey
                v-else
                :custom-form="previewCustomForm"
                :is-preview="true"
                ref="previewCustomFormSurvey"
            />
        </slot>

        <template slot="footer">
            <button-row stacked>
                <button type="button" class="btn btn-outline-primary" @click.prevent="$emit('close')">Close</button>
                <button v-if="!src && !hidePreviewSubmit" type="button" class="btn btn-primary" @click.prevent="savePreview">Preview Saved Document</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import pdf from 'vue-pdf'
import ModalMixin from '@/mixins/ModalMixin'
import { dragscroll } from 'vue-dragscroll'
import ButtonRow from '@/components/ButtonRow'
import CustomFormSurvey from '@/components/CustomFormSurvey'

export default {
    mixins: [ModalMixin],
    directives: {dragscroll},
    components: {pdf, ButtonRow, CustomFormSurvey},
    props: ['docName', 'companyName', 'questions', 'client', 'hidePreviewSubmit'],
    data() {
        return {
            isLoaded: false,
            src: null,
            numPages: null,
            zoom: 100,
        }
    },
    computed: {
        previewCustomForm() {
            return {
                'hr_custom_form_version': {
                    'questions': this.questions
                },
                'response_data': {}
            }
        },
    },
    methods: {
        loadPDFData(resp) {
            const pdfBlob = new Uint8Array(resp)
            pdf.createLoadingTask(pdfBlob).promise.then((pdf) => {
                this.$store.dispatch('STOP_LOADING')
                this.numPages = pdf.numPages
                this.src = pdfBlob
                this.isLoaded = true
            })
        },
        savePreview() {
            if (!this.$refs.previewCustomFormSurvey.validate()) {
                return
            }
            let data = {
                'name': this.name,
                'company_name': this.companyName
            }
            Object.assign(data, this.$refs.previewCustomFormSurvey.getFormData())
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/hr-custom-forms/preview-pdf`, data).then(resp => {
                this.loadPDFData(resp)
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        }
    },
}
</script>
