<template>
    <page>
        <fast-table
            v-show="dataLoaded"
            tableClasses="table-anchor--shorttext"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="onClick"
        >

            <template slot="table-actions">
                <button type="button" class="btn btn-primary btn-collapse mx-2" @click.prevent="$refs.importFileModal.open()">
                    <i class="far fa-fw fa-file-excel"></i> <span class="btn-text">Import Company Addresses</span>
                </button>

                <a href @click.prevent="onExportClients()" class="btn btn-primary d-print-none ml-hf mx-2">
                    <i class="far fa-fw fa-file-excel"></i>
                    <span class="btn-text">Export Clients</span>
                </a>

                <a href @click.prevent="onExportEmails()" class="btn btn-primary d-print-none ml-hf mr-1">
                    <i class="far fa-fw fa-file-excel"></i>
                    <span class="btn-text">Export Emails</span>
                </a>
            </template>

        </fast-table>

        <import-file-modal :import-file-type="'company-addresses'" @updated="getClients()" ref="importFileModal" />
    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import ImportFileModal from './components/ImportFileModal'

export default {
    components: {FastTable, Page, ImportFileModal, },
    computed: {
        clients_active() {
            return this.clients.filter(c => c.is_active)
        },
        clients_inactive() {
            return this.clients.filter(c => !c.is_active)
        },
        tableData() {
            const tableData = []

            const subsets = [
                this.clients_active,
                this.clients_inactive,
            ]

            subsets.forEach(clients_section => {
                const sectionData = []

                clients_section.forEach((c, idx) => {
                    const row = {
                        id: idx,
                        object: c,
                        cells: [
                            c.name,
                            c.client_code,
                            c.num_companies,
                            c.has_ess || c.has_paperless_onboarding || c.num_pos_integrations,
                            c.num_pos_integrations > 0,
                            c.has_ess,
                            c.has_paperless_onboarding,
                            c.use_personal_email_field,
                            c.has_fillable_forms,
                            c.instant_pay_enabled,
                            c.has_tips_allocator,
                        ],
                    }

                    sectionData.push(row)
                })

                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            clients: [],
            dataLoaded: false,
            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Client Code', classes: 'cw-8', isSearchable: true},
                {label: '# of Companies', classes: 'cw-9', isSearchable: false},
                {label: 'GMPS Any', classes: '', type: "checkmark",  isSearchable: false, isFilterable: true},

                {label: 'POS Integration', classes: '', type: "checkmark",  isSearchable: false, isFilterable: true},
                {label: 'GMPS ESS', classes: '', type: "checkmark",  isSearchable: false, isFilterable: true},
                {label: 'GMPS Onboarding', classes: '', type: "checkmark",  isSearchable: false, isFilterable: true},
                {label: 'Personal Email Field', classes: '', type: "checkmark",  isSearchable: false, isFilterable: true},
                {label: 'Fillable Forms', classes: '', type: "checkmark",  isSearchable: false, isFilterable: true},
                {label: 'Instant Pay', classes: '', type: "checkmark",  isSearchable: false, isFilterable: true},
                {label: 'Tips Allocator', classes: '', type: "checkmark",  isSearchable: false, isFilterable: true},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
                {title: 'Inactive', defaultIsClosed: true, id: 2},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Clients')
        this.getClients()
    },
    methods: {
        getClients() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get('/admin/clients').then((resp) => {
                this.clients = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onUpdate(client) {
            const existing = this.clients.find(s => {return s.id == client.id})
            if (existing) {
                Object.assign(existing, client)
            }
        },
        onClick(client) {
            this.$router.push({name: 'admin-client-companies', params: {hashedClientId: this.$hasher.encode(client.id)}})
        },
        onExportEmails() {
            this.$api.redirect(`/admin/companies/export-emails`)
        },
        onExportClients() {
            this.$api.redirect(`/admin/clients/export-clients`)
        },

    },
}

</script>
