<template>
    <modal size="xl" @close="$emit('close')" ref="modal">
        <template slot="title">{{ title }}</template>

        <template slot="headerbuttons">
            <button class="btn btn-outline-primary btn-collapse-xl" :disabled="zoom >= 400" @click.prevent="zoom += 50">
                <i class="far fa-fw fa-search-plus"></i>
                <span class="sr-only">Zoom In</span>
            </button>
            <button class="btn btn-outline-primary btn-collapse-xl ml-1" :disabled="zoom <= 100" @click.prevent="zoom -= 50">
                <i class="far fa-fw fa-search-minus"></i>
                <span class="sr-only">Zoom Out</span>
            </button>
            <button class="btn btn-outline-primary btn-collapse-rs ml-1" @click.prevent="print()">
                <i class="far fa-fw fa-file-pdf"></i>
                <span class="btn-text ml-hf">PDF</span>
            </button>
        </template>

        <template slot="subheading">
        </template>

        <slot>
            <div class="pdf-viewer-wrapper" :class="{'zoom-active': zoom > 100}">
                <pdf ref="pdf" v-show="isLoaded" :src="src" v-for="i in numPages" :key="i" :page="i" :style="`width: ${zoom}%; border: 1px solid #999; margin-bottom: 1em;`"/>
            </div>
        </slot>

        <template slot="footer">
            <slot name="footer">
            </slot>
        </template>
    </modal>
</template>

<script>
import pdf from 'vue-pdf'
import ModalMixin from '@/mixins/ModalMixin'
import { dragscroll } from 'vue-dragscroll'

export default {
    mixins: [ModalMixin],
    directives: {dragscroll},
    components: {pdf, },
    props: ['url', 'title', ],
    data() {
        return {
            isLoaded: false,
            src: this.getDocument(),
            numPages: null,
            zoom: 100,
        }
    },
    watch: {
        url() {
            this.src = this.getDocument()
        },
    },
    methods: {
        getDocument() {
            const src = pdf.createLoadingTask(this.url)
            src.promise.then((resp) => {
                this.numPages = resp.numPages
                this.isLoaded = true
                this.$nextTick(() => {
                    this.open()
                })
            })
            return src
        },
        print() {
            let pdf_url = this.url.url
            if (! this.$store.state.isInApp) {
                window.open(pdf_url)
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.get(pdf_url, false, false).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                // use this rather than ArrayBufferToString because ArrayBufferToString
                // sometimes results in: 'RangeError: Maximum call stack size exceeded'
                function _arrayBufferToBase64( buffer ) {
                    var binary = '';
                    var bytes = new Uint8Array( buffer );
                    var len = bytes.byteLength;
                    for (var i = 0; i < len; i++) {
                        binary += String.fromCharCode( bytes[ i ] );
                    }
                    return window.btoa( binary );
                }

                if (global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cordova_iab) {
                    global.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({
                        command: 'print',
                        content: btoa(_arrayBufferToBase64(resp))
                    }))
                } else {
                    console.error("In app, but global.webkit.messageHandlers not found");
                }
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        }
    },
}
</script>

<style lang="scss">
.xxx-pdf-viewer-wrapper {
    overflow: hidden;
    max-height: 90vh;
    cursor: grab;
}
</style>
