<template>
    <div v-if="dataLoaded">
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                  
                    <div class="container-centered-s py-4">

                        <h5>{{ onboardingApplication.company.name }}</h5>
            
                        <form @submit.prevent="onSubmit">
                            <form-errors :errors="formErrors"/>
            
                                <div v-for="(question, $index) in questions" :key="question.id">
                                    <div v-show="$index == currentQuestion">
                                        <h3 v-html="question.html"></h3>
            
                                        <div class="row" v-for="option in question.options" :key="option.id">
                                            <div class="col-1 text-right">
                                                <input :id="'form-selection-option-' + option.id" :name="'form-selection-question-' + question.id" type="radio" :value="option.option_id" @click="onClick(question.id, option.id)" :checked="formData.questions[question.id].option_id == option.id" />
                                            </div>
            
                                            <div class="col-11">
                                                <label :for="'form-selection-option-' + option.id" v-html="option.html"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            
                                <div class="row mb-2 d-flex align-items-center">
                                    <div class="col">
                                        <router-link v-if="previousPage && (currentQuestion == 0)" :to="previousPage">Back</router-link>
                                        <a v-if="currentQuestion > 0" href @click.prevent="previousQuestion()">Back</a>
                                    </div>
            
                                    <div class="col text-right">
                                        <button v-if="currentQuestion < questions.length - 1" class="btn btn-primary" type="button" @click.prevent="nextQuestion()">Continue</button>
                                        <button v-if="currentQuestion == questions.length - 1" class="btn btn-primary" type="submit">Continue</button>
                                    </div>
                                </div>
                            </form>
            
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import FormMixin from '@/mixins/Form'
import api from '@/api'
import bus from '@/bus'
import WizardMixin from './WizardMixin'

export default {
    mixins: [FormMixin, WizardMixin],
    props: ['onboardingApplication'],
    mounted() {
        this.getQuestions()
    },
    data() {
        return {
            formRules: {
            },
            formData: {
                action: 'form-selection',
                questions: {}
            },
            dataLoaded: false,
            questions: null,
            currentQuestion: 0,
        }
    },
    methods: {
        getQuestions() {
            this.dataLoaded = false
            const appId = this.onboardingApplication.id
            this.$store.dispatch('START_LOADING')
            api.get(`/me/onboarding/${appId}/onboarding-application/form-selection-questions`).then((resp) => {
                this.questions = resp
                if (this.questions.length < 1) {
                    if (this.$route.query.prev) {
                        this.$router.push(this.previousPage)
                    } else {
                        this.onSubmit()
                    }

                    return
                }

                this.currentQuestion = 0
                this.reset()
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__)
            })
        },
        onClick(questionId, optionId) {
            this.$set(this.formData.questions, questionId, {option_id: optionId})
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }
            let missingQuestionCount = 0
            Object.keys(this.formData.questions).forEach((q_id) => {
                if (!this.formData.questions[q_id].option_id) {
                    missingQuestionCount++
                }
            })

            if (missingQuestionCount) {
                this.formErrors = ['Please answer all questions.']
                return
            }

            this.$store.dispatch('START_LOADING')
            api.patch(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application`, this.formData).then((resp) => {
                this.$emit('updated', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        goToQuestion(idx) {
            this.formErrors = ''
            if (idx >= this.questions.length) {
                this.onSubmit()
                return
            }

            if (idx < 0) {
                return
            }

            if (idx > this.currentQuestion) {
                const questionId = this.questions[this.currentQuestion].id
                if (!this.formData.questions[questionId].option_id) {
                    this.formErrors = ['Please provide an answer.']
                    return
                }
            }

            this.currentQuestion = idx
        },
        nextQuestion() {
            this.goToQuestion(this.currentQuestion + 1)
        },
        previousQuestion() {
            this.goToQuestion(this.currentQuestion - 1)
        },
        reset: function() {
            this.errors = {}
            this.formErrors = []
            this.$set(this.formData, 'questions', {})
            this.questions.forEach((q) => {
                this.$set(this.formData.questions, q.id, {option_id: null})
            })
        },
    },
}
</script>
