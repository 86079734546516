<template>
    <page>
        <fast-table
            v-show="dataLoaded"
            :sections="sections"
            :headers="headers"
            :tableClasses="tableClasses"
            :data="tableData"
            @click="currentForm = $event"
        >
            <template slot="table-actions">
                <button type="button" class="btn btn-primary btn-collapse-md" @click.prevent="currentForm = null; $refs.addModal.open()">
                    <i class="far fa-fw fa-plus"></i>
                    <span class="btn-text">Add PDF Form</span>
                </button>
            </template>
        </fast-table>

        <sidebar :form="currentForm" :all-forms="forms" v-if="currentForm" ref="sidebar" @updated="onUpdate($event)" @close="currentForm = null" />
        <add-modal ref="addModal" @updated="forms.push($event)" />
    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import Sidebar from './components/FormSidebar'
import AddModal from './components/FormModal'

export default {
    components: {FastTable, Page, Sidebar, AddModal, },
    computed: {
        tableData() {
            const tableData = []

            this.forms.forEach((f, idx) => {
                const state = f.city ? f.city.state : f.state
                const row = {
                    id: idx,
                    isActive: this.currentForm && f.id == this.currentForm.id,
                    object: f,
                    cells: [
                        f.name,
                        f.slug,
                        f.form_type_label,
                        f.is_live ? 'Yes' : 'No',
                        f.version_number,
                        f.city ? f.city.name : '',
                        state ? state.name : '',
                        f.pdf_file,
                        f.pdf_modified_on,
                        f.yaml_modified_on,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
    },
    data() {
        return {
            forms: [],
            dataLoaded: false,
            currentForm: null,

            tableClasses: 'table-anchor--shorttext',
            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Slug', classes: 'cw-10', isSearchable: true},
                {label: 'Type', classes: 'cw-15', isSearchable: true, isFilterable: true},
                {label: 'Live', classes: 'cw-4', isSearchable: false, isFilterable: true},
                {label: 'Version', classes: 'cw-8', isSearchable: false},
                {label: 'City', classes: 'cw-10', isSearchable: true, isFilterable: true},
                {label: 'State', classes: 'cw-5', isSearchable: true, isFilterable: true},
                {label: 'PDF File', classes: 'cw-12', isSearchable: true},
                {label: 'PDF Updated On', classes: 'cw-10', isSearchable: false, type: 'relative-date'},
                {label: 'YAML Updated On', classes: 'cw-10', isSearchable: false, type: 'relative-date'},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'PDF Forms')
        this.getForms()
    },
    methods: {
        getForms() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/pdf-forms/forms`).then(resp => {
                this.forms = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onUpdate(form) {
            const existing = this.forms.find(x => {return x.id == form.id})
            if (existing) {
                Object.assign(existing, form)
            }
        },
    },
}

</script>
