<template>
    <info-widget>
        <template slot="title">
            Direct Deposit Information
        </template>
        <template slot="headerbutton">
            <button v-if="accounts.length && !readonly && !invalidDirectDeposit" class="btn btn-outline-primary" type="button" @click.prevent="openEditAccountsModal">
                <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                Edit
            </button>
        </template>
        <slot>
            <div class="card-body py-2">
                <!-- Empty state -->
                <div v-if="!accounts.length">
                    <div class="row align-items-center mb-hf">
                        <div class="col-9">
                            <p class="mb-0">
                                <em>No payment accounts added.</em>
                            </p>
                        </div>
                        <div class="col-3 text-right">
                            <more-info class="mt-0">
                                Click "Add Account" if you wish to be paid using direct deposit. Otherwise, you will be paid by check.
                            </more-info>
                        </div>
                    </div>
                    <div class="card mb-1" v-if="!readonly && !invalidDirectDeposit">
                        <div class="card-body p-0 text-center">
                            <a class="d-block p-2" href @click.prevent="openAddAccountsModal()">
                                <i class="far fa-fw fa-plus mr-1"></i>
                                Add Account
                            </a>
                        </div>
                    </div>
                </div>

                <!-- Invalid state -->
                <alert-box v-if="invalidDirectDeposit && accounts.length >= 3" type="caution" class="mb-2">
                    Your direct deposit preferences are locked for editing because you have 3 or more bank accounts. To make them editable, contact your manager to remove bank accounts. Otherwise, your direct deposits will continue as normal.
                </alert-box>
                <alert-box v-if="invalidDirectDeposit && accounts.length <= 2" type="caution" class="mb-2">
                    Your direct deposit preferences are locked for editing because the deposit percentages do not add up to 100%. To make them editable, contact your manager to change deposit percentages for your bank accounts. Otherwise, your direct deposits will continue as normal.
                </alert-box>

                <!-- Populated state -->
                <template v-if="accounts.length">
                <div v-for="account in accounts" :key="account.id">
                    <p v-if="accounts.length == 1 && account.split_type == 'remainder'" class="mt-1 mb-1">
                        <strong>100% of each paycheck</strong> will be deposited into this account:
                    </p>
                    <p v-if="accounts.length > 1 && account.split_type == 'remainder'" class="mt-1 mb-1">
                        The <strong>remainder</strong> of each paycheck will be deposited into this account:
                    </p>
                    <p v-if="accounts.length > 1 && account.split_type == 'percentage' || account.split_type == 'fixed'" class="mt-1 mb-1">
                        A <strong>portion</strong> of each paycheck will be deposited into this account:
                    </p>
                    <direct-deposit-account-card
                        :account="account"
                        :numAccounts="accounts.length"
                        :approvalView="approvalView"
                        :invalidDirectDeposit="invalidDirectDeposit"
                        :url="url"
                    >
                    </direct-deposit-account-card>
                </div>
                </template>
            </div>
        </slot>

        <direct-deposit-modal
            :direct-deposit-accounts="directDepositAccounts"
            :mode="modalMode"
            v-if="isModalOpen"
            :url="url"
            :approvalView="approvalView"
            @updated="onAccountsUpdated($event)"
            @close="isModalOpen = false"
        />
    </info-widget>
</template>

<script>
import DirectDepositModal from '@/components/DirectDepositModal'
import DirectDepositAccountCard from '@/components/DirectDepositAccountCard'
import InfoWidget from '@/components/InfoWidget'

export default {
    components: {DirectDepositModal, DirectDepositAccountCard, InfoWidget, },
    props: ['directDepositAccounts', 'url', 'readonly', 'approvalView', 'hasInvalidDirectDeposit', ],
    computed: {
        accounts() {
            const accounts = this.directDepositAccounts.slice()

            return accounts.sort((a, b) => {
                if (a.split_type == 'remainder') {
                    return 1
                }
                if (b.split_type == 'remainder') {
                    return -1
                }
            })
        },
        invalidDirectDeposit() {
            // hasInvalidDirectDeposit should be true if accounts >= 3 but just in case
            return (this.hasInvalidDirectDeposit || (this.accounts && this.accounts.length >= 3))
        },
    },
    data() {
        return {
            isModalOpen: false,
            modalMode: '',
        }
    },
    methods: {
        openEditAccountsModal() {
            this.modalMode = 'edit'
            this.isModalOpen = true
        },
        openAddAccountsModal() {
            this.modalMode = 'add'
            this.isModalOpen = true
        },
        onAccountsUpdated() {
            this.$emit('updated')
        },
    },
}
</script>
