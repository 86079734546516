import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import jQuery from 'jquery'
import api from './api'
import moment from 'moment'
import * as Cookies from 'tiny-cookie'
import * as Sentry from "@sentry/browser"
import mixpanel from 'mixpanel-browser'

Vue.use(Vuex)

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    plugins: [createPersistedState({
        key: process.env.LOCAL_STORAGE_KEY,
        paths: ['session', 'session_expiry', 'user', 'isInApp'],
    })],
    state: {
        loading: false,
        pageTitle: '',
        session: null,
        session_expiry: null,
        user: null,
        isCloseable: true,
        isInApp: false,
        appVersionClient: null,
        appVersionServer: null,
        appVersionStaleSince: null,
        security_roles: {},
        system_status_messages: [],
        system_status_message_updated: null,
        system_status_flags: {},
        system_status_flags_updated: null,
    },
    actions: {
        SET_CLOSEABLE: ({commit}, isCloseable) => {
            commit('SET_CLOSEABLE', isCloseable)
        },
        SET_PAGE_TITLE: ({commit}, title) => {
            commit('SET_PAGE_TITLE', title)
        },
        CLOSE_LEFT_DRAWER: ({commit}) => {
            commit('CLOSE_LEFT_DRAWER')
        },
        OPEN_MAIN_MENU: ({commit}) => {
            commit('OPEN_MAIN_MENU')
        },
        CLOSE_MAIN_MENU: ({commit}) => {
            commit('CLOSE_MAIN_MENU')
        },
        START_LOADING: ({commit}) => {
            commit('START_LOADING')
        },
        STOP_LOADING: ({commit}) => {
            commit('STOP_LOADING')
        },
        SET_SESSION: ({commit}, sessionData) => {
            commit('SET_SESSION', sessionData)
        },
        SET_SESSION_EXPIRY: ({commit}, expiryDate) => {
            commit('SET_SESSION_EXPIRY', expiryDate)
        },
        SET_APP_VERSION: ({commit}, appVersion) => {
            commit('SET_APP_VERSION', appVersion)
        },
        SET_IS_IN_APP: ({commit}, in_app_status) => {
            commit('SET_IS_IN_APP', in_app_status)
        },
        LOGIN: ({commit, dispatch}, formData) => {
            dispatch('START_LOADING')
            return api.post('/login', formData).then((resp) => {
                commit('SET_SESSION', resp)
                dispatch('STOP_LOADING')
            }).catch((error) => {
                dispatch('STOP_LOADING')
                throw error
            })
        },
        LOGIN_VALIDATE_EMAIL: ({commit, dispatch}, formData) => {
            dispatch('START_LOADING')
            return api.post('/login/complete', formData).then((resp) => {
                commit('SET_SESSION', resp)
                return dispatch('GET_USER').then(() => {
                    dispatch('STOP_LOADING')
                }).catch(err => {
                    dispatch('STOP_LOADING')
                    throw err
                })
            }).catch((error) => {
                dispatch('STOP_LOADING')
                throw error
            })
        },
        LOGOUT: ({commit, dispatch, state}, instant) => {
            if (!state.session || instant) {
                commit('LOGOUT')
                return
            }

            dispatch('START_LOADING')
            api.post('/logout', {}).then(() => {
                dispatch('STOP_LOADING')
                commit('LOGOUT')
            }).catch((error) => {
                dispatch('STOP_LOADING')
                throw error
            })
        },
        GET_USER: ({commit}) => {
            return api.get('/me').then((resp) => {
                commit('SET_USER', resp)
            }).catch((error) => {
                throw error
            })
        },
        GET_SECURITY_ROLES: ({commit}) => {
            return api.get('/security-roles').then((resp) => {
                commit('SET_SECURITY_ROLES', resp)
            }).catch((error) => {
                throw error
            })
        },
        GET_SYSTEM_STATUS: ({commit}) => {
            return api.get('/system-status').then((resp) => {
                commit('SET_SYSTEM_STATUS', resp)
            }).catch((error) => {
                throw error
            })
        },
        GET_SYSTEM_FLAGS: ({commit}) => {
            return api.get('/system-flags').then((resp) => {
                commit('SET_SYSTEM_FLAGS', resp)
            }).catch((error) => {
                throw error
            })
        },
    },
    mutations: {
        SET_CLOSEABLE: (state, isCloseable) => {
            state.isCloseable = isCloseable
        },
        START_LOADING: (state) => {
            state.loading = true
        },
        STOP_LOADING: (state) => {
            state.loading = false
        },
        SET_PAGE_TITLE: (state, title) => {
            state.pageTitle = title
        },
        CLOSE_LEFT_DRAWER: () => {
            jQuery('#drawerLeft').removeClass('show')
        },
        OPEN_MAIN_MENU: () => {
            jQuery('#navbarDropdownMenu').addClass('show')
            jQuery('#navbarDropdown').addClass('show')
        },
        CLOSE_MAIN_MENU: () => {
            jQuery('#navbarDropdownMenu').removeClass('show')
            jQuery('#navbarDropdown').removeClass('show')
        },
        SET_SESSION: (state, session) => {
            state.session = Object.assign({}, session)
        },
        SET_SESSION_EXPIRY: (state, session_expiry) => {
            state.session_expiry = session_expiry
        },
        SET_APP_VERSION: (state, appVersion) => {
            // update the client version the first time, server every time
            if (! state.appVersionClient) {
                state.appVersionClient = appVersion
            }
            state.appVersionServer = appVersion
            // if the app version is stale, remember when it went stale
            if (state.appVersionClient != state.appVersionServer) {
                state.appVersionStaleSince = moment()
                console.log("App version detected stale at " + state.appVersionStaleSince)
            }
        },
        SET_IS_IN_APP: (state, in_app_status) => {
            state.isInApp = in_app_status
        },
        LOGOUT: (state) => {
            Object.keys(Cookies.getAll()).forEach((key) => {
                Cookies.remove(key)
            })
            state.session = null
            state.session_expiry = null
            state.user = null
            state.loading = false
            state.pageTitle = ''
            if (process.env.VUE_APP_RAVEN_JS_DSN) {
                Sentry.configureScope(scope => scope.setUser(null))
            }
            if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID) {
                try {
                    Vue.$gtag.event('logout')
                }
                catch (ex) {
                    Sentry.captureException(ex)
                }
            }
            if (process.env.VUE_APP_MIXPANEL_ENABLED) {
                try {
                    mixpanel.reset()
                }
                catch (ex) {
                    Sentry.captureException(ex)
                }
            }
        },
        SET_USER: (state, user) => {
            state.user = user
            if (user) {
                if (process.env.VUE_APP_RAVEN_JS_DSN) {
                    Sentry.setUser({email: user.email, id: user.id, full_name: user.full_name})
                }
                if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID) {
                    try {
                        Vue.$gtag.event('login')
                    }
                    catch (ex) {
                        Sentry.captureException(ex)
                    }
                }
                if (process.env.VUE_APP_MIXPANEL_ENABLED) {
                    try {
                        mixpanel.identify(user.id)
                    }
                    catch (ex) {
                        Sentry.captureException(ex)
                    }
                }
            }
        },
        SET_SECURITY_ROLES: (state, security_roles) => {
            state.security_roles = security_roles
        },
        SET_SYSTEM_STATUS: (state, system_status_resp) => {
            state.system_status_message_updated = system_status_resp['system_status_message_updated']
            // filter to user role
            let is_employee = Boolean(state.user && state.user.employees && state.user.employees.length)
            let is_employer = Boolean(state.user && state.user.security_profiles && state.user.security_profiles.length)
            let is_staff = Boolean(state.user && state.user.is_staff)
            let messages = []
            if (system_status_resp['system_status_messages']) {
                for (let msg of system_status_resp['system_status_messages']) {
                    if ((msg['show_to_employees'] && is_employee) || (msg['show_to_employers'] && is_employer) || is_staff) {
                        messages.push(msg)
                    }
                }
            }
            state.system_status_messages = messages
        },
        SET_SYSTEM_FLAGS: (state, system_flags_resp) => {
            state.system_flags_updated = system_flags_resp['system_flags_updated']
            state.system_flags = system_flags_resp['system_flags']
        }
    },
})
