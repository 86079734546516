<template>
    <modal @close="$emit('close')" ref="modal">
        <template slot="title">Change Nickname</template>
        <template slot="subheading">
            <div class="p-1 unpad-x bg-150 rounded mb-1">
                <span class="text-muted">Device:</span> <span class="text-nowrap">{{ device.default_label }}</span>
            </div>
        </template>

        <slot>
            <p>
                This is your name for this device so you can identify it in lists.
            </p>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <div class="form-row">
                    <div class="col-6">
                        <form-input v-model="formData.user_label" :errors="errors.user_label" label="Device Nickname" type="text" maxlength="25" :nocaps="true" />
                    </div>
                </div>

                <button-row>
                    <button type="button" class="btn btn-outline-primary" @click.prevent="close">
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">
                        Save
                    </button>
                </button-row>
            </form>

        </slot>
    </modal>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import ModalMixin from '@/mixins/ModalMixin'
import FormMixin from '@/mixins/Form'

export default {
    mixins: [ModalMixin, FormMixin],
    props: ['device'],
        data() {
        return {
            formRules: {
                user_label: {
                    presence: {allowEmpty: false},
                },
            },
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            return {
                user_label: this.device.user_label,
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.post(`/mobile-device/${this.device.id}/set-nickname`, this.formData).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showInfo("Device nickname has been updated.")
                this.$emit('updated', resp['mobile_devices'])
                this.close()
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__)
            })
        },
    },

}
</script>
