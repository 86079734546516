<template>
    <modal :autoshow="true" size="xl" :height-max="true" ref="modal" @close="$emit('close')" :is-closeable="isCloseable" :has-child-overlay="true">
        <template slot="title">
            Edit Security User:
            <span class="text-thin text-nowrap" v-if="mode == 'summary'">Manage Roles</span>
            <span class="text-thin text-nowrap" v-if="mode == 'role'">Security Role</span>
            <span class="text-thin text-nowrap" v-if="mode == 'companies'">Companies</span>
            <span class="text-thin text-nowrap" v-if="mode == 'positions'">Access</span>
            <span class="text-thin text-nowrap" v-if="mode == 'locations'">Access</span>
        </template>

        <template slot="subheading">
            <div v-if="mode != 'user-info'">
                <div class="">
                    <div class="p-1 unpad-x bg-150 rounded">
                        <div><span class="text-muted">User:</span> {{ securityProfile.first_name }} {{ securityProfile.last_name }}</div>
                    </div>
                </div>
            </div>
        </template>

        <slot>
            <div v-if="mode == 'role'">
                <role-selector v-if="currentCompanyId" :role-options="roleOptions" :my-role-options="myRoleOptions" v-model="formData.roles[currentCompanyId]" :errors="errors.role"/>
            </div>

            <div v-if="mode == 'positions'">
                <p>Select the positions that this user should have access to as a <strong>{{ currentRolePretty }}</strong>.</p>

                <position-selector
                    v-if="currentCompanyId"
                    :client="client"
                    :client-positions="companyPositions"
                    v-model="formData.positions[currentCompanyId]"
                    @filter-edit-open="$refs.modal.openModalChild"
                    @filter-edit-close="$refs.modal.closeModalChild"
                />
            </div>

            <div v-if="mode == 'locations'">
                <p>Select the locations that this user should have access to as a <strong>{{ currentRolePretty }}</strong>.</p>

                <location-selector
                    v-if="currentCompanyId"
                    :client="client"
                    :client-work-locations="companyWorkLocations"
                    :hide-company-filter="true"
                    v-model="formData.work_locations[currentCompanyId]"
                    @filter-edit-open="$refs.modal.openModalChild"
                    @filter-edit-close="$refs.modal.closeModalChild"
                />
            </div>

            <div v-if="mode == 'summary'" class="mb-1">
                <security-user-modal-summary
                    :company-roles="summaryCompanyRoles"
                    :show-edit-buttons="true"
                    @edit_positions="editPositions"
                    @edit_locations="editLocations"
                    @edit_role="editRole"
                    @remove_role="removeRole"
                />
            </div>

        </slot>

        <template slot="footer">
            <form-errors :errors="formErrors"/>

            <button-row stacked>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-primary" @click.prevent="onSubmit()">{{ mode == 'summary' ? 'Save &amp; Close' : 'Continue' }}</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import SecurityUserModalMixin from './SecurityUserModalMixin'
import FormMixin from '@/mixins/Form'

export default {
    props: ['client', 'securityProfile', ],
    mixins: [SecurityUserModalMixin],
    data() {
        return {
            mode: 'summary',
            currentCompanyId: null,

            formData: this.makeFormData(),
            clientPositions: [],
            formRules: {
                role: {presence: {allowEmpty: false}},
            },
        }
    },
    computed: {
        companyPositions() {
            return this.clientPositions.filter(p => p.company_id && p.company_id == this.currentCompanyId)
        },
        companyWorkLocations() {
            return this.clientWorkLocations.filter(w => w.company_id && w.company_id == this.currentCompanyId)
        }
    },
    methods: {
        makeFormData() {
            const data = {
                roles: {},
                positions: {},
                work_locations: {},
            }

            this.client.companies.forEach(c => {
                if (!this.canEditRole(c.id)) {
                    return
                }

                data.positions[c.id] = []
                data.work_locations[c.id] = []
                data.roles[c.id] = ''
            })

            this.securityProfile.company_security_profiles.forEach(csp => {
                if (!this.canEditRole(csp.company.id)) {
                    return
                }

                if (csp.is_active) {
                    data.roles[csp.company.id] = csp.role
                }

                csp.security_profile_positions.forEach(cpp => {
                    data.positions[csp.company.id].push(cpp.position.id)
                })

                csp.work_location_security_profiles.forEach(w => {
                    data.work_locations[csp.company.id].push(w.work_location.id)
                })
            })

            return data
        },
        getFormData() {
            const data = {
                roles: this.formData.roles,
                positions: this.formData.positions,
                work_locations: this.formData.work_locations,
            }

            /*
            {
                "roles": {"801": "location-manager"},
                "positions": {},
                "work_locations": {"801": [177, 377, 376]}
            }
            */
            return data
        },
        validate() {
            if (this.mode == 'role' && !this.formData.roles[this.currentCompanyId]) {
                return FormMixin.methods.validate.call(this, ['role'])
            }

            if (this.mode == 'positions' && this.formData.positions[this.currentCompanyId].length < 1) {
                this.formErrors.push('At least one position is required.')
                return false
            }

            if (this.mode == 'locations' && this.formData.work_locations[this.currentCompanyId].length < 1) {
                this.formErrors.push('At least one location is required.')
                return false
            }

            return true
        },
        onSubmit() {
            this.errors = []
            this.formErrors = []

            if (!this.validate()) {
                return
            }

            if (this.mode == 'role') {
                const role = this.formData.roles[this.currentCompanyId]
                if (this.$store.state.security_roles.COMPANY_LEVEL_ROLES.includes(role)) {
                    this.mode = 'summary'
                    this.formData.positions[this.currentCompanyId] = []
                    this.formData.work_locations[this.currentCompanyId] = []
                }
                else if (this.$store.state.security_roles.LOCATION_LEVEL_ROLES.includes(role)) {
                    this.mode = 'locations'
                    this.formData.positions[this.currentCompanyId] = []
                }
                else if (this.$store.state.security_roles.POSITION_LEVEL_ROLES.includes(role)) {
                    this.mode = 'positions'
                    this.formData.work_locations[this.currentCompanyId] = []
                }
                return
            }

            if (this.mode == 'positions' || this.mode == 'locations' || this.mode == 'companies') {
                this.mode = 'summary'
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.put(`/clients/${this.client.id}/security-profiles/${this.securityProfile.id}`, this.getFormData()).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.$bus.showError(errors.__all__)
                this.onError()
            })
        },
        removeRole(companyId) {
            this.formData.roles[companyId] = ''
            this.formData.positions[companyId] = []
            this.formData.work_locations[companyId] = []
        },
        editRole(companyId) {
            this.currentCompanyId = companyId
            this.mode = 'role'
        },
        editLocations(companyId) {
            this.currentCompanyId = companyId
            this.mode = 'locations'
        },
        editPositions(companyId) {
            this.currentCompanyId = companyId
            this.mode = 'positions'
        },
        onFilterOpen() {
            this.$refs.modal.openModalChild()
        },
    },
}
</script>

