<template>
    <modal @close="$emit('close')" ref="modal">
        <template slot="title">Import {{ importFileDescription }}</template>

        <p v-if="importFileType == 'company-addresses'">
            "Client Export" report from Payroll.
            <br><br>
            Required fields: Client Code, Company Code, Street 1, Street2, City, State, Zip Code
            <br><br>
            Keep the "Legal Address" header row, and note the spacing - it should match the Payroll report exactly.
        </p>

        <p v-else>
            Export first to edit template; do not change the column headings or add formatting.
        </p>

        <p v-if="importFileType == 'paygroups'">
            <b>PaySchedule</b> must be one of:
            <br>
            ['weekly', 'bi-weekly', 'monthly', 'semi-monthly', 'quarterly', 'annually']
        </p>

        <slot>
            <form-errors :errors="formErrors"/>

            <div class="form-row pb-2">
                <div class="col">
                    <form-input type="file" v-model="formData.pdf_file" :errors="errors.file" label="Upload spreadsheet:" helper-text="File extension must be .xlsx" accept=".xlsx" @upload="onUpload($event)" />
                </div>
            </div>
        </slot>

        <template slot="footer">
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Import</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [FormMixin, ModalMixin],
    props: ['client', 'importFileType'],
    data() {
        return {
            formData: {
                file: null,
            },
            formRules: {
                file: {presence: {allowEmpty: false}, },
            },
        }
    },
    computed: {
        importFileDescription() {
            return {
                'orgs': 'Orgs',
                'paygroups': 'Paygroups',
                'stores': 'Locations',
                'positions': 'Terminal Values',
                'company-addresses': 'Company Addresses',
            }[this.importFileType]
        }
    },
    methods: {
        onUpload(f) {
            this.formData.file = f[0]
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            let url = ''
            if (this.importFileType == 'company-addresses') {
                url = `/admin/companies/upload-addresses`
            } else {
                url = `/admin/clients/${this.client.id}/config/${this.importFileType}/import`
            }
            api.upload(url, this.formData.file, {fieldName: 'file'}).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showInfo(`Successfully imported ${this.importFileDescription}`)
                this.$emit('updated', resp)
                this.close()
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
    }
}
</script>
