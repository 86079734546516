<template>
    <modal size="xl" @close="$emit('close')" ref="modal" :is-closeable="isCloseable" :autoshow="true">
        <template slot="title">
            <span v-if="taxStatus">Update Tax Status: {{ taxStatus.tax.name }}</span>
            <span v-if="!taxStatus">Add New Tax Status</span>
        </template>
        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.tax" :errors="errors.tax" type="select" label="Tax" :options="taxOptions" :readonly="!!taxStatus"/>
                    </div>
                    <div class="col">
                        <form-input v-model="formData.calculation" :errors="errors.calculation" type="select" label="Calculation" :options="calculationOptions" />
                    </div>
                </div>
            </form>
        </slot>

        <template slot="footer">
            <form-errors :errors="formErrors"/>

            <button-row>
                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['employee', 'taxStatus'],
    mixins: [FormMixin, ModalMixin, ],
    computed: {
        isResidentTax() {
            if (!this.taxes || !this.formData.tax) {return false}
            return !!this.taxes.resident_taxes.find(t => (t.id == this.formData.tax && t.jurisdiction != 'fed'))
        },
        currentTax() {
            if (!this.taxes) {return null}
            let tax = this.taxes.work_taxes.find(t => this.formData.tax == t.id)
            if (tax) {return tax}
            tax = this.taxes.resident_taxes.find(t => this.formData.tax == t.id)
            return tax
        },
        calculationOptions() {
            // NB: this will be replaced/augmented from data attached to the Tax model, when available
            const result = [
                {text: 'Withheld', value: 'always'},
                {text: 'Exempt', value: 'never'},
            ]
            if (this.isResidentTax) {
                result.push({text: 'Credit', value: 'credit'})
            }
            return result
        },
        taxOptions() {
            const result = []
            if (!this.taxes) {return result}
            this.taxes.work_taxes.forEach(t => {
                result.push({text: t.name, value: t.id})
            })
            this.taxes.resident_taxes.forEach(t => {
                if (t.jurisdiction == 'fed') {
                    result.push({text: t.name, value: t.id})
                }
                else {
                    result.push({text: `${t.name} (Resident tax)`, value: t.id})
                }
            })

            //result = result.filter(o => {
            //    return !this.taxStatuses.find(ts => ts.tax_id == o.value)
            //})

            return result
        },
    },
    data() {
        return {
            formData: this.makeFormData(),
            taxes: null,
        }
    },
    mounted() {
        this.getTaxes()
    },
    methods: {
        makeFormData() {
            if (this.taxStatus) {
                return {
                }
            } else {
                return {
                    name: '',
                    starts_on: '',
                }
            }
        },
        getTaxes() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${this.employee.company.client_id}/employees/${this.employee.id}/applicable-taxes`).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.taxes = resp
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
                this.onError()
            })
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let promise
            if (this.deductionType) {
                promise = this.$api.put(`/clients/${this.employee.company.client_id}/employees/${this.employee.id}/tax-statuses/${this.taxStatus.id}`, this.getFormData())
            }
            else {
                promise = this.$api.post(`/clients/${this.employee.company.client_id}/employees/${this.employee.id}/tax-statuses`, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>
