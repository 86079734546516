<template>
    <div>
        <div class="border mb-4 px-1">
            <div class="form-row mt-4">
                <div class="col-12 d-flex flex-row">
                    <div class="flex-grow-1 mr-1">
                        <form-input v-model="formData.slug" type="select" label="Document Title" :errors="errors.slug" @input="onInput()" :opt-groups="documentOptGroups"/>
                    </div>
                    <more-info><slot name="document-title-help"></slot></more-info>
                </div>

                <div class="col-12 d-flex flex-row" v-if="!document">
                    <div class="flex-grow-1 mr-1">
                        <form-input value="" type="text" :disabled="true" label="Issuing Authority" :key="issuerKey" />
                    </div>
                    <more-info><slot name="issuing-authority-help"></slot></more-info>
                </div>

                <div class="col-12 d-flex flex-row" v-if="document && document.enter_issuer">
                    <div class="flex-grow-1 mr-1">
                        <form-input v-model="formData.issuer" type="text" label="Issuing Authority" :errors="errors.issuer" @input="onInput()" :key="issuerKey" />
                    </div>
                    <more-info><slot name="issuing-authority-help"></slot></more-info>
                </div>

                <div class="col-12 d-flex flex-row" v-if="document && !document.enter_issuer">
                    <div class="flex-grow-1 mr-1">
                        <form-input v-model="formData.issuer" type="select" label="Issuing Authority" :errors="errors.issuer" @input="onInput()" :options="issuerOptions" :key="issuerKey" />
                    </div>
                    <more-info><slot name="issuing-authority-help"></slot></more-info>
                </div>

            </div>

            <div class="form-row">
                <div class="col-12 d-flex flex-row">
                    <div class="flex-grow-1 mr-1">
                        <form-input type="text" :disabled="true" label="Document Number (if any)" v-if="!document" />
                        <form-input
                            v-model="formData.number"
                            :maxlength="(document.documentNumberInputOptions && document.documentNumberInputOptions.maxlength) ? document.documentNumberInputOptions.maxlength : ''"
                            :type="document.documentInputType || 'text'"
                            label="Document Number (if any)" :errors="errors.number"
                            @input="onInput()"
                            v-if="document && !document.number_is_na"
                        />
                        <form-input value="N/A" type="text" :disabled="true" label="Document Number (if any)" v-if="document && document.number_is_na" />
                    </div>
                    <more-info><slot name="document-number-help"></slot></more-info>
                </div>

                <div class="col-12 d-flex flex-row">
                    <div class="flex-grow-1 mr-1">
                        <form-input :disabled="true" type="text" label="Expiration Date (if any)" v-if="!document" />
                        <form-input v-model="formData.exp" type="text" placeholder="mm/dd/yyyy" label="Expiration Date (if any)" :errors="errors.exp" @input="onInput()" v-if="document" :maxlength="10" allowed-chars="0123456789/NAna"/>
                    </div>
                    <more-info><slot name="exp-help"></slot></more-info>
                </div>
            </div>
        </div>

        <i9-doc :documents="document.secondary_documents" v-if="document && document.secondary_documents" :errors="errors.secondaryDocument" v-model="formData.secondaryDocument" ref="i9SecondaryDocument" @input="onInput">
            <template slot="document-title-help">
                <p>If the employee presented a List A document that consists of a combination of documents, select the second document in that combination from the drop-down list provided. If you selected a receipt for a combination document in the first List A field, use the drop-down provided to indicate if the second document is a receipt.</p>

                <p>If the employee did not present a List A combination document that consists of a combination of documents, this field will contain N/A. If the employee presented a combination of documents to satisfy the List A requirement, but this field contains N/A, ensure that you made the correct selection in the first Document Title field.</p>
            </template>

            <template slot="issuing-authority-help">
                <p>Select from the drop-down list the issuing authority of the List A document or receipt you entered in the second Document Title field. The issuing authority is the entity that issued the document.</p>

                <p>If the employee did not present a List A document that consists of a combination of documents, this field will contain N/A. If the issuing authority in this field does not match the issuing authority shown on the document presented, ensure that you made the correct selection in the second Document Title field.</p>
            </template>

            <template slot="document-number-help">
                <p>Enter the document or receipt number, if any, exactly as it appears on the document or receipt you entered in the second Document Title field. If the document does not contain a number, enter N/A.</p>

                <p>If a combination of documents was not presented and N/A was entered in the Document Title #2 field, this field will also contain N/A.</p>
            </template>

            <template slot="exp-help">
                <p>Enter the expiration date, if any, of the document or receipt you entered in the second Document Title field. The document or receipt is not acceptable if it has already expired.</p>

                <p>If the document or receipt does not contain an expiration date, enter N/A.</p>

                <p>If a combination of documents was not presented and N/A was entered in the Document Title #2 field, this field will also contain N/A.</p>

                <p>If the document uses text rather than a date to indicate when it expires, enter the text shown on the document, such as &quot;D/S&quot; (which means, &quot;duration of status&quot;).</p>
            </template>
        </i9-doc>

        <i9-doc :documents="document.tertiary_documents" v-if="document && document.tertiary_documents" :errors="errors.tertiaryDocument" v-model="formData.tertiaryDocument" ref="i9TertiaryDocument" @input="onInput">
            <template slot="document-title-help">
                <p>If the employee presented a List A document that consists of a combination of documents, select the third document in that combination from the drop-down list provided. If the combination does not include a third document, this field will contain N/A/</p>

                <p>If the employee presented a combination of three documents to satisfy the List A requirement, but this field already contains N/A, ensure that you made the correct selection in the first and second Document Title fields.</p>
            </template>

            <template slot="issuing-authority-help">
                <p>If you selected Form I-20 or DS-2019 in the third Document Title field, the issuing authority shown in this field relates to the document you selected. The issuing authority is the agency that issued the document. If no document was entered in the third Document Title field, this field will contain N/A.</p>

                <p>If the issuing authority shown in the drop-down list does not match the issuing authority on the document presented, ensure that you made the correct selection in the third Document Title field.</p>
            </template>

            <template slot="document-number-help">
                <p>If you selected Form I-20 or DS-2019 in the third Document Title field, enter the SEVIS number exactly as it appears on the Form I-20 or DS-2019.</p>

                <p>If the employee is a J-1 student, the school's Responsible Officer must authorize employment in writing. Enter such document information in the Additional Information field. See I-9 Central for more information on J-1 students.</p>

                <p>If no document was entered in the third Document Title field, this field will contain N/A.</p>
            </template>

            <template slot="exp-help">
                <p>If you selected Form I-20 or DS-2019 in the third Document Title field, enter the <a target="_blank" href="https://www.uscis.gov/i-9-central/acceptable-documents/acceptable-documents">program end date</a> as indicated on the Form I-20 or DS-2019.</p>

                <p>If the document or receipt does not contain an expiration date, enter N/A.</p>
            </template>
        </i9-doc>
    </div>
</template>

<script>
import moment from 'moment'
import FormMixin from '@/mixins/Form'

export default {
    name: 'I9Doc',
    props: ['documents', 'immigrationStatus', 'value', 'dob', ],
    mixins: [FormMixin, ],
    computed: {
        document() {
            return this.documents.find(d => {
                return d.slug == this.formData.slug
            })
        },
        isAMinor() {
            return moment(this.dob, 'MM/DD/YYYY').isAfter(moment().subtract(18, 'year').subtract(1, 'day').startOf('day'))
        },
        documentOptGroups() {
            const validOptGroup = {
                options: [],
                label: 'Available Options',
            }
            const invalidOptGroup = {
                options: [],
                label: 'Unavailable Options',
            }
            for (let doc of this.documents) {
                if (!doc.slug) {
                    throw doc
                }
                if (!doc.immigration_statuses || (doc.immigration_statuses.indexOf(this.immigrationStatus) >= 0)) {
                    if (!this.isAMinor && doc.is_under_18) {
                        continue
                    }
                    validOptGroup.options.push({text: doc.name, value: doc.slug})
                }
            }

            for (let doc of this.documents) {
                if (!doc.immigration_statuses) {
                    continue
                }
                if (doc.immigration_statuses.indexOf(this.immigrationStatus) < 0) {
                    invalidOptGroup.options.push({text: doc.name, value: doc.slug, disabled: true})
                }
                else  if (!this.isAMinor && doc.is_under_18) {
                    invalidOptGroup.options.push({text: doc.name, value: doc.slug, disabled: true})
                }
            }
            const optGroups = []
            if (validOptGroup.options.length) {
                optGroups.push(validOptGroup)
            }

            if (invalidOptGroup.options.length) {
                optGroups.push(invalidOptGroup)
            }

            return optGroups
        },
        issuerOptions() {
            const options = []
            if (this.document) {
                for (let issuer of this.document.issuers) {
                    options.push({text: issuer, value: issuer})
                }
            }

            return options
        },
        issuerKey() {
            if (!this.document) {
                return 'issuer-key-blank'
            }

            if (this.document.enter_issuer) {
                return 'issuer-key-enter-' + this.document.slug
            }

            if (this.document.issuers.length == 1) {
                return 'issuer-key-single-' + this.document.slug
            }

            return 'issuer-key-' + this.document.slug
        },
    },
    data() {
        return {
            formLabels: {
                slug: 'Document Title',
                number: 'Document Number',
                exp: 'Expiration Date',
            },
            formRules: {
                slug: {presence: {allowEmpty: false}, },
                issuer: {presence: {allowEmpty: false}, },
                number: {presence: {allowEmpty: false}, },
                exp: {presence: {allowEmpty: false}, date: {specialAllowedValues: ['n/a', 'N/A', 'n/A', 'N/a', 'd/s', 'D/S', 'd/S', 'D/s'], earliest: moment()}},
            },
        }
    },
    watch: {
        'value'(newVal, oldVal) {
            if (oldVal && (oldVal.slug == newVal.slug)) {
                return
            }
            this.$set(this, 'formData', this.makeFormData())
            this.$nextTick(() => {
                this.onInput()
            })
        },
    },
    methods: {
        makeFormData() {
            const doc = this.documents.find(d => {
                return d.slug == this.formData.slug
            })
            const formData = {
                slug: this.value.slug,
                name: this.value.text,
                issuer: '',
                number: '',
                exp: '',
                secondaryDocument: {
                    slug: '',
                },
                tertiaryDocument: {
                    slug: '',
                },
            }

            if (doc) {
                if (doc.name) {
                    formData.name = doc.name
                }

                formData.exp = ''

                if (doc.number_is_na) {
                    formData.number = 'N/A'
                }
                else {
                    formData.number = ''
                }

                formData.issuer = ''
                if (!doc.enter_issuer) {
                    if (doc.issuers.length == 1) {
                        formData.issuer = doc.issuers[0]
                    }
                }
            }

            return formData
        },
        reset() {
            FormMixin.methods.reset.call(this)
            if (this.$refs.i9SecondaryDocument) {
                this.$refs.i9SecondaryDocument.reset()
            }
            if (this.$refs.i9TertiaryDocument) {
                this.$refs.i9TertiaryDocument.reset()
            }
        },
        getFormRules(fields) {
            const rules = FormMixin.methods.getFormRules.call(this, fields)
            if (this.document) {
                if (this.document.number_is_na) {
                    rules.number = {}
                } else {
                    if (this.document.documentNumberRules) {
                        rules.number = Object.assign({}, rules.number, this.document.documentNumberRules)
                    }
                }
            }
            return rules
        },
        onInput() {
            this.$emit('input', this.formData)
        },
        validate() {
            let isValid = FormMixin.methods.validate.call(this)

            if (this.$refs.i9SecondaryDocument) {
                isValid = this.$refs.i9SecondaryDocument.validate() && isValid
            }

            if (this.$refs.i9TertiaryDocument) {
                isValid = this.$refs.i9TertiaryDocument.validate() && isValid
            }

            return isValid
        },
    },
}
</script>
