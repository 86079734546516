<template>
    <modal @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable">
        <template slot="title">
            <h3 class="modal-title">Add HR Document:
                <span v-if="mode == 'doc-type'" class="text-thin text-nowrap">Document Information</span>
                <span v-if="mode == 'hr-doc'"class="text-thin text-nowrap">PDF Document</span>
                <span v-if="mode == 'hr-form-doc'" class="text-thin text-nowrap">Fillable Form</span>
            </h3>
        </template>

        <template slot="subheading">
            <div v-if="mode != 'doc-type'" class="mb-1">
                <div class="p-1 unpad-x bg-150 rounded">
                    <div><span class="text-muted">Company: </span>{{ companyAsText }}</div>
                    <div><span class="text-muted">Document Type: </span>
                        <span v-if="formData.type == 'hr-doc'">PDF</span>
                        <span v-if="formData.type == 'hr-form-doc'">FORM</span>
                    </div>
                </div>
            </div>
        </template>

        <slot>
            <p v-if="mode == 'doc-type'">Create a new HR Document. To use it for employee onboarding, add the document to an HR Template.</p>

            <form class="mui-form" @submit.prevent="onSubmit()">
                <form-errors :errors="formErrors"/>

                <template v-if="mode == 'doc-type'">
                    <div class="form-row">
                        <div class="col">
                            <form-input label="Company" v-model="formData.company" :errors="errors.company" type="select" :options="companySelectOptions"/>
                        </div>
                    </div>

                    <form-input type="radio" v-model="formData.type" :errors="errors.type" :options="typeOptions" />
                </template>

                <template v-if="mode == 'hr-doc' || mode == 'covid-policy'">
                    <div class="form-row">
                        <div class="col">
                            <form-input v-model="formData.name" :errors="errors.name" label="Document Name" type="text" />
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col">
                            <form-input @upload="onFileUpload($event)" :errors="errors.pdf_file" label="Upload your document:" helper-text="File extension must be .pdf" type="file" accept=".pdf"/>
                        </div>
                    </div>
                </template>

                <template v-if="mode == 'hr-form-doc'">
                    <div class="form-row">
                        <div class="col">
                            <form-input label="Form" v-model="formData.yaml_form" :errors="errors.yaml_form" type="select" :options="hrFormSelectOptions"/>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col">
                            <form-input v-model="formData.name" :errors="errors.name" label="Document Name" type="text" />
                        </div>
                    </div>
                </template>
            </form>
        </slot>

        <template slot="footer">
            <button-row v-if="mode == 'doc-type'">
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-primary" @click.prevent="onSubmit()">Continue</button>
            </button-row>
            <button-row v-if="mode == 'hr-doc' || mode == 'covid-policy' || mode == 'hr-form-doc'">
                <button type="button" class="btn btn-outline-primary" @click.prevent="back()">Back</button>
                <button type="button" class="btn btn-primary" @click.prevent="onSubmit()">Save Document</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['client'],
    mixins: [FormMixin, ModalMixin],
    computed: {
        companySelectOptions() {
            const options = [{
                text: 'All Companies',
                value: 'all',
            }]
            for (let company of (this.client.companies || [])) {
                options.push({text: company.name, value: company.id})
            }
            return options
        },
        typeOptions() {
            const fillableFormHelperText = `Choose a fillable form for onboarding employees to complete ${this.isFillableFormsDisabled ? '(contact your Payroll Specialist to enable this feature)' : ''}`

            return [
                {
                    text: 'PDF Document',
                    value: 'hr-doc',
                    helper_text: 'Upload a PDF for employees to sign'
                },
                // disabled pending COVID/OSHA feature
                // {
                //     text: 'COVID-19 Policy',
                //     value: 'covid-policy',
                //     helper_text: 'Upload the company COVID-19 policy for employees to sign'
                // },
                {
                    text: 'Fillable Form Document',
                    value: 'hr-form-doc',
                    helper_text: fillableFormHelperText,
                    disabled: this.isFillableFormsDisabled,
                },
            ]
        },
        companyAsText() {
            if (this.formData.company == 'all') {
                return 'ALL'
            }
            let company = (this.client.companies.find(company => company.id == this.formData.company))
            if (company) {
                return company.name || ''
            }
            return ''
        },
        hrFormSelectOptions() {
            const options = []
            for (let form of (this.hrFormOptions || [])) {
                options.push({text: form.name, value: form.id})
            }
            return options
        },
        isFillableFormsDisabled() {
            return !this.client.has_fillable_forms
        },
    },
    data() {
        return {
            mode: 'doc-type',
            formData: this.makeFormData(),
            formRules: {},
            docTypeRules: {
                company: {presence: {allowEmpty: false}, },
                type: {presence: {allowEmpty: false}, },
            },
            hrDocRules: {
                name: {presence: {allowEmpty: false}, },
                file: {presence: {allowEmpty: false}, },
            },
            hrFormDocRules: {
                name: {presence: {allowEmpty: false}, },
                yaml_form: {presence: {allowEmpty: false}, },
            },
            hrFormOptions: [],
        }
    },
    watch: {
        client() {
            this.reset()
        }
    },
    methods: {
        reset() {
            this.mode = 'doc-type'
            FormMixin.methods.reset.call(this)
        },
        getFormRules() {
            switch (this.mode) {
                case 'doc-type':
                    return this.docTypeRules;
                case 'covid-policy':
                case 'hr-doc':
                    return this.hrDocRules;
                case 'hr-form-doc':
                    return this.hrFormDocRules;
                default:
                    return {}
            }
        },
        makeFormData() {
            return {
                company: null,
                type: 'hr-doc',
                name: '',
                file: null,
                yaml_form: null,
            }
        },
        onFileUpload(f) {
            this.formData.file = f[0]
            this.formData.fileName = f[0].name
            this.formData.fileData = null
            const reader = new FileReader()

            reader.onload = () => {
                this.formData.fileData = btoa(reader.result)
            }

            reader.readAsBinaryString(f[0])
        },
        onSubmit() {

            if (!this.validate()) {
                return
            }

            if (this.mode == 'doc-type') {
                if (this.formData.type == 'hr-form-doc') {
                    this.$api.get(`/clients/${this.client.id}/companies/${this.formData.company}/hr-form-options`).then(resp => {
                        this.hrFormOptions = resp
                        this.mode = this.formData.type
                    }).catch(errors => {
                        this.$store.dispatch('STOP_LOADING')
                        this.errors = errors
                        this.formErrors = errors.__all__
                        this.onError()
                    })
                } else if (this.formData.type) {
                    this.mode = this.formData.type
                    if (this.mode == 'covid-policy') {
                        this.formData.name = 'COVID-19 POLICY'
                    }
                }
            } else if (this.mode == 'hr-doc' || this.mode == 'covid-policy' || this.mode == 'hr-form-doc') {
                this.$store.dispatch('START_LOADING')
                let promise

                if (this.mode == 'hr-doc' || this.mode == 'covid-policy') {
                    promise = this.$api.post(`/clients/${this.client.id}/hr-documents`, {
                        company: this.formData.company,
                        name: this.formData.name,
                        filename: this.formData.fileName,
                        pdf_file: this.formData.fileData,
                        designation: this.mode == 'covid-policy' ? 'covid-policy' : 'regular',
                    })
                } else if (this.mode == 'hr-form-doc') {
                    promise = this.$api.post(`/clients/${this.client.id}/hr-form-documents`, {
                        company: this.formData.company,
                        name: this.formData.name,
                        yaml_form: this.formData.yaml_form,
                    })
                }

                promise.then(resp => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$emit('paymasters_hr_documents_updated', resp)
                    this.close()
                }).catch(errors => {
                    this.$store.dispatch('STOP_LOADING')
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.onError()
                })
            }
        },
        back() {
            this.errors = []
            this.formErrors = []
            if (this.mode == 'doc-type') {
                this.close()
            } else if (this.mode == 'hr-doc' || this.mode == 'covid-policy' || this.mode == 'hr-form-doc') {
                this.mode = 'doc-type'
            }
        },
    }
}
</script>
