<template>
    <modal size="xl" @close="$emit('close')" ref="modal" :is-closeable="true" :autoshow="true" v-if="taxes">
        <template slot="title">Intertax Rule Tax Seletion Preview: <b>{{ intertaxRule.name }}</b></template>
        <slot>
            <table class="table" v-if="taxes && taxes.length">
                <thead>
                    <tr>
                        <th>Tax</th>
                        <th>Jurisdiction</th>
                        <th>Type</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="tax in taxes">
                        <td>{{ tax.name }}</td>
                        <td>{{ tax.jurisdiction.canonical_name }}</td>
                        <td>{{ tax.type_pretty }}</td>
                    </tr>
                </tbody>
            </table>

            <p v-else>This intertax rule does not match any taxes.</p>
        </slot>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['intertaxRule'],
    mixins: [ModalMixin, ],
    data() {
        return {
            taxes: null,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/tax-engine/taxes/${this.intertaxRule.tax_id}/intertax-rules/${this.intertaxRule.id}/matched-taxes`).then(resp => {
                this.taxes = resp.sort((a, b) => a.name > b.name ? 1 : -1)
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>
