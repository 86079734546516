<template>
    <modal @close="$emit('close')" ref="modal" :is-closeable="true" size="xl" :autoshow="true">
        <template #title>
            {{ dialogTitle }}
        </template>
        <slot>
            <div v-show="['tnc'].includes(mode)">
                <p>
                    Company: {{ company.name }}
                </p>

                <div v-if="eVerifyCaseDetails" class="bg-light p-2 mb-2">
                    <b>Case #:</b> {{ eVerifyCase.case_number }}
                    <br>
                    <b>Status:</b> {{ eVerifyCase.case_status_pretty }}
                    <br>
                    <b>Employee:</b> {{ eVerifyCase.emp_full_name_sortable }}
                    <br>
                    <b>Start Date:</b> {{ eVerifyCase.emp_start_date|date }}
                </div>

                <b>Next Steps</b>
                <br><br>

                <h4>Step 1</h4>

                <p>
                    You must download, print, and review the Further Action Notice with {{ eVerifyCase.emp_first_name }} privately. Make sure that {{ eVerifyCase.emp_first_name }} fully understands the Further Action Notice. This document will explain why {{ eVerifyCase.emp_first_name }} received this result, and what to do next.
                </p>

                <div class="mx-4 my-4 text-center">
                    <a @click="downloadFile(fanURLEnglish); didFANDownload = true;" class="btn btn-outline-primary mx-2">
                        Download Notice (English)
                    </a>
                    <a @click="downloadFile(fanURLSpanish); didFANDownload = true;" class="btn btn-outline-primary mx-2">
                        Download Notice (Spanish)
                    </a>
                </div>

                <div v-if="eVerifyCaseDetails">
                    <h4>Step 2</h4>

                    <p>
                        Ask if {{ eVerifyCase.emp_first_name }} will choose to take action to resolve this error and correct the data mismatch that led to this result.
                    </p>

                    <ul>
                        <li class="mb-2">
                            <b>If {{ eVerifyCase.emp_first_name }} chooses to take action to resolve this case, indicate that in the option below.</b> Make sure {{ eVerifyCase.emp_first_name }} understands that after you select this option, there will be a deadline of <b class="text-danger">eight federal government working days</b> starting today to take action. If an email was provided, {{ eVerifyCase.emp_first_name }} will receive a confirmation email that indicates when this countdown begins.
                        </li>

                        <li>
                            <b>If {{ eVerifyCase.emp_first_name }} chooses not to take action to resolve this case, indicate that in the option below.</b> You will receive a final result that indicates that we were unable to confirm employment eligibility. {{ eVerifyCase.emp_first_name }} needs to understand that refusing to take action could result in losing this job.
                        </li>
                    </ul>
                </div>

                <div class="bg-light p-2">
                    <b>After {{ eVerifyCase.emp_first_name }} has reviewed the Further Action Notice, indicate the decision below:</b>
                    <br><br>

                    <form-input v-model="formData.decision" type="radio" :options="decisionOptions" :errors="errors.decision" />
                </div>

            </div>

            <div v-show="['referred'].includes(mode)">
                <p>
                    Company: {{ company.name }}
                </p>

                <div v-if="eVerifyCaseDetails" class="bg-light p-2 mb-2">
                    <b>Case #:</b> {{ eVerifyCase.case_number }}
                    <br>
                    <b>Status:</b> {{ eVerifyCase.case_status_pretty }}
                    <br>
                    <b>Employee:</b> {{ eVerifyCase.emp_full_name_sortable }}
                    <br>
                    <b>Start Date:</b> {{ eVerifyCase.emp_start_date|date }}
                </div>

                <p>
                    You have referred {{ eVerifyCase.emp_full_name }} to DHS/SSA on {{ eVerifyCase.referred_on|datelong }}. To proceed, select a language and download the Referral Date Confirmation below. Provide this to the employee, who chose to take action on this mismatch.
                </p>

                <p>
                    {{ eVerifyCase.emp_first_name }} has until {{ referActionDueDate }} to contact DHS to resolve this issue.
                </p>

                <div class="my-4">
                    <a @click="downloadFile(referralDateConfirmationURLEnglish)" class="btn btn-outline-primary mx-2">
                        Download Referral Date Confirmation (English)
                    </a>
                    <br><br>
                    <a @click="downloadFile(referralDateConfirmationURLSpanish)" class="btn btn-outline-primary mx-2">
                        Download Referral Date Confirmation (Spanish)
                    </a>
                </div>

                <p>
                    You can also re-download the Further Action Notice if required.
                </p>

                <div class="my-4">
                    <a @click="downloadFile(fanURLEnglish)" class="btn btn-outline-primary mx-2">
                        Download Notice (English)
                    </a>
                    <br><br>
                    <a @click="downloadFile(fanURLSpanish)" class="btn btn-outline-primary mx-2">
                        Download Notice (Spanish)
                    </a>
                </div>

            </div>
        </slot>

        <template #footer>
            <div class="modal-footer">
                <div v-if="mode == 'tnc'">
                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="onSubmit('tnc')">
                            Save
                        </button>
                    </button-row>
                </div>

                <div v-if="mode == 'referred'">
                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                            Close
                        </button>
                    </button-row>
                </div>

                <div v-if="mode == 'view-case'">
                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                            Close
                        </button>
                    </button-row>
                </div>
            </div>
        </template>
    </modal>

<!-- /Modal: Select Documents -->
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import DownloadableMixin from '@/mixins/Downloadable'

export default {
    mixins: [FormMixin, ModalMixin, DownloadableMixin],
    props: ['client', 'company', 'employee', 'eVerifyCase'],
    mounted() {
        this.reset()
    },
    computed: {
        employeeObjectUrlPath() {
            return this.employee.is_employee ? 'employee' : 'onboarding-application'
        },
        dialogTitle() {
            switch (this.mode) {
                case 'tnc':
                    return "Action Required: Tentative Nonconfirmation (Mismatch)"
                case 'referred':
                    return "Confirmation: Employee intends to take action to resolve this case"
            }
        },
        fanURLEnglish() {
            return `${this.$api.API_BASE}/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCaseDetails.id}/fan?language=english`
        },
        fanURLSpanish() {
            return `${this.$api.API_BASE}/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCaseDetails.id}/fan?language=spanish`
        },
        referralDateConfirmationURLEnglish() {
            return `${this.$api.API_BASE}/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCaseDetails.id}/referral-date-confirmation?language=english`
        },
        referralDateConfirmationURLSpanish() {
            return `${this.$api.API_BASE}/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCaseDetails.id}/referral-date-confirmation?language=spanish`
        },
        referActionDueDate() {
            if (! this.eVerifyCaseDetails) {
                return moment().format('MMMM D YYYY')
            }

            let m = moment(this.eVerifyCaseDetails.tnc_refer_action_due_on)
            return m.format('MMMM D YYYY')
        },
        isFANDownloaded() {
            if (this.didFANDownload) {
                return true
            }

            if (! this.eVerifyCaseDetails) {
                return false
            }

            return this.eVerifyCaseDetails.fan_downloaded_on_first
        },
        decisionOptions() {
            return [
                {
                    html: `<b>${this.eVerifyCase.emp_first_name} will take action to resolve this E-Verify case.</b> ${this.eVerifyCase.emp_first_name} understands that action must be taken by <b class="text-danger">${this.referActionDueDate}.</b>` ,
                    value: 'refer',
                },
                {
                    html: `<b>${this.eVerifyCase.emp_first_name} will not take action to resolve this case.</b> ${this.eVerifyCase.emp_first_name} understands that this cannot be undone and choosing not to take action could result in termination of employment.`,
                    value: 'no_action',
                },
                {
                    html: `<b>The information entered was not correct.</b> I am choosing to close this case.` ,
                    value: 'close',
                },
            ]
        },
    },
    data() {
        return {
            formData: this.makeFormData(),
            eVerifyCaseDetails: null,
            formRules: {
                decision: {presence: {allowEmpty: false} }
            },
            errors: [],
            mode: 'tnc',
            didFANDownload: false,
        }
    },
    methods: {
        makeFormData() {
            return {
                decision: ''
            }
        },
        getInitialData() {
            this.$store.dispatch('START_LOADING')

            let url = `/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCase.id}`

            this.$api.get(url).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.isPristine = true
                // avoid overwriting prop
                this.$set(this, 'eVerifyCaseDetails', resp.case)

                if (resp.case.case_status == 'REFERRED') {
                    this.mode = 'referred'
                }

            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onSubmit(action) {
            const formData = {
                action: action,
                form_data: this.getFormData(),
            }

            if (!this.validate()) {
                return
            }

            // if the FAN isn't downloaded, they can't submit - unless they're just deciding to close
            if (! this.isFANDownloaded) {
                if (this.formData.decision != 'close') {
                    this.$bus.showError("You must download the Further Action Notice and share it with the employee first.")
                    return
                }
            }

            let url = `/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCase.id}`

            this.$store.dispatch('START_LOADING')
            this.$api.post(url, formData).then((resp) => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.isPristine = true
                // avoid overwriting prop
                this.$set(this, 'eVerifyCaseDetails', resp.case)

                if (resp.case.case_status == 'REFERRED') {
                    this.mode = 'referred'
                    return
                }

                this.$nextTick(() => {
                    this.close(true)
                    if (this.formData.decision == 'refer') {
                        this.$emit('everifyReferred', resp)
                    }
                })
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        reset() {
            FormMixin.methods.reset.call(this)
            this.$set(this, 'formData', this.makeFormData())
            this.getInitialData()

            this.mode = 'tnc'

            this.isPristine = true
            this.$store.dispatch('SET_CLOSEABLE', true)
        },
    }
}
</script>
