<template>
    <modal @close="$emit('close')" ref="modal" :is-closeable="true" size="xl" :autoshow="true">
        <template #title>
            <b>Action Required:</b> Close Case
        </template>
        <slot>
            <div v-show="['close-case'].includes(mode)" v-if="dataLoaded">
                <p>
                    Company: {{ company.name }}
                </p>

                <div v-if="eVerifyCaseDetails" class="bg-light p-2 mb-2">
                    <b>Case #:</b> {{ eVerifyCase.case_number }}
                    <br>
                    <b>Status:</b> {{ eVerifyCase.case_status_pretty }}
                    <br>
                    <b>Employee:</b> {{ eVerifyCase.emp_full_name_sortable }}
                    <br>
                    <b>Start Date:</b> {{ eVerifyCase.emp_start_date|date }}
                </div>

                <p v-if="eVerifyCase.case_status == 'CLOSE_CASE_AND_RESUBMIT'">
                    Something is incorrect in the data submitted. E-Verify needs you to close this case and re-enter {{ eVerifyCase.emp_full_name }}'s information in a new case.
                </p>

                <form-input v-model="formData.close_reason" type="select" label="Indicate why you are closing this case:" :options="closeReasonOptions" :errors="errors.close_reason" />

                <div v-if="formData.close_reason == 'OTHER_FREE_TEXT'">
                    <form-input v-model="formData.close_reason_other_detail" type="text" label="Please indicate why you are closing this case:" :errors="errors.close_reason_other_detail" />
                </div>

            </div>
        </slot>

        <template #footer>
            <div class="modal-footer">
                <div v-if="mode == 'close-case'">
                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="onSubmit('close-case')">
                            Close Case
                        </button>
                    </button-row>
                </div>
            </div>
        </template>
    </modal>

<!-- /Modal: Select Documents -->
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import DownloadableMixin from '@/mixins/Downloadable'

export default {
    mixins: [FormMixin, ModalMixin, DownloadableMixin],
    props: ['client', 'company', 'employee', 'eVerifyCase'],
    mounted() {
        this.reset()
    },
    computed: {
        employeeObjectUrlPath() {
            return this.employee.is_employee ? 'employee' : 'onboarding-application'
        },
        formRules() {
            let rules = {}

            rules['close_reason'] = {presence: {allowEmpty: false} }

            if (this.formData.close_reason == 'OTHER_FREE_TEXT') {
                rules['close_reason_other_detail'] = {presence: {allowEmpty: false} }
            }

            return rules
        },
    },
    data() {
        return {
            closeReasonOptions: [],
            formData: this.makeFormData(),
            eVerifyCaseDetails: null,
            errors: [],
            dataLoaded: false,
            mode: 'close-case',
        }
    },
    methods: {
        makeFormData() {
            return {
                close_reason: '',
                close_reason_other_detail: '',
            }
        },
        getInitialData() {
            this.$store.dispatch('START_LOADING')

            let url = `/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCase.id}`

            // include the API call to get valid close reasons
            url += '?closereasons=1'

            this.$api.get(url).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.isPristine = true
                this.dataLoaded = true
                // avoid overwriting prop
                this.$set(this, 'eVerifyCaseDetails', resp.case)
                this.$set(this, 'closeReasonOptions', resp.case_closure_reasons)

            }).catch(errors => {
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onSubmit(action) {
            const formData = {
                action: action,
                form_data: this.getFormData(),
            }

            if (!this.validate()) {
                return
            }

            let url = `/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCase.id}`

            this.$store.dispatch('START_LOADING')
            this.$api.post(url, formData).then((resp) => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.isPristine = true
                // avoid overwriting prop
                this.$set(this, 'eVerifyCaseDetails', resp.case)

                this.$nextTick(() => {
                    this.close(true)
                })
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        reset() {
            FormMixin.methods.reset.call(this)
            this.$set(this, 'formData', this.makeFormData())
            this.getInitialData()

            this.mode = 'close-case'

            this.isPristine = true
            this.$store.dispatch('SET_CLOSEABLE', true)
        },
    }
}
</script>
