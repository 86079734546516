<template>
    <modal @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable">
        <template slot="title">
            <h3 class="modal-title">Edit Document Name</h3>
        </template>

        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <div class="mb-2">
                    <div class="p-1 unpad-x bg-150 rounded">
                        <div><span class="text-muted">Company: </span>{{ document.company.name }}</div>
                        <div><span class="text-muted">Document Name: </span>{{ document.name }}</div>
                    </div>
                </div>

                <form-errors :errors="formErrors"/>

                <alert-box type="caution" v-if="document.has_been_completed" class="mt-1 mb-0">
                    You may not change the name of a document that has already been completed for at least one employee.
                </alert-box>

                <div class="form-row" v-if="!document.has_been_completed">
                    <div class="col">
                        <form-input v-model="formData.name" :errors="errors.name" label="Document Name" type="text" />
                    </div>
                </div>
            </form>
        </slot>

        <template slot="footer">
            <button-row stacked>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary" @click.prevent="onSubmit()" v-if="!document.has_been_completed">
                    Save
                </button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['client', 'document', 'documentType'],
    mixins: [FormMixin, ModalMixin],
    data() {
        return {
            formData: this.makeFormData(),
            formRules: {
                name: {presence: {allowEmpty: false}, },
            },
        }
    },
    watch: {
        client() {
            this.reset()
        }
    },
    methods: {
        makeFormData() {
            const data = {
                name: this.document.name,
                company: this.document.company.id,
            }

            return data
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            let promise

            if (this.documentType == 'hr-doc') {
                promise = this.$api.patch(`/clients/${this.client.id}/hr-documents/${this.document.id}`, this.getFormData())
            } else if (this.documentType == 'uniform') {
                promise = this.$api.patch(`/clients/${this.client.id}/uniforms/${this.document.id}`, this.getFormData())
            } else if (this.documentType == 'hr-custom-form') {
                promise = this.$api.patch(`/clients/${this.client.id}/hr-custom-forms/${this.document.id}`, this.getFormData())
            } else if (this.documentType == 'hr-form-doc') {
                promise = this.$api.patch(`/clients/${this.client.id}/hr-form-documents/${this.document.id}`, this.getFormData())
            }
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        }
    }
}
</script>
