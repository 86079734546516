<template>
    <div :class="[{'has-danger': errors && errors.length}, extraformgroupclasses, `form-input-${type}`]">
        <div v-if="isSelect" class="form-row">
            <div class="col select2-control">
                <label v-if="label.trim()" :for="randomId">{{ label }}</label>
                <span class="field-prefix field-prefix-text" v-if="prefix">{{ prefix }}</span>

                <select2 v-if="options" :id="randomId" :value="value" @change="onInput" :placeholder="noblank ? null : '--Select One--'" :multiple="multiple" :options="options" :disabled="disabled" :readonly="readonly" :showIcon="showIcon"></select2>
                <select2 v-if="optGroups" :id="randomId" :value="value" @change="onInput" :placeholder="noblank ? null : '--Select One--'" :multiple="multiple" :opt-groups="optGroups" :disabled="disabled" :readonly="readonly" :showIcon="showIcon"></select2>
            </div>
            <div v-if="popoverHelperHtml || $slots['popover-helper']" class="">
                <more-info><slot name="popover-helper"><div v-html="popoverHelperHtml"></div></slot></more-info>
            </div>
        </div>

        <div v-if="isTextarea" class="form-row">
            <div :class="containercoldivclasses || 'mui-textfield col'">
                <textarea v-if="isTextarea" :id="randomId" :class="[{'mui--is-invalid': errors && errors.length}, extraclasses]" :value="value" @input="onInput($event)" @focus="$emit('focus')" :rows="rows" :disabled="disabled" :readonly="readonly" :tabindex="tabindex"></textarea>
                <label v-if="label.trim()" :for="randomId">{{ label }}</label>
            </div>

            <div v-if="popoverHelperHtml || $slots['popover-helper']" class="">
                <more-info><slot name="popover-helper"><div v-html="popoverHelperHtml"></div></slot></more-info>
            </div>
        </div>

        <div v-if="isInput" class="form-row">
            <div :class="containercoldivclasses || `mui-textfield mui-textfield-flipped col ${deletable ? 'deletable' : ''}`">
                <label v-if="label.trim()" :for="randomId">{{ label }}</label>
                <span class="field-prefix field-prefix-text" v-if="prefix">{{ prefix }}</span>
                <input :id="randomId" :name="name" :type="inputType" :inputmode="computedInputMode" :size="size" :maxlength="maxlength" :placeholder="placeholder" :class="[{'mui--is-invalid': errors && errors.length}, extraclasses, type]" :value="value" @input="onInput($event)" @focus="$emit('focus')" :disabled="disabled" :readonly="readonly" :autocomplete="autocompleteValue" @keypress="checkAllowedChars" @paste="onPaste" :tabindex="tabindex" />

                <span v-if="this.type == 'password'" class="field-postfix" @click="togglePassword">
                    <i :class="[inputType === 'password'? 'fa-eye':'fa-eye-slash', 'fa', 'field-icon']"></i>
                </span>
                <a href v-if="deletable" class="field-postfix" @click.prevent="deleteInput">
                    <i class="far fa-trash-alt field-icon"></i>
                </a>
            </div>

            <div v-if="popoverHelperHtml || $slots['popover-helper']" class="">
                <more-info><slot name="popover-helper"><div v-html="popoverHelperHtml"></div></slot></more-info>
            </div>
        </div>

        <div v-if="isSearch" class="form-search" :class="{'has-input': value && value.trim()}">
            <input
                :id="randomId"
                type="search"
                :size="size"
                :maxlength="maxlength"
                :placeholder="placeholder"
                class="form-control form-control-search"
                :class="[{'mui--is-invalid': errors && errors.length}, extraclasses, type]"
                :value="value"
                @input="onInput($event)"
                :disabled="disabled"
                :readonly="readonly"
                :autocomplete="autocompleteValue"
                :tabindex="tabindex"
                inputmode="search"
            />
            <span class="form-search-icon"></span>
            <span class="form-search-clear" @click.prevent="$emit('input', '')">
                <button aria-label="Clear Search Terms"></button>
            </span>
        </div>

        <div v-if="isSSN" class="form-row">
            <div class="col mui-textfield mui-textfield-flipped input-ssn">
                <label v-if="label.trim()" :for="randomId">{{ label }}</label>
                <cleave :autocomplete="autocompleteValue" :disabled="disabled" :readonly="readonly" :placeholder="placeholder" :size="11" maxlength="11" :id="randomId" :value="value" @input="onInput($event)" :options="cleaveSSNOptions" :tabindex="tabindex" inputmode="tel"/>
            </div>

            <div v-if="popoverHelperHtml || $slots['popover-helper']" class="">
                <more-info><slot name="popover-helper"><div v-html="popoverHelperHtml"></div></slot></more-info>
            </div>
        </div>

        <div v-if="isCheckbox" class="form-check">
            <label class="form-check-label">
                <input v-if="isCheckbox" :id="randomId" :type="type" class="form-check-input" :class="[{'mui--is-invalid': errors && errors.length}, extraclasses]" :checked="value" @change="onInput($event)" :disabled="disabled || readonly" :readonly="readonly" :tabindex="tabindex"/>
                {{ label }}
            </label>
            <div class="field-helper-text mb-0 mt-0" v-if="helperText">{{ helperText }}</div>
            <div class="field-helper-text mb-0 mt-0" v-if="helperHtml" v-html="helperHtml"></div>
        </div>

        <div v-if="isFile">
            <label v-if="label.trim() || labelHtml.trim()" :for="randomId">{{ label }}<span v-if="labelHtml" v-html="labelHtml">{{ labelHtml }}</span></label>
            <div class="custom-file" :class="[{'mui--is-invalid': errors && errors.length}, extraclasses]">
                <input :id="randomId" type="file" class="custom-file-input" @change="onInput($event)" @input="onInput($event)" :accept="accept" :disabled="disabled" :multiple="multiple" ref="fileInput" :tabindex="tabindex">
                <label class="custom-file-label" :for="randomId" :data-button_text="fileButtonText" :data-input_text="fileInputText">{{ fileInputText }}</label>
            </div>
            <div class="field-helper-text mb-0 pt-hf" v-if="helperText">{{ helperText }}</div>
        </div>

        <div v-if="isDate" class="form-row">
            <div :class="containercoldivclasses || 'mui-textfield mui-textfield-flipped col'">
                <label v-if="label.trim()" :for="randomId">{{ label }}</label>
                <cleave :autocomplete="autocompleteValue" :disabled="disabled" :readonly="readonly" :placeholder="placeholder || 'MM/DD/YYYY'" :size="size" :id="randomId" :value="dateValue" @input="onInput($event)" :options="cleaveDateInputOptions" :class="[{'mui--is-invalid': errors && errors.length}, extraclasses]" :tabindex="tabindex" inputmode="tel" />
            </div>

            <div v-if="popoverHelperHtml || $slots['popover-helper']" class="">
                <more-info><slot name="popover-helper"><div v-html="popoverHelperHtml"></div></slot></more-info>
            </div>
        </div>

        <div v-if="isDatepicker" class="form-row">
            <div :class="containercoldivclasses || 'mui-textfield mui-textfield-flipped col'">
                <label v-if="label.trim()" :for="randomId">{{ label }}</label>
                <input type="date" :autocomplete="autocompleteValue" :disabled="disabled" :readonly="readonly" :size="size" :id="randomId" ref="datepickerInput" :value="value" @input="onInput($event)" :class="['datepicker-control', {'mui--is-invalid': errors && errors.length}, extraclasses]" :tabindex="tabindex" />
            </div>

            <div v-if="popoverHelperHtml || $slots['popover-helper']" class="">
                <more-info><slot name="popover-helper"><div v-html="popoverHelperHtml"></div></slot></more-info>
            </div>
        </div>

        <div v-if="isCurrency" class="form-row">
            <div :class="containercoldivclasses || 'mui-textfield mui-textfield-flipped col'">
                <label v-if="label.trim()" :for="randomId">{{ label }}</label>
                <span class="field-prefix field-prefix-text">$</span>
                <cleave :autocomplete="autocompleteValue" :disabled="disabled" :readonly="readonly" :placeholder="placeholder" :size="size" :id="randomId" :value="value" @input="onInput($event)" :options="cleaveCurrencyInputOptions" :maxlength="maxlength" :class="[{'mui--is-invalid': errors && errors.length}, extraclasses]" :tabindex="tabindex" inputmode="decimal" />
            </div>

            <div v-if="popoverHelperHtml || $slots['popover-helper']" class="">
                <more-info><slot name="popover-helper"><div v-html="popoverHelperHtml"></div></slot></more-info>
            </div>
        </div>

        <div v-if="isDecimal" class="form-row">
            <div :class="containercoldivclasses || 'mui-textfield mui-textfield-flipped col'">
                <label v-if="label.trim()" :for="randomId">{{ label }}</label>
                <cleave :autocomplete="autocompleteValue" :disabled="disabled" :readonly="readonly" :placeholder="placeholder" :size="size" :id="randomId" :value="value" @input="onInput($event)" :options="cleaveDecimalInputOptions" :class="[{'mui--is-invalid': errors && errors.length}, extraclasses]" :tabindex="tabindex" inputmode="decimal" />
            </div>

            <div v-if="popoverHelperHtml || $slots['popover-helper']" class="">
                <more-info><slot name="popover-helper"><div v-html="popoverHelperHtml"></div></slot></more-info>
            </div>
        </div>

        <div v-if="isInteger" class="form-row">
            <div :class="containercoldivclasses || 'mui-textfield mui-textfield-flipped col'">
                <label v-if="label.trim()" :for="randomId">{{ label }}</label>
                <cleave :autocomplete="autocompleteValue" :disabled="disabled" :readonly="readonly" :placeholder="placeholder" :size="size" :id="randomId" :value="value" @input="onInput($event)" :options="cleaveIntegerInputOptions" :maxlength="maxlength" :class="[{'mui--is-invalid': errors && errors.length}, extraclasses]" :tabindex="tabindex" inputmode="tel" />
            </div>

            <div v-if="popoverHelperHtml || $slots['popover-helper']" class="">
                <more-info><slot name="popover-helper"><div v-html="popoverHelperHtml"></div></slot></more-info>
            </div>
        </div>

        <div v-if="isIntegerString" class="form-row">
            <div :class="containercoldivclasses || 'mui-textfield mui-textfield-flipped col'">
                <label v-if="label.trim()" :for="randomId">{{ label }}</label>
                <cleave :autocomplete="autocompleteValue" :disabled="disabled" :readonly="readonly" :placeholder="placeholder" :size="size" :id="randomId" :value="value" @input="onInput($event)" :options="cleaveIntegerStringInputOptions" :maxlength="maxlength" :class="[{'mui--is-invalid': errors && errors.length}, extraclasses]" :tabindex="tabindex" inputmode="tel"/>
            </div>

            <div v-if="popoverHelperHtml || $slots['popover-helper']" class="">
                <more-info><slot name="popover-helper"><div v-html="popoverHelperHtml"></div></slot></more-info>
            </div>
        </div>

        <div v-if="isPhone" class="form-row">
            <div :class="containercoldivclasses || 'mui-textfield mui-textfield-flipped col'">
                <label v-if="label.trim()" :for="randomId">{{ label }}</label>
                <cleave :autocomplete="autocompleteValue" :disabled="disabled" :readonly="readonly" :placeholder="placeholder" :size="size" :id="randomId" inputmode="tel" :value="value" @input="onInput($event)" :options="cleavePhoneInputOptions" :maxlength="maxlength" :class="[{'mui--is-invalid': errors && errors.length}, extraclasses]" :tabindex="tabindex" />
            </div>

            <div v-if="popoverHelperHtml || $slots['popover-helper']" class="">
                <more-info><slot name="popover-helper"><div v-html="popoverHelperHtml"></div></slot></more-info>
            </div>
        </div>

        <div v-if="isRadio" class="mb-2 form-check-set" :class="[{'form-check-set--is-invalid': errors && errors.length}, extraclasses]">
            <div class="field-helper-text text-danger mt-0 mb-1" v-if="errors && errors.length">
                <i aria-hidden="true" title="Error" class="fas fa-fw fa-exclamation-circle"></i>
                <span class="sr-only">Error:</span>
                <span> {{ errors[0] }}</span>
            </div>

            <label :for="randomId" v-if="label">{{ label }}</label>
            <small v-if="helperText">{{ helperText }}</small>

            <ul :id="randomId" class="list-unstyled m-0 pl-2">
                <li v-for="option in options" :key="option.value" class="option form-check mb-hf">
                    <div class="form-row">
                        <div class="col">
                            <label>
                                <input :name="randomId + '-radioset'" type="radio" :value="option.value" @click="onInput($event)" :checked="value == option.value" class="form-check-input" :disabled="disabled || option.disabled" :readonly="readonly">
                                <span v-if="option.html" v-html="option.html"></span>
                                <span v-if="option.text">
                                    {{ option.text }}
                                </span>
                            </label>
                        </div>
                        <div v-if="option.popover_helper_text">
                          <more-info>
                              {{ option.popover_helper_text }}
                          </more-info>
                        </div>
                    </div>
                    <div class="mt-0 field-helper-text" v-if="option.helper_text">
                        <small>{{ option.helper_text }}</small>
                    </div>
                </li>
            </ul>
        </div>

        <div class="field-helper-text" v-if="helperText && !isRadio && !isCheckbox && !isFile">{{ helperText }}</div>
        <div class="field-helper-text" v-if="helperHtml && !isRadio && !isCheckbox && !isFile" v-html="helperHtml"></div>
        <div v-if="errors && errors.length && !isRadio" class="field-helper-text">
            <span class="text-danger" v-if="errors && errors.length">
                <i aria-hidden="true" title="Error" class="fas fa-fw fa-exclamation-circle"></i>
                <span class="sr-only">Error:</span>
                <span> {{ errors[errors.length-1]}}</span>
            </span>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import Select2 from './Select2'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us.js'

export default {
    components: {Cleave, Select2, },
    props: {
        'label': {type: String, default: ''},
        'labelHtml': {type: String, default: ''},
        'value': {required: false},
        'name': {required: false, default: ''},
        'errors': {required: false},
        'type': {required: true},
        'size': {required: false},
        'maxlength': {required: false},
        'options': {required: false},
        'optGroups': {required: false},
        'rows': {required: false},
        'accept': {required: false},
        'extraclasses': {required: false, type: String},
        'extraformgroupclasses': {required: false},
        'containercoldivclasses': {required: false},
        'placeholder': {required: false},
        'noblank': {required: false},
        'prefix': {required: false},
        'disabled': {required: false, default: false},
        'readonly': {required: false, default: false},
        'helperText': {required: false},
        'helperHtml': {required: false},
        'popoverHelperHtml': {required: false},
        'multiple': {required: false, default: false},
        'allowNegative': {required: false, default: false},
        'nocaps': {required: false, default: false},
        'allowedChars': {required: false, default: null},
        'autocomplete': {required: false, default: null},
        'tabindex': {required: false, default: null},
        'inputmode': {required: false, default: ''},
        'deletable': {required: false, default: false},
        'showIcon': {required: false, default: false},
    },
    data() {
        let dateValue = ''
        if (this.type == 'date' && this.value) {
            dateValue = moment(this.value).format("MM/DD/YYYY")
        }

        return {
            randomId: 'input-' + ('' + Math.random()).substring(2),
            fileButtonText: 'Browse',
            fileInputTextDefault: 'Choose file',
            fileInputText: 'Choose file',
            cleaveDateInputOptions: {
                date: true,
                datePattern: ['m', 'd', 'Y'],
            },
            cleaveCurrencyInputOptions: {
                numeral: true,
                numeralPositiveOnly: !this.allowNegative,
                numeralDecimalScale: 2,
            },
            cleaveDecimalInputOptions: {
                numeral: true,
                numeralPositiveOnly: !this.allowNegative,
                numeralDecimalScale: 2,
            },
            cleaveIntegerInputOptions: {
                numeral: true,
                numeralPositiveOnly: !this.allowNegative,
                numeralDecimalScale: 0,
            },
            cleaveIntegerStringInputOptions: {
                numeral: true,
                numeralPositiveOnly: !this.allowNegative,
                numeralDecimalScale: 0,
                delimiter: '',
                stripLeadingZeroes: false,
            },
            cleavePhoneInputOptions: {
                phone: true,
                phoneRegionCode: 'US',
                delimiter: '-',
            },
            cleaveSSNOptions: {
                numericOnly: true,
                delimiter: '-',
                blocks: [3, 2, 4],
            },
            dateValue: dateValue,
            selectSettings: {
            },
            passwordFieldType: 'password',
            datepickerSupported: true
        }
    },
    computed: {
        isInput() {return [
            'color',
            'datetime',
            'datetime-local',
            'email',
            'hidden',
            'image',
            'month',
            'number',
            'password',
            'range',
            'text',
            'time',
            'url',
            'week',
        ].indexOf(this.type) >= 0},
        isSearch() {return this.type == 'search'},
        isSSN() {return this.type == 'ssn'},
        isFile() {return this.type == 'file'},
        isSelect() {return this.type == 'select'},
        isRadio() {return this.type == 'radio'},
        isCheckbox() {return this.type == 'checkbox'},
        isTextarea() {return this.type == 'textarea'},
        isDate() {return this.type == 'date' || (this.type == 'datepicker' && !this.datepickerSupported)}, // fall back to date if datepicker is not supported (notably MacOS Safari < 14.1)
        isDatepicker() {return this.type == 'datepicker' && this.datepickerSupported},
        isCurrency() {return this.type == 'currency'},
        isDecimal() {return this.type == 'decimal'},
        isInteger() {return this.type == 'integer'},
        isIntegerString() {return this.type == 'integer-string'},
        isPhone() {return this.type == 'phone' || this.type == 'tel'},
        inputType() {
            if (this.type === 'password') {
                return this.passwordFieldType
            }
            return this.type
        },
        autocompleteValue() {
            return 'adsfasdfasf324' // Nonsense value to prevent the browser from attempting to autocomplete this.
            //return this.autocomplete ? this.autocomplete : 'adsfasdfasf324'
        },
        computedInputMode() {
            if (this.inputmode) {
                return this.inputmode
            }

            if (this.type == 'search') {return 'search'}
            if (this.type == 'email') {return 'email'}
            if (this.type == 'ssn') {return 'tel'}
            if (this.type == 'number') {return 'decimal'}
            if (this.type == 'decimal') {return 'decimal'}
            if (this.type == 'currency') {return 'decimal'}
            if (this.type == 'integer') {return 'tel'}
            if (this.type == 'integer-string') {return 'tel'}
            if (this.type == 'tel') {return 'tel'}
            if (this.type == 'phone') {return 'tel'}
            if (this.type == 'url') {return 'url'}
            if (this.type == 'date') {return 'tel'}

            return 'text'
        },
    },
    mounted() {
        if (this.isDatepicker) {
            let datepickerInput = this.$refs.datepickerInput
            if (datepickerInput && datepickerInput.type !== 'date') {
                this.datepickerSupported = false
            }
        }
    },
    watch: {
        value: {
            handler() {
                if (this.isFile) {
                    if (!this.value) {
                        if (this.$refs.fileInput) {
                            this.$refs.fileInput.value = null  // Actually clear the file input
                        }
                        this.fileInputText = this.fileInputTextDefault
                    }
                }

                if (this.isDate) {
                    if (this.value) {
                        if (this.value && /^\d{1,2}\/\d{1,2}\/\d{4}$/.exec(this.value)) {
                            this.$set(this, 'dateValue', moment(this.value, 'MM/DD/YYYY').format("MM/DD/YYYY"))
                        }
                        else {
                            this.$set(this, 'dateValue', moment(this.value).format("MM/DD/YYYY"))
                        }
                    } else if (this.value !== null) {
                        this.$set(this, 'dateValue', '')
                    }
                }
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        onInput(evt) {
            if (this.isFile) {
                const files = evt.target.files || evt.dataTransfer.files
                if (!files.length) {
                    return true
                }

                if (files.length == 1) {
                    this.fileInputText = files[0].name
                } else {
                    this.fileInputText = files.length + " files selected"
                }

                this.$emit('upload', files)
                return true
            }

            if (this.isCheckbox) {
                this.$emit('input', evt.target.checked)
                return true
            }

            if (this.isDate) {
                if (!evt.length) {
                    this.$emit('input', '')
                    return true
                }
                if (evt.length < 8) {
                    this.$emit('input', null)
                    return true
                }
                evt = evt.substring(4, 8) + '-' + evt.substring(0, 2) + '-' + evt.substring(2, 4)
                this.$emit('input', moment(evt).format("MM/DD/YYYY"))
                return true
            }

            if (this.isCurrency || this.isInteger || this.isIntegerString || this.isDecimal || this.isPhone) {
                this.$emit('input', evt)
                return true
            }

            if (this.isSSN) {
                this.$emit('input', evt)
                return true
            }

            if (this.isSelect) {
                this.$emit('input', evt)
                return true
            }

            if (this.isInput && this.type == 'text' && !this.nocaps) {
                this.$emit('input', evt.target.value.toUpperCase())
                return true
            }

            this.$emit('input', evt.target.value)
            return true
        },
        checkAllowedChars($evt) {
            if (!this.allowedChars) {
                return true
            }

            const c = String.fromCharCode(($evt.which) ? $evt.which : $evt.keyCode)
            if (this.allowedChars.indexOf(c) < 0) {
                $evt.preventDefault()
            }
            return true
        },
        onPaste($evt) {
            $evt.stopPropagation()
            $evt.preventDefault()

            const clipboardData = $evt.clipboardData || window.clipboardData
            let value = clipboardData.getData('Text')

            if (this.allowedChars) {
                value = value.split('').filter(c => (this.allowedChars.indexOf(c) >= 0)).join('')
            }

            if (this.maxlength) {
                value = value.substring(0, this.maxlength)
            }

            this.$emit('input', value)
        },
        togglePassword: function () {
            // toggle password field's visibility
            this.passwordFieldType = (this.passwordFieldType === 'password') ? 'text' : 'password'
        },
        deleteInput: function () {
            // delete the input (logic handled by parent)
            this.$emit('delete')
        },
    },
}
</script>

<style scoped>
    .custom-file {
        display: block;
    }
    .custom-file-control:before {
        content: attr(data-button_text);
    }

    .custom-file-control:after {
        content: attr(data-input_text);
    }

    .form-input-file .field-helper-text {
        margin-top: 0;
    }

    .mui-textfield.deletable > input {
        padding-right: 24px;
    }
</style>
