<template>
    <modal @close="$emit('close')" ref="modal">
        <template slot="title">New Hire Sync (to POS) Options</template>

        <slot>
            <form-errors :errors="formErrors"/>

            <div class="form-row pb-2">
                <div class="col">
                    <form-input label="New Hire Sync to POS Enabled" v-model="formData.pos_new_hire_sync" :errors="errors.pos_new_hire_sync" type="checkbox" />
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.pos_new_hire_sync">
                <div class="col">
                    <form-input label="Default position (must exist in POS)" v-model="formData.pos_new_hire_default_position" :errors="errors.pos_new_hire_default_position" :nocaps="true" type="text">
                        <template #popover-helper>
                            <p>
                                For CaesarVision or Altametrics, default position to put new hires into (typically <b>Kitchen</b>, <b>Crew</b>, etc.)
                            </p>
                            <p>
                                Note: must be a valid position in CaesarVision/Altametrics for this client.
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

        </slot>

        <template slot="footer">
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [FormMixin, ModalMixin],
    props: ['client', 'integration', ],
    data() {
        return {
            dataLoaded: false,
            formData: this.makeFormData(),
        }
    },
    mounted() {
        
    },
    computed: {
        formRules() {
            let rules = {}

            if (this.formData.pos_new_hire_sync) {
                rules['pos_new_hire_default_position'] = {presence: {allowEmpty: false}, }
            }

            return rules
        },
    },
    methods: {
        makeFormData() {
            let fields = [
                'pos_new_hire_sync', 'pos_new_hire_default_position',
            ]
            let formData = {}

            fields.forEach(f => {
                formData[f] = this.integration[f]
            })
            return formData
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            let url = `/admin/clients/${this.client.id}/pos-integrations/${this.integration.id}/new-hire-pos-sync`
            api.put(url, this.formData).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
    }
}
</script>
