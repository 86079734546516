<template>
    <div>
        <div>
            <div class="mb-2">
                <a href @click.prevent="close"><i class="far fa-fw fa-chevron-left mr-hf"></i>Back to HR Docs</a>
            </div>

            <!-- <h2 class="text-thin text-muted mb-2">Documents</h2> -->
            <p>Review the {{ totalDocumentCount }} {{ totalDocumentCount == 1 ? 'document' : 'documents' }} below.</p>

            <div class="card doc-item mb-2" v-for="doc_task in doctasks" :key="doc_task.id">
                <div class="card-body">
                    <div class="todo-item" :class="{'todo-item-completed': doc_task.approved_on}">
                        <div class="todo-item-content">
                            <h6 class="doc-title mb-2">{{ doc_task.document_name }}</h6>
                            <div class="doc-action-primary mb-1">
                                <a v-if="doc_task.approved_on" href class="btn btn-outline-primary" @click.prevent="setCurrentPDF(doc_task)"><i class="far fa-fw fa-external-link mr-1"></i>View Document</a>
                                <a v-else href class="btn btn-outline-primary" @click.prevent="setCurrentPDF(doc_task)"><i class="far fa-fw fa-external-link mr-1"></i>Review Document</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <pdf-modal :url="currentPDF.url" :title="currentPDF.title" v-if="currentPDF" @close="setCurrentPDF(null)" ref="pdfModal">
                <template slot="footer" v-if="currentDocTask && !currentDocTask.approved">
                    <button-row stacked>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="rejectDocument(true)">
                            Reject and Cancel
                        </button>
                        <button v-if="currentDocTask.task_type == 'custom-form-completion'" type="button" class="btn btn-outline-primary" @click.prevent="rejectDocument()">
                            Reject and Re-Send
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="signDocument()">
                            Approve
                        </button>
                    </button-row>
                </template>
            </pdf-modal>
        </div>
    </div>
</template>

<script>
import ButtonRow from '@/components/ButtonRow'
import PdfModal from '@/components/PdfModal'
import moment from 'moment'

export default {
    components: {ButtonRow, PdfModal},
    props: {doctasks: {required: true}, employee: {required: true}, client: {required: true}},
    computed: {
        API_BASE() {
            return this.$api.API_BASE
        },
        allFormsApproved() {
            const unapprovedDocs = this.doctasks.filter((f) => {
                return !f.approved_on
            }).length

            return unapprovedDocs
        },
        totalDocumentCount() {
            return this.doctasks.length
        },
    },
    data() {
        return {
            mode: '',
            formErrors: [],
            currentPDF: null,
            currentHRDocument: null,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        onAppUpdated() {
            // this.$store.dispatch('START_LOADING')
            // return this.$api.get(`/clients/${this.client.id}/onboarding-applications/${this.onboardingApplication.id}`).then((resp) => {
            //     this.$emit('updated', resp)
            //     this.$store.dispatch('STOP_LOADING')
            // }).catch((errors) => {
            //     this.$bus.showError(errors.__all__)
            //     this.$store.dispatch('STOP_LOADING')
            // })
        },
        setCurrentPDF(docTask) {
            if (!docTask) {
                this.currentPDF = null
                this.currentHRDocument = null
                return
            }

            let url
            if (docTask.task_type == 'hr-doc-completion') {
                url =`${this.API_BASE}/clients/${this.client.id}/employees/${this.employee.id}/hr-documents/${docTask.employee_hr_document_id}/signed`
            } else if (docTask.task_type == 'custom-form-completion') {
                url =`${this.API_BASE}/clients/${this.client.id}/employees/${this.employee.id}/hr-custom-forms/${docTask.employee_hr_custom_form_id}/pdf`
            }

            this.currentDocTask = docTask

            this.currentPDF = {
                title: docTask.document_name,
                url: {
                    url: url,
                    withCredentials: true,
                },
            }
        },
        rejectDocument(removeRequirement=false) {
            const rejectData = {
                'action': removeRequirement ? 'reject-cancel' : 'reject-resend',
            }

            let url;
            if (this.currentDocTask) {
                url = `/clients/${this.client.id}/employees/${this.employee.id}/document-tasks/${this.currentDocTask.id}`
            } else {
                return false
            }

            this.$store.dispatch('START_LOADING')
            this.$api.post(url, rejectData).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated')
                this.setCurrentPDF(null)

                if (this.allFormsApproved) {
                    this.close()
                }
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        signDocument() {
            const signatureData = {
                'action': 'approve',
                'date': moment().format('MM/DD/YYYY'),
                'datetime': moment().format('MM/DD/YYYY HH:mm:ss Z'),
            }

            let url;
            if (this.currentDocTask) {
                url = `/clients/${this.client.id}/employees/${this.employee.id}/document-tasks/${this.currentDocTask.id}`
            }

            this.$store.dispatch('START_LOADING')
            this.$api.post(url, signatureData).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated')
                this.setCurrentPDF(null)

                if (this.allFormsApproved) {
                    this.close()
                }
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
    },
}
</script>
