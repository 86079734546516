<template>
    <page>
        <div v-if="warnings.length" class="alert alert-danger mt-2">
            <h4>Warnings</h4>

            <ul>
                <li v-for="warning in warnings">
                    {{ warning }}
                </li>
            </ul>
        </div>

        <fast-table
            v-show="dataLoaded"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="currentOrg = $event"
        >

            <template slot="table-actions">
                <a class="btn btn-primary btn-collapse mr-2" :href="exportFileURL">
                    <i class="far fa-fw fa-file-excel"></i> <span class="btn-text">Export</span>
                </a>

                <button type="button" class="btn btn-primary btn-collapse mr-2" @click.prevent="$refs.importFileModal.open()">
                    <i class="far fa-fw fa-file-excel"></i> <span class="btn-text">Import</span>
                </button>

                <button type="button" class="btn btn-primary btn-collapse" @click.prevent="pullFromPayroll()">
                    <i class="fas fa-fw fa-cloud-upload-alt"></i> <span class="btn-text">Pull From Payroll</span>
                </button>
            </template>

        </fast-table>

        <import-file-modal :client="client" :import-file-type="'orgs'" @updated="getData()" ref="importFileModal" />
    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import ImportFileModal from './components/ImportFileModal'

export default {
    components: {Page, FastTable, ImportFileModal, },
    props: ['client', ],
    computed: {
        tableData() {
            const tableData = []

            this.orgs.forEach((o, idx) => {
                const row = {
                    id: idx,
                    isActive: this.currentOrg && o.id == this.currentOrg.id,
                    object: o,
                    cells: [
                        o.OrgLevel.toString(),
                        o.OrgCode.toString(),
                        o.OrgLabel,
                        o.Parent,
                        o.POSValues,
                        o.IgnoreIfNoHours,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
        exportFileURL() {
            return `${this.$api.API_BASE}/admin/clients/${this.client.id}/config/orgs/export`
        }
    },
    data() {
        return {
            orgs: [],
            warnings: [],
            dataLoaded: false,
            currentOrg: null,

            headers: [
                {label: 'Org Level', classes: '', isSearchable: true, isFilterable: true},
                {label: 'Org Code', classes: '', isSearchable: true},
                {label: 'Org Label', classes: '', isSearchable: true},
                {label: 'Parent', classes: '', isSearchable: true},
                {label: 'POS Values', classes: '', isSearchable: true},
                {label: 'Ignore If No Hours', classes: '', type: "checkmark",  isSearchable: false},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', `Client ${this.client.client_code} - ${this.client.name} / Orgs`)
        this.getData()
    },
    methods: {
        getData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/clients/${this.client.id}/config/orgs`).then((resp) => {
                this.orgs = resp['data']
                this.warnings = resp['warnings']
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        pullFromPayroll() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/admin/clients/${this.client.id}/config/orgs/pull`, {}).then((resp) => {
                this.$bus.showInfo('Orgs pulled successfully.')
                this.$store.dispatch('STOP_LOADING')
                this.getData()
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
    },
}

</script>
