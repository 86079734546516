<template>
    <pdf-modal :url="pdf.url" :title="pdf.title" :has-accept="true" v-if="pdf" @close="$emit('close')" ref="pdfModal">

    <template slot="footer" v-if="(form && !form.filled_on) || (hrDocument && !hrDocument.signed_on) || (hrFormDocument && !hrFormDocument.filled_on)">
        <div v-if="errors && errors.length">
            <div v-for="error in errors" class="alert alert-danger mt-2">
                {{ error }}
            </div>

            <button-row>
                <button v-if="form || hrFormDocument" type="button" class="btn btn-block btn-outline-primary mb-1" @click.prevent="$emit('close')">
                    Edit Form
                </button>
                <button v-if="hrDocument" type="button" class="btn btn-block btn-outline-primary mb-1" @click.prevent="$emit('close')">
                    Close
                </button>
            </button-row>
        </div>

        <div v-if="!(errors && errors.length)">
            <div class="form-row">
                <div class="col-12" v-if="form">
                    <p>{{ form.acceptance_text }}</p> <!-- XXX: implement this or add generic text? -->
                </div>

                <div class="col-12" v-if="hrDocument">
                    <p>I have read and agree with this document.</p>
                </div>
            </div>

            <div v-for="warning in warnings" class="alert alert-warning mt-2">
                {{ warning }}
            </div>

            <button-row>
                <button v-if="form || hrFormDocument" type="button" class="btn btn-block btn-outline-primary mb-1" @click.prevent="$emit('close')">
                    Edit Form
                </button>
                <button v-if="hrDocument" type="button" class="btn btn-block btn-outline-primary mb-1" @click.prevent="$emit('close')">
                    Close
                </button>
                <button type="button" class="btn btn-block btn-primary mb-1" @click.prevent="signForm()">
                    Sign Document
                </button>
            </button-row>
        </div>
    </template>

</pdf-modal>
</template>

<script>
import PdfModal from '@/components/PdfModal'
import ButtonRow from '@/components/ButtonRow'
import moment from 'moment'

export default {
    props: ['onboardingApplication', 'form', 'hrDocument', 'hrFormDocument', 'pdf', 'formData', 'knownData', 'uploads', 'errors', 'warnings'],
    components: {PdfModal, ButtonRow, },
    methods: {
        signForm() {
            if (this.form) {
                const formData = {
                    uploads: this.uploads || [],
                    form_data: Object.assign({}, this.formData),
                    signatures: {
                        'signature': {
                            'first_name': this.knownData.employee.first_name,
                            'last_name': this.knownData.employee.last_name,
                            'full_name': this.knownData.employee.full_name,
                            'date': this.knownData.system.date
                        },
                    },
                }

                if (formData.form_data['translator_count'] && (1 * formData.form_data['translator_count']) >= 1) {
                    formData.signatures['translator_signature'] = {
                        'first_name': formData.form_data['translator_first_name'],
                        'last_name': formData.form_data['translator_last_name'],
                        'full_name': `${formData.form_data['translator_first_name']} ${formData.form_data['translator_last_name']}`,
                        'date': this.knownData.system.date
                    }
                }

                if (formData.form_data['translator_count'] && (1 * formData.form_data['translator_count']) >= 2) {
                    formData.signatures['translator_signature_2'] = {
                        'first_name': formData.form_data['translator_first_name_2'],
                        'last_name': formData.form_data['translator_last_name_2'],
                        'full_name': `${formData.form_data['translator_first_name_2']} ${formData.form_data['translator_last_name_2']}`,
                        'date': this.knownData.system.date
                    }
                }

                if (formData.form_data['translator_count'] && (1 * formData.form_data['translator_count']) >= 3) {
                    formData.signatures['translator_signature_3'] = {
                        'first_name': formData.form_data['translator_first_name_3'],
                        'last_name': formData.form_data['translator_last_name_3'],
                        'full_name': `${formData.form_data['translator_first_name_3']} ${formData.form_data['translator_last_name_3']}`,
                        'date': this.knownData.system.date
                    }
                }

                if (formData.form_data['translator_count'] && (1 * formData.form_data['translator_count']) >= 4) {
                    formData.signatures['translator_signature_4'] = {
                        'first_name': formData.form_data['translator_first_name_4'],
                        'last_name': formData.form_data['translator_last_name_4'],
                        'full_name': `${formData.form_data['translator_first_name_4']} ${formData.form_data['translator_last_name_4']}`,
                        'date': this.knownData.system.date
                    }
                }

                if (formData.form_data['translator_count'] && (1 * formData.form_data['translator_count']) >= 5) {
                    formData.signatures['translator_signature_5'] = {
                        'first_name': formData.form_data['translator_first_name_5'],
                        'last_name': formData.form_data['translator_last_name_5'],
                        'full_name': `${formData.form_data['translator_first_name_5']} ${formData.form_data['translator_last_name_5']}`,
                        'date': this.knownData.system.date
                    }
                }

                this.$store.dispatch('START_LOADING')
                this.$api.post(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application/forms/${this.form.id}?sign=1`, formData).then(() => {
                    this.$emit('updated')
                    this.$store.dispatch('STOP_LOADING')
                    this.$refs.pdfModal.close()
                }).catch((errors) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.showError(errors.__all__)
                })
            }
            else if (this.hrFormDocument) {
                const formData = {
                    form_data: Object.assign({}, this.formData),
                    signatures: {
                        'signature': {
                            'first_name': this.knownData.employee.first_name,
                            'last_name': this.knownData.employee.last_name,
                            'full_name': this.knownData.employee.full_name,
                            'date': this.knownData.system.date
                        },
                    },
                }
                this.$store.dispatch('START_LOADING')
                this.$api.post(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application/hr-form-documents/${this.hrFormDocument.id}?sign=1`, formData).then(() => {
                    this.$emit('updated')
                    this.$store.dispatch('STOP_LOADING')
                    this.$refs.pdfModal.close()
                }).catch((errors) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.showError(errors.__all__)
                })
            }
            else if (this.hrDocument) {
                const signatureData = {
                    'date': moment().format('MM/DD/YYYY'),
                    'datetime': moment().format('MM/DD/YYYY HH:mm:ss Z'),
                }

                this.$store.dispatch('START_LOADING')
                this.$api.post(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application/hr-documents/${this.hrDocument.id}`, signatureData).then(() => {
                    this.$emit('updated')
                    this.$store.dispatch('STOP_LOADING')
                    this.$refs.pdfModal.close()
                }).catch((errors) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.showError(errors.__all__)
                })
            }

        },
    },
}
</script>
