<template>
    <sidebar @close="$emit('close')">
        <template slot="title">
            {{ state.long_name }}
        </template>
        <slot>

            <state-form-selection-rules-sidebar-widget
                :state="state"
                :all-states="allStates"
                @updated="$emit('updated')"
            />

            <tax-withholding-config-sidebar-widget
                jurisdiction="state"
                :state="state"
                @updated="$emit('updated')"
            />
        </slot>
    </sidebar>
</template>

<script>
import FormRulesModal from './StateFormRulesModal'
import StateFormSelectionRulesSidebarWidget from './StateFormSelectionRulesSidebarWidget'
import TaxWithholdingConfigSidebarWidget from './TaxWithholdingConfigSidebarWidget'
import Sidebar from '@/components/Sidebar'
import InfoWidget from '@/components/InfoWidget'

export default {
    components: {FormRulesModal, Sidebar, InfoWidget, TaxWithholdingConfigSidebarWidget, StateFormSelectionRulesSidebarWidget, },
    props: ['state', 'allStates', 'involuntaryWithholdingTypes', 'deductionTypes', 'earningTypes', ],
    computed: {
        enabledTaxWithholdingFields() {
            if (this.state.tax_withholding_configs.length) {
                return this.state.tax_withholding_configs[0].field_configs.filter(fc => (fc.is_enabled)).slice().sort((a,b) => (
                    (a.tax_field < b.tax_field ? -1 : a.tax_field > b.tax_field ? 1 : 0)
                ))
            } else {
                return []
            }
        }
    },
    data() {
        return {
            rulesEditModalOpen: false,
        }
    },
}
</script>

