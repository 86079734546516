<template>
    <div class="row">
        <div class="col-12">
            <div class="mui-textfield">
                <span class="textfield">
                    {{ employeeUpdate.address_1 }}
                    <br />
                    {{ employeeUpdate.address_2 }}
                    <br v-if="employeeUpdate.address_2" />
                    {{ employeeUpdate.city }}, {{ employeeUpdate.state }} {{ employeeUpdate.postal_code }}
                </span>
                <label>Resident Address</label>
            </div>

            <div class="mui-textfield">
                <span class="textfield">
                    <template v-if="employeeUpdate.use_mailing_address">
                        {{ employeeUpdate.mailing_address_1 }}
                        <br />
                        {{ employeeUpdate.mailing_address_2 }}
                        <br v-if="employeeUpdate.mailing_address_2" />
                        {{ employeeUpdate.mailing_city }}, {{ employeeUpdate.mailing_state }} {{ employeeUpdate.mailing_postal_code }}
                    </template>
                    <template v-else>
                        Same as above
                    </template>
                </span>
                <label>Mailing Address</label>
            </div>

            <portal to="address-edit-modal-footer">
                <button-row stacked>
                    <button type="button" @click.prevent="onPrev()" class="btn btn-outline-primary">Back</button>
                    <button type="button" @click.prevent="onSubmit()" class="btn btn-primary">Save</button>
                </button-row>
            </portal>

            <!-- TODO show filled forms and/or school district and municipality (geocode) here? -->
            </div>
        </div>
</template>

<script>
import api from '@/api'
import ButtonRow from '@/components/ButtonRow'

export default {
    mixins: [],
    components: {ButtonRow, },
    props: ['employee', 'employeeUpdate', 'prev'],
    watch: {},
    computed: {
        addressChanged() {
            return (
                (this.employeeUpdate.address_1 != this.employee.address_1) ||
                (this.employeeUpdate.address_2 != this.employee.address_2) ||
                (this.employeeUpdate.city != this.employee.city) ||
                (this.employeeUpdate.state != this.employee.state) ||
                (this.employeeUpdate.postal_code != this.employee.postal_code)
            )
        },
        mailingAddressChanged() {
            return (
                (this.employeeUpdate.mailing_address_1 != this.employee.mailing_address_1) ||
                (this.employeeUpdate.mailing_address_2 != this.employee.mailing_address_2) ||
                (this.employeeUpdate.mailing_city != this.employee.mailing_city) ||
                (this.employeeUpdate.mailing_state != this.employee.mailing_state) ||
                (this.employeeUpdate.mailing_postal_code != this.employee.mailing_postal_code)
            )
        }
    },
    data() {
        return {
            formData: {'action': 'confirm-address-update'},
            pollInterval: null
        }
    },
    destroyed() {
        if (this.pollInterval) {
            clearInterval(this.pollInterval)
        }
    },
    methods: {
        onSubmit() {
            this.$store.dispatch('START_LOADING')
            api.patch(`/me/ess/companies/${this.employee.company_id}/updates/${this.employeeUpdate.id}`, this.formData).then((resp) => {
                // poll every 2 seconds to see the task status
                this.pollInterval = setInterval(() => {this.pollEmployeeUpdate()}, 2000)
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        pollEmployeeUpdate() {
            api.get(`/me/ess/updates/${this.employeeUpdate.id}/progress`).then((resp) => {
                if (resp['status'] == 'SUCCESS') {
                    clearInterval(this.pollInterval)
                    this.$emit('updated')
                    this.$store.dispatch('STOP_LOADING')
                } else if (resp['status'] == 'FAILURE') {
                    clearInterval(this.pollInterval)
                    this.$bus.showError(resp['error'])
                    this.$store.dispatch('STOP_LOADING')
                } else {
                    // do nothing, keep polling
                }
            })
        },
        onPrev() {
            this.$emit('prev')
        },
    }
}
</script>