<template>
    <sidebar @close="$emit('close')">
        <template slot="title" v-if="dataLoaded">
            {{ jurisdiction.canonical_name }}
        </template>
        <slot v-if="dataLoaded">
            <involuntary-withholding-law-sidebar-widget
                :jurisdiction="jurisdiction"
                :earning-types="earningTypes"
                :deduction-types="deductionTypes"
                :involuntary-withholding-types="involuntaryWithholdingTypes"
                @updated="getData()"
            />

            <minimum-wage-sidebar-widget
                :jurisdiction="jurisdiction"
                @updated="getData()"
            />

            <unemployment-insurance-program-rate-sidebar-widget
                v-if="jurisdiction.type == 'state'"
                :jurisdiction="jurisdiction"
                @updated="getData()"
            />

            <filing-status-sidebar-widget
                :jurisdiction="jurisdiction"
                @updated="getData()"
            />

        </slot>
    </sidebar>
</template>

<script>
import InvoluntaryWithholdingLawSidebarWidget from './InvoluntaryWithholdingLawSidebarWidget'
import MinimumWageSidebarWidget from './MinimumWageSidebarWidget'
import UnemploymentInsuranceProgramRateSidebarWidget from './UnemploymentInsuranceProgramRateSidebarWidget.vue'
import FilingStatusSidebarWidget from './FilingStatusSidebarWidget'
import Sidebar from '@/components/Sidebar'

export default {
    components: {Sidebar, InvoluntaryWithholdingLawSidebarWidget, MinimumWageSidebarWidget, FilingStatusSidebarWidget, UnemploymentInsuranceProgramRateSidebarWidget, },
    props: ['jurisdictionId', 'involuntaryWithholdingTypes', 'deductionTypes', 'earningTypes', ],
    data() {
        return {
            dataLoaded: false,
            jurisdiction: null,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/tax-engine/jurisdictions/${this.jurisdictionId}`).then(resp => {
                this.jurisdiction = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
    },
}
</script>

