<template>
    <modal size="xl" ref="modal" @close="$emit('close')" :is-closeable="isCloseable" :autoshow="true">
        <template slot="title">
            <span v-if="unemploymentInsuranceProgramRate">Update Unemployment Insurance Program Rate</span>
            <span v-if="!unemploymentInsuranceProgramRate">Add Unemployment Insurance Program Rate</span>
        </template>
        <slot>

            <form class="mui-form" @submit.prevent="onSubmit">
                <div class="form-row mb-4">
                    <div class="col-6">
                        <form-input v-model="formData.starts_on" :errors="errors.starts_on" type="date" label="Starts On" />
                    </div>
                    <div class="col-6">
                        <form-input v-model="formData.inactive_on" :errors="errors.inactive_on" type="date" label="Expires On" />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col-6">
                        <form-input v-model="formData.name" :errors="errors.name" type="text" label="Name" :readonly="!!unemploymentInsuranceProgramRate" />
                    </div>

                    <div class="col-6">
                        <form-input v-model="formData.rate_type" :errors="errors.rate_type" type="select" label="Type" :options="typeOptions" />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col-6">
                        <form-input v-model="formData.is_employer_paid" :errors="errors.is_employer_paid" type="checkbox" label="Is Employer Paid" />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col-6">
                        <form-input v-model="formData.taxable_amount" :errors="errors.taxable_amount" type="currency" label="Taxable Wage Base" />
                    </div>

                    <div class="col-3" v-if="formData.rate_type == 'constant'">
                        <form-input v-model="formData.rate" :errors="errors.rate" type="number" label="Rate" />
                    </div>

                    <div class="col-3" v-if="formData.rate_type == 'min-max'">
                        <form-input v-model="formData.min_rate" :errors="errors.min_rate" type="number" label="Min. Rate" />
                    </div>

                    <div class="col-3" v-if="formData.rate_type == 'min-max'">
                        <form-input v-model="formData.max_rate" :errors="errors.max_rate" type="number" label="Max. Rate" />
                    </div>

                    <div class="col-3" v-if="formData.rate_type == 'list-selection'">
                        <form-input v-model="formData.rate_choices" :errors="errors.rate_choices" type="textarea" rows="6" cols="5" label="Rates (one per line)" />
                    </div>
                </div>

            </form>
        </slot>

        <template slot="footer">
            <form-errors :errors="formErrors"/>

            <button-row>
                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import {validateForm} from '@/utils'

export default {
    props: ['unemploymentInsuranceProgramRate', 'jurisdiction', ],
    mixins: [FormMixin, ModalMixin, ],
    computed: {
        typeOptions() {
            return [
                {text: 'Constant', value: 'constant'},
                {text: 'Min/Max', value: 'min-max'},
                {text: 'List Selection', value: 'list-selection'},
            ]
        },
    },
    data() {
        return {
            formRules: {
                starts_on: {presence: {allowEmpty: false}, date: true},
                rate_type: {presence: {allowEmpty: false}},
                taxable_amount: {presence: {allowEmpty: false}},
            },
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            if (this.unemploymentInsuranceProgramRate) {
                return {
                    starts_on: this.unemploymentInsuranceProgramRate.current_version.starts_on,
                    inactive_on: this.unemploymentInsuranceProgramRate.current_version.inactive_on,
                    name: this.unemploymentInsuranceProgramRate.name,
                    is_employer_paid: this.unemploymentInsuranceProgramRate.current_version.is_employer_paid,
                    taxable_amount: this.unemploymentInsuranceProgramRate.current_version.taxable_amount,
                    rate_type: this.unemploymentInsuranceProgramRate.current_version.rate_type,
                    rate: this.unemploymentInsuranceProgramRate.current_version.rate,
                    min_rate: this.unemploymentInsuranceProgramRate.current_version.min_rate,
                    max_rate: this.unemploymentInsuranceProgramRate.current_version.max_rate,
                    rate_choices: this.unemploymentInsuranceProgramRate.current_version.rate_choices.join('\n'),
                }
            } else {
                return {
                    starts_on: '',
                    inactive_on: '',
                    name: '',
                    is_employer_paid: true,
                    rate_type: 'constant',
                    taxable_amount: '',
                    rate: '',
                    min_rate: '',
                    max_rate: '',
                    rate_choices: '',
                }
            }
        },
        getFormData() {
            const data = JSON.parse(JSON.stringify(this.formData))
            data.rate_choices = data.rate_choices.split('\n').map(x => x.trim()).filter(x => x)
            return data
        },
        validate() {
            let isValid = FormMixin.methods.validate.call(this)
            const fields = []
            const data = this.getFormData()

            if (!this.unemploymentInsuranceProgramRate) {
                fields.push('name')
            }

            if (this.formData.rate_type == 'constant') {
                fields.push('rate')
            }
            else if (this.formData.rate_type == 'min-max') {
                fields.push('min_rate')
                fields.push('max_rate')
            }
            else if (this.formData.rate_type == 'list-selection') {
                fields.push('rate_choices')
            }

            fields.forEach(f => {
                const rules = {}
                const labels = {}

                rules[f] = {presence: {allowEmpty: false}}
                labels[f] = 'This field'

                Object.assign(this.errors, validateForm(data, rules, labels))
            })

            return isValid
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let promise, url;
            if (this.unemploymentInsuranceProgramRate) {
                url = `/admin/tax-engine/jurisdictions/${this.jurisdiction.id}/unemployment-insurance-program-rates/${this.unemploymentInsuranceProgramRate.id}`
                promise = this.$api.put(url, this.getFormData())
            }
            else {
                url = `/admin/tax-engine/jurisdictions/${this.jurisdiction.id}/unemployment-insurance-program-rates`
                promise = this.$api.post(url, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>

