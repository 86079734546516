<template>
    <page>

        <fast-table
            v-show="dataLoaded"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="currentDeductionType = $event"
        >
            <template slot="table-actions">
                <button type="button" class="btn btn-primary btn-collapse-md" @click.prevent="currentDeductionType = null; addModalOpen = true">
                    <i class="far fa-fw fa-plus"></i>
                    <span class="btn-text">Add Deduction Type</span>
                </button>
            </template>
        </fast-table>

        <sidebar
            :deduction-type="currentDeductionType"
            v-if="currentDeductionType"
            @updated="onUpdate($event)"
            @deleted="currentDeductionType = null; getData()"
            @close="currentDeductionType = null"
        />
        <add-modal v-if="addModalOpen" @updated="getData()" @close="addModalOpen = false" />

    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import AddModal from './components/DeductionTypeModal'
import Sidebar from './components/DeductionTypeSidebar'

export default {
    components: {FastTable, Page, Sidebar, AddModal, },
    computed: {
        future() {
            return this.deductionTypes.filter(d => d.current_version.is_future)
        },
        active() {
            return this.deductionTypes.filter(d => d.current_version.is_active)
        },
        inactive() {
            return this.deductionTypes.filter(d => d.current_version.is_past)
        },
        tableData() {
            const tableData = []
            const subsets = [
                this.future,
                this.active,
                this.inactive,
            ]

            subsets.forEach(deductionTypes => {
                const sectionData = []

                deductionTypes.forEach((e, idx) => {
                    const row = {
                        id: idx,
                        isActive: this.currentDeductionType && e.id == this.currentDeductionType.id,
                        object: e,
                        cells: [
                            e.name,
                            e.has_been_used ? 'Yes' : 'No',
                            e.current_version.version,
                            e.current_version.starts_on,
                            e.current_version.ends_on,
                        ],
                    }

                    sectionData.push(row)
                })
                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            deductionTypes: [],
            dataLoaded: false,
            currentDeductionType: null,
            addModalOpen: false,

            headers: [
                {label: 'Deduction Type', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Has Been Used', classes: 'cw-5', isFilterable: true},
                {label: 'Version', classes: 'cw-5', isFilterable: false},
                {label: 'Starts On', classes: 'cw-5', type: 'date'},
                {label: 'Ends On', classes: 'cw-5', type: 'date'},
            ],
            sections: [
                {title: 'Future', defaultIsClosed: false, id: 1},
                {title: 'Current', defaultIsClosed: false, id: 2},
                {title: 'Past', defaultIsClosed: true, id: 3},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tax Engine / Deduction Types')
        this.getData()
    },
    methods: {
        getData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/tax-engine/deduction-types`).then(resp => {
                this.deductionTypes = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdate(deductionType) {
            const existing = this.deductionTypes.find(x => {return x.id == deductionType.id})
            if (existing) {
                Object.assign(existing, deductionType)
            }
        },
    },
}

</script>
