<template>
    <div :class="question.type" class="question mb-1" v-if="isInput && isVisible()">
        <div v-if="question.type == 'constant'">
            <input type="hidden" :value="question.constant">
        </div>

        <div v-if="question.type == 'checkbox'">
            <form-input :placeholder="question.placeholder" :label="question.text" v-model="value" :errors="errors" type="checkbox" :helper-text="question.help_text" :popover-helper-html="popoverHTML" :readonly="isReadonly" />
        </div>

        <div v-if="question.type == 'info'">
            <p class="my-4">{{ question.text }}</p>
        </div>

        <div v-if="question.type == 'text'">
            <form-input :placeholder="question.placeholder" :label="question.text" v-model="value" :errors="errors" type="text" :helper-text="question.help_text" :popover-helper-html="popoverHTML" :maxlength="question.maxlength || null" :nocaps="question.not_all_caps" :allowed-chars="question.allowed_chars" :prefix="question.prefix" :readonly="isReadonly" />
        </div>

        <div v-if="question.type == 'integer'">
            <form-input :placeholder="question.placeholder" :label="question.text" v-model="value" :errors="errors" type="integer-string" :helper-text="question.help_text" :popover-helper-html="popoverHTML" :readonly="isReadonly" />
        </div>

        <div v-if="question.type == 'positive-integer'">
            <form-input :placeholder="question.placeholder" :label="question.text" v-model="value" :errors="errors" type="integer-string" :allow-negative="false" :helper-text="question.help_text" :popover-helper-html="popoverHTML" :readonly="isReadonly" />
        </div>

        <div v-if="question.type == 'currency'">
            <form-input :placeholder="question.placeholder" :label="question.text" v-model="value" :errors="errors" type="currency" :allow-negative="true" :helper-text="question.help_text" :popover-helper-html="popoverHTML" :readonly="isReadonly" />
        </div>

        <div v-if="question.type == 'positive-currency'">
            <form-input :placeholder="question.placeholder" :label="question.text" v-model="value" :errors="errors" type="currency" :allow-negative="false" :helper-text="question.help_text" :popover-helper-html="popoverHTML" :readonly="isReadonly" />
        </div>

        <div v-if="question.type == 'email'">
            <form-input :placeholder="question.placeholder" :label="question.text" v-model="value" :errors="errors" type="email" :helper-text="question.help_text" :popover-helper-html="popoverHTML" :allowed-chars="question.allowed_chars" :readonly="isReadonly" />
        </div>

        <div v-if="question.type == 'date'">
            <form-input :placeholder="question.placeholder" :label="question.text" v-model="value" :errors="errors" type="date" :helper-text="question.help_text" :popover-helper-html="popoverHTML" :readonly="isReadonly" />

        </div>

        <div v-if="question.type == 'ssn'">
            <form-input :placeholder="question.placeholder" :label="question.text" v-model="value" :errors="errors" type="ssn" :helper-text="question.help_text" :popover-helper-html="popoverHTML" :readonly="isReadonly" />
        </div>

        <div v-if="question.type == 'select-one' && question.widget != 'select'" class="form-check-set mb-2" :class="{'form-check-set--is-invalid': errors && errors.length}">
            <div class="field-helper-text text-danger mt-0 mb-1" v-if="errors && errors.length">
                <i aria-hidden="true" title="Error" class="fas fa-fw fa-exclamation-circle"></i>
                <span class="sr-only">Error:</span>
                <span> {{ errors[0]}}</span>
            </div>

            <div class="form-row">
                <div class="col">
                    <label :for="'question-options-' + question.slug">{{ question.text }}
                        <small v-if="question.help_text">{{ question.help_text }}</small>
                    </label>
                </div>

                <div v-if="popoverHTML" class="">
                    <more-info><div v-html="popoverHTML"></div></more-info>
                </div>
            </div>

            <ul :id="'question-options-' + question.slug" class="list-unstyled m-0 pl-2">
                <li v-for="o in question.options" :key="question.slug + '.' + o.slug" class="option form-check mb-hf">
                    <div class="form-row">
                        <div class="col">
                            <label><input type="radio" :value="o.slug" :checked="value == o.slug" @click="onClick(o.slug)" class="form-check-input" :disabled="isReadonly && value != o.slug"> {{ o.text }}</label>
                        </div>

                        <div class="" v-if="optionPopoverHTML[o.slug]">
                            <more-info><div v-html="optionPopoverHTML[o.slug]"></div></more-info>
                        </div>
                    </div>

                    <div v-show="value == o.slug && o.questions.length" class="child-questions question-inset ml-1" v-if="o.questions">
                        <question ref="questions" v-for="cq in o.questions" :question="cq" :parent="question" :parent-value="value" :is-option-child="true" :key="cq.slug" v-if="value == o.slug" />
                    </div>
                </li>
            </ul>
        </div>

        <div v-if="question.type == 'select-one' && question.widget == 'select'" class="mui-select mb-2">
            <form-input :placeholder="question.placeholder" :label="question.text" v-model="value" :errors="errors" type="select" :help_text="question.help_text" :options="selectOptions" :popover-helper-html="popoverHTML" :readonly="isReadonly" />
        </div>

        <div v-if="question.type == 'select-many'">
            <div class="field-helper-text text-danger mt-4 mb-1" v-if="errors && errors.length">{{ error }}</div>

            <label :for="'question-options-' + question.slug">{{ question.text }}</label>
            <small v-if="question.help_text">{{ question.help_text }}</small>
            <ul :id="'question-options-' + question.slug" class="list-unstyled ml-2">
                <li v-for="o in question.options" :key="question.slug + '.' + o.slug" class="option">
                    <label><input type="checkbox" :value="o.slug" :checked="value && value.indexOf(o.slug) >= 0" @click="onClick(o.slug)" :readonly="isReadonly"> {{ o.text }}</label>

                    <div v-show="value && value.indexOf(o.slug) >= 0 && o.questions.length" class="child-questions question-inset ml-1" v-if="o.questions">
                        <question ref="questions" v-for="cq in o.questions" :question="cq" :parent="question" :parent-value="value" :is-option-child="true" :key="cq.slug" v-if="value && value.indexOf(o.slug) >= 0" />
                    </div>
                </li>
            </ul>

            <div v-if="popoverHTML" class="">
                <more-info><div v-html="popoverHTML"></div></more-info>
            </div>
        </div>

        <div class="child-questions question-inset" v-if="question.questions">
            <question ref="questions" v-for="childQuestion in question.questions" :question="childQuestion" :parent="question" :parent-value="value" :key="childQuestion.slug" />
        </div>
    </div>
</template>

<script>
/* NB: this module is written using ES5 syntax on purpose. It is used in `pdf_forms` Django app
outside of the single page Vue application, and loaded using http-vue-loader. It is still
usable in single page Vue apps, but in order to make it compatible with more direct browser
consumption without setting up a compilation step, we use more plain syntax and allow for
pre-loaded globals like window.jQuery, etc.
*/

const moment = window.moment || require('moment')
const jQuery = window.jQuery || require('jquery')
const PresetOptions = require('./PresetOptions').default
const MarkdownIt = require('markdown-it')({breaks: true})
const validate = require('validate.js')

var findOption = function(question, slug) {
    let options;

    if (question.preset_options) {
        if (!PresetOptions[question.preset_options]) {
            return null
        }

        options = PresetOptions[question.preset_options]
    }
    else {
        options = question.options
    }

    for (var i = 0; i < options.length; i++) {
        var o = options[i];
        if (o.slug == slug) {
            return o;
        }
    }

    return null;
};

var VALIDATORS = {
    'length': function(value, options={}) {
        if (options.special_allowed_values && options.special_allowed_values.indexOf(value) >= 0) {
            return null;
        }

        if (options.special_disallowed_values && options.special_disallowed_values.indexOf(value) >= 0) {
            return `"${value}" is not a valid response.`
        }

        const min = options.minlength || 0
        const max = options.maxlength || value.length
        if (value.length < min) {
            return `This value must be at least ${min} characters long.`
        }

        if (value.length > max) {
            return max == 1 ? `This value must be at most ${max} character long.` : `This value must be at most ${max} characters long.`
        }

        return null
    },
    'ssn': function(value, options={}) {
        if (options.special_allowed_values && options.special_allowed_values.indexOf(value) >= 0) {
            return null;
        }

        if (options.special_disallowed_values && options.special_disallowed_values.indexOf(value) >= 0) {
            return `"${value}" is not a valid response.`
        }

        const pattern = /^(\d{3})-?(\d{2})-?(\d{4})$/
        if (!pattern.test(value)) {
            return 'Please enter a valid SSN.'
        }

        const match = value.match(pattern)
        if ((match[1] == '000') || (match[1] == '666') || (match[1]*1 >= 900)) {
            return 'Please enter a valid SSN.'
        }

        if (match[2] == '00') {
            return 'Please enter a valid SSN.'
        }

        if (match[3] == '0000') {
            return 'Please enter a valid SSN.'
        }

        return null;
    },
    'email': function(value, options={}) {
        if (options.special_allowed_values && options.special_allowed_values.indexOf(value) >= 0) {
            return null;
        }

        if (options.special_disallowed_values && options.special_disallowed_values.indexOf(value) >= 0) {
            return `"${value}" is not a valid response.`
        }

        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(value)) {
            return 'Please enter a valid email address.';
        }

        return null;
    },
    'us_phone': function(value, options={}) {
        if (options.special_allowed_values && options.special_allowed_values.indexOf(value) >= 0) {
            return null;
        }

        if (options.special_disallowed_values && options.special_disallowed_values.indexOf(value) >= 0) {
            return `"${value}" is not a valid response.`
        }

        var re = /^[0-9]{9,10}$/;

        if (!re.test(value)) {
            return 'Please enter a valid 9 or 10 digit phone number.';
        }

        return null;
    },
    'email_i9': function(value, options={}) {
        if (options.special_allowed_values && options.special_allowed_values.indexOf(value) >= 0) {
            return null;
        }

        if (options.special_disallowed_values && options.special_disallowed_values.indexOf(value) >= 0) {
            return `"${value}" is not a valid response.`
        }

        var re = /^[a-zA-Z0-9]+[a-zA-Z0-9_\-.]+@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;

        if (!re.test(value)) {
            return 'Please enter a valid email address.';
        }

        return null;
    },
    'integer': function(value, options={}) {
        if (!/^-?\d+$/.exec(value)) {
            return 'Please enter a valid whole number.';
        }

        if (options.min_value && parseInt(value, 10) < options.min_value) {
            return `Please enter a valid whole number no less than ${options.min_value}.`;
        }
        if (options.max_value && parseInt(value, 10) > options.max_value) {
            return `Please enter a valid whole number no greater than ${options.max_value}.`;
        }

        return null;
    },
    'positive-integer': function(value, options={}) {
        if (!/^\d+$/.exec(value)) {
            return 'Please enter a valid positive number or zero.';
        }
        if (options.min_value && parseInt(value, 10) < options.min_value) {
            return `Please enter a valid whole number no less than ${options.min_value}.`;
        }
        if (options.max_value && parseInt(value, 10) > options.max_value) {
            return `Please enter a valid whole number no greater than ${options.max_value}.`;
        }

        return null;
    },
    'positive-currency': function(value, options={}) {
        const pattern = /^(\d+)(\.\d{1,2})?$/
        const convertToCents = function(val) {
            const match = pattern.exec(val)
            if (!match) {return null}
            return match[1] * 100 + 1*(match[2] ? match[2].substring(1) : 0)
        }

        if (!pattern.exec(value)) {
            return 'Please enter a valid positive dollar amount or 0.';
        }

        if (options.min_value || options.max_value) {
            const centsValue = convertToCents(value)
            if (options.min_value && convertToCents(options.min_value)) {  // Make sure the min_value isn't malformed
                if (centsValue < convertToCents(options.min_value)) {
                    return `Please enter a valid amount no less than $${options.min_value}.`;
                }
            }

            if (options.max_value && convertToCents(options.max_value)) {  // Make sure the max_value isn't malformed
                if (centsValue > convertToCents(options.max_value)) {
                    return `Please enter a valid amount no greater than $${options.max_value}.`;
                }
            }
        }

        return null;
    },
    'currency': function(value, options={}) {
        const pattern = /^(-?\d+)(\.\d{1,2})?$/
        const convertToCents = function(val) {
            const match = pattern.exec(val)
            if (!match) {return null}
            return match[1] * 100 + 1*(match[2] ? match[2].substring(1) : 0)
        }

        if (!pattern.exec(value)) {
            return 'Please enter a valid positive dollar amount or 0.';
        }

        if (options.min_value || options.max_value) {
            const centsValue = convertToCents(value)
            if (options.min_value && convertToCents(options.min_value)) {  // Make sure the min_value isn't malformed
                if (centsValue < convertToCents(options.min_value)) {
                    return `Please enter a valid amount no less than $${options.min_value}.`;
                }
            }

            if (options.max_value && convertToCents(options.max_value)) {  // Make sure the max_value isn't malformed
                if (centsValue > convertToCents(options.max_value)) {
                    return `Please enter a valid amount no greater than $${options.max_value}.`;
                }
            }
        }

        return null;
    },
    'date': function(value, options={}) {
        const dateOptions = {
            specialAllowedValues: options.special_allowed_values || null,
        }

        if (options.earliest || options.latest) {
            if (options.earliest) {
                dateOptions.earliest = moment().add(options.earliest.count, options.earliest.units).startOf('day').format('YYYY-MM-DD')
            }

            if (options.latest) {
                dateOptions.latest = moment().add(options.latest.count, options.latest.units).endOf('day').format('YYYY-MM-DD')
            }
        }

        const errors = validate({date: value}, {date: {
            presence: {allowEmpty: options.allowEmpty},
            date: dateOptions,
        }})
        if (errors && errors.date) {
            return errors.date[errors.date.length - 1]
        }
    },
    'state-abbreviation': function(value, options={}) {
        if (!value || !value.trim() || value.trim().length != 2) {
            return 'Please enter a valid two letter state abbreviation.'
        }
    },
    'us_can_mex_postcode': function(value, options={}) {
        if (!value || !value.trim() || value.trim().length < 5 || value.trim().length > 6) {
            return 'Please enter a valid post code.'
        }

        value = value.trim()

        if ((value.length == 6) && (!/^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/.test(value))) {
            return 'Please enter a valid post code.'
        }

        if ((value.length == 5) && (!/^\d\d\d\d\d$/.test(value))) {
            return 'Please enter a valid post code.'
        }

    },
};

module.exports = {
    name: 'Question',
    props: ['question', 'initial', 'parent', 'parentValue', 'isOptionChild'],
    data: function() {
        var types = {
            'ssn': 'password',
        };

        return {
            value: this.initial || '',
            errors: [],
            inputType: types[this.question.type] || this.question.type,
        }
    },
    computed: {
        isInput: function() {
            if (this.question.template || this.question.source || this.question.type == 'signature' || this.question.type == 'math' || this.question.type == 'calc') {
                return false;
            }

            return true;
        },
        presetOptions() {
            if (!this.question.preset_options || !PresetOptions[this.question.preset_options]) {
                return []
            }

            return PresetOptions[this.question.preset_options]
        },
        selectOptions() {
            const result = []
            const options = this.question.preset_options ? this.presetOptions : this.question.options
            options.forEach(o => {
                result.push({text: o.text, value: o.slug})
            })
            return result
        },
        popoverHTML() {
            if (!this.question.popover_help_text) {return ''}
            return MarkdownIt.render(this.question.popover_help_text.replace('\n', '\n\n'))
        },
        optionPopoverHTML() {
            const result = {}
            this.question.options.forEach(o => {
                result[o.slug] = ''
                if (o.popover_help_text) {
                    result[o.slug] = MarkdownIt.render(o.popover_help_text.replace('\n', '\n\n'))
                }
            })

            return result
        },
        isReadonly() {
            if (this.value && this.question.readonly) {
                return true
            }

            return false
        },
    },
    methods: {
        isVisible: function() {
            var i;

            if (!this.isInput) {
                return false;
            }

            if (!(this.parent && this.parent.slug)) {
                return true; // Top level question
            }

            if (this.isOptionChild) {
                return true; // Controlled by template logic
            }

            // Child question follows parent question
            if (this.parent.type == 'checkbox') {
                if (this.parentValue && this.question.condition !== 'unchecked') {
                    return true;
                }

                if (!this.parentValue && this.question.condition == 'unchecked') {
                    return true;
                }

                return false;
            }

            if (this.parent.type == 'text') {
                // Do we want conditionals based on text values?
            }

            if (this.parent.type == 'select-one') {
                return this.question.condition_options.indexOf(this.parentValue) >= 0;
            }

            var opt;
            if (this.parent.type == 'select-many') {
                if (this.question.condition == 'any') {
                    for (i = 0; i < this.question.condition_options.length; i++) {
                        opt = this.question.condition_options[i];
                        if ((this.parentValue || []).indexOf(opt) >= 0) {
                            return true;
                        }
                    }

                    return false;
                }

                if (this.question.condition == 'all') {
                    for (i = 0; i < this.question.condition_options.length; i++) {
                        opt = this.question.condition_options[i];
                        if ((this.parentValue || []).indexOf(opt) < 0) {
                            return false;
                        }
                    }

                    return true;
                }
            }
        },
        onClick: function(slug) {
            if (this.question.type == 'checkbox') {
                this.$set(this, 'value', !this.value);
            }

            if (this.question.type == 'select-one') {
                this.$set(this, 'value', slug);
            }

            if (this.question.type == 'select-many') {
                var newValue = (this.value || []).slice()
                var index = newValue.indexOf(slug);
                if (index >= 0) {
                    newValue.splice(index, 1);
                }
                else {
                    newValue.push(slug)
                }

                this.$set(this, 'value', newValue);
            }
        },
        validate: function() {
            var success = this._validate();

            for (var i = 0; i < (this.$refs.questions || []).length; i++) {
                var q = this.$refs.questions[i];
                var qValid = q.validate();
                success = success && qValid;
            }

            return success;
        },
        _validate: function() {
            this.errors = [];

            if (!this.isVisible()) {
                return true;
            }

            if (this.question.type == 'constant') {
                return true;
            }

            if (this.question.type == 'info') {
                return true;
            }

            if (this.question.required === false) {
                if (jQuery.trim(this.value || '').length < 1) {
                    return true;  // This question is optional AND the value is blank.
                }
            }
            else {
                if (this.value === '') {
                    if (this.question.validation_options && this.question.validation_options.special_allowed_values && this.question.validation_options.special_allowed_values.indexOf('N/A') >= 0) {
                        this.errors.push('Please answer this question or enter N/A.');
                    }
                    else {
                        this.errors.push('Please answer this question.');
                    }
                    return false;
                }
            }

            var validator = VALIDATORS[this.question.validation || this.question.type];
            if (!validator) {
                return true;
            }

            const validatorOptions = Object.assign({presence: {allowEmpty: false}}, this.question.validation_options)
            var err = validator(this.value, validatorOptions);
            if (err) {
                this.errors.push(err);
                return false;
            }

            return true;
        },
        getValues: function() {
            // This method returns values usable by the server-side PDF filler
            var values = {}, taxValues = {}, localTaxValues = {};
            var i;

            if (this.question.type == 'select-one') {
                let o = findOption(this.question, this.value);
                if (o) {
                    values[this.question.slug] = o.hasOwnProperty('output_value') ? o.output_value : o.text;
                    values[this.question.slug + '__' + this.value] = o.hasOwnProperty('output_value') ? o.output_value : 'X';

                    if (this.question.tax_field) {
                        taxValues[this.question.tax_field] = o.hasOwnProperty('tax_value') ? o.tax_value : (o.hasOwnProperty('output_value') ? o.output_value : o.text);
                    }

                    if (this.question.local_tax_field && this.question.local_tax_city_name) {
                        if (!localTaxValues.hasOwnProperty(this.question.local_tax_city_name)) {
                            localTaxValues[this.question.local_tax_city_name] = {}
                        }

                        localTaxValues[this.question.local_tax_city_name][this.question.local_tax_field] = o.hasOwnProperty('local_tax_value') ? o.local_tax_value : (o.hasOwnProperty('output_value') ? o.output_value : o.text);
                    }
                }

            } else if (this.question.type == 'select-many') {
                for (i = 0; i < this.value.length; i++) {
                    let o = findOption(this.question, this.value[i]);
                    values[this.question.slug + '__' + this.value[i]] = o.hasOwnProperty('output_value') ? o.output_value : 'X';
                }
            } else if (this.question.type == 'date') {
                if (this.value instanceof Date) {
                    values[this.question.slug] = moment(this.value).format('MM/DD/YYYY');
                }
                else {
                    values[this.question.slug] = this.value;
                }
            } else if (this.question.type == 'checkbox') {
                values[this.question.slug] = '';
                if (this.value) {
                    values[this.question.slug] = this.question.hasOwnProperty('output_value') ? this.question.output_value : 'X';
                }

                if (this.question.tax_field && this.value) {
                    taxValues[this.question.tax_field] = this.question.tax_value ? this.question.tax_value : (this.question.output_value ? this.question.output_value : 'X');
                }

                if (this.question.local_tax_field && this.question.local_tax_city_name && this.value) {
                    if (!localTaxValues.hasOwnProperty(this.question.local_tax_city_name)) {
                        localTaxValues[this.question.local_tax_city_name] = {}
                    }

                    localTaxValues[this.question.local_tax_city_name][this.question.local_tax_field] = this.question.local_tax_value ? this.question.local_tax_value : (this.question.output_value ? this.question.output_value : 'X');
                }
            } else if (this.question.type == 'constant') {
                values[this.question.slug] = this.question.constant;

                if (this.question.tax_field) {
                    taxValues[this.question.tax_field] = this.question.tax_value ? this.question.tax_value : ('' + this.question.constant)
                }

                if (this.question.local_tax_field && this.question.local_tax_city_name) {
                    if (!localTaxValues.hasOwnProperty(this.question.local_tax_city_name)) {
                        localTaxValues[this.question.local_tax_city_name] = {}
                    }

                    localTaxValues[this.question.local_tax_city_name][this.question.local_tax_field] = this.question.local_tax_value ? this.question.local_tax_value : ('' + this.question.constant);
                }
            } else if (this.question.type == 'info') {
                // Do nothing as this isn't a real question
            } else {
                values[this.question.slug] = '' + this.value;

                if (this.question.tax_field) {
                    taxValues[this.question.tax_field] = '' + this.value
                }

                if (this.question.local_tax_field && this.question.local_tax_city_name) {
                    if (!localTaxValues.hasOwnProperty(this.question.local_tax_city_name)) {
                        localTaxValues[this.question.local_tax_city_name] = {}
                    }

                    localTaxValues[this.question.local_tax_city_name][this.question.local_tax_field] = '' + this.value
                }
            }

            // Process dependent questions
            const updateLocalTaxValues = (qTaxValues) => {
                Object.keys(qTaxValues).forEach(cityName => {
                    if (!localTaxValues[cityName])  {
                        localTaxValues[cityName] =  {}
                    }

                    Object.assign(localTaxValues[cityName], qTaxValues[cityName])
                })
            }

            for (i = 0; i < (this.$refs.questions || []).length; i++) {
                var q = this.$refs.questions[i];
                if (q.isVisible) {
                    const qValues = q.getValues();

                    if (qValues.__tax_values) {
                        Object.assign(taxValues, qValues.__tax_values);
                        delete qValues.__tax_values;
                    }

                    if (qValues.__local_tax_values) {
                        updateLocalTaxValues(qValues.__local_tax_values);
                        delete qValues.__local_tax_values;
                    }
                    Object.assign(values, qValues);
                }
            }

            if (Object.keys(taxValues).length) {
                values.__tax_values = taxValues;
            }
            if (Object.keys(localTaxValues).length) {
                values.__local_tax_values = localTaxValues;
            }

            return values;
        }
    }
}
</script>

<style scoped lang="scss">
.question-inset .question-inset {
    margin-left: 0;
    padding: 0;
    background-color: inherit;
    border-width: 0;
}
</style>
