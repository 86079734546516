<template>
<div>
    <div
        class="custom-form-wizard-question info-widget card mb-2"
        @click="takeFocus"
        tabindex="0"
    >
        <div class="card-header pt-hf">
            <div class="handle">
                <i class="fas fa-grip-horizontal text-muted"></i>
            </div>
            <div class="form-row">
                <div class="col">
                    <form-input
                        class="question-prompt"
                        v-model="question.prompt"
                        label="Question"
                        type="text"
                        :nocaps="true"
                        @focus="takeFocus"
                    />
                </div>
                <div class="col-1 delete-col">
                    <tooltip
                        tooltipText="Delete Question"
                        :tooltipEnabled="isTooltipEnabled"
                    >
                        <a href @click.prevent="onQuestionDelete">
                            <i class="far fa-trash-alt"></i>
                        </a>
                    </tooltip>
                </div>
            </div>
            <div v-if="showInstructions" class="form-row mt-hf">
                <div class="col">
                    <form-input
                        class="question-prompt"
                        v-model="question.instructions"
                        label="Instructions"
                        type="textarea"
                        :nocaps="true"
                        @focus="takeFocus"
                    />
                </div>
            </div>
            <a v-else href class="btn btn-link" @click.prevent="addInstructions()"><i class="far fa-plus mr-hf"></i>Add Instructions</a>
        </div>
        <div class="card-body pt-2 pb-1">
            <div class="form-row">
                <div class="col-7">
                    <form-input
                        v-model="question.question_type"
                        noblank="true"
                        label="Response Type"
                        class="question-type-select"
                        type="select"
                        :options="questionTypeOptions"
                        :showIcon="true"
                        @input="onQuestionTypeChange"
                    />
                </div>
                <div class="col-5 is-required-col">
                    <form-input
                        v-model="question.is_required"
                        label="Response Required"
                        type="checkbox"
                        @input="takeFocus"
                    />
                </div>
            </div>
            <div class="question-options" v-if="isTypeWithOptions(question.question_type)">
                <template v-for="(option, idx) in question.options">
                    <div class="form-row" :key="'opt-' + idx">
                        <div class="col">
                            <form-input type="text" :deletable="true" :nocaps="true" v-model="question.options[idx]" :placeholder="'Option ' + String(idx + 1)" @delete="onOptionDelete(idx)"/>
                        </div>
                    </div>
                </template>
                <a href class="btn btn-link" @click.prevent="addOption()"><i class="far fa-plus mr-hf"></i>Add Option</a>
            </div>
            <transition name="expand">
                <div class="question-preview-transition" v-if="focused">
                    <div class="question-preview-wrapper">
                        <h5>PREVIEW</h5>

                        <div v-if="question.question_type == 'select-one'">
                            <p class="mb-1">{{ question.prompt || 'Untitled Question' }}</p>
                            <small v-if="question.instructions" class="text-muted">{{ question.instructions}}</small>
                            <form-input
                                :options="makeInputOptionsFromQuestionOptions(question.options)"
                                :type="getInputTypeFromQuestionType(question.question_type)"
                            />
                        </div>

                        <div v-else-if="question.question_type == 'dropdown'">
                            <p class="mb-0">{{ question.prompt || 'Untitled Question' }}</p>
                            <small v-if="question.instructions" class="text-muted">{{ question.instructions}}</small>
                            <form-input
                                :options="makeInputOptionsFromQuestionOptions(question.options)"
                                :type="getInputTypeFromQuestionType(question.question_type)"
                            />
                        </div>

                        <div v-else-if="question.question_type == 'select-multiple'">
                            <p class="mb-1">{{ question.prompt || 'Untitled Question' }}</p>
                            <small v-if="question.instructions" class="text-muted">{{ question.instructions}}</small>
                            <form-input
                                v-for="(option, idx) in question.options.filter(o => o)"
                                :key="idx"
                                :label="option"
                                :type="getInputTypeFromQuestionType(question.question_type)"
                                class="mb-1"
                            />
                        </div>

                        <div v-else-if="question.question_type == 'address'" class="question-address">
                            <p class="mb-1">{{ question.prompt || 'Untitled Question' }}</p>
                            <small v-if="question.instructions" class="text-muted">{{ question.instructions}}</small>
                            <form-input label="Address 1" type="text" :maxlength="50" />
                            <form-input label="Address 2 (Optional)" type="text" :maxlength="50" />
                            <form-input label="City" type="text" :maxlength="50" />

                            <div class="form-row">
                                <div class="col-6">
                                    <form-input label="State" type="select" :options="stateOptions"/>
                                </div>

                                <div class="col-6">
                                    <form-input label="Zip Code" :maxlength="5" type="text" inputmode="tel" />
                                </div>
                            </div>
                        </div>

                        <div v-else-if="question.question_type == 'upload'">
                            <p class="mb-hf">{{ question.prompt || 'Untitled Question' }}</p>
                            <small v-if="question.instructions" class="text-muted">{{ question.instructions}}</small>
                            <input class="d-none" type="file" @change="onUpload($event)" accept=".pdf,.jpg,.jpeg,.png,.heic,.heif;capture=camera"/>
                            <button class="btn btn-outline-primary btn-boxy">
                                <i class="far fa-camera mr-hf"></i>
                                <span class="mr-8">Take Photo</span>
                                <i class="far fa-upload mr-hf"></i>
                                <span>Upload File</span>
                            </button>
                        </div>

                        <div v-else-if="question.question_type == 'phone-number'">
                            <p class="mb-0">{{ question.prompt || 'Untitled Question' }}</p>
                            <small v-if="question.instructions" class="text-muted">{{ question.instructions}}</small>
                            <form-input
                                :type="getInputTypeFromQuestionType(question.question_type)"
                                placeholder="XXX-XXX-XXXX"
                            />
                        </div>

                        <div v-else>
                            <p class="mb-0">{{ question.prompt || 'Untitled Question' }}</p>
                            <small v-if="question.instructions" class="text-muted">{{ question.instructions}}</small>
                            <form-input
                                :type="getInputTypeFromQuestionType(question.question_type)"
                                :nocaps="true"
                            />
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</div>
</template>

<script>
import FormInput from '@/components/FormInput'
import Tooltip from '@/components/Tooltip.vue'
import states from '@/states'

export default {
    props: ['question', 'focused'],
    components: {FormInput, Tooltip},
    data() {
        return {
            isTooltipEnabled: true,
            showInstructions: false,
            questionHasFocus: false,
        }
    },
    computed: {
        questionTypeOptions() {
            return [
                {text: 'Short Answer', value: 'short-answer', icon: 'grip-lines'},
                {text: 'Paragraph Answer', value: 'paragraph', icon: 'align-justify'},
                {text: 'Select One', value: 'select-one', icon: 'circle'},
                {text: 'Select Multiple', value: 'select-multiple', icon: 'check-square'},
                {text: 'Dropdown', value: 'dropdown', icon: 'caret-circle-down'},
                {text: 'Take Photo/File Upload', value: 'upload', icon: 'upload'},
                {text: 'Date', value: 'date', icon: 'calendar'},
                {text: 'Address', value: 'address', icon: 'home'},
                {text: 'Phone Number', value: 'phone-number', icon: 'phone'},
                {text: 'Email', value: 'email', icon: 'envelope'},
            ]
        },
        stateOptions() {
            const result = []
            states.forEach((s) => {
                result.push({text: s.name.toUpperCase(), value: s.abbreviation})
            })
            return result
        },
    },
    mounted() {
        if (this.question.instructions) {
            this.showInstructions = true
        }
    },
    methods: {
        takeFocus() {
            this.$emit('focus', this.question.idx)
        },
        onQuestionDelete() {
            this.isTooltipEnabled = false
            this.$nextTick(() => {
                this.$emit('delete')
            })
        },
        makeOptionsFormData() {
            let data = []
            for (let i = 0; i < 3; i++) {
                data.push('');
            }
            return data
        },
        onQuestionTypeChange() {
            if (this.isTypeWithOptions(this.question.question_type) && !this.question.options.length) {
                this.question.options = this.makeOptionsFormData()
            }
            this.takeFocus()
        },
        isTypeWithOptions(type) {
            return ['select-one', 'select-multiple', 'dropdown'].includes(type)
        },
        addOption() {
            this.question.options.push('')
        },
        addInstructions() {
            this.showInstructions = true
            this.takeFocus()
        },
        onOptionDelete(idx) {
            this.question.options.splice(idx, 1)
        },
        getInputTypeFromQuestionType(type) {
            let typeMap = {
                'short-answer': 'text',
                'paragraph': 'textarea',
                'select-one': 'radio',
                'select-multiple': 'checkbox',
                'dropdown': 'select',
                'date': 'date',
                'address': 'text',
                'phone-number': 'tel',
                'email': 'email',
            }
            if (type in typeMap) {
                return typeMap[type]
            }
            return 'input'
        },
        makeInputOptionsFromQuestionOptions(options) {
            return options.filter(o => o).map(o => {
                return { text: o, value: o }
            })
        },
    },
}

</script>

<style lang="scss">
    .custom-form-wizard-question {
        &.card {
            background-color: #fff;
        }
        .card-header {
            background-color: #f6f6f6;
        }
        .handle {
            display: flex;
            justify-content: center;
            cursor: move;
            font-size: 1rem;
            margin-bottom: -4px;
        }
        .question-prompt .mui-textfield, .question-preview-wrapper .mui-textfield {
            margin-bottom: 4px;
            > input {
                border: 1px solid rgba(0, 0, 0, 0.26);
                background-color: white;
                margin-top: 8px;
                padding-left: 4px;
                padding-right: 4px;
            }
            > textarea {
                border: 1px solid rgba(0, 0, 0, 0.26);
                background-color: white;
                padding-left: 4px;
                padding-right: 4px;
                padding-top: 4px;
            }
        }
        .delete-col {
            display: flex;
            align-items: end;
            justify-content: center;
            i {
                padding-bottom: 14px;
            }
        }
        .is-required-col {
            align-items: end;
            display: flex;
            justify-content: end;
            .form-input-checkbox {
                padding-bottom: 24px;
                font-size: 14px;
            }
        }
        .question-preview-wrapper {
            background-color: #E8F4F9;
            padding: 12px;
            margin-bottom: 4px;
            .question-address .select2 {
                margin-top: 8px;
            }
            .form-input-radio {
                .list-unstyled {
                    padding-left: 0 !important;
                }
                .form-check-set {
                    padding-left: 0;
                    margin-bottom: 0 !important;
                    margin-left: 0;
                    border-left: 0;
                    .form-check {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
        .question-preview-transition {
            max-height: 500px;
            overflow-y: auto;
        }
        .expand-enter-active,
        .expand-leave-active {
            transition: max-height 0.4s;
            overflow: hidden;
        }
        .expand-enter,
        .expand-leave-to {
            max-height: 0;
            overflow: hidden;
        }
    }
</style>
