<template>
    <modal size="xl" @close="$emit('close')" ref="modal">
        <template slot="title">
          W2 Edit SSN
          <span v-if="w2detail"> - {{ w2detail.w2.tax_year }}</span>
     </template>

        <template slot="subheading">
            <div v-if="w2detail">
               {{ w2detail.w2.company.name }} / 
               {{ w2detail.w2.employee.full_name }}
            </div>
        </template>

        <slot>
            <div v-if="w2detail">
                <br>
                <p>
                    Enter this employee's SSN as it was on their {{ w2detail.w2.tax_year }} W2, regardless of whether it's their SSN today.
                </p>
                <br>
                <form-errors :errors="formErrors"/>

                <div class="form-row pb-2">
                    <div class="col">
                        <form-input  type="ssn" v-model="formData.ssn" :errors="errors.ssn" label="Corrected SSN" />
                    </div>
                </div>
            </div>
        </slot>

        <template slot="footer">
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [FormMixin, ModalMixin],
    props: ['w2reviewdetail'],
    data() {
        return {
            w2detail: null,
            dataLoaded: false,
            formRules: [],
            formData: this.makeFormData(),
        }
    },
    mounted() {
        this.w2detail = this.w2reviewdetail
    },
    watch: {
        w2reviewdetail() {
            this.w2detail = this.w2reviewdetail
        }
    },
    methods: {
        makeFormData() {
            let formData = {}
            formData = {
                ssn: '',
            }

            return formData
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let url = `/admin/w2-review/detail/${this.w2detail.w2.id}/update-ssn`

            this.$store.dispatch('START_LOADING')
            api.post(url, this.formData).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.$bus.showInfo(`SSN updated`)
                this.close()
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>
