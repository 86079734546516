<template>
<page>
<div class="nav-tabs-container pt-1">
    <div class="nav-tabs-scroll-helper">
        <ul class="nav nav-tabs">

            <li class="nav-item"><a href class="nav-link" :class="{'active': currentJurisdictionType == 'state'}" @click.prevent="currentJurisdictionType = 'state'">States</a></li>
            <li class="nav-item"><a href class="nav-link" :class="{'active': currentJurisdictionType == 'city'}" @click.prevent="currentJurisdictionType = 'city'">Cities</a></li>
            <li class="nav-item"><a href class="nav-link" :class="{'active': currentJurisdictionType == 'fed'}" @click.prevent="currentJurisdictionType = 'fed'">Federal</a></li>
            <li class="nav-item"><a href class="nav-link" :class="{'active': currentJurisdictionType == 'county'}" @click.prevent="currentJurisdictionType = 'county'">Counties</a></li>
            <li class="nav-item"><a href class="nav-link" :class="{'active': currentJurisdictionType == 'school-district'}" @click.prevent="currentJurisdictionType = 'school-district'">School Districts</a></li>
        </ul>
    </div>
</div>

<div class="flex-body flex-container">
    <div class="flex-body flex-container tab-pane" role="tabpanel">
        <div class="view">
            <fast-table
                v-show="dataLoaded"
                :sections="sections"
                :headers="headers"
                :data="tableData"
                @click="currentJurisdictionId = $event.id"
            >
                <template slot="table-actions" v-if="['city', 'county', 'school-district'].includes(currentJurisdictionType)">
                    <button type="button" class="btn btn-primary btn-collapse-md" @click.prevent="currentJurisdictionId = null; addModalOpen = true">
                        <i class="far fa-fw fa-plus"></i>
                        <span class="btn-text">Add {{ {'city': 'City', 'county': 'County', 'school-district': 'School District'}[currentJurisdictionType] }}</span>
                    </button>
                </template>
            </fast-table>

            <sidebar
                :jurisdictionId="currentJurisdictionId"
                :key="currentJurisdictionId"
                :earning-types="earningTypes"
                :deduction-types="deductionTypes"
                :involuntary-withholding-types="involuntaryWithholdingTypes"
                v-if="currentJurisdictionId"
                @updated="onUpdate($event)"
                @close="currentJurisdictionId = null"
            />

            <add-modal
                v-if="addModalOpen"
                :jurisdictionType="currentJurisdictionType"
                :jurisdictions="jurisdictions"
                @updated="onUpdate($event)"
                @close="addModalOpen = false"
            />
        </div>
    </div>
</div>
</page>
</template>

<script>
import Page from '@/components/Page'
import FastTable from '@/components/FastTable'
import Sidebar from './components/JurisdictionSidebar'
import AddModal from './components/JurisdictionModal'

export default {
    components: {FastTable, Sidebar, Page, AddModal},
    computed: {
        currentJurisdictions() {
            return this.jurisdictions.filter(x => x.type == this.currentJurisdictionType)
        },
        tableData() {
            const tableData = []

            this.currentJurisdictions.forEach((j, idx) => {
                const p = this.jurisdictions.find(x => x.id == j.parent_id)

                const row = {
                    id: idx,
                    isActive: this.currentJurisdictionId && j.id == this.currentJurisdictionId,
                    object: j,
                    cells: [
                        j.canonical_name,
                        (['city', 'county', 'school-district'].includes(j.type) && p) ? p.name : '',
                        j.updated_on,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
    },
    data() {
        return {
            currentJurisdictionType: 'state',
            jurisdictions: [],
            dataLoaded: false,
            currentJurisdictionId: null,
            addModalOpen: false,

            involuntaryWithholdingTypes: [],
            earningTypes: [],
            deductionTypes: [],

            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                {label: 'State', classes: '', isFilterable: true},
                {label: 'Last Update', classes: 'cell-date', type: 'date', isSearchable: false},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tax Engine / Jurisdictions')
        this.getJurisdictions()
        this.getInvoluntaryWithholdingTypes()
        this.getEarningTypes()
        this.getDeductionTypes()
    },
    methods: {
        getJurisdictions() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/tax-engine/jurisdictions`).then(resp => {
                this.jurisdictions = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        getInvoluntaryWithholdingTypes() {
            this.$store.dispatch('START_LOADING')
            this.$api.get('/admin/tax-engine/involuntary-withholding-types').then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.involuntaryWithholdingTypes = resp
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getEarningTypes() {
            this.$store.dispatch('START_LOADING')
            this.$api.get('/admin/tax-engine/earning-types').then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.earningTypes = resp
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getDeductionTypes() {
            this.$store.dispatch('START_LOADING')
            this.$api.get('/admin/tax-engine/deduction-types').then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.deductionTypes = resp
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdate(jurisdiction) {
            if (!jurisdiction) {
                return this.getJurisdictions()
            }
            const existing = this.jurisdictions.find(x => x.id == jurisdiction.id)
            if (existing) {
                Object.assign(existing, jurisdiction)
            }
            else {
                this.jurisdictions.push(jurisdiction)
            }
        },
    },
}

</script>
