<template>
<sidebar @close="$emit('close')">
    <template slot="title">
        W2 Review
    </template>

    <template slot="header">
        <div class="row mb-1">
            <div class="col">
                <div class="text-muted" v-if="w2detail">
                    {{ w2detail.w2.tax_year }}
                    <br>
                    {{ w2detail.w2.company.name }}
                    <br>
                    {{ w2detail.w2.employee.full_name }}
                </div>
            </div>
        </div>
    </template>

    <slot v-if="dataLoaded">
        <p class="mb-2">
            <a href class="btn btn-outline-primary mr-1" @click.prevent="openPreview"><i class="far fa-fw fa-external-link mr-1"></i>Preview W2</a>
            <a href class="btn btn-outline-primary ml-1" @click.prevent="openLastPaycheck"><i class="far fa-fw fa-external-link mr-1"></i>Last Paycheck</a>
        </p>
        <p class="mb-2">
            <a href class="btn btn-outline-primary mr-1" @click.prevent="openCompare"><i class="far fa-fw fa-external-link mr-1"></i>Compare to Filed</a>

            <a v-if="w2detail.w2.ssn_employee_mismatch" href class="btn btn-outline-primary mr-1" @click.prevent="openEditSSN"><i class="far fa-fw fa-external-link mr-1"></i>Edit SSN</a>
        </p>
        <p class="mb-2">
            <a href class="btn btn-outline-primary mr-1" @click.prevent="openMarkResolved"><i class="far fa-fw fa-external-link mr-1"></i>Mark Resolved</a>
        </p>


        <p class="mb-2 py-2 border-top border-bottom" v-if="w2detail.w2.flag_resolved_on">
            <b>Resolved On:</b> {{ w2detail.w2.flag_resolved_on|date }}
            <span v-if="w2detail.w2.flag_resolved_by">
                <br>
                <b>Resolved By:</b> {{ w2detail.w2.flag_resolved_by.email }}
            </span>
            <br>
            <b>Resolution Action:</b> {{ w2detail.w2.flag_resolved_action }}
            <br>
            <b>Resolution Note:</b> {{ w2detail.w2.flag_resolution_note }}
        </p>

        <div>
            <h4>Issues</h4>

            <ul class="pl-2">
                <li v-for="issue in w2detail.issues">
                    {{ issue }}
                </li>
            </ul>

        </div>
    </slot>

    <w2-modal :w2detail="currentW2" v-if="currentW2" ref="W2Modal" />
    <w2-compare-modal :w2reviewdetail="w2detail" v-if="w2detail" ref="W2CompareModal" />
    <w2-edit-ssn-modal :w2reviewdetail="w2detail" v-if="w2detail" ref="W2EditSSNModal" />
    <w2-mark-resolved-modal :w2reviewdetail="w2detail" v-if="w2detail" ref="W2MarkResolvedModal" />
    <paycheck-modal :client="client" :paycheck="lastPaycheck" v-if="lastPaycheck" ref="paycheckModal" />
    
</sidebar>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import Sidebar from '@/components/Sidebar'
import W2Modal from '@/components/W2Modal'
import W2CompareModal from './W2CompareModal'
import W2EditSsnModal from './W2EditSSNModal'
import W2MarkResolvedModal from './W2MarkResolvedModal'
import PaycheckModal from '@/components/PaycheckModal'

export default {
    components: {Sidebar, W2Modal, W2CompareModal, W2EditSsnModal, W2MarkResolvedModal, PaycheckModal},
    props: ['w2reviewdetail', ],
    data() {
        return {
            dataLoaded: false,
            w2detail: null,
            currentW2: null,
            client: null,
            lastPaycheck: null,
        }
    },
    mounted() {
        this.getW2ReviewDetail()
    },
    watch: {
        w2reviewdetail() {
            this.getW2ReviewDetail()
        }
    },
    methods: {
        getW2ReviewDetail() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            const url = `/admin/w2-review/detail/${this.w2reviewdetail.id}`
            api.get(url).then(resp => {
                this.w2detail = resp
                this.currentW2 = resp['w2']
                this.client = resp['client']
                this.lastPaycheck = resp['last_paycheck']
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__[0])
            })
        },
        openPreview() {
            this.$refs.W2Modal.open()
        },
        openCompare() {
            this.$refs.W2CompareModal.open()
        },
        openEditSSN() {
            this.$refs.W2EditSSNModal.open()
        },
        openMarkResolved() {
            this.$refs.W2MarkResolvedModal.open()
        },
        openLastPaycheck() {
            this.$refs.paycheckModal.open()
        },
    },
}
</script>
