<template>
    <modal size="xl" @close="$emit('close')" ref="modal" :is-closeable="true" :autoshow="true">
        <template slot="title">Pay Stub</template>

        <slot>
            <pre>
                <div v-for="line in paycheck.slice(1)">{{ line.replace('\u001b[1m', '').replace('\u001b[0m', '') }}&nbsp;</div>
            </pre>
        </slot>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['paycheck'],
    mixins: [ModalMixin, ],
}
</script>
