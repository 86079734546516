<template>
<div>
    <fast-table
        v-show="dataLoaded"
        :sections="sections"
        :headers="headers"
        :data="tableData"
        @click="currentStateId = $event.id"
    />

    <sidebar
        :state="currentState"
        :key="currentStateId"
        :all-states="states"
        :earning-types="earningTypes"
        :deduction-types="deductionTypes"
        :involuntary-withholding-types="involuntaryWithholdingTypes"
        v-if="currentStateId"
        @updated="onUpdate($event)"
        @close="currentStateId = null"
    />
</div>
</template>

<script>
import FastTable from '@/components/FastTable'
import Sidebar from './components/StateSidebar'

export default {
    components: {FastTable, Sidebar, },
    computed: {
        currentState() {
            return this.states.find(s => s.id == this.currentStateId)
        },
        tableData() {
            const tableData = []

            this.states.forEach((s, idx) => {
                let numTaxFieldsEnabled = 0
                if (s.tax_withholding_configs.length) {
                    s.tax_withholding_configs.forEach((config, idx) => {
                        numTaxFieldsEnabled += config.num_fields_enabled
                    })
                }

                const row = {
                    id: idx,
                    isActive: this.currentState && s.id == this.currentState.id,
                    object: s,
                    cells: [
                        s.name,
                        numTaxFieldsEnabled,
                        s.updated_on,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
    },
    data() {
        return {
            states: [],
            dataLoaded: false,
            currentStateId: null,

            involuntaryWithholdingTypes: [],
            earningTypes: [],
            deductionTypes: [],

            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Tax Withholding Fields', classes: 'cw-11', isSearchable: false},
                {label: 'Last Update', classes: 'cell-date', type: 'date', isSearchable: false},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tax Engine / States')
        this.getStates()
        this.getInvoluntaryWithholdingTypes()
        this.getEarningTypes()
        this.getDeductionTypes()
    },
    methods: {
        getStates() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/tax-engine/states`).then(resp => {
                this.states = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        getInvoluntaryWithholdingTypes() {
            this.$store.dispatch('START_LOADING')
            this.$api.get('/admin/tax-engine/involuntary-withholding-types').then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.involuntaryWithholdingTypes = resp
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getEarningTypes() {
            this.$store.dispatch('START_LOADING')
            this.$api.get('/admin/tax-engine/earning-types').then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.earningTypes = resp
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getDeductionTypes() {
            this.$store.dispatch('START_LOADING')
            this.$api.get('/admin/tax-engine/deduction-types').then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.deductionTypes = resp
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdate(state) {
            if (!state) {
                return this.getStates()
            }
            const existing = this.states.find(s => {return s.id == state.id})
            if (existing) {
                Object.assign(existing, state)
            }
        },
    },
}

</script>
