<template>
    <modal :autoshow="true" size="xl" ref="modal" @close="$emit('close')" :is-closeable="false" :close-warning-message="closeWarningMessage">

        <template slot="title">
            {{ stepTitle }}
        </template>

        <component
            :is="step"
            :prev="prev"
            ref="stepComponent"
            :employee="employee"
            :employeeUpdate="employeeUpdate"
            @patch="onPatch"
            @next="onNextStep"
            @prev="onPrevStep"
            @updated="onUpdated"
        />

        <template slot="footer">
            <portal-target name="address-edit-modal-footer"></portal-target>
        </template>
    </modal>
</template>

<script>
const STEP_ORDER = [
    'edit-address',
    'correct-address',
    'choose-geocode',
    'choose-school-district',
    'form-selection',
    'complete-forms',
    'confirm-address-update',
]
const STEP_TITLES = {
    'default': "Edit Address",
    'correct-address': "Review Address",
    'choose-geocode': "Resident Address Details",
    'choose-school-district': "Resident School District",
    'form-selection': "Tax Form Updates",
    'complete-forms': "Complete Forms",
    'confirm-address-update': "Confirm Address Update"
}

import ModalMixin from '@/mixins/ModalMixin'
import EditAddress from './EmployeeAddressEditStep.vue'
import CorrectAddress from './EmployeeAddressCorrectionStep.vue'
import ChooseGeocode from './EmployeeAddressGeocodeStep.vue'
import ChooseSchoolDistrict from './EmployeeAddressSchoolDistrictStep.vue'
import FormSelection from './EmployeeFormSelectionStep.vue'
import CompleteForms from './EmployeeFormFillStep.vue'
import ConfirmAddressUpdate from './EmployeeAddressConfirmStep.vue'
import api from '@/api'

export default {
    mixins: [ModalMixin, ],
    components: {EditAddress, CorrectAddress, ChooseGeocode, ChooseSchoolDistrict, FormSelection, CompleteForms, ConfirmAddressUpdate},
    props: ['employee'],
    watch: {
        employee() {
            this.reset()
        },
    },
    computed: {
        stepTitle() {
            if (this.step in STEP_TITLES) {
                return STEP_TITLES[this.step]
            } else {
                return STEP_TITLES['default']
            }
        },
    },
    data() {
        return {
            step: STEP_ORDER[0],
            employeeUpdate: null,
            prev: false,
            closeWarningMessage: "You have not saved your changes. Are you sure you want to discard them?"
        }
    },
    methods: {
        getNextStep(step) {
            return STEP_ORDER[STEP_ORDER.indexOf(step) + 1]
        },
        getPrevStep(step) {
            return STEP_ORDER[STEP_ORDER.indexOf(step) - 1]
        },
        onPrevStep() {
            this.prev = true
            let step = this.getPrevStep(this.step)

            if ((step == 'complete-forms') && (!this.employeeUpdate.has_form_fill_step)) {
                step = this.getPrevStep(step)
            }
            if ((step == 'form-selection') && (!this.employeeUpdate.has_form_fill_step)) {
                step = this.getPrevStep(step)
            }
            if ((step == 'choose-school-district') && (!this.employeeUpdate.has_school_district_disambiguation_step)) {
                step = this.getPrevStep(step)
            }
            if ((step == 'choose-geocode') && (!this.employeeUpdate.has_geocode_disambiguation_step)) {
                step = this.getPrevStep(step)
            }
            if ((step == 'correct-address') && (!this.employeeUpdate.has_address_correction_step)) {
                step = this.getPrevStep(step)
            }

            if (!step) {
                this.$emit('close')
            }
            this.step = step
        },
        onNextStep() {
            this.prev = false
            let step = this.getNextStep(this.step)

            if ((step == 'correct-address') && !this.employeeUpdate.has_address_correction_step) {
                step = this.getNextStep(step)
            }
            if ((step == 'choose-geocode') && (!this.employeeUpdate.has_geocode_disambiguation_step)) {
                step = this.getNextStep(step)
            }
            if ((step == 'choose-school-district') && (!this.employeeUpdate.has_school_district_disambiguation_step)) {
                step = this.getNextStep(step)
            }
            if ((step == 'form-selection') && (!this.employeeUpdate.has_form_fill_step)) {
                step = this.getNextStep(step)
            }
            if ((step == 'complete-forms') && (!this.employeeUpdate.has_form_fill_step)) {
                step = this.getNextStep(step)
            }

            if (!step) {
                this.$emit('close')
            }
            this.step = step
        },
        onPatch(update) {
            if (update) {
                this.employeeUpdate = update
            } else {
                this.getEmployeeUpdate()
            }
        },
        getEmployeeUpdate() {
            this.$store.dispatch('START_LOADING')
            api.get(`/me/ess/companies/${this.employee.company_id}/updates/${this.employeeUpdate.id}`, {}).then((resp) => {
                this.employeeUpdate = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdated() {
            this.$emit('updated')
            this.$emit('close')
        },
        reset() {
            this.employeeUpdate = null
            this.step = STEP_ORDER[0]
        },
    },
}
</script>
