<template>
    <page>
        <fast-table
            v-show="dataLoaded"
            :sections="sections"
            :headers="headers"
            :tableClasses="tableClasses"
            :data="tableData"
            @click="currentCompany = $event"
            />

        <sidebar :company="currentCompany" v-if="currentCompany" ref="sidebar" @updated="onUpdate($event)" @close="currentCompany = null" />
    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Sidebar from './components/CompanySidebar'
import Page from '@/components/Page'

export default {
    components: {FastTable, Sidebar, Page},
    props: ['client', ],
    computed: {
        tableData() {
            const tableData = []

            this.companies.forEach((c, idx) => {
                const row = {
                    id: idx,
                    isActive: this.currentCompany && c.id == this.currentCompany.id,
                    object: c,
                    cells: [
                        c.name,
                        c.company_code,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
    },
    data() {
        return {
            companies: [],
            dataLoaded: false,
            currentCompany: null,

            tableClasses: 'table-anchor--shorttext',
            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Company Code', classes: 'cell-medium', isSearchable: true},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', `Client ${this.client.client_code} - ${this.client.name} / Companies`)
        this.getCompanies()
    },
    methods: {
        getCompanies() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/clients/${this.client.id}/companies`).then((resp) => {
                this.companies = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onUpdate(company) {
            const existing = this.companies.find(c => {return c.id == company.id})
            if (existing) {
                Object.assign(existing, company)
            }
        },
    },
}

</script>
