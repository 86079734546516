<template>
<div>
    <!-- Heading row - mimic the row structure below -->
    <div class="form-row py-1 d-none d-lg-flex">
        <div class="col-auto"><span class="cw-toggle d-inline-block"></span></div>
        <div class="col unpad-l">
            <div class="form-row">
                <div class="col-lg-3 col-xl-4"><span class="theading">Company</span></div>
                <div class="col"><span class="theading">Role</span></div>
            </div>
        </div>
    </div>

    <div v-for="cr in companyRoles" :key="cr.company.id" class="form-row py-1 border-bottom">
        <!-- Toggle button -->
        <div class="col-auto">
            <button v-if="$store.state.security_roles.PAYROLL_ADMIN_MANAGED_ROLES.includes(cr.role)" class="btn-reset widget-toggle-wrapper collapse-trigger" :class="{'is-expanded': openSummaryCompanies[cr.company.id]}" @click.prevent="toggleSummaryCompany(cr.company.id)">
                <collapse-toggle-button />
            </button>
            <!-- Spacer to hold the same position as the collapse toggle -->
            <span v-else class="cw-toggle d-inline-block"></span>
        </div>
        <div class="col unpad-l">
            <!-- Inner row 1: top summary -->
            <div class="form-row">
                <div class="col-12 col-lg-3 col-xl-4">
                    <span class="btnlike">{{ cr.company.name }}</span>
                </div>

                <div class="col mt-n1 mt-lg-0">
                    <span class="btnlike" v-if="$store.state.security_roles.POSITION_LEVEL_ROLES.includes(cr.role)">
                        <strong>{{ cr.role_pretty }}</strong> for {{ cr.positions.length }} {{ cr.positions.length == 1 ? 'position' : 'positions' }}
                    </span>
                    <span class="btnlike" v-if="$store.state.security_roles.LOCATION_LEVEL_ROLES.includes(cr.role)">
                        <strong>{{ cr.role_pretty }}</strong> for {{ cr.work_locations.length }} {{ cr.work_locations.length == 1 ? 'location' : 'locations' }}
                    </span>
                    <span class="btnlike" v-if="$store.state.security_roles.COMPANY_LEVEL_ROLES.includes(cr.role)">
                        <strong>{{ cr.role_pretty }}</strong>
                    </span>
                    <span class="btnlike" v-if="!cr.role">
                        <span class="text-empty-state">No access</span>
                    </span>
                </div>

                <div class="col-12 col-md-auto mt-md-n1 mt-lg-0" v-if="showEditButtons">
                    <button class="btn btn-link px-0 px-md-1 btn-block text-left text-md-center" v-if="$store.state.security_roles.LOCATION_LEVEL_ROLES.includes(cr.role)" @click.prevent="$emit('edit_locations', cr.company.id)">Edit Locations</button>
                    <button class="btn btn-link px-0 px-md-1 btn-block text-left text-md-center" v-if="$store.state.security_roles.POSITION_LEVEL_ROLES.includes(cr.role)" @click.prevent="$emit('edit_positions', cr.company.id)">Edit Positions</button>
                </div>

                <div class="col-12 col-md-auto mt-md-n1 mt-lg-0" v-if="showEditButtons">
                    <button class="btn btn-link px-0 px-md-1 btn-block text-left text-md-center" v-if="cr.role" @click.prevent="$emit('edit_role', cr.company.id)">Change Role</button>
                </div>

                <div class="col-12 col-md-auto mt-md-n1 mt-lg-0" v-if="showEditButtons">
                    <button class="btn btn-link px-0 px-md-1 btn-block text-left text-md-center" v-if="cr.role" @click.prevent="removeRole(cr.company.id)">Remove Role</button>
                    <button class="btn btn-link px-0 px-md-1 btn-block text-left text-md-center" v-if="!cr.role" @click.prevent="$emit('edit_role', cr.company.id)">Assign Role</button>
                </div>
            </div>
            <!-- Inner row 2: Access details -->
            <div class="py-1" v-if="openSummaryCompanies[cr.company.id]">
                <div class="form-row">
                    <div class="col offset-lg-3 offset-xl-4" v-if="$store.state.security_roles.POSITION_LEVEL_ROLES.includes(cr.role)">
                        <div v-for="pos in cr.positions" :key="'position-' + pos.id" class="text-small mb-qt">
                            {{ pos.terminal_value }}
                        </div>
                    </div>
                    <div class="col offset-lg-3 offset-xl-4" v-if="$store.state.security_roles.LOCATION_LEVEL_ROLES.includes(cr.role)">
                        <div v-for="w in cr.work_locations" :key="'work-location-' + w.id" class="text-small mb-qt">
                            {{ w.name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['companyRoles', 'showEditButtons', ],
    data() {
        return {
            openSummaryCompanies: {},
        }
    },
    methods: {
        toggleSummaryCompany(companyId) {
            this.$set(this.openSummaryCompanies, companyId, !this.openSummaryCompanies[companyId])
        },
        removeRole(companyId) {
            this.$emit('remove_role', companyId)
            this.openSummaryCompanies[companyId] = false
        },
    },
}
</script>
