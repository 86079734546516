<template>
    <modal size="xl" @close="$emit('close')" ref="modal" :is-closeable="isCloseable" :autoshow="true">
        <template slot="title">
            <span v-if="deductionType">Update Deduction Type: </span><span v-if="deductionType" class="text-thin text-nowrap">{{ deductionType.name }}</span>
            <span v-if="!deductionType">Add New Deduction Type</span>
        </template>
        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <div class="form-row mb-2">
                    <div class="col-6">
                        <form-input v-model="formData.starts_on" :errors="errors.starts_on" type="date" label="Starts On" />
                    </div>
                    <div class="col-6">
                        <form-input v-model="formData.inactive_on" :errors="errors.inactive_on" type="date" label="Expires On" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.name" :errors="errors.name" type="text" label="Name" :readonly="!!deductionType" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.for_primary_benefit_of_employer" :errors="errors.for_primary_benefit_of_employer" type="checkbox" label="For Primary Benefit of Employer" />
                    </div>
                </div>
            </form>
        </slot>

        <template slot="footer">
            <form-errors :errors="formErrors"/>

            <button-row>
                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['deductionType', ],
    mixins: [FormMixin, ModalMixin, ],
    computed: {
        typeOptions() {
            return [
                {text: 'Hourly', value: 'hourly'},
                {text: 'Per-unit', value: 'per-unit'},
                {text: 'Amount', value: 'amount'},
            ]
        },
    },
    data() {
        return {
            states: [],
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            if (this.deductionType) {
                return {
                    name: this.deductionType.name,
                    for_primary_benefit_of_employer: this.deductionType.current_version.for_primary_benefit_of_employer,
                    starts_on: this.deductionType.current_version.starts_on,
                    inactive_on: this.deductionType.current_version.inactive_on,
                }
            } else {
                return {
                    name: '',
                    for_primary_benefit_of_employer: false,
                    starts_on: '',
                    inactive_on: '',
                }
            }
        },
        getFormData() {
            return this.formData
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let promise
            if (this.deductionType) {
                promise = this.$api.put(`/admin/tax-engine/deduction-types/${this.deductionType.id}`, this.getFormData())
            }
            else {
                promise = this.$api.post(`/admin/tax-engine/deduction-types`, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>
