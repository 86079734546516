<template>
    <modal size="xl" @close="$emit('close')" ref="modal" :is-closeable="isCloseable" :autoshow="true">
        <template slot="title">
            <span v-if="jurisdiction">Update {{ {'city': 'City', 'county': 'County', 'school-district': 'School District'}[jurisdictionType] }}: </span><span v-if="jurisdiction" class="text-thin text-nowrap">{{ jurisdiction.canonical_name }}</span>
            <span v-if="!jurisdiction">Add New {{ {'city': 'City', 'county': 'County', 'school-district': 'School District'}[jurisdictionType] }}</span>
        </template>
        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <form-errors :errors="formErrors"/>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.name" :errors="errors.name" type="text" label="Name" />
                    </div>
                </div>

                <div class="form-row" v-show="jurisdictionType == 'state'">
                    <div class="col">
                        <form-input v-model="formData.short_name" :errors="errors.short_name" type="text" label="Short Name" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.parent" :errors="errors.parent" type="select" :label="parentJurisdictionLabel" :options="parentJurisdictionOptions" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.alternative_names" :errors="errors.alternative_names" type="textarea" label="Alternative Names" />
                    </div>
                </div>

            </form>
        </slot>

        <template slot="footer">
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['jurisdiction', 'jurisdictions', 'jurisdictionType', ],
    mixins: [FormMixin, ModalMixin, ],
    computed: {
        parentJurisdictionLabel() {
            const LABELS = {
                'state': 'Federal',
                'city': 'State',
                'county': 'State',
                'school-district': 'City',
                'fed': 'None',
            }

            return LABELS[this.jurisdictionType]
        },
        parentJurisdictionOptions() {
            const opts = []
            const PARENT_TYPE_CHOICES = {
                'state': 'fed',
                'city': 'state',
                'county': 'state',
                'school-district': 'city',
                'fed': null,
            }
            this.jurisdictions.filter(j => j.type == PARENT_TYPE_CHOICES[this.jurisdictionType]).forEach(j => {
                opts.push({text: j.canonical_name, value: j.id})
            })
            opts.sort((a, b) => {
                return a.text > b.text ? 1 : -1
            })
            return opts
        },
    },
    data() {
        return {
            states: [],
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            if (this.jurisdiction) {
                return {
                    name: this.jurisdiction.name,
                    short_name: this.jurisdiction.short_name,
                    parent: this.jurisdiction.parent_id,
                    alternative_names: this.jurisdiction.alternative_names.join('\n'),
                    type: this.jurisdictionType,
                }
            } else {
                return {
                    name: '',
                    short_name: '',
                    parent: '',
                    alternative_names: '',
                    type: this.jurisdictionType,
                }
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let promise
            if (this.jurisdiction) {
                promise = this.$api.put(`/admin/tax-engine/jurisdictions/${this.jurisdiction.id}`, this.getFormData())
            }
            else {
                promise = this.$api.post(`/admin/tax-engine/jurisdictions`, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>

