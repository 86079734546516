<template>
    <sidebar @close="$emit('close')" v-if="taxExemptionsGroup">
        <template slot="title">
            {{ taxExemptionsGroup.name }}
        </template>
        <slot>

            <div class="mb-2">
                <button class="btn btn-primary" type="button" @click.prevent="editModalOpen = true">Edit</button>
            </div>

            <exempt-earning-types-sidebar-widget
                :tax-exemptions-group="taxExemptionsGroup"
                :earning-types="earningTypes"
                @updated="$emit('updated')"
            />

            <exempt-deduction-types-sidebar-widget
                :tax-exemptions-group="taxExemptionsGroup"
                :deduction-types="deductionTypes"
                @updated="$emit('updated')"
            />

            <edit-modal
                v-if="editModalOpen"
                :tax-exemptions-group="taxExemptionsGroup"
                @updated="$emit('updated', $event)"
                @close="editModalOpen = false"
            />

        </slot>
    </sidebar>
</template>

<script>
import ExemptEarningTypesSidebarWidget from './ExemptEarningTypesSidebarWidget'
import ExemptDeductionTypesSidebarWidget from './ExemptDeductionTypesSidebarWidget'
import EditModal from './TaxExemptionsGroupModal'
import Sidebar from '@/components/Sidebar'

export default {
    components: {Sidebar, ExemptEarningTypesSidebarWidget, ExemptDeductionTypesSidebarWidget, EditModal, },
    props: ['taxExemptionsGroupId', 'deductionTypes', 'earningTypes', ],
    computed: {
        hasBeenUsed() {
            return true
        },
    },
    data() {
        return {
            editModalOpen: false,
            taxExemptionsGroup: null
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/tax-engine/tax-exemptions-groups/${this.taxExemptionsGroupId}`).then(resp => {
                this.taxExemptionsGroup = resp
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onDelete() {
            if (!confirm("Are you sure?")) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.del(`/admin/tax-engine/tax-exemptions-groups/${this.taxExemptionsGroupId}`).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>

