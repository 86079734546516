<template>
    <div id="client" v-if="$store.state.user">
        <portal v-if="client && client.preview_dashboard" to="mainNavbarClientSection">
            <main-navbar-client-section :client="client"/>
        </portal>
        <transition name="fade" mode="out-in">
            <router-view class="flex-page-wrapper flex-container flex-body view" v-if="dataLoaded" :client="client" />
        </transition>
    </div>
</template>

<script>
import MainNavbarClientSection from '@/components/MainNavbarClientSection'

export default {
    components: {MainNavbarClientSection},
    data() {
        return {
            dataLoaded: false,
            client: null,
        }
    },
    mounted() {
        this.$store.dispatch('GET_USER').then(() => {
            if (this.pendingLinkClientIds.includes(parseInt(this.clientId))) {
                this.$router.push({name: 'link-client', params: {hashedClientId: this.$route.params.hashedClientId}})
                return
            }

            this.getClient()
            this.getWarnings()
        })
        this.$bus.$on('client_data_updated', updates => {
            if (updates.indexOf('security_profiles') >= 0 || updates.indexOf('companies') >= 0 ) {
                this.$api.get(`/clients/${this.clientId}`).then((resp) => {
                    this.client = resp
                }).catch((errors) => {
                    this.$bus.showError(errors.__all__)
                })
            }
        })
    },
    computed: {
        clientId() {
            return this.$hasher.decode(this.$route.params.hashedClientId)
        },
        pendingLinkClientIds() {
            let result = []
            if (! this.$store.state.user) {
                return result
            }
            this.$store.state.user.employer_pending_link_clients.forEach((c) => {
                result.push(c.id)
            })

            return result
        },
    },
    methods: {
        getClient() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${this.clientId}`).then((resp) => {
                this.client = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getWarnings() {
            if (this.$route.name == 'critical-warnings') {
                return
            }

            const next = this.$route.fullPath
            const url = `/clients/${this.clientId}/warnings/active`

            return this.$api.get(url).then(resp => {
                if (resp.filter(w => w.is_critical).length) {
                    this.$router.replace({name: 'critical-warnings', params: {hashedClientId: this.$route.params.hashedClientId}, query: {next: next}})
                }
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
            })
        },
    }
}
</script>
