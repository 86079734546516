<script>
import Worker from 'worker-loader!./filter.worker.js'

export default {
    data() {
        return {
            filteredDataIds: null,
            filterWorker: null,
        }
    },
    watch: {
        filters() {
            this.performFiltering()
        },
        data() {
            this.populateWorkerData()
        },
    },
    created() {
        this.filterWorker = new Worker()
        this.filterWorker.addEventListener('message', (m) => {
            if (m.data.action == 'filter') {
                this.$set(this, 'filteredDataIds', m.data.result)
            }
        })
    },
    destroyed() {
        if (this.filterWorker) {
            this.filterWorker.terminate()
            this.filterWorker = null
        }
    },
    computed: {
        filteredData() {
            if (this.filteredDataIds) {
                const result = []

                this.filteredDataIds.forEach((sectionIds, sectionIdx) => {
                    sectionIds = new Set(sectionIds)
                    result[sectionIdx] = this.data[sectionIdx].filter(r => sectionIds.has(r.id))
                })
                return result
            }
            return this.data
        },
    },
    methods: {
        populateWorkerData() {
            const headers = JSON.parse(JSON.stringify(this.headers))  // May contain methods
            this.filterWorker.postMessage({
                action: 'create',
                headers: headers,
                sections: this.sections,
                data: this.data,
                managers: this.managers,
            })
        },
        performFiltering() {
            if (this.filters && this.filters.length) {
                this.filterWorker.postMessage({
                    action: 'filter',
                    filters: this.filters,
                })
            }
            else {
                this.filteredDataIds = null
            }
        },
    },
}
</script>
