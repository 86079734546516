<template>
<section class="item-details-section">
    <div>
        <span class="text-semibold">Date range: </span>
        <span>{{ version.starts_on|date("MM/DD/YYYY") }}</span>
        <span v-if="version.ends_on"> - {{ version.ends_on|date("MM/DD/YYYY") }}</span>
        <span v-else>+</span>
    </div>

    <div>
        <span class="text-semibold">Minimum Wage: </span>
        <span>${{ version.minimum_wage|formatCurrency }}</span>
    </div>

    <div>
        <span class="text-semibold">Max Tip Credit: </span>
        <span>${{ version.max_tip_credit_claimed|formatCurrency }}</span>
    </div>

    <div>
        <span class="text-semibold">Minor Minimum Wage: </span>
        <span v-if="version.minor_minimum_wage">${{ version.minor_minimum_wage|formatCurrency }}</span>
        <span v-else>N/A</span>
    </div>

    <div>
        <span class="text-semibold">Minor Max Tip Credit: </span>
        <span v-if="version.minor_max_tip_credit_claimed">${{ version.minor_max_tip_credit_claimed|formatCurrency }}</span>
        <span v-else>N/A</span>
    </div>

    <div>
        <span class="text-semibold">Minor Age: </span>
        <span v-if="version.minor_age_years">{{ version.minor_age_years }} years, {{ version.minor_age_months }} months</span>
        <span v-else>N/A</span>
    </div>

</section>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'
export default {
    components: {InfoWidget, },
    props: ['version', ],
    data() {
        return {
            isExpanded: false,
        }
    },
}
</script>
