<template>
    <modal @close="$emit('close')" ref="modal" :is-closeable="isCloseable" :autoshow="true">
        <template slot="title">
            <h3 class="modal-title">Upload HR Document: <span class="text-thin text-nowrap">{{ employee.full_name }}</span></h3>
        </template>

        <slot>
            <form id="pm-employee-doc-upload-form" class="mui-form" @submit.prevent="onSubmit()">

                <div class="form-row pb-2">
                    <div class="col">
                        <form-input @upload="onFileUpload($event)" :errors="errors.file" label="Upload your document:" helper-text="File extension must be .pdf" type="file" accept=".pdf"/>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.name" :errors="errors.name" label="Document Name" type="text" />
                    </div>
                </div>

            </form>
        </slot>

        <template slot="footer">
            <form-errors :errors="formErrors"/>

            <button-row>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button form="pm-employee-doc-upload-form" type="submit" class="btn btn-primary" @click.prevent="onSubmit()">Upload</button>
            </button-row>

        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import moment from 'moment'

export default {
    components: {},
    mixins: [ModalMixin, FormMixin, ],
    props: ['employee', 'client'],
    data() {
        return {
            formData: {
                name: '',
                file: null,
            },
            formRules: {
                name: {presence: {allowEmpty: false}, },
                file: {presence: {allowEmpty: false}, },
            },
        }
    },
    methods: {
        onFileUpload(f) {
            this.formData.file = f[0]
            this.$set(this.formData, 'name', f[0].name.toUpperCase())
        },
        getUploadFormData() {
            return {
                name: this.formData.name,
                date: moment().format('MM/DD/YYYY'),
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.upload(`/clients/${this.client.id}/employees/${this.employee.id}/documents`, this.formData.file, {fieldName: 'file', formData: this.getUploadFormData()}).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.$bus.showInfo('Document uploaded successfully.')
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        }
    },
}
</script>
