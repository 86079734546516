<template>
    <modal :autoshow="true" size="xl" ref="modal" @close="$emit('close')" :is-closeable="isCloseable">
        <template slot="title">
            <template v-if="contact">Edit Emergency Contact {{ contact.idx }}</template>
            <template v-else>Add Emergency Contact</template>
        </template>

        <form @submit.prevent="onSubmit">
            <form-errors :errors="formErrors"/>
            <div>
                <div class="row mb-1">
                    <div class="col-12">
                        <emergency-contact-form
                            :instance="formData"
                            ref="emergencyContactForm"
                            @update="onEmergencyContactUpdate($event)"
                        />
                    </div>
                </div>
            </div>
        </form>

        <template slot="footer">
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit">Save</button>
                </div>
            </div>
        </template>

    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import FormMixin from '@/mixins/Form'
import api from '@/api'

import EmergencyContactForm from '@/components/EmergencyContactForm'

export default {
    mixins: [ModalMixin, FormMixin],
    components: {EmergencyContactForm},
    props: ['employee', 'contact'],
    watch: {
        contact() {
            this.reset()
        }
    },
    data() {
        const data = {
            formData: this.makeFormData(),
        }
        return data
    },
    methods: {
        makeFormData() {
            let formData = {
                first_name: '',
                last_name: '',
                relationship: '',
                email: '',
                phone: '',
            }
            if (this.contact) {
                formData = {
                    id: this.contact.id,
                    first_name: this.contact.first_name,
                    last_name: this.contact.last_name,
                    relationship: this.contact.relationship,
                    email: this.contact.email,
                    phone: this.contact.phone,
                }
            }
            return formData
        },
        onEmergencyContactUpdate(data) {
            this.formData = data
        },
        onSubmit() {
            if (!this.$refs.emergencyContactForm.validate()) {
                return
            }

            let promise
            if (this.contact) {
                promise = api.put(`/me/ess/companies/${this.employee.company_id}/emergency-contacts/${this.contact.id}`, {data: this.getFormData()})
            } else {
                promise = api.post(`/me/ess/companies/${this.employee.company_id}/emergency-contacts`, {data: this.getFormData()})
            }

            this.$store.dispatch('START_LOADING')
            promise.then((resp) => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.close()
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reset: function() {
            this.formData = this.makeFormData()
            this.formErrors = []
            FormMixin.methods.reset.call(this)
        },
    }
}
</script>
