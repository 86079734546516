<template>
    <div>
        <div class="flex-body pb-2">
            <div>
                <div class="mx-auto d-flex flex-column justify-content-center">
                    <!-- Summary container -->
                    <div class="py-2">
                        <p class="lead text-semibold">
                            The following data was found on the POS system:
                        </p>
                        <!-- Big metrics -->
                        <div class="pb-4">
                            <div class="d-flex flex-wrap">
                                <div class="mr-8">
                                    <div class="text-display1">
                                        {{ this.taskDetail.payroll_summary_stats.total_employees_for_payroll }}
                                    </div>
                                    <div class="theading">
                                        Employees
                                    </div>
                                </div>
                                <div class="mr-8">
                                    <div class="text-display1">
                                        {{ this.taskDetail.payroll_summary_stats.total_hours_reg }}
                                    </div>
                                    <div class="theading">
                                        Regular Hours
                                    </div>
                                </div>
                                <div class="mr-8">
                                    <div class="text-display1">
                                        {{ this.taskDetail.payroll_summary_stats.total_hours_ot }}
                                    </div>
                                    <div class="theading">
                                        Overtime Hours
                                    </div>
                                </div>
                                <div class="mr-8" v-if="this.taskDetail.payroll_summary_stats.total_hours_other">
                                    <div class="text-display1">
                                        {{ this.taskDetail.payroll_summary_stats.total_hours_other }}
                                    </div>
                                    <div class="theading">
                                        Other Hours
                                    </div>
                                </div>
                                <div class="mr-8" v-if="this.taskDetail.payroll_summary_stats.total_other_earnings">
                                    <div class="text-display1">
                                        ${{ this.taskDetail.payroll_summary_stats.total_other_earnings }}
                                    </div>
                                    <div class="theading">
                                        Other Earnings
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Smaller summary statements -->
                        <p class="lead mb-hf" v-if="this.taskDetail.skipped_data_summary.num_employees">
                            <i aria-hidden="true" class="fas fa-fw fa-exclamation-triangle text-warning mr-1"></i> 
                            <strong class="text-bold text-warning-dark">
                                {{ this.taskDetail.skipped_data_summary.num_employees }}
                            </strong> employees will be skipped. 
                            <strong class="text-semibold">
                                {{ this.taskDetail.skipped_data_summary.total_hours_reg }}
                            </strong> regular and
                            <strong class="text-semibold">
                                {{ this.taskDetail.skipped_data_summary.total_hours_ot }}
                            </strong> OT
                            hours will be skipped.
                        </p>
                    </div>
                    <!-- END Summary container -->

                    <!-- Warnings container -->
                    <div class="py-1">
                        <div class="table-section">
                            <a href class="table-section-titlebar collapse-trigger" role="button" :class="{'is-expanded': warningsExpanded}" @click.prevent="warningsExpanded = !warningsExpanded">
                                <span class="widget-toggle-wrapper">
                                    <collapse-toggle-button />
                                </span>
                                <h5 class="table-section-title">
                                  Warnings <span class="text-regular">({{ this.taskDetail.skipped_data_summary.skipped_records.length }})</span>
                                </h5>
                            </a>

                            <div class="collapse" :class="{'show': warningsExpanded}">
                                <div class="py-2">
                                    <div class="tr-wrapper tr-hasissue issue-blocker p-2" v-for="record in this.taskDetail.skipped_data_summary.skipped_records">
                                        <div class="row row-alt">
                                            <div class="col-md-3">
                                                <div class="d-flex">
                                                    <div class="flex-grow-0">
                                                        <span class="counter counter-warnings mr-1">
                                                            <i aria-hidden="true" class="counter-icon fas fa-fw fa-exclamation-triangle"></i>
                                                            <span class="counter-label d-inline-block w-1r opacity-0">1 </span>
                                                            <span class="sr-only">warnings</span>
                                                        </span>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <h4 class="mb-0">
                                                            {{ record.Name }}
                                                        </h4>
                                                        <div class="pt-qt text-muted d-flex align-items-center">
                                                            <span class="mr-1" v-if="record.Payroll_ID">{{ record.Payroll_ID }}</span>
                                                        </div>
                                                        <div class="pt-qt text-muted d-flex align-items-center" v-if="record.Store">
                                                            <b>Store: {{ record.Store }}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <h4>
                                                    {{ record.title }}
                                                </h4>
                                                <div class="text-danger-dark">
                                                    <em>Recorded hours will not be imported.</em>
                                                </div>
                                                <div v-if="record.reason_code == 'mismatch'">
                                                    <div class="card card-body card-inset float-left mt-1 py-hf px-1">
                                                        <div class="text-800">
                                                            <div>
                                                                <span class="d-inline-block" style="width: 4rem;">POS:</span>
                                                                <span class="text-semibold">
                                                                    {{ record.Name_in_POS }}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span class="d-inline-block" style="width: 4rem;">Payroll:</span>
                                                                <span class="text-semibold">
                                                                    {{ record.Name_in_Payroll }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="p-2">
                                                    {{ record.Reg_Hrs }} Reg Hrs
                                                    <br>
                                                    <span v-if="record.OT_Hrs">
                                                        {{ record.OT_Hrs }} OT Hrs
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <h5 class="theading mb-hf">
                                                    Suggestion
                                                </h5>
                                                <div>
                                                    {{ record.suggestion }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <br>
                                    <a class="btn btn-outline-primary btn-sm" :href="downloadWarningsURL">
                                        <i class="far fa-fw fa-file-excel"></i> <span class="btn-text">Download</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END Warnings container -->

                    <!-- Locations (Stores) container -->
                    <div class="py-1">
                        <div class="table-section">
                            <a href class="table-section-titlebar collapse-trigger" role="button" :class="{'is-expanded': locationsExpanded}" @click.prevent="locationsExpanded = !locationsExpanded">
                                <span class="widget-toggle-wrapper">
                                    <collapse-toggle-button />
                                </span>
                                <h5 class="table-section-title">
                                  Locations <span class="text-regular">({{ this.taskDetail.payroll_summary_by_location.length }})</span>
                                </h5>
                            </a>

                            <div class="collapse" :class="{'show': locationsExpanded}">
                                <fast-table
                                    :sections="locationsTable.sections"
                                    :headers="locationsTable.headers"
                                    :data="locationsTableData"
                                    :fixed-table-height="locationsFixedTableHeight"
                                >
                                </fast-table>
                            </div>
                        </div>
                    </div>
                    <!-- END Locations (Stores) container -->

                    <!-- Employees container -->
                    <div class="py-1">
                        <div class="table-section">
                            <a href class="table-section-titlebar collapse-trigger" role="button" :class="{'is-expanded': employeesExpanded}" @click.prevent="employeesExpanded = !employeesExpanded">
                                <span class="widget-toggle-wrapper">
                                    <collapse-toggle-button />
                                </span>
                                <h5 class="table-section-title">
                                  Employees <span class="text-regular">({{ this.taskDetail.df_payroll.length }})</span>
                                </h5>
                            </a>

                            <div class="collapse" :class="{'show': employeesExpanded}">
                                <fast-table
                                    :sections="employeesTable.sections"
                                    :headers="employeesTable.headers"
                                    :data="employeesTableData"
                                    :fixed-table-height="employeesFixedTableHeight"
                                >
                                </fast-table>
                            </div>
                        </div>
                    </div>
                    <!-- END Employees container -->

                    <div class="py-2 border-top">
                        <div class="row row-alt">
                            <div class="col-6">
                                <router-link class="btn btn-outline-primary" :to="{name: 'pos-integration', query: {'initial': this.taskDetail.id}}">Back</router-link>
                            </div>
                            <div class="col-6 text-right">
                                <p>
                                    <router-link class="btn btn-primary" :to="{name: 'pos-integration-review-final', params: {hashedClientId: this.$route.params.hashedClientId, taskId: this.$route.params.taskId}}">Continue</router-link>
                                </p>
                            </div>
                        </div>

                        <p class="mb-0 text-right" v-if="this.taskDetail.skipped_data_summary.num_employees">
                            <i aria-hidden="true" class="fas fa-exclamation-triangle text-warning mr-hf"></i>
                            <strong class="text-bold text-warning-dark">
                                {{ this.taskDetail.skipped_data_summary.num_employees }}
                            </strong> employees will be skipped.
                            <strong class="text-bold text-warning-dark">
                                {{ this.taskDetail.skipped_data_summary.total_hours_reg }}
                            </strong> regular and
                            <strong class="text-bold text-warning-dark">
                                {{ this.taskDetail.skipped_data_summary.total_hours_ot }}
                            </strong> OT hours will be skipped.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'
import FastTable from '@/components/FastTable'

import moment from 'moment'

export default {
    props: ['client', 'taskDetail'],
    components: {FastTable, },
    computed: {
        downloadWarningsURL() {
            return `${this.$api.API_BASE}/clients/${this.client.id}/pos-integration/${this.$route.params.taskId}/warnings/download`
        },
        employeesTableData() {
            const tableData = []

            if (this.taskDetail.df_payroll && this.taskDetail.df_payroll.length) {
                this.taskDetail.df_payroll.forEach((obj, idx) => {
                    let cells = [
                        obj.Full_Name.toUpperCase(),
                        obj.Payroll_ID,
                        obj.CompanyName.toUpperCase(),
                        obj.WorkLocationName.toUpperCase(),
                        obj.Reg_Hrs,
                        obj.OT_Hrs,
                    ]
                    if (this.taskDetail.tips_fields.includes('cash_tips')) {
                        cells.push(obj.Cash_Tips_Amt)
                    }
                    if (this.taskDetail.tips_fields.includes('charged_tips')) {
                        cells.push(obj.Charged_Tips_Amt)
                    }
                    const row = {
                        id: idx,
                        object: obj,
                        cells: cells,
                    }

                    tableData.push(row)
                })
            }

            return [tableData, ]
        },
        employeesFixedTableHeight() {
            if (this.taskDetail.df_payroll && this.taskDetail.df_payroll.length) {
                if (this.taskDetail.df_payroll.length > 10) {
                    // just make it 400 px tall, let it scroll
                    return 400
                } else {
                    return this.taskDetail.df_payroll.length * 40 + 80
                }
            }

            return 0
        },
        locationsTableData() {
            const tableData = []

            if (this.taskDetail.payroll_summary_by_location && this.taskDetail.payroll_summary_by_location.length) {
                this.taskDetail.payroll_summary_by_location.forEach((obj, idx) => {
                    let cells = [
                        obj.WorkLocationName.toUpperCase(),
                        // obj.CompanyName.toUpperCase(),
                        obj.has_data,
                        obj.num_employees,
                        obj.total_hours_reg,
                        obj.total_hours_ot,
                    ]
                    if (this.taskDetail.tips_fields.includes('cash_tips')) {
                        cells.push(obj.total_cash_tips)
                    }
                    if (this.taskDetail.tips_fields.includes('charged_tips')) {
                        cells.push(obj.total_charged_tips)
                    }
                    const row = {
                        id: idx,
                        object: obj,
                        cells: cells,
                    }

                    tableData.push(row)
                })
            }

            return [tableData, ]
        },
        locationsFixedTableHeight() {
            if (this.taskDetail.payroll_summary_by_location && this.taskDetail.payroll_summary_by_location.length) {
                // 40px per row plus a litle bit extra to avoid scrollbar
                return this.taskDetail.payroll_summary_by_location.length * 40 + 80
            }

            return 0
        },
    },
    data() {
        let employees_headers = [
            {label: 'Employee', classes: '', isSearchable: true, id: 'employee-name'},
            {label: 'Employee Num', classes: 'cw-3h cell-right', isSearchable: true, sortDataType: 'numeric', id: 'employee-num'},

            {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true, id: 'company'},
            {label: 'Location', classes: 'cell-location', isSearchable: true, isFilterable: true, id: 'location'},

            {label: 'Reg Hrs', classes: 'cw-3h cell-right', isSearchable: true, sortDataType: 'numeric', id: 'hours_reg'},
            {label: 'OT Hrs', classes: 'cw-3h cell-right', isSearchable: true, sortDataType: 'numeric', id: 'hours_ot'},
        ]

        let locations_headers = [
            {label: 'Location', classes: 'cw-7h', isSearchable: true, id: 'store-id'},
            // {label: 'Company', classes: 'cw-7h', isFilterable: true, id: 'company-name'},
            {label: 'Has Data', classes: 'cw-2h cell-center', isFilterable: true, type: 'checkmark', id: 'has-data'},

            {label: 'Num Employees', classes: 'cw-2h cell-right', isSearchable: true, sortDataType: 'numeric', id: 'num-ees'},
            {label: 'Reg Hrs', classes: 'cw-2h cell-right', isSearchable: true, sortDataType: 'numeric', id: 'hours_reg'},
            {label: 'OT Hrs', classes: 'cw-2h cell-right', isSearchable: true, sortDataType: 'numeric', id: 'hours_ot'},
        ]

        if (this.taskDetail.tips_fields.includes('cash_tips')) {
            employees_headers.push({label: 'Cash Tips', classes: 'cw-3h cell-right', isSearchable: true, sortDataType: 'numeric', id: 'cash_tips'})
            locations_headers.push({label: 'Cash Tips', classes: 'cw-2h cell-right', isSearchable: true, sortDataType: 'numeric', id: 'cash_tips'})
        }
        if (this.taskDetail.tips_fields.includes('charged_tips')) {
            employees_headers.push({label: 'Charged Tips', classes: 'cw-3h cell-right', isSearchable: true, sortDataType: 'numeric', id: 'charged_tips'})
            locations_headers.push({label: 'Charged Tips', classes: 'cw-2h cell-right', isSearchable: true, sortDataType: 'numeric', id: 'charged_tips'})
        }

        return {
            warningsExpanded: false,
            employeesExpanded: false,
            locationsExpanded: false,
            employeesTable: {
                headers: employees_headers,
                sections: [
                    {title: 'Employees', defaultIsClosed: false, id: 1},
                ],
            },
            locationsTable: {
                headers: locations_headers,
                sections: [
                    {title: 'Locations', defaultIsClosed: false, id: 1},
                ],
            }
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Point-of-Sale Integration')
    },
    methods: {
    },
}
</script>
