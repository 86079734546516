<template>
    <page>
        <fast-table
            v-show="dataLoaded"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="currentEntitlement = $event"   
        >
            <template slot="table-actions">
                <button type="button" class="btn btn-primary btn-collapse" @click.prevent="$refs.formEntitlementModal.open()">
                    <i class="far fa-fw fa-plus"></i> <span class="btn-text">Add Entitlement</span>
                </button>
            </template>
        </fast-table>

        <sidebar :client="client" :client-entitlement="currentEntitlement" v-if="currentEntitlement" ref="formEntitlementSidebar" @updated="onUpdate" @close="currentEntitlement = null" />
    
        <form-entitlement-modal ref="formEntitlementModal" :client="client" @updated="onUpdate" />
    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import Sidebar from './components/FormEntitlementSidebar'
import FormEntitlementModal from './components/FormEntitlementModal'

export default {
    components: {FastTable, Page, Sidebar, FormEntitlementModal},
    props: ['client', ],
    computed: {
        activeEntitlements() {
            return this.clientEntitlements.filter(t => t.is_active)
        },
        inactiveEntitlements() {
            return this.clientEntitlements.filter(t => !t.is_active)
        },
        tableData() {
            const tableData = []

            const subsets = [
                this.activeEntitlements,
                this.inactiveEntitlements,
            ]

            subsets.forEach(entitlements => {
                const sectionData = []

                entitlements.forEach((e, idx) => {
                    const row = {
                        id: idx,
                        isActive: this.currentEntitlement && e.id == this.currentEntitlement.id,
                        object: e,
                        cells: [
                            e.yaml_form ? e.yaml_form.name : '',
                            e.yaml_form ? e.yaml_form.slug : '',
                            e.yaml_form ? e.yaml_form.version_number : '',
                        ],
                    }

                    sectionData.push(row)
                })
                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            clientEntitlements: [],
            dataLoaded: false,
            currentEntitlement: null,

            headers: [
                {label: 'Form', classes: '', isSearchable: true, defaultSort: true, isFilterable: true},
                {label: 'Slug', classes: 'cell-medium', isSearchable: true},
                {label: 'Version', classes: 'cell-narrow', isSearchable: false},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
                {title: 'Inactive', defaultIsClosed: true, id: 2},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', `Client ${this.client.client_code} - ${this.client.name} / Form Entitlements`)
        this.getEntitlements()
    },
    methods: {
        getEntitlements() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/clients/${this.client.id}/form-entitlements`).then((resp) => {
                this.clientEntitlements = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onUpdate(clientEntitlement) {
            const existing = this.clientEntitlements.find(ce => {return ce.id == clientEntitlement.id})
            if (existing) {
                Object.assign(existing, clientEntitlement)
            } else {
                this.clientEntitlements.push(clientEntitlement)
            }
        },
    },
}

</script>
