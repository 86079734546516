<template>
    <modal size="lg" @close="reset(); $emit('close')" ref="modal" :is-closeable="isCloseable" :autoshow="true">
        <template slot="title">
            <h3 class="modal-title">Edit Termination Settings</h3>
        </template>

        <slot>
            <div class="mb-2">
                <div class="p-1 unpad-x bg-150 rounded">
                    <div><span class="text-muted">Company: </span>{{ document.company.name }}</div>
                    <div><span class="text-muted">Document Name: </span>{{ document.name }}</div>
                </div>
            </div>

            <form-errors :errors="formErrors"/>

            <alert-box type="info" v-if="documentHasDraft" class="mt-1 mb-2">
                Any changes to termination settings will apply to both your active and draft versions of this document.
            </alert-box>

            <p>When an employee who has <strong>not completed</strong> this document is terminated, what should happen?</p>
            <div class="form-check">
                <label>
                    <input name="remove_requirement_on_termination" type="radio" @click="formData.remove_requirement_on_termination = false" :checked="formData.remove_requirement_on_termination == false" class="form-check-input">
                    Document is <strong>still required</strong> for this employee after they are terminated.
                </label>
            </div>
            <div class="form-check">
                <label>
                    <input name="remove_requirement_on_termination" type="radio" @click="formData.remove_requirement_on_termination = true" :checked="formData.remove_requirement_on_termination == true" class="form-check-input">
                    Document is <strong>canceled</strong> and no longer required for this employee when they are terminated.
                </label>
            </div>

        </slot>

        <template slot="footer">
            <button-row stacked>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary" @click.prevent="onSubmit()">
                    Save
                </button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import InfoWidget from '@/components/InfoWidget'

export default {
    props: ['client', 'document', 'documentType', 'documentHasDraft'],
    mixins: [FormMixin, ModalMixin],
    components: {InfoWidget, },
    data() {
        return {
            step: 'positions',
            formData: this.makeFormData(),
            formRules: {
                name: {presence: {allowEmpty: false}, },
            },
            allPositions: [],
            positionsLoaded: false,
        }
    },
    watch: {
        client() {
            this.reset()
        }
    },
    methods: {
        makeFormData() {
            const data = {
                remove_requirement_on_termination: this.document.remove_requirement_on_termination,
            }

            return data
        },
        onSubmit() {
            this.$store.dispatch('START_LOADING')
            let promise

            if (this.documentType == 'hr-doc') {
                promise = this.$api.patch(`/clients/${this.client.id}/hr-documents/${this.document.id}/termination-settings`, this.getFormData())
            } else if (this.documentType == 'uniform') {
                promise = this.$api.patch(`/clients/${this.client.id}/uniforms/${this.document.id}/termination-settings`, this.getFormData())
            } else if (this.documentType == 'hr-custom-form') {
                promise = this.$api.patch(`/clients/${this.client.id}/hr-custom-forms/${this.document.id}/termination-settings`, this.getFormData())
            }
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        }
    }
}
</script>
