<template>
    <table-page
        :client="client"
        :warnings="warnings"
        :changelogs="changelogs"
        @changelog_date_filter_change="onChangelogDateRangeChange"
        @warnings_date_filter_change="onWarningsDateRangeChange"
        @warnings_updated="getWarnings"
        @updated="onApplicationsUpdated($event)"
    >
        <fast-table
            :recordLabel="recordLabel"
            ref="table"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            :managers="client.company_managers"
            @click="onClick"
            @click_cell="onClick($event.object, $event.columnIdx)"
            :use-date-filters="true"
            @date_filter_change="onDateFilter"
            :useFiltersFromUrl="dataLoaded"
        >

            <template slot="table-actions">
                <!--
                <button type="button" class="btn btn-primary btn-collapse mx-1 d-none d-sm-inline-block" @click.prevent="syncEmployees()" v-if="$store.state.user.is_superuser" style="background-color: purple">
                    <i class="far fa-fw fa-sync"></i> <span class="btn-text sr-only">Sync Employees</span>
                </button>
                -->

                <button type="button" class="btn btn-primary btn-collapse mr-2" @click.prevent="openAddEmployeeModal()">
                    <i class="far fa-fw fa-plus"></i> <span class="btn-text">Add Employee</span>
                </button>
                <button v-if="client.has_feature_bulk_add_onboarding_apps" type="button" class="btn btn-primary btn-collapse" @click.prevent="openBulkAddEmployeesModal()">
                    <i class="far fa-fw fa-file-excel"></i> <span class="btn-text">Bulk Add Employees</span>
                </button>
            </template>
        </fast-table>

        <add-employee-modal ref="addEmployeeModal" :client="client" @payroll_onboarding_applications_updated="onApplicationsUpdated($event)" @close="addEmployeeModalOpen = false" v-if="addEmployeeModalOpen" />

        <bulk-add-employees-modal ref="bulkAddEmployeesModal" :client="client" @payroll_onboarding_applications_updated="onApplicationsUpdated($event)" @close="bulkAddEmployeesModalOpen = false" v-if="bulkAddEmployeesModalOpen" />

        <employee-sidebar
            v-if="selectedEmployee"
            :employee-id="(selectedEmployee.status == 'hired' && selectedEmployee.employee) ? selectedEmployee.employee.id : null"
            :onboarding-application-id="(selectedEmployee.status == 'hired' && selectedEmployee.employee) ? null : selectedEmployee.id"
            :tab="tab" :section="section"
            :client="client"
            @close="selectedEmployeeId = null"
            :key="selectedEmployee.id"
            @updated="onApplicationsUpdated($event)"
            ref="employeeSidebar"
        />
    </table-page>

</template>

<script>
import AddEmployeeModal from './components/AddEmployeeModal'
import BulkAddEmployeesModal from './components/BulkAddEmployeesModal'
import FastTable from '@/components/FastTable'
import TablePageMixin from '@/mixins/TablePageMixin'
import moment from 'moment'
import EmployeeSidebar from '@/components/EmployeeSidebar'

export default {
    components: {AddEmployeeModal, BulkAddEmployeesModal, EmployeeSidebar, FastTable,  },
    mixins: [TablePageMixin, ],
    props: ['client'],
    computed: {
        tableData() {
            const tableData = []
            const subsets = [
                this.onboarding,
                this.readyForApproval,
                this.inProgress,
                this.completed,
                this.canceled,
            ]

            if (!this.onboarding.length) {
                subsets.shift()
            }

            subsets.forEach(apps => {
                const sectionData = []

                apps.forEach((a) => {
                    let firstPosition = {}
                    if (a.jobs && a.jobs.length) {
                        firstPosition = a.jobs[0].position
                    }

                    const eeName = {value: a.full_name_sortable, _meta: {}}
                    if (!this.$permissions.hasAccess(
                            a.company.client_id,
                            a.company.id,
                            Array.from(new Set(a.jobs.map(j => j.position.work_location.id))),
                            Array.from(new Set(a.jobs.map(j => j.position.id))),
                        )) {
                        eeName._meta.additionalCSSClasses = ['multi-manager']
                    }

                    const dateHired = {value: moment(a.date_hired).toDate(), _meta: {}}
                    if ((a.status == 'hired' && a.onboarded_on) || a.canceled_on) {
                        dateHired._meta.type = 'date'
                    }
                    else if (moment(dateHired.value) <= moment().startOf('day')) {
                        dateHired._meta.additionalCSSClasses = ['date-value-overdue']
                    }

                    let warnings = ''
                    if (a.onboarding_error_message) {
                        warnings = 'error'
                    }
                    else if (this.warnings) {
                        const appWarnings = this.mainWarnings.filter(w => w.onboarding_application_id == a.id)
                        warnings = appWarnings.filter(w => !w.is_resolved).length
                        if (warnings < 1) {
                            if (appWarnings.filter(w => w.warning_type == 'i9-completed-late').length) {
                                warnings = 'scarlet-letter'
                            }
                        }
                    }

                    const row = {
                        id: a.id,
                        isActive: a.id == this.selectedEmployeeId,
                        object: a,
                        cells: [
                            eeName,
                            warnings,
                            a.progress,
                            dateHired,
                            a.company.name,
                        ],
                        subrows: [],
                    }

                    const addOrgLevels = (position, cells) => {
                        this.client.org_level_labels_after_location.forEach((l, idx) => {
                            cells.push(position.terminal_values_tuple_after_location[idx])
                        })
                    }

                    row.cells.push(firstPosition.work_location.name)
                    addOrgLevels(firstPosition, row.cells)

                    a.jobs.slice(1).forEach(j => {
                        const subrow = ['', '', '', '', ''];
                        subrow.push(j.position.work_location.name)
                        addOrgLevels(j.position, subrow)

                        row.subrows.push(subrow)
                    })

                    row.cells.push(a.employee ? a.employee.email : a.email)

                    row.cells.push(
                        a.ssn_last_4 ? 'XXX-XX-' + a.ssn_last_4 : ''
                    )

                    row.cells.push(
                        (!a.has_direct_deposit)
                    )

                    sectionData.push(row)
                })

                tableData.push(sectionData)
            })

            return tableData
        },
        readyForApproval() {
            return this.onboardingApplications.filter((onApp) => {return onApp.status == 'needs-manager-approval'})
        },
        inProgress() {
            return this.onboardingApplications.filter((onApp) => {return ['started', 'logged-in'].indexOf(onApp.status) >= 0})
        },
        onboarding() {
            return this.onboardingApplications.filter((onApp) => {return (onApp.status == 'hired' && !onApp.onboarded_on)})
        },
        completed() {
            return this.onboardingApplications.filter((onApp) => {return onApp.status == 'hired' && onApp.onboarded_on})
        },
        canceled() {
            return this.onboardingApplications.filter((onApp) => {return onApp.status == 'canceled'})
        },
        selectedEmployee() {
            return this.onboardingApplications.find((emp) => {return emp.id == this.selectedEmployeeId})
        },
        defaultOpenSection() {
            return this.$route.query.section
        },
        sections() {
            const sections = [
                {title: 'Sending to Payroll', defaultIsClosed: false, id: 'sending-to-payroll', hasDateFilter: false},
                {title: 'Ready for Approval', defaultIsClosed: (this.defaultOpenSection && this.defaultOpenSection != 'needs-manager-approval') ? true : false, id: 'needs-manager-approval', hasDateFilter: false},
                {title: 'In Progress', defaultIsClosed: (this.defaultOpenSection && this.defaultOpenSection != 'in-progress') ? true : false, id: 'in-progress', hasDateFilter: false},
                {title: 'Completed', defaultIsClosed: true, id: 'hired', hasDateFilter: true},
                {title: 'Canceled', defaultIsClosed: true, id: 'canceled', hasDateFilter: true},
            ]

            if (!this.onboarding.length) {
                sections.shift()
            }
            return sections
        },
    },
    data() {
        const headers = [
            {label: 'Employee', classes: '', isSearchable: true},
            {label: 'Warnings', classes: 'cell-warning', isSearchable: false, type: 'warning', isFilterable: true, isClickable: true},
            {label: 'Progress', classes: 'cell-progress', isSearchable: false, type: 'progress'},
            {label: 'Starts In', classes: 'cell-date-hired', isSearchable: false, type: 'relative-date', defaultSort: true, defaultSortIsDesc: false},
            {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true},
            {label: 'Location', classes: 'cell-org-struct', isSearchable: true, isFilterable: true},
        ]

        this.client.org_level_labels_after_location.forEach(l => {
            headers.push(
                {
                    label: l,
                    classes: 'cell-org-struct',
                    isSearchable: true,
                    isFilterable: true,
                }
            )
        })

        headers.push(
            {label: 'Email', classes: 'cw-email', isSearchable: true},
            {label: 'SSN', classes: 'cw-ssn', isSearchable: true},
            {label: 'Paper Checks', classes: 'cell-paper-check', isSearchable: false, type: "checkmark", isFilterable: true},
        )

        return {
            onboardingApplications: [],
            dataLoaded: false,
            selectedEmployeeId: null,

            recordLabel: "onboarding applications",
            headers: headers,
            refreshing: false,

            addEmployeeModalOpen: true,
            bulkAddEmployeesModalOpen: false,
            tab: 'misc',
            section: "onboarding",
            openedSidebarFromRoute: false
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Onboarding Employees')
        this.getApplications()
        this.getWarnings()
        this.getChangelogs()
        this.$bus.$on('client_data_updated', updates => {
            if (updates.indexOf('onboarding_applications') >= 0) {
                this.getApplications(true)
                this.getWarnings()
                this.getChangelogs()
            }
        })
    },
    methods: {
        filterRelevantWarnings(w) {
            return w.onboarding_application_id
        },
        filterRelevantChangelog(c) {
            return c.onboarding_application_id
        },
        onApplicationsUpdated(app) {
            if (!app) {
                this.getApplications(true)
                return
            }
            const existingApp = this.onboardingApplications.find((a) => {return app.id == a.id})
            if (existingApp) {
                Object.assign(existingApp, app)
            }
            else {
                this.onboardingApplications.push(app)
            }

            this.addEmployeeModalOpen = false
            this.$nextTick(() => this.refresh())
        },
        refresh() {
            if (this.refreshing) {return}
            this.getApplications(true)
        },
        getApplications(silent) {
            if (!silent) {
                this.dataLoaded = false
                this.$store.dispatch('START_LOADING')
            }

            this.getMainWarnings()

            let url = `/clients/${this.client.id}/onboarding-applications`
            const params = []
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (range) {
                    params.push(`date-filter.${s.id}.start=${encodeURIComponent(range.start)}`)
                    params.push(`date-filter.${s.id}.end=${encodeURIComponent(range.end)}`)
                }
            })

            url += '?' + params.join('&')

            this.refreshing = true
            let ignoreForSessionExpiry = silent
            return this.$api.get(url, ignoreForSessionExpiry).then(resp => {
                this.onboardingApplications = resp
                this.refreshing = false
                this.dataLoaded = true
                if (this.selectedEmployee) {
                    this.$refs.employeeSidebar.updateEmployee(this.selectedEmployee)
                }
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
                if (this.$route.query.id && !this.openedSidebarFromRoute) {
                    this.openSidebarFromRoute()
                }

            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                this.refreshing = false
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
                this.$bus.showError(errors.__all__)
            })
        },
        openAddEmployeeModal() {
            this.addEmployeeModalOpen = true
            this.$nextTick(() => {
                this.$refs.addEmployeeModal.open()
            })
        },
        openBulkAddEmployeesModal() {
            this.bulkAddEmployeesModalOpen = true
            this.$nextTick(() => {
                this.$refs.bulkAddEmployeesModal.open()
            })
        },
        onClick(employee, columnIdx) {
            this.tab = 'misc'
            this.section = 'onboarding'

            if (columnIdx == 1 && (this.mainWarnings.find(w => w.onboarding_application_id == employee.id) || !employee.onboarding_error_message)) {
                this.tab = 'warnings'
                this.section = ''
            }

            this.selectedEmployeeId = employee.id
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getApplications()
        },
        syncEmployees() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/clients/${this.client.id}/force-sync`).then(() => {
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        openSidebarFromRoute() {
            if (this.$route.query.id) {
                let onboardingAppId = this.$route.query.id
                this.tab = 'misc'
                this.section = 'onboarding'
                this.selectedEmployeeId = onboardingAppId
                this.openedSidebarFromRoute = true
            }
        },
    }
}
</script>

