<template>
    <page>

        <fast-table
            v-show="dataLoaded"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            :activeRowId="currentTaxId"
            @click="currentTaxId = $event.id"
        >
            <template slot="table-actions">
                <button type="button" class="btn btn-primary btn-collapse-md" @click.prevent="currentTaxId = null; addModalOpen = true">
                    <i class="far fa-fw fa-plus"></i>
                    <span class="btn-text">Add Tax</span>
                </button>
            </template>
        </fast-table>

        <sidebar
            :key="currentTaxId"
            :tax-id="currentTaxId"
            :taxes="taxes"
            :jurisdictions="jurisdictions"
            :deduction-types="deductionTypes"
            :earning-types="earningTypes"
            :tax-exemptions-groups="taxExemptionsGroups"
            :standard-deduction-formulas="standardDeductionFormulas"
            :low-income-exemption-formulas="lowIncomeExemptionFormulas"
            :exemption-formulas="exemptionFormulas"
            v-if="currentTaxId"
            @updated="onUpdate($event)"
            @deleted="currentTaxId = null; getData()"
            @close="currentTaxId = null"
        />
        <add-modal
            v-if="addModalOpen"
            :taxes="taxes"
            :jurisdictions="jurisdictions"
            :tax-exemptions-groups="taxExemptionsGroups"
            @updated="getData()"
            @close="addModalOpen = false"
        />

    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import AddModal from './components/TaxModal'
import Sidebar from './components/TaxSidebar'

export default {
    components: {FastTable, Page, Sidebar, AddModal, },
    computed: {
        future() {
            return this.taxes.filter(t => t.current_version.is_future)
        },
        active() {
            return this.taxes.filter(t => t.current_version.is_active)
        },
        inactive() {
            return this.taxes.filter(t => t.current_version.is_past)
        },
        tableData() {
            const tableData = []
            if (!this.dataLoaded) {
                return [[], [], []]
            }
            const subsets = [
                this.future,
                this.active,
                this.inactive,
            ]

            subsets.forEach(taxes => {
                const sectionData = []

                taxes.forEach((t, idx) => {
                    const j = t.jurisdiction
                    const jLabels = new Array(4)  // A 4 element array containing of [school district, city, county, state]

                    if (j.type == 'fed') {
                        // Do nothing, the 4 elements are blank
                    }
                    else if (j.type == 'state') {
                        jLabels[3] = j.short_name
                    }
                    else if (j.type == 'city') {
                        jLabels[1] = j.name
                        const state = this.jurisdictionsById[j.parent_id]
                        jLabels[3] = state.short_name
                    }
                    else if (j.type == 'county') {
                        jLabels[1] = j.name
                        const state = this.jurisdictionsById[j.parent_id]
                        jLabels[3] = state.short_name
                    }
                    else if (j.type == 'school-district') {
                        jLabels[0] = j.name
                        const city = this.jurisdictionsById[j.parent_id]
                        jLabels[1] = city.name
                        const state = this.jurisdictionsById[city.parent_id]
                        jLabels[3] = state.short_name
                    }

                    const row = {
                        id: t.id,
                        object: t,
                        cells: [
                            t.name,
                            t.type_pretty,
                            t.jurisdiction.type_pretty,
                            jLabels[0],
                            jLabels[1],
                            jLabels[2],
                            jLabels[3],
                            t.current_version.starts_on,
                            t.current_version.ends_on,
                        ],
                    }

                    sectionData.push(row)
                })
                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            taxes: [],
            jurisdictions: [],
            jurisdictionsById: {},
            deductionTypes: [],
            earningTypes: [],
            taxExemptionsGroups: [],
            standardDeductionFormulas: [],
            exemptionFormulas: [],
            lowIncomeExemptionFormulas: [],
            dataLoaded: false,
            currentTaxId: null,
            addModalOpen: false,

            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Type', classes: '', isSearchable: false, isFilterable: true},
                {label: 'Jurisdiction Type', classes: '', isSearchable: false, isFilterable: true},
                {label: 'School District', classes: 'cw-9', isSearchable: true, isFilterable: false},
                {label: 'City', classes: 'cw-9', isSearchable: true, isFilterable: false},
                {label: 'County', classes: 'cw-9', isSearchable: true, isFilterable: false},
                {label: 'State', classes: 'cw-3', isSearchable: false, isFilterable: true},
                {label: 'Starts On', classes: 'cw-5', type: 'date'},
                {label: 'Ends On', classes: 'cw-5', type: 'date'},
            ],
            sections: [
                {title: 'Future', defaultIsClosed: false, id: 1},
                {title: 'Current', defaultIsClosed: false, id: 2},
                {title: 'Past', defaultIsClosed: true, id: 3},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tax Engine / Taxes')
        this.getData()
    },
    methods: {
        getData() {
            this.dataLoaded = false

            const promises = []
            this.$store.dispatch('START_LOADING')
            promises.push(this.$api.get(`/admin/tax-engine/taxes`).then(resp => {
                this.taxes = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            }))

            promises.push(this.$api.get(`/admin/tax-engine/jurisdictions`).then(resp => {
                this.jurisdictions = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            }))

            promises.push(this.$api.get(`/admin/tax-engine/deduction-types`).then(resp => {
                this.deductionTypes = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            }))

            promises.push(this.$api.get(`/admin/tax-engine/earning-types`).then(resp => {
                this.earningTypes = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            }))

            promises.push(this.$api.get(`/admin/tax-engine/tax-exemptions-groups`).then(resp => {
                this.taxExemptionsGroups= resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            }))

            promises.push(this.$api.get(`/admin/tax-engine/standard-deduction-formulas`).then(resp => {
                this.standardDeductionFormulas = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            }))

            promises.push(this.$api.get(`/admin/tax-engine/exemption-formulas`).then(resp => {
                this.exemptionFormulas = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            }))

            promises.push(this.$api.get(`/admin/tax-engine/low-income-exemption-formulas`).then(resp => {
                this.lowIncomeExemptionFormulas = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            }))

            Promise.all(promises).then(() => {
                // Combine taxes and jurisdictions
                this.jurisdictions.forEach(j => {
                    this.jurisdictionsById[j.id] = j
                })
                this.taxes.forEach(t => {
                    t.jurisdiction = this.jurisdictionsById[t.jurisdiction_id]
                })

                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
                this.$nextTick(() => {  // Not sure why this is needed, but it fixes section headers.
                    this.$bus.$emit('resize')
                })
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
            })

        },
        onUpdate(tax) {
            if (tax) {
                const existing = this.taxes.find(x => {return x.id == tax.id})
                if (existing) {
                    Object.assign(existing, tax)
                }
            }
            else {
                this.getData()
            }
        },
    },
}

</script>
