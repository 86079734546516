<template>
    <modal @close="$emit('close')" ref="modal" :is-closeable="true" size="xl" :autoshow="true">
        <template #title>
            <b>Action Required:</b> Match Photo
        </template>
        <slot>
            <div v-if="['photo-match'].includes(mode)">
                <p>
                    Company: {{ company.name }}
                </p>

                <div v-if="eVerifyCase" class="bg-light p-2 mb-2">
                    <b>Case #:</b> {{ eVerifyCase.case_number }}
                    <br>
                    <b>Status:</b> {{ eVerifyCase.case_status_pretty }}
                    <br>
                    <b>Employee:</b> {{ eVerifyCase.emp_full_name_sortable }}
                    <br>
                    <b>Start Date:</b> {{ eVerifyCase.emp_start_date|date }}
                </div>

                <p>
                    Does this photo match the photo on {{ eVerifyCase.emp_full_name }}'s photo document?
                </p>

                <div class="row">
                    <div class="col-12 col-lg-4">
                        <b>From E-Verify</b>
                        <br>

                        <img v-if="photoImage" v-bind:src="'data:image/jpeg;base64,'+photoImage" class="img-fluid" />
                    </div>
                    <div class="col-12 col-lg-8">
                        <div v-if="employerDocumentsWithPhotos.length || employeeDocumentsWithPhotos.length" class="d-flex d-flex justify-content-start mb-1">
                            <div v-if="employeeDocumentsWithPhotos.length" class="mr-2">
                                <p class="mb-hf text-bold">Uploaded by Employee</p>

                                <div class="py-hf" v-for="doc in employeeDocumentsWithPhotos">
                                    <div v-if="doc.photos.length">
                                        <div class="mb-1">{{ doc.document_title }}</div>
                                        <div class="docphoto" :class="{'zoom-active': zoom > 100}">
                                            <img :src="`${$api.API_BASE}/clients/${client.id}/onboarding-applications/${onboardingApplicationId}/forms/${latestI9Form.id}/photos/${doc.photos[0].id}/preview`" alt="Document Photo" :style="(zoom > 100) ? `width: ${zoom}%; max-width: ${zoom}%;` : ''" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="employerDocumentsWithPhotos.length">
                                <p class="mb-hf text-bold">Uploaded by Manager</p>

                                <div class="py-hf" v-for="doc in employerDocumentsWithPhotos">
                                    <div v-if="doc.photos.length">
                                        <div class="mb-1">{{ doc.document_title }}</div>
                                        <div class="docphoto" :class="{'zoom-active': zoom > 100}">
                                            <img :src="`${$api.API_BASE}/clients/${client.id}/onboarding-applications/${onboardingApplicationId}/forms/${latestI9Form.id}/photos/${doc.photos[0].id}/preview`" alt="Document Photo" :style="(zoom > 100) ? `width: ${zoom}%; max-width: ${zoom}%;` : ''" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="employerDocumentsWithPhotos.length || employeeDocumentsWithPhotos.length" class="position-relative mb-2">
                            <button class="btn btn-outline-primary btn-collapse-xl" :disabled="zoom >= 400" @click.prevent="zoom += 50">
                                <i class="far fa-fw fa-search-plus"></i>
                                <span class="sr-only">Zoom In</span>
                            </button>
                            <button class="btn btn-outline-primary btn-collapse-xl ml-1" :disabled="zoom <= 100" @click.prevent="zoom -= 50">
                                <i class="far fa-fw fa-search-minus"></i>
                                <span class="sr-only">Zoom Out</span>
                            </button>
                        </div>

                        <div class="bg-light p-2">
                            <form-input v-model="formData.photo_match_response" type="radio" :options="photoMatchResponseOptions" :errors="errors.photo_match_response" />
                        </div>
                    </div>
                </div>

            </div>
        </slot>

        <template #footer>
            <div class="modal-footer">
                <div v-if="mode == 'photo-match'">
                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="onSubmit('photo-match')">
                            Save
                        </button>
                    </button-row>
                </div>

                <div v-if="mode == 'view-case'">
                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                            Close
                        </button>
                    </button-row>
                </div>
            </div>
        </template>
    </modal>

<!-- /Modal: Select Documents -->
</template>

<style scoped lang="scss">
    .docphoto img {
        max-height: 200px;
        max-width: auto;
    }
    .docphoto.zoom-active img {
        max-height: none;
    }
    .docphoto.zoom-active {
        max-width: 100%;
        overflow: auto;
        max-height: 200px;
    }
</style>


<script>
import $ from 'jquery'
import moment from 'moment'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import i9Documents from '@/utils/i9'
import DownloadableMixin from '@/mixins/Downloadable'

export default {
    mixins: [FormMixin, ModalMixin, DownloadableMixin],
    props: ['client', 'company', 'employee', 'eVerifyCase', 'latestI9Form'],
    mounted() {
        this.reset()
    },
    computed: {
        employeeObjectUrlPath() {
            return this.employee.is_employee ? 'employee' : 'onboarding-application'
        },
        onboardingApplicationId() {
            return this.latestI9Form ? this.latestI9Form.onboarding_application_id : null
        },
        photoMatchResponseOptions() {
            return [
                {
                    html: `Yes, this photo matches`,
                    value: 'matching',
                },
                {
                    html: `No, this photo does not match`,
                    value: 'not-matching',
                },
                {
                    html: `No photo displayed`,
                    value: 'no-image',
                },
            ]
        },
        employeeDocumentsWithPhotos() {
            const result = []
            if (! this.latestI9Form) {
                return result
            }

            this.latestI9Form.photos.filter(p => !p.is_manager_uploaded).forEach(p => {
                const doc = result.find(x => x.document_slug == p.document_slug)
                if (!doc) {
                    result.push({
                        document_title: p.document_title,
                        document_slug: p.document_slug,
                        document_list: p.document_list,
                        is_manager_uploaded: false,
                        photos: [p],
                        docDetails: doc,
                    })
                }
                else {
                    doc.photos.push(p)
                    doc.photos.sort(a => {
                        return (a.side == 'front') ? -1 : 1
                    })
                }
            })
            return result
        },
        employerDocumentsWithPhotos() {
            const result = []
            if (! this.latestI9Form) {
                return result
            }

            const addDoc = (doc, list) => {
                const x = {
                    document_title: doc.shortName ? doc.shortName : doc.name,
                    document_slug: doc.slug,
                    document_list: list,
                    requiresBackPhoto: doc.requiresBackPhoto,
                    is_manager_uploaded: true,
                    photos: [],
                    docDetails: doc,
                }

                const front = this.latestI9Form.photos.find(p => p.document_slug == doc.slug && p.is_manager_uploaded && p.side == 'front')
                const back = this.latestI9Form.photos.find(p => p.document_slug == doc.slug && p.is_manager_uploaded && p.side == 'back')
                if (front) {
                    x.photos.push(front)
                    if (back) {
                        x.photos.push(back)
                    }
                }

                // only count if it has photos
                if (x.photos.length) {
                    result.push(x)
                }
            }

            if (this.latestI9Form.employer_form_data.doc_a_slug) {
                const doc = this.findI9Document(this.latestI9Form.employer_form_data.doc_a_slug)
                addDoc(doc, 'list-a')

                if (this.latestI9Form.employer_form_data.doc_a_secondary_slug) {
                    addDoc(this.findI9Document(doc.slug, this.latestI9Form.employer_form_data.doc_a_secondary_slug), 'list-a-1')
                }
                if (this.latestI9Form.employer_form_data.doc_a_tertiary_slug) {
                    addDoc(this.findI9Document(doc.slug, '', this.latestI9Form.employer_form_data.doc_a_tertiary_slug), 'list-a-2')
                }
            }

            if (this.latestI9Form.employer_form_data.doc_b_slug) {
                addDoc(this.findI9Document(this.latestI9Form.employer_form_data.doc_b_slug), 'list-b')
            }

            if (this.latestI9Form.employer_form_data.doc_c_slug) {
                addDoc(this.findI9Document(this.latestI9Form.employer_form_data.doc_c_slug), 'list-c')
            }

            return result
        },
    },
    data() {
        return {
            zoom: 100,
            formData: this.makeFormData(),
            formRules: {
                photo_match_response: {presence: {allowEmpty: false} }
            },
            errors: [],
            mode: 'photo-match',
            photoImage: '',
        }
    },
    methods: {
        makeFormData() {
            return {
                photo_match_response: '',
            }
        },
        getInitialData() {
            this.$store.dispatch('START_LOADING')

            let url = `/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCase.id}`

            this.$api.get(url).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.isPristine = true

                this.photoImage = resp['photo_to_match']

            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onSubmit(action) {
            const formData = {
                action: action,
                form_data: this.getFormData(),
            }

            if (!this.validate()) {
                return
            }

            let url = `/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCase.id}`

            this.$store.dispatch('START_LOADING')
            this.$api.post(url, formData).then((resp) => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.isPristine = true
                this.$nextTick(() => {
                    this.close(true)
                })
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },

        reset() {
            FormMixin.methods.reset.call(this)
            this.$set(this, 'formData', this.makeFormData())
            this.getInitialData()

            this.mode = 'photo-match'

            this.isPristine = true
            this.$store.dispatch('SET_CLOSEABLE', true)
        },

        findI9Document(slug, secondarySlug, tertiarySlug) {
            let doc = i9Documents.listA.find(d => d.slug == slug)
            if (!doc) {
                doc = i9Documents.listB.find(d => d.slug == slug)
            }
            if (!doc) {
                doc = i9Documents.listC.find(d => d.slug == slug)
            }
            if (!doc) {return null}

            if (secondarySlug) {
                return doc.secondary_documents.find(d => d.slug == secondarySlug)
            }

            if (tertiarySlug) {
                return doc.tertiary_documents.find(d => d.slug == tertiarySlug)
            }

            return doc
        },
    }
}
</script>
