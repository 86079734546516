<template>
    <a href class="table-section-titlebar collapse-trigger" role="button" :class="{'is-expanded': expanded}" @click.prevent="$emit('click')">
        <span class="widget-toggle-wrapper">
            <collapse-toggle-button />
        </span>
        <h5 class="table-section-title">
          {{ category }} Warnings <span class="text-regular">({{ warningCount }})</span>
        </h5>
    </a>
</template>

<script>
export default {
    props: ['warningCount', 'expanded', 'category'],
}
</script>
