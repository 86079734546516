<template>
<div v-if="dataLoaded && taxWithholdings">
    <div class="flex-body body-scroll">
        <div class="container-fluid">
            <div class="container-centered-l py-4">
                <template v-for="tw in taxWithholdings">
                    <info-widget :key="tw.id" class="mb-4">
                        <template slot="title">
                            {{ tw.jurisdiction_name }} Taxes
                        </template>
                        <template slot="headerbutton">
                            <button v-if="tw.doc_url" class="btn btn-outline-primary" type="button" @click.prevent="viewTaxFormPDF(tw.doc_url)">
                                <i class="far fa-file-pdf" aria-hidden="true" title="Edit"></i>
                                View Document (PDF)
                            </button>
                            <button v-if="company.has_enhanced_ess" class="btn btn-outline-primary ml-1" type="button" @click.prevent="editTaxWithholding(tw)">
                                <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                                Edit
                            </button>
                        </template>
                        <slot>
                            <div class="card-body py-2">
                                <div class="form-row">
                                    <div v-for="tax_field in tw.tax_data" :key="tax_field.tax_field" class="mui-textfield col-12 col-md-6 col-lg-4">
                                        <span class="textfield">{{ tax_field.value }}</span>
                                        <label>{{ tax_field.display_name }}</label>
                                    </div>
                                </div>
                                <!-- display this if the changed_on was after the feature deploy date -->
                                <div v-if="tw.changed_on && isAfterFeatureRelease(tw.changed_on)" class="card card-body item-details">
                                    Last changed by {{ tw.change_source }} on {{ tw.changed_on|datetime }}
                                </div>
                            </div>
                        </slot>
                    </info-widget>
                </template>

                <tax-withholding-edit-modal :employee="employee" :taxWithholding="currentTaxWithholding" @updated="onTaxWithholdingUpdate" ref="taxWithholdingModal" v-if="taxWithholdingModalOpen" @close="taxWithholdingModalOpen = false" />
            </div>
        </div>
    </div>
</div>
</template>


<script>

import InfoWidget from '@/components/InfoWidget'
import TaxWithholdingEditModal from './components/EmployeeTaxWithholdingEditModal.vue'

import moment from 'moment'

export default {
    props: ['company'],
    components: {InfoWidget, TaxWithholdingEditModal},
    computed: {},
    data() {
        return {
            taxWithholdings: null,
            dataLoaded: false,
            currentTaxWithholding: null,
            taxWithholdingModalOpen: false,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData(silent) {
            if(!silent) {
                this.dataLoaded = false
                this.$store.dispatch('START_LOADING')
            }
            this.$api.get(`/me/ess/companies/${this.company.id}/personal-info`).then(resp => {
                this.employee = resp
                this.$api.get(`/me/ess/companies/${this.company.id}/tax-withholdings-formatted`).then(resp => {
                    this.taxWithholdings = resp
                    this.dataLoaded = true
                    if (!silent) { this.$store.dispatch('STOP_LOADING') }
                }).catch((errors) => {
                    if (!silent) { this.$store.dispatch('STOP_LOADING') }
                    this.$bus.showError(errors.__all__)
                })
            }).catch((errors) => {
                if (!silent) { this.$store.dispatch('STOP_LOADING') }
                this.$bus.showError(errors.__all__)
            })
        },
        editTaxWithholding(taxWithholding) {
            this.getData(true)
            this.currentTaxWithholding = taxWithholding
            this.taxWithholdingModalOpen = true
        },
        onTaxWithholdingUpdate() {
            this.taxWithholdingModalOpen = false
            this.getData()
        },
        viewTaxFormPDF(doc_url) {
            window.open(doc_url, '_blank')
        },
        isAfterFeatureRelease(changeOnDate) {
            let changeOnMoment = moment(changeOnDate)
            let featureReleaseMoment = moment('2022-09-01')
            return changeOnMoment.isAfter(featureReleaseMoment)
        }
    },
}

</script>
