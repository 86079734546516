<template>
<info-widget :collapsible="true" :is-expanded="widgetExpanded" @toggle_expand="widgetExpanded = !widgetExpanded">
    <template slot="title">Tax Withholding Config</template>
    <template slot="headerbutton">
        <button class="btn btn-outline-primary" type="button" @click.prevent.stop="modalOpen = true">
            <i class="far fa-pencil" aria-hidden="true" title="Edit"></i>&nbsp;
            <slot name="add-button">Edit</slot>
        </button>
    </template>


    <slot>
        <div class="card mb-0">
            <div class="card-body py-1">
                <p v-if="!enabledTaxWithholdingFields.length">This jurisdiction has no tax withholding fields enabled.</p>

                <ul class="list-unstyled m-0" v-if="enabledTaxWithholdingFields.length">
                    <li v-for="(field, i) in enabledTaxWithholdingFields" :key="field.id" class="py-hf" :class="{'border-bottom': i < enabledTaxWithholdingFields.length - 1}">
                        <span>{{ field.display_name }}</span>
                    </li>
                </ul>
            </div>

        </div>
    </slot>
</div>

<edit-modal
    v-if="modalOpen"
    :city="city"
    :state="state"
    :fed="fed"
    :jurisdiction="jurisdiction"
    @updated="$emit('updated', $event)"
    @close="modalOpen = false"
/>

</info-widget>
</template>


<script>
import InfoWidget from '@/components/InfoWidget'
import EditModal from './TaxWithholdingConfigModal'

export default {
    props: ['city', 'state', 'fed', 'jurisdiction', ],
    components: {EditModal, InfoWidget, },
    computed: {
        juris() {
            const jurisdictions = {
                'fed': this.fed,
                'state': this.state,
                'city': this.city,
            }
            return jurisdictions[this.jurisdiction]
        },
        enabledTaxWithholdingFields() {
            if (this.juris.tax_withholding_configs.length) {
                return this.juris.tax_withholding_configs[0].field_configs.filter(fc => (fc.is_enabled)).slice().sort((a,b) => (
                    (a.tax_field < b.tax_field ? -1 : a.tax_field > b.tax_field ? 1 : 0)
                ))
            } else {
                return []
            }
        }
    },
    data() {
        return {
            modalOpen: false,
            widgetExpanded: false,
        }
    },
}
</script>



