<template>
<div>
    <div class="nav-tabs-container pt-1">
        <div class="nav-tabs-scroll-helper">
            <ul class="nav nav-tabs">

                <li class="nav-item">
                    <a class="nav-link" :class="{'active': mode == 'all'}" href id="tabLinkPositions1" role="tab" aria-controls="tabContentPositions1" :aria-selected="mode == 'all'" @click.prevent="mode = 'all'; $bus.$emit('resize')">
                        All Companies ({{ allCompanyCount }})
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" :class="{'active': mode == 'selected'}" href id="tabLinkPositions2" role="tab" aria-controls="tabContentPositions1" :aria-selected="mode == 'selected'" @click.prevent="mode = 'selected'; $bus.$emit('resize')">
                        Selected ({{ selectedCompanyCount }})
                    </a>
                </li>

            </ul>
        </div>
    </div>

    <div class="flex-body flex-container tab-content company-selector">
        <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'all'}" id="tabContentCompanies1" role="tabpanel" aria-labelledby="tabLinkCompanies1">
            <fast-table
                :sections="companySections"
                :headers="companyHeaders"
                :data="companyTableData"
                @click="onCompanyClick"
                @select="onCompanySelect"
                @filter-edit-open="$emit('filter-edit-open')"
                @filter-edit-close="$emit('filter-edit-close')"
                :use-overlay-filter-editor="true"
                :has-row-select="true"
                :show-search="true"
                :show-filter="false"
                :show-more-actions="false"
            />

        </div>

        <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'selected'}" id="tabContentCompanies2" role="tabpanel" aria-labelledby="tabLinkCompanies2">
            <fast-table
                :sections="companySections"
                :headers="companyHeaders"
                :data="selectedCompanyTableData"
                @click="onCompanyClick"
                @select="onCompanySelect"
                @filter-edit-open="$emit('filter-edit-open')"
                @filter-edit-close="$emit('filter-edit-close')"
                :use-overlay-filter-editor="true"
                :has-row-select="true"
                :show-search="true"
                :show-filter="false"
                :show-more-actions="false"
            />

        </div>
        <!-- Add filters -->

    </div>
</div>
</template>

<script>
import FastTable from '@/components/FastTable'

export default {
    props: ['client', 'companies', 'value', ],
    components: {FastTable, },
    computed: {
        companyTableData() {
            const result = []

            this.companies.forEach(c => {
                if (!this.canEditRole(c.id)) {
                    return  // This user can't edit this company role at all
                }

                result.push({
                    id: c.id,
                    isSelected: this.formData.companies.includes(c.id),
                    object: c,
                    cells: [
                        c.name,
                    ],
                })
            })

            return [result]
        },
        selectedCompanyTableData() {
            let result = this.companyTableData[0].filter(row => {
                return this.formData.companies.includes(row.object.id)
            })

            result = JSON.parse(JSON.stringify(result))
            result.forEach(row => {
                row.isSelected = true
            })

            return [result] },
        allCompanyCount() {
            return this.companyTableData[0].length
        },
        selectedCompanyCount() {
            return this.selectedCompanyTableData[0].length
        },
    },
    data() {
        return {
            mode: 'all',
            formData: {
                companies: Array.from(this.value),
            },
            companySections: [
                {title: 'All', id: 'all'},
            ],
            companyHeaders: [
                    {label: 'Company', classes: 'cell-company', defaultSort: true, isFilterable: true, isSearchable: true, },
            ],
        }
    },
    mounted() {
        this.$bus.$emit('resize')
    },
    methods: {
        canEditRole(companyId) {
            return this.$permissions.hasRolesInCompany(this.client.id, companyId, this.$store.state.security_roles.SETTINGS_ROLES)
        },
        onCompanySelect(rows) {
            const companies = new Set()
            rows.forEach(row => {
                companies.add(row.object.id)
            })

            this.formData.companies = Array.from(companies)

            this.$emit('input', this.formData.companies)
        },
        onCompanyClick(company) {
            if (this.formData.companies.includes(company.id)) {
                this.formData.companies = this.formData.companies.filter(cId => cId != company.id)
            }
            else {
                this.formData.companies.push(company.id)
            }

            this.$emit('input', this.formData.companies)
        },
    },
}

</script>

<style>
.company-selector .table-wrapper {
    max-height: 300px;
}
</style>
