<template>
    <modal @close="$emit('close')" ref="modal">
        <template slot="title">Send a Download Link</template>

        <slot>
            <div>
                <div v-if="mode == 'form'">
                    <p>
                        Enter your mobile phone number. We'll send you an SMS with 
                        a link to install the GetMyPayStub app.
                    </p>
                    <form @submit.prevent="onPhoneSubmit" novalidate class="mui-form">
                        <form-errors :errors="formErrors"/>
    
                        <div class="form-row">
                            <div class="col-8 col-sm-6">
                                <form-input placeholder="Phone Number" v-model="phoneFormData.phone" :errors="errors.phone" type="phone" />
                            </div>
                        </div>
                        <div class="form-row mb-1">
                            <form-input type="checkbox" v-model="phoneFormData.phoneConsent" :errors="errors.phoneConsent" label="I agree to receive text messages. Message & data rates may apply. You can opt out of receiving text messages at any time by replying STOP." />
                        </div>
                        <div class="form-row mt-4">
                            <div class="col">
                                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                            </div>
                            <div class="col text-right">
                                <button type="submit" class="btn btn-block btn-primary">Send Link</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div v-if="mode == 'confirmation'">
                    <p>
                        <i class="fas fa-check text-primary mr-hf"></i> A link has been sent to <strong>{{ formatted_number }}</strong>.
                    </p>
                    <div class="row mt-4">
                        <div class="col-12 col-rs-6 offset-rs-3">
                            <button type="button" class="btn btn-block btn-primary" @click.prevent="close()">Done</button>
                        </div>
                    </div>
                </div>
            </div>
        </slot>

        <template slot="footer">
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import bus from '@/bus'

import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [FormMixin, ModalMixin, ], 
    mounted() {
    }, 
    data() {
        return {
            mode: 'form',
            formatted_number: '',
            phoneFormData: {
                phone: '',
                phoneConsent: false,
            },
            phoneFormRules: {
                phone: {presence: {allowEmpty: false}},
                phoneConsent: {
                    presence: {allowEmpty: false},
                    inclusion: {
                        within: [true],
                        message: "^is required to send texts"
                    }
                },
            },
        }
    }, 
    methods: {
        getFormRules() {
            return this.phoneFormRules
        },
        getFormData() {
            return this.phoneFormData
        },
        onPhoneSubmit() {
            this.errors = {}
            this.formErrors = []
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.post(`/send-app-phone-link`, this.phoneFormData).then((resp) => {
                this.mode = 'confirmation'
                this.formatted_number = resp['formatted_number']
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
    },
}
</script>
