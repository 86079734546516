<template>
<div>
    <section class="table-section">
        <div v-if="loaded && !changelogs.length">
            <div class="row-empty py-0">
                This object has no change history.
            </div>
        </div>
        <div v-if="loaded && changelogs.length">
            <div class="mt-n2">
                <div class="pt-hf pb-qt border-bottom" :key="changelog.id" v-for="changelog in sortedChangelogs" v-if="changelog.log_level > 10">
                    <!-- Row content -->
                    <div class="d-flex">
                        <div class="flex-0 pl-hf">
                            <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': openChangelogs[changelog.id]}" @click.prevent="toggleChangelog(changelog.id)">
                                <collapse-toggle-button />
                            </a>
                        </div>
                        <div class="flex-1">
                            <!-- Row 1: Date -->
                            <div class="btnlike pb-qt">
                                <span>
                                    {{ changelog.created_on|datetime }}
                                </span>
                            </div>
                            <!-- Row 2: Change Info -->
                            <div class="row mx-0">
                                <div v-if="changelog.tracked_object_type && changelog.tracked_object_type != 'Status'" class="col-12 col-md-auto px-0 mb-qt">
                                    <span class="mr-hf">
                                        {{ changelog.tracked_object_type }}<span>:</span>
                                    </span>
                                </div>
                                <div v-if="changelog.tracked_object_name" class="col-auto px-0">
                                    <span class="btnlike pt-0 mr-hf">
                                        {{ changelog.tracked_object_name }}
                                    </span>
                                </div>
                                <div class="col-auto px-0">
                                    <span class="btnlike pt-0 mr-hf">
                                        {{ changelog.action_pretty }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Detail content -->
                    <div class="mb-1" :class="{'collapse': !openChangelogs[changelog.id]}">
                        <changeset :client="client" :changelog="changelog" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import Changeset from '@/components/Changeset'

export default {
    props: ['changelogs', 'activeChangelogId', 'client', ],
    components: {Changeset},
    computed: {
        loaded() {
            return Array.isArray(this.changelogs)
        },
        sortedChangelogs() {
            const result = this.changelogs.slice()
            result.sort((a, b) => {
                return a.created_on > b.created_on ? -1 : 1
            })

            return result
        },
    },
    data() {
        const data = {
            openChangelogs: {},
        }

        if (this.activeChangelogId) {
            data.openChangelogs[this.activeChangelogId] = true
        }

        return data
    },
    methods: {
        toggleChangelog(changelogId) {
            this.$set(this.openChangelogs, changelogId, !this.openChangelogs[changelogId])
        },
    },
}
</script>
