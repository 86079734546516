<template>
    <insight-widget :collapsible="true" :is-expanded="widgetExpanded" @toggle_expand="widgetExpanded = !widgetExpanded">
        <template slot="title">
            Onboarding <span class="text-regular">({{ totalNumOnboardingApps }})</span>
            <span class="counter-warnings pl-1" v-if="warnings && warnings.length">
                <i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings"></i>
                <span class="counter-label">{{ warnings.length }}</span>
            </span>
        </template>
        <template slot="headerbutton"><router-link :to="{name: 'onboarding', params: {hashedClientId: $hasher.encode(client.id)}}">Go<span class='d-none d-sm-inline'> To Page </span><i class="fas fa-fw fa-chevron-right"></i></router-link></template>
        <slot>
            <div class="onboarding-insight-widget-content">
                <!-- Pie Chart -->
                <div class="d-flex p-2">
                    <div class="legend col-7 pl-0 pr-0">
                        <div v-for="slice in pieChartData" class="pb-2 d-flex">
                            <div class="legend-color mr-hf" :style="`background-color: ${slice.color}`"></div>
                            <span><router-link :to="{name: 'onboarding', query: {section: slice.section}}">{{ slice.label }}</router-link> ({{ slice.value }})</span>
                        </div>
                    </div>
                    <div class="col-5">
                        <pie-chart :chart-data="pieChartData" doughnut gap class="onboarding-pie-chart"></pie-chart>
                    </div>
                </div>
                <!-- Warnings -->
                <table class="table css-table table-fixed mb-hf" v-if="warningTypes.length">
                    <thead class="pb-0" :class="{'border-bottom': warningsOpen}">
                        <tr>
                            <th class="cw-toggle">
                                <a href class="collapse-trigger" role="button" :class="{'is-expanded': warningsOpen}" @click.prevent="warningsOpen = !warningsOpen">
                                    <collapse-toggle-button class="pl-0 pb-0"/>
                                </a>
                            </th>
                            <th class="pl-hf">Warning</th>
                            <th class="text-right pr-3">Num Employees</th>
                        </tr>
                    </thead>
                    <tbody v-show="warningsOpen">
                        <tr v-for="warningType in warningTypes" :key="warningType.type" >
                            <td></td>
                            <td class="pl-hf">
                                <router-link :to="{name: 'onboarding', params: {hashedClientId: $hasher.encode(client.id)}, query: getWarningTypeFilterQueryParams(warningType)}">{{ warningType.label }}</router-link>
                            </td>
                            <td class="text-right pr-3">
                                    {{ numWarningsByType[warningType.type] }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- Time Until Expected Start Date -->
                <div class="table-section pb-0">
                    <a href class="table-section-titlebar collapse-trigger pl-0 pr-0" role="button" :class="{'is-expanded': timeUntilStartSectionExpanded}" @click.prevent="timeUntilStartSectionExpanded = !timeUntilStartSectionExpanded">
                        <collapse-toggle-button class="pt-hf pl-1"></collapse-toggle-button>
                        <span class="table-section-title text-dark text-semibold">Time Until Expected Start Date</span>
                    </a>
                    <section v-show="timeUntilStartSectionExpanded" class="p-2">
                        <bar-chart :chart-data="timeUntilStartBarChartData" stacked></bar-chart>
                    </section>
                </div>
            </div>
        </slot>
    </insight-widget>
</template>

<script>
    import InsightWidget from '@/components/EmployerDashboard/InsightWidget'
    import PieChart from '@/components/Charts/PieChart'
    import BarChart from '@/components/Charts/BarChart'
    import {objectToQueryParams} from '@/components/FastTable/filter-utils'

    export default {
        props: ['client', 'onboardingApps', 'warnings'],
        components: {InsightWidget, PieChart, BarChart},
        data() {
            return {
                widgetExpanded: true,
                timeUntilStartSectionExpanded: true,
                warningsOpen: false,
            }
        },
        computed: {
            totalNumOnboardingApps() {
                return this.pieChartData.reduce((sum, slice) => sum + slice.value, 0)
            },
            pieChartData() {
                let data = [
                    {
                        label: "In Progress",
                        value: 0,
                        color: '#368F99',
                        section: 'in-progress'
                    },
                    {
                        label: "Ready for Approval",
                        value: 0,
                        color: '#FFA200',
                        section: 'needs-manager-approval'
                    },
                    {
                        label: "Sending to Payroll",
                        value: 0,
                        color: '#2A7EFF',
                        section: 'hired'
                    },
                ]
                this.onboardingApps.forEach(o => {
                    let status = o.status
                    if (['started', 'logged-in'].includes(status)) { status = 'in-progress'}
                    let section = data.find(s => s.section == status)
                    section.value += 1
                })
                return data
            },
            timeUntilStartBarChartData() {
                let stacksData = [
                    {
                        value: 0,
                        label: "In Progress",
                        color: '#368F99',
                        stack: 'in-progress'
                    },
                    {
                        value: 0,
                        label: "Ready for Approval",
                        color: '#FFA200',
                        stack: 'needs-manager-approval'
                    },
                    {
                        value: 0,
                        label: "Sending to Payroll",
                        color: '#2A7EFF',
                        stack: 'hired'
                    }
                ]
                let data = [
                    {
                        value: 0,
                        label: "3+ days ago",
                        stacks: JSON.parse(JSON.stringify(stacksData)),
                    },
                    {
                        value: 0,
                        label: "1-2 days ago",
                        stacks: JSON.parse(JSON.stringify(stacksData)),
                    },
                    {
                        value: 0,
                        label: "In 0-3 days",
                        stacks: JSON.parse(JSON.stringify(stacksData)),
                    },
                    {
                        value: 0,
                        label: "In 4+ days",
                        stacks: JSON.parse(JSON.stringify(stacksData)),
                    },
                ]
                this.onboardingApps.forEach(o => {
                    let barIdx
                    if (o.days_until_start <= -3) {
                        barIdx = 0
                    } else if (o.days_until_start <= -1 && o.days_until_start >= -2) {
                        barIdx = 1
                    } else if (o.days_until_start >= 0 && o.days_until_start <= 3) {
                        barIdx = 2
                    } else if (o.days_until_start >= 4) {
                        barIdx = 3
                    }
                    let status = o.status
                    if (['started', 'logged-in'].includes(status)) { status = 'in-progress'}
                    data[barIdx].value += 1
                    let stack = data[barIdx].stacks.find(s => s.stack == status)
                    stack.value += 1
                })
                return data
            },
            warningTypes() {
                const types = []
                const seen = new Set()
                if (!this.warnings) {
                    return types
                }
                this.warnings.forEach(w => {
                    if (seen.has(w.warning_type)) {
                        return
                    }
                    seen.add(w.warning_type)
                    types.push({
                        type: w.warning_type,
                        label: w.warning_type_pretty,
                    })
                })

                return types
            },
            numWarningsByType() {
                const result = {}
                if (!this.warnings) {
                    return result
                }
                this.warningTypes.forEach(t => {
                    result[t.type] = 0
                })

                this.warnings.forEach(w => {
                    result[w.warning_type] += 1
                })

                return result
            },
        },
        methods: {
            getWarningTypeFilterQueryParams(warningType) {
                return objectToQueryParams({
                    numFilters: 1,
                    filters: {
                        warnings: [
                            {
                                value: warningType.type,
                                label: warningType.label
                            }
                        ]
                    }
                })
            },
        }
    }
</script>

<style lang="scss">
    .onboarding-insight-widget-content {
        .table-section {
            .table-section-titlebar {
                font-size: 16px;
            }
        }
        .onboarding-pie-chart {
            max-width: 120px;
        }
        .legend-color {
            width: 18px;
            height: 18px;
            border-radius: 2px;
        }
    }
</style>