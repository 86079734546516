<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div class="container-centered py-4">

                        <h1 class="text-center mb-2">
                            Review Trusted Devices
                        </h1>

                        <div class="mb-2 text-center">
                            <span class="text-muted">
                                {{ state.user.email }}
                            </span>
                        </div>

                        <div class="m-2">
                            <p>
                                You've added this as a Trusted Device. You can give it a name,
                                and review your other Trusted Devices.
                            </p>

                            <p>
                                You can always manage your devices in Settings / Account & Security.
                            </p>
                        </div>

                        <hr>

                        <br><br>

                        <div class="card card-body mb-2 text-center mx-auto device-tile" :class="{'newest-device': device_idx == 0}" v-for="(device, device_idx) in mobile_devices" style="max-width: 240px;">
                            <div class="new-device-marker-tab" v-if="device_idx == 0">
                                New Device
                            </div>
                            <div class="mb-1">
                                <i :class="['fal', device.icon, 'display-4', 'opacity-50']"></i>
                            </div>

                            <div class="mb-1 text-large text-semibold">
                                <span v-if="device.user_label">
                                    {{ device.user_label }}
                                </span>
                                <span v-else>
                                    <i>No Nickname</i>
                                </span>

                                <a href="#" @click.prevent="editMobileDeviceNickname(device)" class="pl-1"><i class="far fa-pencil"></i></a>
                            </div>

                            <div class="mb-1">
                                {{ device.device_model_formatted }}
                            </div>

                            <div class="mb-1" v-if="device.last_used">
                                <i>Last sign in {{ device.last_used|fromNow }}</i>
                            </div>

                            <div>
                                <a href="#" @click.prevent="deauthorizeDevice(device)"><i class="far fa-trash"></i> Remove</a>
                            </div>
                        </div>

                        <button-row stacked>
                            <router-link class="btn btn-primary" :to="{name: 'index'}">Continue</router-link>
                        </button-row>
                    </div>
                </div>
            </div>
        </div>

        <mobile-device-nickname-modal ref="mobileDeviceNicknameModal" :device="currentMobileDevice" v-if="currentMobileDevice" @updated="onMobileDevicesUpdated($event)" />
    </div>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import moment from 'moment'

import MobileDeviceNicknameModal from '@/pages/UserAccount/components/MobileDeviceNicknameModal'
import ButtonRow from '@/components/ButtonRow'

export default {
    components: {MobileDeviceNicknameModal, ButtonRow, },
    computed: {
        state() {return this.$store.state},
    },
    data() {
      return {
          mobile_devices: [],
          currentMobileDevice: null,
      }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Review Trusted Devices')
        this.getUserAccountInfo()
    },
    methods: {
        getUserAccountInfo() {
            this.$store.dispatch('START_LOADING')

            this.$store.dispatch('GET_USER').then(() => {
                api.get('/me/account-info').then((data) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.mobile_devices = data['mobile_devices']

                    // if we got an explicit new device ID, and that device
                    // isn't first in the list, move it to be first
                    let new_device_id = this.$route.query.ndid
                    if (new_device_id) {
                        if (this.mobile_devices[0].id != new_device_id) {
                            let new_device_idx = this.mobile_devices.findIndex((x) => x.id == new_device_id);
                            let new_device_elm = this.mobile_devices.find((x) => x.id == new_device_id);
                            this.mobile_devices.splice(new_device_idx, 1)
                            this.mobile_devices.unshift(new_device_elm)
                        }
                    }
                }).catch((errors) => {
                    this.$store.dispatch('STOP_LOADING')
                    bus.showError(errors.__all__)
                })
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__)
            })
        },
        editMobileDeviceNickname(device) {
            this.currentMobileDevice = device
            this.$nextTick(() => {
                this.$refs.mobileDeviceNicknameModal.open()
            })
        },
        onMobileDevicesUpdated(mobile_devices) {
            this.mobile_devices = mobile_devices
        },
        deauthorizeDevice(device) {
          if (! confirm('Removing this device will turn off 1-button verification on that device.\n\n' + 'The next time you log in, we will confirm your identity via email or another Trusted Device (if you have one).\n\n' + 'Do you want to proceed?')) {
            return;
          }

          this.$store.dispatch('START_LOADING')
          api.post(`/mobile-device/${device.id}/deauthorize`, {}).then((data) => {
              this.$store.dispatch('STOP_LOADING')
              this.mobile_devices = data['mobile_devices']

              // if we got an explicit new device ID, and that device
              // isn't first in the list, move it to be first
              let new_device_id = this.$route.query.ndid
              if (new_device_id) {
                  if (this.mobile_devices[0].id != new_device_id) {
                      let new_device_idx = this.mobile_devices.findIndex((x) => x.id == new_device_id);
                      let new_device_elm = this.mobile_devices.find((x) => x.id == new_device_id);
                      this.mobile_devices.splice(new_device_idx, 1)
                      this.mobile_devices.unshift(new_device_elm)
                  }
              }

              bus.showInfo('Trusted Device cleared.')
          }).catch((errors) => {
              this.$store.dispatch('STOP_LOADING')
              bus.showError(errors.__all__)
          })
        },
    }
}
</script>

<style>
.device-tile {
    border-radius: 12px !important;
}

.newest-device {
    border: 6px solid #00A050 !important;
    border-top-right-radius: 0 !important;
}

.new-device-marker-tab {
    position: absolute;
    background: #00A050;
    color: white;
    padding: 0.5em 1em;
    right: -6px;
    top: -37px;
}
</style>
