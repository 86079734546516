<template>
    <modal size="xl" @close="$emit('close')" ref="modal">
        <template slot="title">
            W2 Detail <span v-if="w2">- {{ w2.tax_year }}</span>
        </template>

        <template slot="headerbuttons" v-if="w2">
            <a @click="downloadFile(w2.client_file_url)" class="btn btn-primary d-print-none ml-hf">
              <i class="far fa-fw fa-file-pdf"></i>
              <span class="btn-text">Download PDF</span>
            </a>
        </template>

        <slot>
            <client-w2-detail :w2="w2" v-if="w2" />
        </slot>
    </modal>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import ClientW2Detail from '@/pages/client/employee-w2s/components/ClientW2Detail'
import ModalMixin from '@/mixins/ModalMixin'
import DownloadableMixin from '@/mixins/Downloadable'

export default {
    mixins: [ModalMixin, DownloadableMixin],
    props: ['w2detail', 'client', ],
    components: {ClientW2Detail, },
    data() {
        return {
            w2: null,
        }
    },
    watch: {
        w2detail: {
            handler() {
                this.getW2Detail()
            },
            immediate: true,
        }
    },
    methods: {
        getW2Detail() {
            this.$store.dispatch('START_LOADING')
            const url = this.client ? `/clients/${this.client.id}/employee-w2s/${this.w2detail.id}` : `/admin/w2-review/detail/${this.w2detail.id}/preview`
            api.get(url).then(resp => {
                this.w2 = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__[0])
            })
        },
    },
}
</script>
