<template>
    <modal @close="$emit('close')" ref="modal" :fade="false" :v-center="true" :autoshow="true" :is-closeable="isCloseable">
        <template slot="title">{{ doc.is_manager_uploaded ? 'Manager' : 'Employee' }} Document Photos</template>

        <alert-box type="caution" v-if="!doc.is_manager_uploaded && !form.accepted_on">
            <p class="mb-1">
                <strong>
                Per the U.S Citizenship and Immigration Services' (USCIS), "Handbook for Employers: Guidance for Completing I-9," Employers or an Employer's authorized representative must physically examine each original document <em>(e.g. List A, List B or C documents)</em> from the employee to determine if the document reasonably appears to be genuine and relates to the person presenting it. The examination of these documents must occur in the physical presence of the employee presenting them.
                </strong>
            </p>
            <p class="text-small mb-1">
                For additional instructions for employers regarding completing Form I-9, including how to handle minors, employees with disabilities and other special situations, the following link will take you to the USCIS webpage where you can review Form M-274 &mdash; Handbook for Employers: Guidance for completing Form I-9.
            </p>
            <p class="text-small mb-0">
                <a href="http://www.uscis.gov/book/export/html/59502" target="_blank">www.uscis.gov/book/export/html/59502</a>
            </p>
        </alert-box>

        <slot>
            <div v-if="mode == 'list'">
                <h4>{{ documentTitle }}</h4>
                <p class="text-small text-muted">{{ documentList }}</p>

                <div class="card card-body p-1 mb-1" v-for="photo in sortedPhotos">
                    <div class="d-flex mb-1">
                        <span class="flex-grow-1 text-bold btnlike">{{ photo.side_pretty }}:</span>
                        <span class="flex-grow-0 text-right position-relative">
                            <button class="btn btn-outline-primary btn-collapse-xl" :disabled="zoom >= 400" @click.prevent="zoom += 50">
                                <i class="far fa-fw fa-search-plus"></i>
                                <span class="sr-only">Zoom In</span>
                            </button>
                            <button class="btn btn-outline-primary btn-collapse-xl ml-1" :disabled="zoom <= 100" @click.prevent="zoom -= 50">
                                <i class="far fa-fw fa-search-minus"></i>
                                <span class="sr-only">Zoom Out</span>
                            </button>
                        </span>
                    </div>
                    <div>
                        <div class="docphoto" :class="{'zoom-active': zoom > 100}">
                            <img :src="`${$api.API_BASE}/clients/${client.id}/onboarding-applications/${onboardingApplicationId}/forms/${form.id}/photos/${photo.id}/preview`" alt="Document Photo" :style="(zoom > 100) ? `width: ${zoom}%; max-width: ${zoom}%;` : ''" />
                        </div>
                    </div>
                </div>

                <p class="text-muted pt-1">
                    Uploaded on {{ doc.photos[0].created_on|date }} by {{ doc.is_manager_uploaded ? 'Manager' : 'Employee' }}
                </p>

                <div v-if="doc.is_manager_uploaded && mostRecentI9">
                    <button-row class="mt-0 pt-0">
                        <button v-if="!company.i9_document_photos_er_required" type="button" class="btn btn-outline-primary" @click.prevent="removePhotos(doc)">
                            Remove
                        </button>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="mode = 'upload'">
                            Replace...
                        </button>
                    </button-row>
                </div>
            </div>

            <div class="mb-2" v-if="mode == 'upload'">
                <div v-if="!mostRecentI9">
                    This document has been superseded by a more recent version.
                </div>

                <photo-upload
                    v-if="mostRecentI9"
                    :doc="doc"
                    :list="doc.document_list"
                    @close="doc.photos.length ? (mode = 'list') : close()"
                    @uploads="onDocUpload($event)"
                    :back-button-label="doc.photos.length ? 'Back' : 'Cancel'"
                />
            </div>
        </slot>

        <template slot="footer" v-if="mode == 'list'">
            <button-row stacked>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close">
                    Done
                </button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import PhotoUpload from './I9PhotoUpload'

export default {
    props: ['client', 'company', 'onboardingApplicationId', 'form', 'doc', 'mostRecentI9'],
    mixins: [ModalMixin],
    components: {PhotoUpload, },
    computed: {
        employeePhotoCount() {
            return this.doc.photos.filter(p => !p.is_manager_uploaded).length
        },
        employerPhotoCount() {
            return this.doc.photos.filter(p => p.is_manager_uploaded).length
        },
        sortedPhotos() {
            const photos = Array.from(this.doc.photos)
            photos.sort(a => (a.side == 'front') ? -1 : 1)
            return photos
        },
        documentTitle() {
            return this.doc.photos[0].document_title
        },
        documentList() {
            return ({
                'list-a': 'List A Documents That Establish Identity and Employment Authorization',
                'list-a-1': 'List A Documents That Establish Identity and Employment Authorization (secondary document)',
                'list-a-2': 'List A Documents That Establish Identity and Employment Authorization (tertiary document)',
                'list-b': 'List B Documents That Establish Identity',
                'list-c': 'List C Documents That Establish Employment Authorization',
            })[this.doc.photos[0].document_list]
        },
    },
    data() {
        let mode = 'list'
        if (this.doc.is_manager_uploaded && this.doc.photos.length < 1) {
            mode = 'upload'
        }

        return {
            zoom: 100,
            mode: mode,
        }
    },
    methods: {
        onDocUpload(photos) {
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/onboarding-applications/${this.onboardingApplicationId}/forms/${this.form.id}/photos`, photos).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated')
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                Object.keys(errors).forEach(field => {
                    if (field.substring(0, 2) == '__' && field != '__all__') {return}
                    this.formErrors = this.formErrors.concat(errors[field])
                })

                this.$bus.showError(this.formErrors)
            })
        },
        removePhotos(doc) {
            if (!confirm('Are you sure?')) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.del(`/clients/${this.client.id}/onboarding-applications/${this.onboardingApplicationId}/forms/${this.form.id}/photos/${doc.document_list}`).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated')
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                Object.keys(errors).forEach(field => {
                    if (field.substring(0, 2) == '__' && field != '__all__') {return}
                    this.formErrors = this.formErrors.concat(errors[field])
                })

                this.$bus.showError(this.formErrors)
            })
        },
    },
}
</script>

<style scoped>
.docphoto img {
    max-height: 300px;
    max-width: auto;
}
.docphoto.zoom-active img {
    max-height: none;
}
.docphoto.zoom-active {
    max-width: 100%;
    overflow: auto;
    max-height: 300px;
}
</style>
