import { render, staticRenderFns } from "./CovidVaccinationStatusReportingModal.vue?vue&type=template&id=94d5b7ca&scoped=true&"
import script from "./CovidVaccinationStatusReportingModal.vue?vue&type=script&lang=js&"
export * from "./CovidVaccinationStatusReportingModal.vue?vue&type=script&lang=js&"
import style0 from "./CovidVaccinationStatusReportingModal.vue?vue&type=style&index=0&id=94d5b7ca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94d5b7ca",
  null
  
)

export default component.exports