<template>
    <span class="flex-grow-1">
        <form-input type="search" placeholder="Search" v-model="search" @input="$emit('input', $event)" />
    </span>
</template>

<script>
export default {
    props: ['value'],
    data() {
        return {
            search: this.value
        }
    },
}
</script>
