<template>
    <modal @close="$emit('close')" ref="modal">
        <template slot="title">Change Name</template>
        <template slot="subheading">
            <div class="p-1 unpad-x bg-150 rounded mb-1">
                <span class="text-muted">Organization:</span> <span class="text-nowrap">{{ sec_profile.client.name }}</span>
            </div>
        </template>

        <slot>
            <p>
                This name is how you appear to other security users and to employees whom you manage.
            </p>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <div class="form-row">
                    <div class="col-6">
                        <form-input v-model="formData.first_name" :errors="errors.first_name" label="First Name" type="text" maxlength="25" />
                    </div>
                    <div class="col-6">
                        <form-input v-model="formData.last_name" :errors="errors.last_name" label="Last Name" type="text" maxlength="25" />
                    </div>
                </div>

                <button-row>
                    <button type="button" class="btn btn-outline-primary" @click.prevent="close">
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-primary">
                        Continue
                    </button>
                </button-row>
            </form>

        </slot>
    </modal>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import ModalMixin from '@/mixins/ModalMixin'
import FormMixin from '@/mixins/Form'

export default {
    mixins: [ModalMixin, FormMixin],
    props: ['sec_profile'],
        data() {
        return {
            formRules: {
                first_name: {
                    presence: {allowEmpty: false},
                },
                last_name: {
                    presence: {allowEmpty: false},
                },
            },
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            return {
                first_name: this.sec_profile.first_name,
                last_name: this.sec_profile.last_name,
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.post(`/me/clients/${this.sec_profile.client.id}/update/${this.sec_profile.id}/name`, this.formData).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showInfo("Name has been updated.")
                this.$emit('updated')
                this.close()
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__)
            })
        },
    },

}
</script>
