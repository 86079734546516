<template>
    <modal @close="$emit('close')" ref="modal" :fade="false" :v-center="true" :autoshow="true" :is-closeable="isCloseable">
        <template slot="title">{{ imageTitle }}</template>

        <slot>
            <div class="mt-hf">
                <div class="d-flex mb-1">
                    <span class="flex-grow-0 text-right position-relative">
                        <button class="btn btn-outline-primary btn-collapse-xl" :disabled="zoom >= 400" @click.prevent="zoom += 50">
                            <i class="far fa-fw fa-search-plus"></i>
                            <span class="sr-only">Zoom In</span>
                        </button>
                        <button class="btn btn-outline-primary btn-collapse-xl ml-1" :disabled="zoom <= 100" @click.prevent="zoom -= 50">
                            <i class="far fa-fw fa-search-minus"></i>
                            <span class="sr-only">Zoom Out</span>
                        </button>
                    </span>
                </div>
                <div>
                    <div class="docphoto" :class="{'zoom-active': zoom > 100}">
                        <img :src="imageUrl" :alt="imageTitle" :style="(zoom > 100) ? `width: ${zoom}%; max-width: ${zoom}%;` : ''" />
                    </div>
                </div>
            </div>
        </slot>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['imageUrl', 'imageTitle'],
    mixins: [ModalMixin],
    components: {},
    data() {
        return {
            zoom: 100,
        }
    },
}
</script>

<style scoped>
.docphoto img {
    max-height: 300px;
    max-width: auto;
}
.docphoto.zoom-active img {
    max-height: none;
}
.docphoto.zoom-active {
    max-width: 100%;
    overflow: auto;
    max-height: 300px;
}
</style>
