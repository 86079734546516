<template>
    <insight-widget :collapsible="true" :is-expanded="widgetExpanded" @toggle_expand="widgetExpanded = !widgetExpanded">
        <template slot="title">
            Self-Service Access
            <span class="counter-warnings pl-1" v-if="warnings && warnings.length">
                <i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings"></i>
                <span class="counter-label">{{ warnings.length }}</span>
            </span>
        </template>
        <template slot="headerbutton"><router-link :to="{name: 'self-service-access', params: {hashedClientId: $hasher.encode(client.id)}}">Go<span class='d-none d-sm-inline'> To Page </span><i class="fas fa-fw fa-chevron-right"></i></router-link></template>
        <slot>
            <div class="ess-insight-widget-content">
                <!-- Tabs -->
                <div class="nav-tabs-container pt-qt">
                    <div class="nav-tabs-scroll-helper">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link pl-2 pr-1" href data-toggle="tab" role="tab" :class="{active: activeTab == 'active_ee'}" @click.prevent="activeTab = 'active_ee'">Active Employees</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link pl-1 pr-1" href data-toggle="tab" role="tab" :class="{active: activeTab == 'inactive_ee'}" @click.prevent="activeTab = 'inactive_ee'">Inactive Employees</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Pie Chart -->
                <div class="d-flex p-2">
                    <div class="legend col-7 pl-0 pr-0">
                        <div v-for="slice in pieChartData[activeTab]" class="pb-2 d-flex">
                            <div class="legend-color mr-hf" :style="`background-color: ${slice.color}`"></div>
                            <span><router-link :to="{name: 'self-service-access', query: getUserFilterQueryParams(slice.status)}">{{ slice.label }}</router-link> ({{ slice.value }})</span>
                        </div>
                    </div>
                    <div class="col-5">
                        <pie-chart :chart-data="pieChartData[activeTab]" doughnut gap class="ess-pie-chart"></pie-chart>
                    </div>
                </div>
                <!-- Warnings -->
                <table class="table css-table table-fixed mb-hf" v-if="warningTypes.length">
                    <thead class="pb-0" :class="{'border-bottom': warningsOpen}">
                        <tr>
                            <th class="cw-toggle">
                                <a href class="collapse-trigger" role="button" :class="{'is-expanded': warningsOpen}" @click.prevent="warningsOpen = !warningsOpen">
                                    <collapse-toggle-button class="pl-0 pb-0"/>
                                </a>
                            </th>
                            <th class="pl-hf">Warning</th>
                            <th class="text-right pr-3">Num Employees</th>
                        </tr>
                    </thead>
                    <tbody v-show="warningsOpen">
                        <tr v-for="warningType in warningTypes" :key="warningType.type" >
                            <td></td>
                            <td class="pl-hf">
                                <router-link :to="{name: 'self-service-access', params: {hashedClientId: $hasher.encode(client.id)}, query: getWarningTypeFilterQueryParams(warningType)}">{{ warningType.label }}</router-link>
                            </td>
                            <td class="text-right pr-3">
                                    {{ numWarningsByType[warningType.type] }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </slot>
    </insight-widget>
</template>

<script>
    import InsightWidget from '@/components/EmployerDashboard/InsightWidget'
    import PieChart from '@/components/Charts/PieChart'
    import BarChart from '@/components/Charts/BarChart'
    import {objectToQueryParams} from '@/components/FastTable/filter-utils'

    export default {
        props: ['client', 'widgetData', 'warnings'],
        components: {InsightWidget, PieChart, BarChart},
        data() {
            return {
                widgetExpanded: true,
                activeTab: 'active_ee',
                warningsOpen: false,
            }
        },
        computed: {
            pieChartData() {
                let data = {
                    active_ee: [
                        {
                            label: "OK",
                            value: 0,
                            color: '#2A7EFF',
                            status: 'OK'
                        },
                        {
                            label: "Not Linked",
                            value: 0,
                            color: '#525252',
                            status: 'Not Linked'
                        },
                        {
                            label: "Withdrew Consent",
                            value: 0,
                            color: '#999',
                            status: 'Withdrew Consent'
                        },
                        {
                            label: "Not Registered",
                            value: 0,
                            color: '#DDD',
                            status: 'Not Registered'
                        },
                    ],
                    inactive_ee: [
                        {
                            label: "OK",
                            value: 0,
                            color: '#2A7EFF',
                            status: 'OK'
                        },
                        {
                            label: "Not Linked",
                            value: 0,
                            color: '#525252',
                            status: 'Not Linked'
                        },
                        {
                            label: "Withdrew Consent",
                            value: 0,
                            color: '#999',
                            status: 'Withdrew Consent'
                        },
                        {
                            label: "Not Registered",
                            value: 0,
                            color: '#DDD',
                            status: 'Not Registered'
                        },
                    ]
                }
                this.widgetData.forEach(u => {
                    // NB: employees and onboarding_applications within each user object passed to this component are not filtered by company/manager
                    // rather each user is filtered based on if they *include* an ee/oa or company_security_profile_ids for the company/manager filter
                    // so the active/inactive tab and status calculated here may be misleading depending on what the expectation is for this filter
                    // TODO KB consider somehow applying the filter to employees and onboarding_application too to resolve this
                    let tab = u.is_active ? 'active_ee' : 'inactive_ee'
                    let status = 'OK'
                    if (! u.has_user) {
                        status = 'Not Registered'
                    } else {
                        u.employees.forEach(e => {
                            if (! e.user_id) {
                                status = 'Not Linked'
                            }
                        })
                        if (u.digital_consent_revoked_on) {
                            status = 'Withdrew Consent'
                        }
                    }
                    let section = data[tab].find(s => s.status == status)
                    section.value += 1
                })
                return data
            },
            warningTypes() {
                const types = []
                const seen = new Set()
                if (!this.warnings) {
                    return types
                }
                this.warnings.forEach(w => {
                    if (seen.has(w.warning_type)) {
                        return
                    }
                    seen.add(w.warning_type)
                    types.push({
                        type: w.warning_type,
                        label: w.warning_type_pretty,
                    })
                })

                return types
            },
            numWarningsByType() {
                const result = {}
                if (!this.warnings) {
                    return result
                }
                this.warningTypes.forEach(t => {
                    result[t.type] = 0
                })

                this.warnings.forEach(w => {
                    result[w.warning_type] += 1
                })

                return result
            },
        },
        methods: {
            getUserFilterQueryParams(status) {
                return objectToQueryParams({
                    numFilters: 1,
                    filters: {
                        'account-status': [
                            {
                                value: status,
                                label: status
                            }
                        ]
                    }
                })
            },
            getWarningTypeFilterQueryParams(warningType) {
                return objectToQueryParams({
                    numFilters: 1,
                    filters: {
                        warnings: [
                            {
                                value: warningType.type,
                                label: warningType.label
                            }
                        ]
                    }
                })
            },
        }
    }
</script>

<style lang="scss">
    .ess-insight-widget-content {
        .ess-pie-chart {
            max-width: 120px;
        }
        .legend-color {
            width: 18px;
            height: 18px;
            border-radius: 2px;
        }
    }
</style>