<template>
    <div class="button-row-container" :class="buttonRowClasses">
        <!-- Optionally include form errors just above buttons -->
        <slot name="errors"></slot>
        <!-- Actual buttons go here; generally use 1 or 2 -->
        <div class="button-row">
            <slot></slot>
        </div>
    </div>
</template>

<style scoped lang="scss">
  // Import Sass globals
  @import "@/assets/scss/globals";

  // 
  // Component styles
  //

  .button-row-container {
    padding-top: spacer(2); 
    // Ensures breathing room between form content & buttons. 
    // Override this in markup as needed.
  }

  // Defaults for button-row
  .button-row {
    display: flex;
    align-items: center;
    justify-content: center; // Centers a single button; keeps 2 block buttons side-by-side
    
    ::v-deep .btn {
      margin-top: 0;
      margin-bottom: 0.5rem;    
      display: block; // By default, replicates .btn-block but without the side effects of @extend 
      width: 100%;
      
      &:first-child {
        margin-right: 5px;
      }
      
      &:last-child {
        margin-left: 5px;
      }
    }

    .tooltip-wrapper {
      width: 100%;
    }
  }

  // Inline
  //    Non-block buttons (when screen is wide enough)
  .button-row--inline {
    
    .button-row {
      justify-content: space-between; 
      // Puts the 1st button on the left edge, 2nd button on the right edge
      
        
      ::v-deep .btn {
        @include media-breakpoint-up(rs) { // For small phones, buttons should stay block
          display: inline-block;
          width: auto;
        }
      }
    }
  }

  // Centered
  .button-row--center {
    
    .button-row {
      justify-content: center; // Override inline behavior if it's also set
    
        
      ::v-deep .btn {
        @include media-breakpoint-up(rs) {
          max-width: 50%; 
          // Constrains a single button if it's block-formatted; 
          // has no effect on 2+ buttons
        }
      }
    }
  }

  // Stacked
  //    On small screens only, wrap/stack the buttons (deals with long labels)
  .button-row--stacked { 
    
    .button-row {
      flex-wrap: wrap;
      
      @include media-breakpoint-up(rs) {
        flex-wrap: nowrap;
      }

      @include media-breakpoint-down(ss) {
        
        ::v-deep .btn {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }
    }
  }
</style>

<script>
export default {
    props: {
        // 'Center':
        //    For single-button situations only.
        //    By default, a single button will stretch the entire row width.
        //    Add 'center' property to an instance when you want that single
        //    button to be narrower & sit in the middle instead.
        //    Can be combined with 'inline'.
        center: {
            type: Boolean,
            default: false,
        },
        // 'Inline':
        //    Buttons in <button-row> are block by default, meaning that they stretch 
        //    to fill their entire row. Add 'inline' property to an instance
        //    when you want the buttons to switch to inline-block (width based on 
        //    the button label, flush with left/right edges) on larger screens 
        //    ('rs' and wider). Generally we do this on pages but avoid it in modals.
        inline: {
            type: Boolean,
            default: false,
        },
        // 'Stacked':
        //    Add 'stacked' property to an instance to deal with long button labels.
        //    Only has an effect when there are 2+ buttons in the row.
        //    Buttons get stacked vertically on small screens, then
        //    transition to normal side-by-side layout
        //    on larger screens ('rs' breakpoint).
        stacked: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        buttonRowClasses() {
            let buttonRowClasses = ''
            if (this.inline) {
                buttonRowClasses += ' button-row--inline'
            }
            if (this.center) {
                buttonRowClasses += ' button-row--center'
            }
            if (this.stacked) {
                buttonRowClasses += ' button-row--stacked'
            }
            return buttonRowClasses
        },
    },
}
</script>
