<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">
                    <div class="container-centered-s py-4">
                        <h5>{{ onboardingApplication.company.name }}</h5>
                        <h3>Emergency Contacts</h3>
                        <p class="mt-2">Please provide information for at least one person to contact in case of emergency.</p>

                        <form @submit.prevent="onSubmit">
                            <form-errors :errors="formErrors"/>

                            <div v-for="(id, $index) in Object.keys(emergencyContactForms)" :key="id">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6 class="mb-1">Contact {{ $index + 1 }}</h6>
                                    </div>
                                    <div class="col text-right">
                                        <a href class="btn btn-link" @click.prevent="removeEmergencyContact(id, $index)" v-if="Object.keys(emergencyContactForms).length > 1">Remove</a>
                                    </div>
                                </div>
                                <emergency-contact-form
                                    :instance="formData.emergency_contacts[$index]"
                                    ref="emergencyContactForms"
                                    @update="onEmergencyContactUpdate($index, $event)"
                                />
                            </div>
                            <p class="mb-2">
                                <a href class="btn btn-link" @click.prevent="addEmergencyContact()"><i class="far fa-plus mr-hf"></i>Add another</a>
                            </p>

                            <div class="row mb-2 d-flex align-items-center">
                                <div class="col">
                                    <router-link v-if="previousPage" :to="previousPage">Back</router-link>
                                </div>
                            
                                <div class="col text-right">
                                    <button class="btn btn-primary">Continue</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import api from '@/api'
import WizardMixin from './WizardMixin'
import EmergencyContactForm from '@/components/EmergencyContactForm'

export default {
    mixins: [FormMixin, WizardMixin],
    components: {EmergencyContactForm},
    props: ['onboardingApplication', ],
    mounted() {
        this.reset()
    },
    data() {
        const data = {
            formData: this.makeFormData(),
            emergencyContactForms: {},
        }

        data.emergencyContactForms = {0: 0}
        if (this.onboardingApplication) {
            for (let i = 0; i < this.onboardingApplication.emergency_contacts.length; i++) {
                data.emergencyContactForms[i] = i
            }
        }

        return data
    },
    methods: {
        makeFormData() {
            if (this.onboardingApplication) {
                const formData = {
                    id: this.onboardingApplication.id,
                    action: 'give-emergency-contact',
                    emergency_contacts: [],
                }

                this.onboardingApplication.emergency_contacts.forEach((contact) => {
                    formData.emergency_contacts.push({
                        id: contact.id,
                        first_name: contact.first_name,
                        last_name: contact.last_name,
                        relationship: contact.relationship,
                        phone: contact.phone,
                        email: contact.email,
                    })
                })

                return formData
            }
            else {
                return {
                    action: 'give-emergency-contact',
                    emergency_contacts: [],
                }
            }
        },
        addEmergencyContact() {
            const id = Math.max(1, ...Object.keys(this.emergencyContactForms)) + 1
            this.$set(this.emergencyContactForms, id, Object.keys(this.emergencyContactForms).length - 1)
            this.$emit('scrollbottom')
        },
        removeEmergencyContact(id, idx) {
            const emergency_contacts = []
            this.formData.emergency_contacts = [];
            (this.$refs.emergencyContactForms || []).forEach((form, i) => {
                if (i != idx) {
                    emergency_contacts.push(form.getFormData())
                }
            })

            this.$set(this.formData, 'emergency_contacts', emergency_contacts)
            this.$delete(this.emergencyContactForms, id)
        },
        onEmergencyContactUpdate(idx, data) {
            this.$set(this.formData.emergency_contacts, idx, data)
        },
        onSubmit() {
            let errorCount = 0;
            (this.$refs.emergencyContactForms || []).forEach((form) => {
                if (!form.validate()) {
                    errorCount += 1
                }
            })

            if (!this.validate() || errorCount) {
                return
            }

            this.$store.dispatch('START_LOADING')
            api.patch(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application`, this.getFormData()).then((resp) => {
                this.$emit('updated', resp)
                this.$emit('next')
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reset: function() {
            (this.$refs.emergencyContactForms || []).forEach((form) => {
                form.reset()
            })
            if (! this.emergencyContactForms) {
                this.emergencyContactForms = {1: 1}
            }
            this.formErrors = []
            this.formData = this.makeFormData()

            FormMixin.methods.reset.call(this)
        },
    }
}
</script>
