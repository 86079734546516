<template>
    <modal size="xl" ref="modal" @close="$emit('close')" :is-closeable="isCloseable" :autoshow="true">
        <template slot="title">
            <span v-if="involuntaryWithholdingLaw">Update: </span><span v-if="involuntaryWithholdingLaw" class="text-thin text-nowrap">{{ involuntaryWithholdingLaw.involuntary_withholding_type.name }}</span>
            <span v-if="!involuntaryWithholdingLaw">Add Invluntary Withholding Law</span>
        </template>
        <slot>

            <form class="mui-form" @submit.prevent="onSubmit">
                <div class="form-row mb-4">
                    <div class="col-6">
                        <form-input v-model="formData.starts_on" :errors="errors.starts_on" type="date" label="Starts On" />
                    </div>
                    <div class="col-6">
                        <form-input v-model="formData.inactive_on" :errors="errors.inactive_on" type="date" label="Expires On" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.type" :errors="errors.type" type="select" label="Type" :options="typeOptions" :readonly="!!involuntaryWithholdingLaw" />
                    </div>
                </div>

                <div v-if="formData.type">
                    <div class="form-row mb-2" v-if="currentType.has_rate">
                        <div class="col-2">
                            <form-input v-model="formData.min_rate" :errors="errors.min_rate" type="integer" :label="currentType.has_min_max_rate ? 'Min. Rate' : 'Rate'" />
                        </div>
                        <div class="col-2" v-if="currentType.has_min_max_rate">
                            <form-input v-model="formData.max_rate" :errors="errors.max_rate" type="integer" label="Max. Rate" />
                        </div>
                        <div class="col-2" v-if="currentType.has_arrears_rate">
                            <form-input v-model="formData.arrears_rate" :errors="errors.arrears_rate" type="integer" label="Additional Arrears Rate" />
                        </div>
                        <div class="col-2" v-if="currentType.has_non_credit_rate">
                            <form-input v-model="formData.min_non_credit_rate" :errors="errors.min_non_credit_rate" type="integer" label="Non-credit Rate" />
                        </div>
                    </div>

                    <div class="mb-2" v-if="currentType.has_exempt_amount">
                        <div class="" v-for="filingStatus in jurisdiction.filing_statuses">
                            <h6>Exempt Amounts for {{ filingStatus.name }}</h6>
                            <div class="form-row mb-2">
                                <div class="col">
                                    <form-input v-model="formData.exempt_amounts[filingStatus.id].base_amount" :errors="errors.exempt_amounts[filingStatus.id].base_amount" type="currency" label="Base Amount" />
                                </div>
                                <div class="col">
                                    <form-input v-model="formData.exempt_amounts[filingStatus.id].per_dependent_amount" :errors="errors.exempt_amounts[filingStatus.id].per_dependent_amount" type="currency" label="Per Dependent Amount" />
                                </div>
                                <div class="col">
                                    <form-input v-model="formData.exempt_amounts[filingStatus.id].additional_exempt_amount" :errors="errors.exempt_amounts[filingStatus.id].additional_exempt_amount" type="currency" label="Additional Exempt Amount" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-2" v-if="formData.type">
                        <div class="card-header pr-1 d-flex align-items-center">
                            <span class="btnlike mr-auto">
                                <h4 class="mb-0">Earnings Excluded from Disposable</h4>
                            </span>
                        </div>

                        <div class="card-body py-1">
                            <table-selector
                                :sections="[{title: 'All', id: 'all'}]"
                                :headers="[{label: 'Name', classes: 'cell-wide'}]"
                                :items="earningTypeSelectionData"
                                v-model="formData.earning_types_excluded_from_disposable"
                            />
                        </div>
                    </div>

                    <div class="card mb-2" v-if="formData.type">
                        <div class="card-header pr-1 d-flex align-items-center">
                            <span class="btnlike mr-auto">
                                <h4 class="mb-0">Deductions that Reduce Disposable</h4>
                            </span>
                        </div>

                        <div class="card-body py-1">
                            <table-selector
                                :sections="[{title: 'All', id: 'all'}]"
                                :headers="[{label: 'Name', classes: 'cell-wide'}]"
                                :items="deductionTypeSelectionData"
                                v-model="formData.deductions_that_reduce_disposable"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </slot>

        <template slot="footer">
            <form-errors :errors="formErrors"/>

            <button-row>
                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import TableSelector from '@/components/TableSelector'

export default {
    props: ['involuntaryWithholdingLaw', 'jurisdiction', 'earningTypes', 'involuntaryWithholdingTypes', 'deductionTypes'],
    components: {TableSelector, },
    mixins: [FormMixin, ModalMixin, ],
    computed: {
        typeOptions() {
            let types = []
            if (this.jurisdiction.type == 'state') {
                types = this.involuntaryWithholdingTypes.filter(w => {
                    return ['child-support', 'tax-levy', 'creditor-garnishment'].includes(w.type)
                })
            }
            else if (this.jurisdiction.type == 'city') {
                types = this.involuntaryWithholdingTypes.filter(w => {
                    return ['tax-levy', 'creditor-garnishment'].includes(w.type)
                })
            }
            else if (this.jurisdiction.type == 'county') {
                types = this.involuntaryWithholdingTypes.filter(w => {
                    return ['tax-levy', 'creditor-garnishment'].includes(w.type)
                })
            }
            else if (this.jurisdiction.type == 'school-district') {
                types = this.involuntaryWithholdingTypes.filter(w => {
                    return ['tax-levy'].includes(w.type)
                })
            }
            else {types = this.involuntaryWithholdingTypes}

            const result = []
            types.forEach(w => {
                result.push({
                    text: w.name,
                    value: w.type,
                })
            })
            return result
        },
        currentType() {
            if (!this.formData.type) {
                return null
            }

            return this.involuntaryWithholdingTypes.find(w => w.type == this.formData.type)
        },
        earningTypeSelectionData() {
            // This returns data so we can select it via the table selector
            const result = []
            this.earningTypes.forEach(item => {
                result.push({
                    id: item.id,
                    cells: [
                        item.name,
                    ],
                })
            })

            return [result]
        },
        deductionTypeSelectionData() {
            // This returns data so we can select it via the table selector
            const result = []
            this.deductionTypes.forEach(item => {
                result.push({
                    id: item.id,
                    cells: [
                        item.name,
                    ],
                })
            })

            return [result]
        },
    },
    watch: {
        'formData.type': function() {
            this.$nextTick(() => {
                this.$bus.$emit('resize')
            })
        },
    },
    data() {
        return {
            formRules: {
                starts_on: {presence: {allowEmpty: false}, date: true},
                type: {presence: {allowEmpty: false}},
            },
            formData: this.makeFormData(),
            errors: {exempt_amounts: this.makeAmountExemptErrors()},
        }
    },
    methods: {
        makeAmountExemptErrors() {
            const exemptAmountErrors = {}
            const filingStatuses = this.jurisdiction.filing_statuses || []
            filingStatuses.forEach(x => {
                exemptAmountErrors[x.id] = []
            })
            return exemptAmountErrors
        },
        getFormData() {
            const type = this.currentType
            const data = JSON.parse(JSON.stringify(this.formData))  // Make a copy
            if (type.has_rate) {
                if (!type.has_min_max_rate) {
                    data.max_rate = data.min_rate
                }

                if (!type.has_arrears_rate) {
                    data.arrears_rate = 0
                }

                if (type.has_non_credit_rate) {
                    data.max_non_credit_rate = data.min_non_credit_rate
                }
                else if (!type.has_non_credit_rate) {
                    data.min_non_credit_rate = 0
                    data.max_non_credit_rate = 0
                }
            }
            else {
                data.min_rate = 100
                data.max_rate = 100
                data.arrears_rate = 0
                data.min_non_credit_rate = 0
                data.max_non_credit_rate = 0
            }

            data.involuntary_withholding_type = type.id
            return data
        },
        makeFormData() {
            const exemptAmounts = {}
            const filingStatuses = this.jurisdiction.filing_statuses || []
            filingStatuses.forEach(x => {
                const ea = this.involuntaryWithholdingLaw ? this.involuntaryWithholdingLaw.current_version.exempt_amounts.find(ea => ea.filing_status_id == x.id) : null
                exemptAmounts[x.id] = {
                    base_amount: ea ? ea.base_amount : '',
                    per_dependent_amount: ea ? ea.per_dependent_amount : '',
                    additional_exempt_amount: ea ? ea.additional_exempt_amount : '',
                }
            })

            if (this.involuntaryWithholdingLaw) {
                return {
                    starts_on: this.involuntaryWithholdingLaw.current_version.starts_on,
                    inactive_on: this.involuntaryWithholdingLaw.current_version.inactive_on,
                    type: this.involuntaryWithholdingLaw.involuntary_withholding_type.type,
                    min_rate: this.involuntaryWithholdingLaw.current_version.min_rate,
                    max_rate: this.involuntaryWithholdingLaw.current_version.max_rate,
                    arrears_rate: this.involuntaryWithholdingLaw.current_version.arrears_rate,
                    min_non_credit_rate: this.involuntaryWithholdingLaw.current_version.min_non_credit_rate,
                    max_non_credit_rate: this.involuntaryWithholdingLaw.current_version.max_non_credit_rate,
                    earning_types_excluded_from_disposable: this.involuntaryWithholdingLaw.current_version.earning_types_excluded_from_disposable.map(x => x.id),
                    deductions_that_reduce_disposable: this.involuntaryWithholdingLaw.current_version.deductions_that_reduce_disposable.map(x => x.id),
                    jurisdiction: this.jurisdiction.id,
                    exempt_amounts: exemptAmounts,
                }
            } else {
                return {
                    starts_on: '',
                    inactive_on: '',
                    type: '',
                    min_rate: '',
                    max_rate: '',
                    arrears_rate: 0,
                    min_non_credit_rate: '',
                    max_non_credit_rate: '',
                    earning_types_excluded_from_disposable: [],
                    deductions_that_reduce_disposable: [],
                    jurisdiction: this.jurisdiction.id,
                    exempt_amounts: exemptAmounts,
                }
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }
            this.errors.exempt_amounts = Object.assign(this.makeAmountExemptErrors(), this.errors.exempt_amounts || {})

            let promise, url;
            if (this.involuntaryWithholdingLaw) {
                url = `/admin/tax-engine/jurisdictions/${this.jurisdiction.id}/involuntary-withholding-laws/${this.involuntaryWithholdingLaw.id}`
                promise = this.$api.put(url, this.getFormData())
            }
            else {
                url = `/admin/tax-engine/jurisdictions/${this.jurisdiction.id}/involuntary-withholding-laws`
                promise = this.$api.post(url, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.errors.exempt_amounts = Object.assign(this.makeAmountExemptErrors(), this.errors.exempt_amounts || {})
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>
