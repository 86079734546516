import 'babel-polyfill'
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import {PMDateFilters, PMBus, PMAPI, PMPermissions, PMConstants, IDHasher} from '@/plugins'
import PortalVue from 'portal-vue'
import VuePluralize from 'vue-pluralize'
import VueTour from 'vue-tour'

import MoreInfo from '@/components/MoreInfo'
import CollapseToggleButton from '@/components/CollapseToggleButton'
import AlertBox from '@/components/AlertBox'
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import PageSearch from '@/components/PageSearch'

import 'jquery'
import 'popper.js'
import 'bootstrap/dist/js/bootstrap.js'
import 'muicss/dist/js/mui.min.js'
import 'toastr/toastr.scss'
import 'select2/dist/css/select2.min.css'
import 'vue-tour/dist/vue-tour.css'

import '@/polyfills/endsWith'

import '@/assets/fonts/roboto/roboto.css'
import '@/assets/font-awesome/css/all.css'

import '@/assets/scss/style.scss'
import '@/assets/scss/local.scss'

Vue.config.productionTip = false

Vue.use(PMDateFilters)
Vue.use(PMAPI)
Vue.use(PMBus)
Vue.use(PMPermissions)
Vue.use(PMConstants)
Vue.use(PortalVue)
Vue.use(VuePluralize)
Vue.use(IDHasher)
Vue.use(VueTour)

import numeral from "numeral"
Vue.filter("formatCurrency", function (value) {
    return numeral(value).format("0,0.00");
});

Vue.component('MoreInfo', MoreInfo)
Vue.component('CollapseToggleButton', CollapseToggleButton)
Vue.component('AlertBox', AlertBox)
Vue.component('FormInput', FormInput)
Vue.component('FormErrors', FormErrors)
Vue.component('PageSearch', PageSearch)

import * as Sentry from "@sentry/browser"
import { Vue as VueIntegration } from "@sentry/integrations"
import { Integrations } from "@sentry/tracing"

if (process.env.VUE_APP_RAVEN_JS_DSN) {
    Sentry.init({
        dsn: process.env.VUE_APP_RAVEN_JS_DSN,
        environment: process.env.VUE_APP_DELTA_IS_PRODUCTION ? 'production' : process.env.VUE_APP_DELTA_ENV_CODE,
        ignoreErrors: [
            "Rendering cancelled",
        ],
        integrations: [
            new Integrations.BrowserTracing(),
            new VueIntegration({
                Vue,
                attachProps: true,
                logError: true,
                tracing: true,
                tracingOptions: {
                    trackComponents: true,
                },
            }),
        ],
    })
}

// Google Analytics
import VueGtag from 'vue-gtag'
if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID) {
    try {
        Vue.use(VueGtag, {
          config: {
              id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
              appName: 'GetMyPayStub',
          }
        }, router)
    }
    catch (ex) {
        Sentry.captureException(ex)
    }
}

// Mixpanel
import mixpanel from 'mixpanel-browser'
if (process.env.VUE_APP_MIXPANEL_ENABLED && process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN) {
    try {
        mixpanel.init(process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN, {debug: false})
    }
    catch (ex) {
        Sentry.captureException(ex)
    }
}

new Vue({
    render: h => h(App),
    router: router,
    store: store,
}).$mount('#app')
