<template>
    <page>
        <fast-table
            v-show="dataLoaded"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="currentLocation = $event"
        >

            <template slot="table-actions">
                <a class="btn btn-primary btn-collapse mr-2" :href="exportFileURL">
                    <i class="far fa-fw fa-file-excel"></i> <span class="btn-text">Export</span>
                </a>

                <button type="button" class="btn btn-primary btn-collapse mr-2" @click.prevent="$refs.importFileModal.open()">
                    <i class="far fa-fw fa-file-excel"></i> <span class="btn-text">Import</span>
                </button>
            </template>

        </fast-table>

        <import-file-modal :client="client" :import-file-type="'stores'" @updated="getData()" ref="importFileModal" />
    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import ImportFileModal from './components/ImportFileModal'

export default {
    components: {Page, FastTable, ImportFileModal, },
    props: ['client', ],
    computed: {
        tableData() {
            const tableData = []

            this.locations.forEach((o, idx) => {
                const row = {
                    id: idx,
                    isActive: this.currentLocation && o.id == this.currentLocation.id,
                    object: o,
                    cells: [
                        o.Store,
                        o.StoreAltID,
                        o.StorePropID,
                        o.CompanyCode,
                        o.OrgLevel1,
                        o.OrgLevel2,
                        o.OrgLevel3,
                        o.OrgLevel4,
                        o.OrgLevel5,
                        o.WorkLocation,
                        o.KnownAs,
                        o.City,
                        o.State,
                        o.PayGroup,
                        o.WOTCAPIEnabled,
                        o.EVerifyEnabled,
                        o.SyncEmployeesToPOS,
                        o.POSIntegrationRef,
                        o.POSCredentialRef,
                        o.BusinessTypePrimary,
                        o.BusinessTypeSecondary,
                        o.BusinessTypeBrand,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
        exportFileURL() {
            return `${this.$api.API_BASE}/admin/clients/${this.client.id}/config/stores/export`
        }
    },
    data() {
        return {
            locations: [],
            dataLoaded: false,
            currentLocation: null,

            headers: [
                {label: 'Store', classes: '', isSearchable: true},
                {label: 'Store Alt ID', classes: '', isSearchable: true},
                {label: 'Store Prop ID', classes: '', isSearchable: true},
                {label: 'Company', classes: '', isSearchable: true, isFilterable: true},
                {label: 'OrgLevel1', classes: '', isSearchable: true},
                {label: 'OrgLevel2', classes: '', isSearchable: true},
                {label: 'OrgLevel3', classes: '', isSearchable: true},
                {label: 'OrgLevel4', classes: '', isSearchable: true},
                {label: 'OrgLevel5', classes: '', isSearchable: true},
                {label: 'WorkLocation', classes: '', isSearchable: true},
                {label: 'KnownAs', classes: '', isSearchable: true},
                {label: 'City', classes: '', isSearchable: true},
                {label: 'State', classes: '', isSearchable: true},
                {label: 'PayGroup', classes: '', isSearchable: true},
                {label: 'WOTC API', classes: '', type: "checkmark", isSearchable: true},
                {label: 'E-Verify', classes: '', type: "checkmark", isSearchable: true},
                {label: 'Sync EEs to POS', classes: '', type: "checkmark", isSearchable: true},
                {label: 'POS Integration Ref', classes: '', isSearchable: true},
                {label: 'POS Credential Ref', classes: '', isSearchable: true},
                {label: 'Bus. Type Primary', classes: '', isSearchable: true},
                {label: 'Bus. Type Secondary', classes: '', isSearchable: true},
                {label: 'Bus. Type Brand', classes: '', isSearchable: true},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', `Client ${this.client.client_code} - ${this.client.name} / Locations`)
        this.getData()
    },
    methods: {
        getData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/clients/${this.client.id}/config/stores`).then((resp) => {
                this.locations = resp['data']
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
    },
}

</script>
