<template>
    <sidebar @close="$emit('close')">
        <template slot="title">
            {{ clientEntitlement.yaml_form.name }}
        </template>
        <slot>
            <info-widget :collapsible="false">
                <template slot="title">Form Information</template>

                <slot>
                    <div class="card-body py-1 mt-1">
                        <div class="mui-textfield">
                            <span class="textfield">{{ clientEntitlement.yaml_form.name }}</span>
                            <label>Form</label>
                        </div>

                        <div class="mui-textfield">
                            <span class="textfield">{{ clientEntitlement.yaml_form.slug }}</span>
                            <label>Slug</label>
                        </div>

                        <div class="mui-textfield">
                            <span class="textfield">{{ clientEntitlement.yaml_form.is_live ? "Yes" : "No"}}</span>
                            <label>Is Live</label>
                        </div>

                        <div class="mui-textfield">
                            <span class="textfield">{{ clientEntitlement.yaml_form.version_number }}</span>
                            <label>Version</label>
                        </div>

                        <button-row stacked class="pt-0">
                            <button type="button" class="btn btn-outline-primary" @click.prevent="inactivate()" v-if="clientEntitlement.is_active">Deactivate Entitlement</button>
                            <button type="button" class="btn btn-outline-primary" @click.prevent="reactivate()" v-if="!clientEntitlement.is_active">Reactivate Entitlement</button>
                        </button-row>
                    </div>
                </slot>
            </info-widget>
        </slot>

        <form-entitlement-modal ref="formEntitlementModal" :client="client" :clientEntitlement="clientEntitlement" @updated="onUpdate" />

    </sidebar>
</template>

<script>
import FormEntitlementModal from './FormEntitlementModal.vue'
import Sidebar from '@/components/Sidebar'
import InfoWidget from '@/components/InfoWidget'
import ButtonRow from '@/components/ButtonRow'
import api from '@/api'
import bus from '@/bus'

export default {
    components: {Sidebar, InfoWidget, FormEntitlementModal, ButtonRow, },
    props: ['client', 'clientEntitlement', ],
    data() {
        return {
            companyEntitlements: this.clientEntitlement.company_entitlements,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        onUpdate(clientEntitlement) {
            this.companyEntitlements = clientEntitlement.company_entitlements
            this.$emit('updated', clientEntitlement)
        },
        reactivate() {
            if (!confirm('Are you sure?')) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.put(`/admin/clients/${this.client.id}/form-entitlements/${this.clientEntitlement.id}/status`, {status: 'active'}).then(resp => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.$emit('close')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        inactivate() {
            if (!confirm('Are you sure?')) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.put(`/admin/clients/${this.client.id}/form-entitlements/${this.clientEntitlement.id}/status`, {status: 'inactive'}).then(resp => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.$emit('close')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>

