<template>
    <div>
        <div v-if="!mode">
            <div class="mb-2">
                <a href @click.prevent="close()"><i class="far fa-fw fa-chevron-left mr-hf"></i>Employee Info</a>
            </div>

            <h2 class="text-thin text-muted mb-2">Approval Checklist</h2>

            <p>
                <strong>Complete all items below</strong> to approve this employee. Items can be completed in any order.
            </p>

            <ul class="list-unstyled border-top">
                <li class="px-1 py-2 border-bottom">
                    <a class="todo-item" :class="{'todo-item-completed': onboardingApplication.hr_info_approved_on}" href @click.prevent="mode = 'hr'"><span class="todo-item-content">HR and Pay Information</span></a>
                </li>
                <li class="px-1 py-2 border-bottom">
                    <a class="todo-item" :class="{'todo-item-completed': allFormsApproved}" href @click.prevent="mode = 'forms'"><span class="todo-item-content">Required Documents</span></a>
                </li>
                <li class="px-1 py-2 border-bottom">
                    <a class="todo-item" :class="{'todo-item-completed': onboardingApplication.direct_deposit_approved_on}" href @click.prevent="mode = 'direct-deposit'"><span class="todo-item-content">Direct Deposit Information</span></a>
                </li>
                <li class="px-1 py-2 border-bottom">
                    <a class="todo-item" :class="{'todo-item-completed': I9InfoApproved}" href @click.prevent="mode = 'i9'"><span class="todo-item-content">Employment Eligibility Verification (I-9)</span></a>
                </li>

            </ul>

            <div class="mt-4">
                <form-errors :errors="formErrors"/>
                <button @click.prevent="approve()" class="btn btn-block btn-primary">Approve</button>
            </div>
        </div>

        <div v-if="mode == 'hr'">
            <div class="mb-2">
                <a href @click.prevent="mode = ''"><i class="far fa-fw fa-chevron-left mr-hf"></i>Approval Checklist</a>
            </div>

            <h2 class="text-thin text-muted mb-2">HR and Pay Information</h2>
            <employee-details :client="client" :employee="onboardingApplication" @updated="$emit('updated', $event)" :canEdit="!onboardingApplication.hr_info_approved_on" @edit="$emit('edit')" @edit-email="$emit('edit-email')"/>

            <div class="mt-4">
                <button @click.prevent="onHRFormSubmitted()" class="btn btn-block btn-primary" v-if="!onboardingApplication.hr_info_approved_on">Accept HR and Pay Info</button>
            </div>
        </div>

        <div v-if="mode == 'forms'">
            <div class="mb-2">
                <a href @click.prevent="mode = ''"><i class="far fa-fw fa-chevron-left mr-hf"></i>Approval Checklist</a>
            </div>

            <h2 class="text-thin text-muted mb-2">Required Documents</h2>
            <p>Complete the {{ totalDocumentCount }} {{ totalDocumentCount == 1 ? 'document' : 'documents' }} below.</p>

            <!-- PDF Forms -->
            <div class="card doc-item mb-2" v-for="form in forms" :key="form.id" v-if="form.form_designation != 'i9'">
                <div class="card-body">
                    <div class="todo-item" :class="{'todo-item-completed': form.accepted_on}">
                        <div class="todo-item-content">
                            <h6 class="doc-title mb-2">{{ form.yaml|yamlTitle }}</h6>

                            <div class="doc-action-primary mb-1">
                                <a v-if="form.accepted_on" :href="`${API_BASE}/clients/${client.id}/pdf-files/${form.id}`" class="btn btn-outline-primary" @click.prevent="setCurrentPDF(form)"><i class="far fa-fw fa-external-link mr-1"></i>View Document</a>
                                <a v-if="!form.accepted_on" :href="`${API_BASE}/clients/${client.id}/pdf-files/${form.id}`" class="btn btn-outline-primary" @click.prevent="setCurrentPDF(form)"><i class="far fa-fw fa-external-link mr-1"></i>Sign Document</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <!-- Legacy Fillable Forms -->
            <div class="card doc-item mb-2" v-for="formDoc in onboardingApplication.hr_form_documents" :key="formDoc.id">
                <div class="card-body">
                    <div class="todo-item" :class="{'todo-item-completed': formDoc.accepted_on}">
                        <div class="todo-item-content">
                            <h6 class="doc-title mb-2">{{ formDoc.hr_form_document.name }}</h6>
                            <div class="doc-action-primary mb-1">
                                <a v-if="formDoc.accepted_on" href class="btn btn-outline-primary" @click.prevent="setCurrentPDF(formDoc)"><i class="far fa-fw fa-external-link mr-1"></i>View Document</a>
                                <a v-if="!formDoc.accepted_on" href class="btn btn-outline-primary" @click.prevent="setCurrentPDF(formDoc)"><i class="far fa-fw fa-external-link mr-1"></i>Sign Document</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- HR Docs -->
            <div class="card doc-item mb-2" v-for="hr_doc in onboardingApplication.hr_documents" :key="hr_doc.id">
                <div class="card-body">
                    <div class="todo-item" :class="{'todo-item-completed': hr_doc.accepted_on}">
                        <div class="todo-item-content">
                            <h6 class="doc-title mb-2">{{ hr_doc.hr_document.name }}</h6>
                            <div class="doc-action-primary mb-1">
                                <a v-if="hr_doc.accepted_on" href class="btn btn-outline-primary" @click.prevent="setCurrentPDF(hr_doc)"><i class="far fa-fw fa-external-link mr-1"></i>View Document</a>
                                <a v-else href class="btn btn-outline-primary" @click.prevent="setCurrentPDF(hr_doc)"><i class="far fa-fw fa-external-link mr-1"></i>Sign Document</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Custom Forms -->
            <div class="card doc-item mb-2" v-for="cf in onboardingApplication.onboarding_application_hr_custom_forms" :key="cf.id">
                <div class="card-body">
                    <div class="todo-item" :class="{'todo-item-completed': cf.accepted_on}">
                        <div class="todo-item-content">
                            <h6 class="doc-title mb-2">{{ cf.hr_custom_form.name }}</h6>
                            <div class="doc-action-primary mb-1">
                                <a v-if="cf.accepted_on" href class="btn btn-outline-primary" @click.prevent="setCurrentPDF(cf)"><i class="far fa-fw fa-external-link mr-1"></i>View Document</a>
                                <a v-else href class="btn btn-outline-primary" @click.prevent="setCurrentPDF(cf)"><i class="far fa-fw fa-external-link mr-1"></i>Sign Document</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Uniforms -->
            <div class="card doc-item mb-2" v-for="uniform in onboardingApplication.onboarding_application_uniforms" :key="uniform.id">
                <div class="card-body">
                    <div class="todo-item todo-item-completed">
                        <div class="todo-item-content">
                            <h6 class="doc-title mb-2">{{ uniform.uniform.name }}</h6>

                            <div class="mb-1" v-for="(item, idx) in uniform.response_data" :key="idx">
                                <span class="text-small"><strong>{{ item.name }}</strong></span><br/>
                                <span class="text-small text-muted">Size: {{ item.category }} {{ item.size }}</span><br/>
                                <span v-if="item.color" class="text-small text-muted">Color: {{ item.color }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <pdf-modal :url="currentPDF.url" :title="currentPDF.title" v-if="currentPDF" @close="setCurrentPDF(null)" ref="pdfModal">
                <template slot="footer" v-if="(currentForm && !currentForm.accepted_on) || (currentHRDocument && !currentHRDocument.accepted_on) || (currentHRCustomForm && !currentHRCustomForm.accepted_on) || (currentHRFormDocument && !currentHRFormDocument.accepted_on)">
                    <button-row stacked>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="$refs.pdfModal.close()">
                            Close
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="signForm()">
                            Sign Document
                        </button>
                    </button-row>
                </template>
            </pdf-modal>
        </div>

        <!-- Direct Deposit Container -->

        <div v-if="mode == 'direct-deposit'">
            <div class="mb-2">
                <a href @click.prevent="mode = ''"><i class="far fa-fw fa-chevron-left mr-hf"></i>Approval Checklist</a>
            </div>

            <h2 class="text-thin text-muted mb-2">Direct Deposit Information</h2>
            <direct-deposit-form
                :direct-deposit-accounts="onboardingApplication.direct_deposit_accounts"
                :url="`/clients/${client.id}/onboarding-applications/${this.onboardingApplication.id}/direct-deposit-accounts`"
                @updated="onAppUpdated($event)"
                :readonly="onboardingApplication.direct_deposit_approved_on"
                :approval-view="true"
            />

            <div class="mt-4">
                <button @click.prevent="acceptDirectDepositInfo()" type="button" class="btn btn-primary btn-block" v-if="!onboardingApplication.direct_deposit_approved_on">Accept Direct Deposit Info</button>
            </div>
        </div>

        <div v-if="mode == 'i9'">
            <div class="mb-2">
                <a href @click.prevent="mode = ''"><i class="far fa-fw fa-chevron-left mr-hf"></i>Approval Checklist</a>
            </div>

            <h2 class="text-thin text-muted mb-2">Employment Eligibility Verification (I-9)</h2>

            <!-- I-9 Skipped -->
            <div class="card doc-item mb-2" v-if="onboardingApplication.i9_skipped">
                <div class="card-body">
                    <div class="todo-item" :class="{'todo-item-completed': true}">
                        <div class="todo-item-content">
                            <h6 class="doc-title mb-2">I-9 Employment Eligibility Verification</h6>

                            <div class="doc-action-primary mb-1">
                                Skipped due to employee being active in payroll
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- I9 -->
            <div class="card doc-item mb-2" v-if="i9Form && !onboardingApplication.i9_skipped">
                <div class="card-body">
                    <div class="todo-item" :class="{'todo-item-completed': i9Form.accepted_on}">
                        <div class="todo-item-content">
                            <h6 class="doc-title mb-2">{{ i9Form.yaml|yamlTitle }}</h6>

                            <div class="doc-action-primary mb-1">
                                <a href class="btn btn-outline-primary" v-if="!i9Form.accepted_on" @click.prevent="openI9"><i class="far fa-fw fa-pencil mr-1"></i>Complete Document</a>
                                <a v-if="i9Form.accepted_on" :href="`${API_BASE}/clients/${client.id}/pdf-files/${i9Form.id}`" class="btn btn-outline-primary" @click.prevent="setCurrentPDF(i9Form)"><i class="far fa-fw fa-external-link mr-1"></i>View Document</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- I9 supporting docs -->
            <div class="card doc-item mb-2" v-if="i9Form && onboardingApplication.company.feature_i9_document_photos && !onboardingApplication.i9_skipped">
                <div class="card-body">
                    <div class="todo-item" :class="{'todo-item-completed': i9PhotosCompleted}">
                        <div class="todo-item-content">
                            <h6 class="doc-title mb-2">I-9 Supporting Documents {{ onboardingApplication.company.i9_document_photos_er_required ? '' : '(Optional)' }}</h6>

                            <div>
                                <document-photos-list
                                    :client="client"
                                    :company="onboardingApplication.company"
                                    :onboarding-application-id="onboardingApplication.id"
                                    :form="i9Form"
                                    :most-recent-i9="true"
                                    @updated="onAppUpdated"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- E-Verify -->
            <div class="card doc-item mb-2" v-if="onboardingApplication.everify_required && !onboardingApplication.i9_skipped">
                <div class="card-body">
                    <div class="todo-item" :class="{'todo-item-completed': EVerifyCaseCreated}">
                        <div class="todo-item-content">
                            <h6 class="doc-title mb-2">E-Verify</h6>

                            <div class="doc-action-primary mb-1">
                                <div v-if="i9Form && i9Form.accepted_on">
                                    <div v-if="EVerifyAccessEnabled">
                                        <a href class="btn btn-outline-primary" v-if="!EVerifyCaseCreated" @click.prevent="openEVerifyCaseCreation"><i class="far fa-fw fa-pencil mr-1"></i>Create E-Verify Case</a>
                                    </div>
                                    <div v-if="!EVerifyAccessEnabled">
                                        You must complete E-Verify Training before creating cases.

                                        <br><br>

                                        <router-link class="btn btn-outline-primary" :to="{name: 'training-e-verify', params: {hashedCompanyId: $route.params.hashedCompanyId}}">Go To E-Verify Training</router-link>
                                    </div>

                                    <div class="info-widget p-2" v-if="latestEVerifyCase && latestEVerifyCase.case_creation_errors.errors">
                                        <div class="item-details-banner--danger p-2">
                                            <span class="text-danger-dark text-bold">Errors creating case:</span>

                                            <div v-for="err in latestEVerifyCase.case_creation_errors.errors" class="my-2">
                                                <span class="text-danger-dark text-bold">
                                                    <span v-if="err.attribute_name">{{ err.attribute_name }}: </span>
                                                    {{ err.message }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <span v-if="EVerifyCaseCreated">
                                        E-Verify Case # {{ latestEVerifyCase.case_number }}
                                    </span>
                                </div>
                                <div v-else>
                                    Complete I-9 before creating E-Verify case
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <pdf-modal :url="currentPDF.url" :title="currentPDF.title" v-if="currentPDF" @close="setCurrentPDF(null)" ref="pdfModal">
                <template slot="footer" v-if="currentForm && !currentForm.accepted_on">
                    <button-row stacked>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="$refs.pdfModal.close()">
                            Close
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="signForm()">
                            Sign Document
                        </button>
                    </button-row>
                </template>
            </pdf-modal>
        </div>

        <i9-modal :client="client" :employee="onboardingApplication" @updated="onAppUpdated($event)" v-if="i9Form && i9ModalOpen" @close="i9ModalOpen = false"/>
        <e-verify-modal :client="client" :company="onboardingApplication.company" :employee="onboardingApplication" @updated="onAppUpdated($event)" v-if="i9Form && EVerifyModalOpen" @close="EVerifyModalOpen = false"/>

        <approval-modal :client="client" :employee="onboardingApplication" @updated="onAppUpdated($event)" ref="approvalModal"/>
    </div>
</template>

<script>
import JobForm from './JobForm'
import DirectDepositForm from '@/components/DirectDepositForm'
import EmployeeDetails from './EmployeeDetails'
import ButtonRow from '@/components/ButtonRow'
import DocumentPhotosList from './DocumentPhotosList'
import PdfModal from '@/components/PdfModal'
import I9Modal from './I9Modal'
import EVerifyModal from './EVerifyModal'
import ApprovalModal from './ApprovalModal'
import moment from 'moment'
import YAML from 'js-yaml'

export default {
    components: {JobForm, DirectDepositForm, EmployeeDetails, ButtonRow, DocumentPhotosList, PdfModal, I9Modal, EVerifyModal, ApprovalModal},
    props: {onboardingApplication: {required: true}, client: {required: true}},
    computed: {
        acaCategoryOptions() {
            return [
                {text: 'Full Time', value: 'Full Time'},
                {text: 'Variable', value: 'Variable'},
            ]
        },
        API_BASE() {
            return this.$api.API_BASE
        },
        allFormsApproved() {
            const unapprovedForms = this.forms.filter((f) => {
                return !f.accepted_on
            }).length
            const unapprovedDocs = this.onboardingApplication.hr_documents.filter((f) => {
                return !f.accepted_on
            }).length
            const unapprovedCustomForms = this.onboardingApplication.onboarding_application_hr_custom_forms.filter((f) => {
                return !f.accepted_on
            }).length
            const unapprovedFormDocs = this.onboardingApplication.hr_form_documents.filter((f) => {
                return !f.accepted_on
            }).length

            let result = ((unapprovedForms + unapprovedDocs + unapprovedCustomForms + unapprovedFormDocs) == 0)

            return result
        },
        EVerifyAccessEnabled() {
            const userSP = this.$permissions.getSecurityProfile(this.client.id)
            if (userSP) {
                return userSP.everify_access == 'enabled'
            }

            if (this.$permissions.isSuperuser()) {
                return true
            }

            return false
        },

        EVerifyCaseCreated() {
            // only look at cases AFTER the pre-draft stage
            const cases = this.onboardingApplication.everify_cases.filter((c) => {
                return c.case_status != 'PRE_DRAFT'
            })

            return (cases.length > 0)
        },

        latestEVerifyCase() {
            const cases = this.onboardingApplication.everify_cases
            if (! cases) {
                return {}
            }

            cases.sort((a, b) => {
                return (a.last_update_on > b.last_update_on) ? 1 : -1
            })
            return cases[cases.length - 1]
        },

        I9InfoApproved () {
            if (this.onboardingApplication.i9_skipped) {
                return true
            }

            // this.i9Form
            let result = this.i9Form && this.i9Form.accepted_on

            if (result && this.onboardingApplication.company.feature_i9_document_photos && this.onboardingApplication.company.i9_document_photos_er_required) {
                result = result && this.i9PhotosCompleted
            }

            if (this.onboardingApplication.everify_required) {
                result = result && this.EVerifyCaseCreated
            }

            return result
        },
        readyForApproval() {
            return (
                this.onboardingApplication.hr_info_approved_on &&
                this.allFormsApproved &&
                this.onboardingApplication.direct_deposit_approved_on &&
                this.I9InfoApproved
            )
        },
        forms() {
            const forms = this.onboardingApplication.forms.filter(f => f.form_designation != 'i9')
            forms.sort((a, b) => {
                if (a.priority_order == b.priority_order) {
                    return a.id > b.id ? 1 : -1
                }
                return (a.priority_order > b.priority_order) ? 1 : -1
            })
            return forms
        },
        i9Form() {
            return this.onboardingApplication.forms.find(f => f.form_designation == 'i9')
        },
        i9PhotosCompleted() {
            if (!this.i9Form.accepted_on) {return false}

            const docSlugs = new Set([
                this.i9Form.employer_form_data.doc_a_slug,
                this.i9Form.employer_form_data.doc_a_secondary_slug,
                this.i9Form.employer_form_data.doc_a_tertiary_slug,
                this.i9Form.employer_form_data.doc_b_slug,
                this.i9Form.employer_form_data.doc_c_slug,
            ].filter(x => x))  // set of non-empty slugs for the documents we need

            this.i9Form.photos.filter(p => p.is_manager_uploaded).forEach(p => {
                if (docSlugs.has(p.document_slug)) {
                    docSlugs.delete(p.document_slug)
                }
            })

            return Array.from(docSlugs).length == 0
        },
        totalDocumentCount() {
            // NB: use this.forms rather than this.onboardingApplication.forms in order to
            // exclude I-9 from this list, since it's handled separately
            let c = this.forms.length + this.onboardingApplication.hr_documents.length + this.onboardingApplication.onboarding_application_uniforms.length + this.onboardingApplication.onboarding_application_hr_custom_forms.length + this.onboardingApplication.hr_form_documents.length

            return c
        },
    },
    data() {
        return {
            mode: '',
            formErrors: [],
            currentPDF: null,
            currentForm: null,
            currentHRDocument: null,
            currentHRCustomForm: null,
            currentHRFormDocument: null,
            i9ModalOpen: false,
            EVerifyModalOpen: false,
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        onAppUpdated() {
            this.$store.dispatch('START_LOADING')
            return this.$api.get(`/clients/${this.client.id}/onboarding-applications/${this.onboardingApplication.id}`).then((resp) => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        setCurrentPDF(form) {
            if (!form) {
                this.currentPDF = null
                this.currentForm = null
                this.currentHRDocument = null
                this.currentHRCustomForm = null
                this.currentHRFormDocument = null
                return
            }

            if (form.yaml) {
                this.currentForm = form
                this.currentHRDocument = null
                this.currentHRCustomForm = null
                this.currentHRFormDocument = null

                this.currentPDF = {
                    title: this.parseYAML(form.yaml).title,
                    url: {
                        url: `${this.API_BASE}/clients/${this.client.id}/onboarding-applications/${this.onboardingApplication.id}/forms/${form.id}/pdf`,
                        withCredentials: true,
                    },
                    requires_admin_signature: true,
                }
            }

            if (form.hr_document) {
                this.currentForm = null
                this.currentHRDocument = form
                this.currentHRCustomForm = null
                this.currentHRFormDocument = null

                this.currentPDF = {
                    title: form.hr_document.name,
                    url: {
                        url: `${this.API_BASE}/clients/${this.client.id}/onboarding-applications/${this.onboardingApplication.id}/hr-documents/${form.id}/pdf`,
                        withCredentials: true,
                    },
                    requires_admin_signature: true,
                }
            }

            if (form.hr_custom_form) {
                this.currentForm = null
                this.currentHRDocument = null
                this.currentHRCustomForm = form
                this.currentHRFormDocument = null

                this.currentPDF = {
                    title: form.hr_custom_form.name,
                    url: {
                        url: `${this.API_BASE}/clients/${this.client.id}/onboarding-applications/${this.onboardingApplication.id}/hr-custom-forms/${form.id}/pdf`,
                        withCredentials: true,
                    },
                    requires_admin_signature: true,
                }
            }

            if (form.hr_form_document) {
                this.currentForm = null
                this.currentHRDocument = null
                this.currentHRCustomForm = null
                this.currentHRFormDocument = form

                this.currentPDF = {
                    title: form.hr_form_document.name,
                    url: {
                        url: `${this.API_BASE}/clients/${this.client.id}/onboarding-applications/${this.onboardingApplication.id}/hr-form-documents/${form.id}/pdf`,
                        withCredentials: true,
                    },
                    requires_admin_signature: true,
                }
            }

        },
        signForm() {
            const signatureData = {
                'date': moment().format('MM/DD/YYYY'),
                'datetime': moment().format('MM/DD/YYYY HH:mm:ss Z'),
            }

            let url;
            if (this.currentForm) {
                url = `/clients/${this.client.id}/onboarding-applications/${this.onboardingApplication.id}/forms/${this.currentForm.id}/sign`
            }
            else if (this.currentHRDocument) {
                url = `/clients/${this.client.id}/onboarding-applications/${this.onboardingApplication.id}/hr-documents/${this.currentHRDocument.id}/sign`
            }
            else if (this.currentHRCustomForm) {
                url = `/clients/${this.client.id}/onboarding-applications/${this.onboardingApplication.id}/hr-custom-forms/${this.currentHRCustomForm.id}/sign`
            }
            else if (this.currentHRFormDocument) {
                url = `/clients/${this.client.id}/onboarding-applications/${this.onboardingApplication.id}/hr-form-documents/${this.currentHRFormDocument.id}/sign`
            }

            this.$store.dispatch('START_LOADING')
            this.$api.post(url, signatureData).then(() => {
                this.onAppUpdated().then(() => {
                    this.setCurrentPDF(null)

                    if (this.allFormsApproved) {
                        this.mode = ''
                    }
                })
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        acceptDirectDepositInfo() {
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/onboarding-applications/${this.onboardingApplication.id}/direct-deposit-info`, {}).then((resp) => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.mode = ''
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        onHRFormSubmitted() {
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/onboarding-applications/${this.onboardingApplication.id}/hr-info`, {}).then((resp) => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.mode = ''
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        approve() {
            this.formErrors = []
            if (!this.readyForApproval) {
                this.formErrors = ['Complete all checklist items to approve.']
                return
            }
            this.$refs.approvalModal.open()
        },
        parseYAML(yaml) {
            return YAML.safeLoad(yaml)
        },
        openEVerifyCaseCreation() {
            this.setCurrentPDF(null)
            this.EVerifyModalOpen = true
        },
        openI9() {
            this.setCurrentPDF(null)
            this.i9ModalOpen = true
        },
    },
    filters: {
        yamlTitle(yaml) {
            return YAML.safeLoad(yaml).title
        },
    },
}
</script>
