<template>
<section class="item-details-section">
    <div>
        <span class="text-semibold">Date range: </span>
        <span>{{ version.starts_on|date("MM/DD/YYYY") }}</span>
        <span v-if="version.ends_on"> - {{ version.ends_on|date("MM/DD/YYYY") }}</span>
        <span v-else>+</span>
    </div>

    <div v-if="version.jurisdiction_type">
        <span class="text-semibold">Jurisdiction Type: </span>
        <span>{{ version.jurisdiction_type_pretty }}</span>
    </div>

    <div v-if="version.jurisdiction_selection">
        <span class="text-semibold">Jurisdiction Selection: </span>
        <span>{{ version.jurisdiction_selection_pretty }}</span>
    </div>

    <div v-if="version.parent_jurisdiction">
        <span class="text-semibold">{{ parentJurisdictionLabel }}: </span>
        <span>{{ version.parent_jurisdiction.canonical_name }}</span>
    </div>

    <div v-if="version.jurisdiction_selection == 'specific'">
        <span class="text-semibold">Jurisdictions: </span>
        <ul>
            <li v-for="j in version.jurisdictions">{{ j.canonical_name }}</li>
        </ul>
    </div>

    <div v-if="version.tax_type">
        <span class="text-semibold">Tax Type: </span>
        <span>{{ version.tax_type_pretty }}</span>
    </div>

    <div v-if="version.tax_selection">
        <span class="text-semibold">Tax Selection: </span>
        <span>{{ version.tax_selection_pretty }}</span>
    </div>

    <div v-if="version.tax_selection == 'specific'">
        <span class="text-semibold">Taxes: </span>
        <ul>
            <li v-for="t in version.remote_taxes">{{ t.name }}</li>
        </ul>
    </div>

    <div v-if="version.tax_tag_selection">
        <span class="text-semibold">Tax Tag Selection: </span>
        <span>{{ version.tax_tag_selection_pretty }}</span>
    </div>

    <div v-if="version.tax_tag_selection == 'specific'">
        <span class="text-semibold">Tax Tags: </span>
        <ul>
            <li v-for="t in version.tax_tags">{{ t.name }}</li>
        </ul>
    </div>

    <div v-if="version.calculation_algorithm">
        <span class="text-semibold">Calculation algorithm: </span>
        <span>{{ version.calculation_algorithm_pretty }}</span>
    </div>

    <div v-if="version.calculation_algorithm == 'credit'">
        <span class="text-semibold">Credit Proportion: </span>
        <span>{{ version.credit_proportion }}%</span>
    </div>


</section>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'
export default {
    components: {InfoWidget, },
    props: ['version', ],
    data() {
        return {
            isExpanded: false,
        }
    },
    computed: {
        parentJurisdictionLabel() {
            const LABELS = {
                'city': 'State',
                'county': 'State',
                'school-district': 'City',
            }

            return LABELS[this.version.jurisdiction_type] || ''
        },
    }
}
</script>
