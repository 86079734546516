<template>
<div>
    <div class="nav-tabs-container pt-1">
        <div class="nav-tabs-scroll-helper">
            <ul class="nav nav-tabs">

                <li class="nav-item">
                    <a class="nav-link" :class="{'active': mode == 'all'}" href role="tab" :aria-selected="mode == 'all'" @click.prevent="mode = 'all'; $bus.$emit('resize')">
                        All Items ({{ allItemsCount }})
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" :class="{'active': mode == 'selected'}" href role="tab" :aria-selected="mode == 'selected'" @click.prevent="mode = 'selected'; $bus.$emit('resize')">
                        Selected ({{ selectedItemsCount }})
                    </a>
                </li>

            </ul>
        </div>
    </div>

    <div class="flex-body flex-container tab-content" style="min-height: 200px">
        <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'all'}" role="tabpanel">
            <selection-table
                :value="value"
                @input="$emit('input', $event)"
                :sections="sections"
                :headers="headers"
                :items="allItems"
                :readonly="readonly"
            />
        </div>
        <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'selected'}" role="tabpanel">
            <selection-table
                :value="value"
                @input="$emit('input', $event)"
                :sections="sections"
                :headers="headers"
                :items="selectedItems"
                :readonly="readonly"
            />
        </div>
    </div>
</div>
</template>

<script>
import Cell from '@/components/FastTable/Cell'
import SelectionTable from './Table'

export default {
    props: ['sections', 'headers', 'items', 'value', 'readonly', ],
    components: {SelectionTable, },
    computed: {
        allItems() {
            const value = this.value ? this.value : []
            const result = []
            this.items.forEach(sectionData => {
                const sd = []
                sectionData.forEach(item => {
                    sd.push({
                        id: item.id,
                        readonly: item.readonly,
                        isSelected: value.includes(item.id) || (item.readonly && item.isSelected),
                        cells: item.cells,
                    })
                })
                result.push(sd)
            })

            return result
        },
        selectedItems() {
            const result = []
            this.allItems.forEach(sectionData => {
                result.push(sectionData.filter(item => item.isSelected))
            })

            return result
        },
        allItemsCount() {
            let result = 0
            this.allItems.forEach(sectionData => {
                result += sectionData.length
            })
            return result
        },
        selectedItemsCount() {
            let result = 0
            this.selectedItems.forEach(sectionData => {
                result += sectionData.length
            })
            return result
        },
    },
    data() {
        return {
            mode: 'all',
        }
    },
}

</script>
