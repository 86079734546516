<template>
    <page>

        <fast-table
            v-show="dataLoaded"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="currentTaxExemptionsGroupId = $event.id"
        >
            <template slot="table-actions">
                <button type="button" class="btn btn-primary btn-collapse-md" @click.prevent="currentTaxExemptionsGroupId = null; addModalOpen = true">
                    <i class="far fa-fw fa-plus"></i>
                    <span class="btn-text">Add Tax Exempt Group</span>
                </button>
            </template>
        </fast-table>

        <sidebar
            :tax-exemptions-group-id="this.currentTaxExemptionsGroupId"
            :deduction-types="deductionTypes"
            :earning-types="earningTypes"
            v-if="currentTaxExemptionsGroupId"
            :key="currentTaxExemptionsGroupId"
            @updated="onUpdate($event)"
            @deleted="currentTaxExemptionsGroupId = null; getData()"
            @close="currentTaxExemptionsGroupId = null"
        />
        <add-modal
            v-if="addModalOpen"
            @updated="getData()"
            @close="addModalOpen = false"
        />

    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import AddModal from './components/TaxExemptionsGroupModal'
import Sidebar from './components/TaxExemptionsGroupSidebar'

export default {
    components: {FastTable, Page, Sidebar, AddModal, },
    computed: {
        tableData() {
            const tableData = []
            const subsets = [
                this.tax_exempt_groups,
            ]

            subsets.forEach(tax_exempt_groups => {
                const sectionData = []

                tax_exempt_groups.forEach((t, idx) => {
                    const row = {
                        id: idx,
                        isActive: this.currentTaxExemptionsGroupId && t.id == this.currentTaxExemptionsGroupId,
                        object: t,
                        cells: [
                            t.name,
                            //t.exempt_earning_types.length,
                            //t.exempt_deduction_types.length,
                            //t.taxes.length,
                            t.has_been_used ? 'Yes' : 'No',
                        ],
                    }

                    sectionData.push(row)
                })
                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            tax_exempt_groups: [],
            jurisdictions: [],
            deductionTypes: [],
            earningTypes: [],
            dataLoaded: false,
            currentTaxExemptionsGroupId: null,
            addModalOpen: false,

            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                //{label: 'Earnings', classes: '', isSearchable: false, isFilterable: false},
                //{label: 'Deductions', classes: '', isSearchable: false, isFilterable: false},
                //{label: 'Taxes', classes: '', isSearchable: false, isFilterable: false},
                {label: 'Has Been Used', classes: 'cw-5', isFilterable: true},
            ],
            sections: [
                {title: 'All', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tax Engine / Tax Exempt Groups')
        this.getData()
    },
    methods: {
        getData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/tax-engine/tax-exemptions-groups`).then(resp => {
                this.tax_exempt_groups = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })

            this.$api.get(`/admin/tax-engine/deduction-types`).then(resp => {
                this.deductionTypes = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })

            this.$api.get(`/admin/tax-engine/earning-types`).then(resp => {
                this.earningTypes = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })

        },
        onUpdate(taxExemptionGroup) {
            if (taxExemptionGroup) {
                const existing = this.tax_exempt_groups.find(x => {return x.id == taxExemptionGroup.id})
                if (existing) {
                    Object.assign(existing, taxExemptionGroup)
                }
            }
            else {
                this.getData()
            }
        },
    },
}

</script>
