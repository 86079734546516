<template>
    <sidebar @close="$emit('close')" v-if="securityProfile">
        <template slot="title" v-if="securityProfile">
            {{ securityProfile.full_name }}
        </template>

        <template slot="header" v-if="securityProfile">
            <div class="mb-1">
                <div class="py-qt">
                    <a :href="`mailto:${securityProfile.email}`">{{ securityProfile.email }}</a>
                </div>
                <div class="d-flex flex-wrap">
                    <div class="py-qt text-nowrap" v-if="securityProfile.phone_formatted">
                        <span>{{ securityProfile.phone_formatted }}</span>
                        <span class="ml-hf mr-1 text-500">·</span>
                    </div>
                    <div class="py-qt text-nowrap">
                        <span class="text-muted" v-if="securityProfile.is_active && !securityProfile.user">Pending Activation</span>
                        <span class="text-muted" v-if="securityProfile.is_active && securityProfile.user">Active</span>
                        <span class="text-muted" v-if="!securityProfile.is_active">Inactive</span>
                    </div>
                </div>
            </div>

        </template>

        <slot v-if="securityProfile">
            <div class="tab-content">

                <b>You do not have access to manage this Security User.</b>

                <div v-if="securityProfile.email == this.$store.state.user.email" class="pt-2">
                    You may edit your own profile settings in
                    <router-link  :to="{name: 'account'}">Account &amp; Security</router-link>
                </div>

            </div>
        </slot>

    </sidebar>
</template>

<script>
import Sidebar from '@/components/Sidebar'

export default {
    props: ['securityProfile', 'client', 'tab', 'section', 'itemId', ],
    components: {Sidebar, },
}
</script>
