<template>
    <div v-if="dataLoaded">
        <div class="container-fluid flex-body flex-container">
            <div class="p-4">
  
                <button class="btn btn-primary" @click="runTests = !runTests">{{ runTests ? 'Pause' : 'Resume' }}</button>
                <table class="table table-responsive rule-test-table">
                    <thead>
                        <tr>
                            <th class="text-center">
                                <span style="white-space: nowrap;">Res <i class="fas fa-arrow-right"></i></span> /
                                <span style="white-space: nowrap;">Work <i class="fas fa-arrow-down"></i></span>
                            </th>
                            <th :title="s.long_name" class="text-center" v-for="s in states" @click.prevent="testResidentState(s.name)">{{ s.name }}</th>
                        </tr>
                    </thead>
            
                    <tbody>
                        <tr v-for="s1 in states">
                            <th class="text-center" @click.prevent="testWorkState(s1.name)" :title="s1.long_name">{{ s1.name }}</th>
                            <td
                                v-for="s2 in states"
                                class="text-center"
                                @click.prevent="onClick(s1.name, s2.name)"
                                :title="`Work: ${s1.long_name}, Resident: ${s2.long_name}`"
                            >
                                <span v-if="!results[s1.name][s2.name].tested && results[s1.name][s2.name].running" class="fa fa-spin fa-spinner"></span>
                                <span v-if="!results[s1.name][s2.name].tested && !results[s1.name][s2.name].running">...</span>
            
                                <span v-if="results[s1.name][s2.name].tested && !results[s1.name][s2.name].errors.length && !results[s1.name][s2.name].warnings.length">OK</span>
            
                                <span v-if="results[s1.name][s2.name].tested && !results[s1.name][s2.name].warnings.length"></span>
                                <div class="counter counter-warnings text-center d-block" v-if="results[s1.name][s2.name].tested && results[s1.name][s2.name].warnings.length">
                                    <i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings"></i>
                                </div>
            
                                <span v-if="results[s1.name][s2.name].tested && !results[s1.name][s2.name].errors.length"></span>
                                <div class="counter counter-warnings text-danger text-center d-block" v-if="results[s1.name][s2.name].tested && results[s1.name][s2.name].errors.length">
                                    <i class="counter-icon fas fa-fw fa-skull-crossbones" aria-label="Errors"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            
                <modal @close="currentResult = null" ref="modal" :is-closeable="true" v-if="currentResult">
                    <template slot="title" v-if="currentResult">
                        <h3 class="modal-title">Work State {{ currentResult.workState.name }}, Resident State: {{ currentResult.residentState.name }}</h3>
                    </template>
            
                    <slot>
                        <div v-if="currentResult">
                            <p v-if="currentResult.results.length && currentResult.errors.length < 1">Tests successfully passed: {{ currentResult.results.length }}</p>
            
                            <div v-if="currentResult.warnings.length">
                                <h5>Warnings:</h5>
            
                                <p v-for="e in currentResult.warnings" class="ml-2">
                                    {{ e.error ? e.error : e }}
                                </p>
                            </div>
            
                            <div v-if="currentResult.errors.length">
                                <h5>Errors:</h5>
            
                                <p v-for="e in currentResult.errors" class="ml-2">
                                    {{ e.error ? e.error : e }}
                                </p>
                            </div>
                        </div>
                    </slot>
                </modal>
    
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import Modal from '@/components/Modal'

export default {
    components: {Modal, },
    computed: {
        results() {
            if (!this.dataLoaded) {
                return
            }

            const result = {}
            this.states.forEach(s1 => {
                result[s1.name] = {}
                this.states.forEach(s2 => {
                    const t = {
                        workState: s1,
                        residentState: s2,
                        tested: false,
                        running: false,
                        results: [],
                        errors: [],
                        warnings: [],
                    }

                    if (this.testResults[s1.name] && this.testResults[s1.name][s2.name]) {
                        t.tested = true
                        t.results = this.testResults[s1.name][s2.name].results
                        t.errors = this.testResults[s1.name][s2.name].errors
                        t.warnings = this.testResults[s1.name][s2.name].warnings
                    }
                    else {
                        if (!this.rules.find(x => (x[0] == s1.name && x[1] == s1.name))) {
                            t.tested = true
                            t.warnings = [`State ${s1.name} does not have any rules!`]
                        }

                        if (!this.rules.find(x => (x[0] == s2.name && x[1] == s2.name))) {
                            t.tested = true
                            t.warnings = [`State ${s2.name} does not have any rules!`]
                        }
                    }

                    if (this.currentTest && this.currentTest[0] == s1.name && this.currentTest[1] == s2.name) {
                        t.running = true
                        t.tested = false
                    }
                    else if (this.testQueue.find(x => {return x[0] == s1.name && x[1] == s2.name})) {
                        t.running = true
                        t.tested = false
                    }

                    result[s1.name][s2.name] = t
                })
            })

            return result
        },
    },
    data() {
        return {
            dataLoaded: false,
            states: [],
            rules: [],
            testResults: {},
            runTests: true,
            currentTest: null,
            currentResult: null,

            testQueue: [],
            testInterval: null,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'State Rules Test')
        this.getStates()
        this.getRules()
        this.testInterval = setInterval(() => {this.runTestFromQueue()}, 1000)
    },
    beforeDestroy() {
        this.runTests = false
        clearInterval(this.testInterval)
        this.testInterval = null
    },
    methods: {
        getStates() {
            this.dataLoaded = false
            return api.get(`/admin/tax-engine/states`).then(resp => {
                this.states = resp
                if (this.rules.length) {
                    this.dataLoaded = true
                    this.runTests = true
                    this.runAllTests()
                }
            }).catch((errors) => {
                bus.showError(errors.__all__)
            })
        },
        getRules() {
            api.get(`/admin/pdf-forms/auto-tester/rules`).then(resp => {
                this.rules = resp
                if (this.states.length) {
                    this.dataLoaded = true
                    this.runTests = true
                    this.runAllTests()
                }
            }).catch((errors) => {
                bus.showError(errors.__all__)
            })
        },
        runAllTests() {
            for (let i=this.states.length - 1; i >= 0; i--) {
                this.testWorkState(this.states[i].name)
            }
        },
        runTest(workState, residentState) {
            this.currentTest = [workState, residentState]

            return api.get(`/admin/pdf-forms/auto-tester/${workState}/${residentState}`).then(resp => {
                if (!this.testResults[workState]) {
                    this.$set(this.testResults, workState, {})
                }
                this.$set(this.testResults[workState], residentState, resp)
                this.currentTest = null
            }).catch((errors) => {
                bus.showError(errors.__all__)
                this.currentTest = null
            })
        },
        runTestFromQueue() {
            if (this.currentTest) {
                return
            }

            if (this.testQueue.length && this.runTests) {
                const t = this.testQueue.shift()
                this.runTest(t[0], t[1]).then(() => {
                    this.runTestFromQueue()
                })
            }
        },
        onClick(s1, s2) {
            if (this.results[s1][s2].tested) {
                this.$set(this, 'currentResult', this.results[s1][s2])
                this.$nextTick(() => {
                    this.$refs.modal.open()
                })
            }
            else {
                this.enqueueTest([s1, s2])
            }
        },
        testWorkState(s) {
            for (let i=this.states.length - 1; i >= 0; i--) {
                this.enqueueTest([s, this.states[i].name])
            }

        },
        testResidentState(s) {
            for (let i=this.states.length - 1; i >= 0; i--) {
                this.enqueueTest([this.states[i].name, s])
            }

        },
        enqueueTest(t) {
            if (!this.testQueue.find(x => (x[0] == t[0] && x[1] == t[1]))) {
                if (!this.rules.find(x => (x[0] == t[0] && x[1] == t[0]))) {
                    return
                }

                if (!this.rules.find(x => (x[0] == t[1] && x[1] == t[1]))) {
                    return
                }

                if (this.testResults[t[0]] && this.testResults[t[0]][t[1]]) {
                    this.testResults[t[0]][t[1]] = null
                }
                this.testQueue.unshift(t)
            }
        },
    },
}

</script>

<style scoped lang="scss">
.rule-test-table {
    th, td {
        min-width: 64px;
        width: 64px;
        cursor: pointer;
    }

    th {
        position: sticky;
    }
}
</style>
