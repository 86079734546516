<template>
    <div class="cell-content" :class="(cell && cell._meta && cell._meta.additionalCSSClasses) ? cell._meta.additionalCSSClasses : []" @click.prevent="onClick">
        <div v-if="type == 'string'">{{ cellValue }} <i v-if="(cell && cell._meta && cell._meta.hasWarningTriangle)" class="fas fa-fw fa-exclamation-triangle"></i></div>

        <div v-if="type == 'date' && cell">{{ cellValue|date(header.format) }}</div>
        <div v-if="type == 'date' && !cell">---</div>
        <div v-if="type == 'relative-date' && cell">{{ cellValue|fromNow }}</div>

        <div v-if="type == 'money' && cell">${{ cellValue }}</div>
        <div v-if="type == 'money' && !cell">---</div>

        <progress-bar v-if="type == 'progress' && cell !== ''" :progress="cellValue" />

        <i v-if="type == 'checkmark' && cellValue" class="text-muted fas fa-fw fa-check" aria-label="Yes"></i>

        <div class="counter counter-warnings" v-if="type == 'warning' && cellValue && !isNaN(parseInt(cellValue, 10))" >
            <i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings"></i>
            <span class="counter-label">{{ cellValue }}</span>
        </div>

        <div class="counter counter-errors" v-if="type == 'warning' && cellValue == 'error'" >
            <i class="counter-icon fas fa-fw fa-exclamation-circle" aria-label="Error"></i>
        </div>

        <div class="counter text-500" v-if="type == 'warning' && cellValue == 'scarlet-letter'" >
            <i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="I-9 Completed Late"></i>
        </div>

        <div v-if="type == 'checkmark-or-warning' && cellValue">
            <div v-if="cellValue == 'OK'">
                <i class="text-muted fas fa-fw fa-check" aria-label="Yes"></i>
            </div>
            <div v-else>
                <i class="counter-icon fas fa-fw fa-exclamation-triangle" :aria-label="cellValue"></i>
            </div>
        </div>

        <div v-if="type == 'checkmark-or-blank' && cellValue">
            <div v-if="cellValue == 'OK'">
                <i class="text-muted fas fa-fw fa-check" aria-label="Yes"></i>
            </div>
            <div v-else>

            </div>
        </div>

        <a :href="cell.url" class="d-print-none" v-if="type == 'link'" @click.prevent="open(cell.url)">
            <i class="far fa-fw fa-file-pdf mr-hf"></i>
            <span>{{ cell.label }}</span>
        </a>

        <span v-if="type == 'router-link'">
            <router-link :to="cell.route">{{ cell.label }}</router-link>
        </span>
    </div>
</template>

<script>
import ProgressBar from '@/components/ProgressBar'

export default {
    props: ['cell', 'header'],
    components: {ProgressBar, },
    computed: {
        type() {
            if (this.cell && this.cell._meta && this.cell._meta.type) {
                return this.cell._meta.type
            }

            return this.header.type || 'string'
        },
        cellValue() {
            if (this.cell && this.cell._meta) {
                return this.cell.value
            }

            return this.cell
        },
    },
    methods: {
        open(url) {
            window.open(url)
        },
        onClick($event) {
            this.$emit('click', $event)
        },
    },
}
</script>
