<template>
<div>
    <form-errors :errors="formErrors" v-if="!hideFormErrors" />

    <div class="form-row">
        <div class="col">
            <form-input label="Company" v-model="formData.company" :errors="errors.company" type="select" :options="companyOptions"/>
        </div>
    </div>

    <form-input label="First Name" v-model="formData.first_name" :errors="errors.first_name" type="text" maxlength="25" />
    <form-input label="Middle Name" v-model="formData.middle_name" :errors="errors.middle_name" type="text" />
    <form-input label="Last Name" v-model="formData.last_name" :errors="errors.last_name" type="text" maxlength="25" />

    <div class="form-row">
        <div class="col-8 col-sm-6">
            <form-input label="Suffix" v-model="formData.suffix" :errors="errors.suffix" type="select" :options="suffixOptions"/>
        </div>
    </div>

    <form-input :disabled="employee" label="Email Address" v-model="formData.email" :errors="errors.email" type="email" />

    <div class="form-row">
        <div class="col">
            <div class="form-group-helpinfo">
                <div class="form-helpinfo-field">
                    <form-input label="ACA Employment Category" v-model="formData.aca_category" :errors="errors.aca_category" type="select" :options="acaCategoryOptions" />

                    <div class="field-helper-text" v-if="formData.aca_category == 'Full Time'">
                        On average at least 30 hours of service per week.
                    </div>

                    <div class="field-helper-text" v-if="formData.aca_category == 'Variable'">
                        On average less than 30 hours of service per week.
                    </div>

                </div>

                <more-info>If the new employee is expected to work at least 30 hours per week, on average, select "Full Time" for this field. If they are not expected to work at least 30 hours per week, select "Variable".</more-info>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="col-8 col-sm-6">
            <form-input label="Pay Type" v-model="formData.pay_type" :errors="errors.pay_type" type="radio" :options="payTypeOptions" extraclasses="label--small choices--flushleft" />
        </div>
    </div>

    <div class="form-row">
        <div class="col-8 col-sm-6">
            <form-input label="Expected Start Date" v-model="formData.date_hired" :errors="errors.date_hired" type="date" />
        </div>
    </div>

    <div v-if="selectedCompany && formData.pay_type">
        <h5 class="mt-4 mb-2">{{ client.position_label_pretty }} Information</h5>

        <div v-for="(id, $index) in Object.keys(jobForms)" :key="id">
            <div class="row align-items-center">
                <div class="col">
                    <h6 class="mb-1">#{{ $index + 1 }}</h6>
                </div>
                <div class="col text-right">

                    <div class="dropdown d-inline-block" v-if="Object.keys(jobForms).length > 1">
                        <a href="" class="btn btn-icon dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"></i><span class="sr-only">More Actions</span>
                        </a>
                        <div class="dropdown-menu has-icons dropdown-menu-right">
                            <a class="dropdown-item" href @click.prevent="removeJob(id, $index)"><i class="fas fa-fw fa-times-circle mr-1"></i>Remove</a>
                        </div>
                    </div>

                </div>
            </div>

            <job-form
                :client="client"
                :company="selectedCompany"
                :instance="formData.jobs[$index]"
                ref="jobForms"
                @update="onJobUpdate($index, $event)"
                :all-jobs="formData.jobs"
                :pay_type="formData.pay_type"
            />
        </div>

        <p class="mb-2" v-if="formData.pay_type == 'H'">
            <a href class="btn btn-link" @click.prevent="addJob()"><i class="far fa-plus mr-hf"></i>Add another</a>
        </p>
    </div>
</div>
</template>

<script>
import api from '@/api'
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import FormMixin from '@/mixins/Form'
import JobForm from './JobForm'
import moment from 'moment'

export default {
    mixins: [FormMixin],
    components: {FormInput, FormErrors, JobForm, },
    props: {employee: {required: false, default: null}, client: {required: true}, hideFormErrors: {required: false, default: false}},
    computed: {
        payTypeOptions() {
            return [
                {text: 'Hourly', value: 'H'},
                {text: 'Salary', value: 'S'},
            ]
        },
        companies() {
            return this.client.companies || []
        },
        companyOptions() {
            const options = []
            for (let company of this.companies) {
                if (company.has_paperless_onboarding) {
                    options.push({text: company.name, value: company.id})
                }
            }
            return options
        },
        selectedCompany() {
            return this.companies.find((c) => {return c.id == this.formData.company})
        },
    },
    watch: {
        'formData.pay_type'() {
            if (this.formData.pay_type == 'S') {
                while (this.formData.jobs.length > 1) {
                    const id = Math.max(1, ...Object.keys(this.jobForms))
                    const idx = this.jobForms[id]
                    this.removeJob(id, idx)
                }
            }
        },
        client: function() {
            this.reset()
        },
        employee: function() {
            this.reset()
        },
    },
    data() {
        const date_hired_latest = moment().add(364, 'days')

        const data = {
            suffixOptions: [
                {text: 'JR', value: 'JR'},
                {text: 'SR', value: 'SR'},
                {text: 'III', value: 'III'},
                {text: 'IV', value: 'IV'},
            ],
            acaCategoryOptions: [
                {text: 'Full Time', value: 'Full Time'},
                {text: 'Variable', value: 'Variable'},
            ],
            formLabels: {
                aca_category: 'ACA Category',
                date_hired: 'Expected Start Date',
            },
            formRules: {
                first_name: {presence: {allowEmpty: false}, },
                last_name: {presence: {allowEmpty: false}, },
                email: {
                    email: true,
                    presence: {allowEmpty: false},
                },
                date_hired: {presence: {allowEmpty: false}, date: {latest: date_hired_latest, tooLate: "can't be more than a year from today."}},
                company: {presence: {allowEmpty: false},},
                aca_category: {presence: {allowEmpty: false},},
                pay_type: {presence: {allowEmpty: false},},
            },
            formData: this.makeFormData(),
            jobForms: {},
        }

        if (this.employee) {
            for (let i = 0; i < this.employee.jobs.length; i++) {
                data.jobForms[i] = i
            }
        }
        else {
            data.jobForms = {1: 1}
        }

        return data
    },
    methods: {
        makeFormData() {
            if (this.employee) {
                const formData = {
                    id: this.employee.id,
                    first_name: this.employee.first_name,
                    middle_name: this.employee.middle_name,
                    last_name: this.employee.last_name,
                    suffix: this.employee.suffix,
                    email: this.employee.email,
                    date_hired: this.employee.date_hired,
                    company: this.employee.company.id,
                    aca_category: this.employee.aca_category,
                    jobs: [],
                }

                this.employee.jobs.forEach((job) => {
                    formData.jobs.push({
                        id: job.id,
                        location: job.position.work_location.id,
                        position: job.position.id,
                        pay_type: job.pay_type,
                        hourly_rate: job.hourly_rate,
                        default_hours: job.default_hours,
                    })
                })

                return formData
            }
            else {
                return {
                    first_name: '',
                    middle_name: '',
                    last_name: '',
                    suffix: '',
                    email: '',
                    date_hired: '',
                    company: '',
                    aca_category: '',
                    jobs: [],
                }
            }
        },
        addJob() {
            const id = Math.max(1, ...Object.keys(this.jobForms)) + 1
            this.$set(this.jobForms, id, Object.keys(this.jobForms).length - 1)
            this.$emit('scrollbottom')
        },
        removeJob(id, idx) {
            const jobs = []
            this.formData.jobs = [];
            (this.$refs.jobForms || []).forEach((form, i) => {
                if (i != idx) {
                    jobs.push(form.getFormData())
                }
            })

            this.$set(this.formData, 'jobs', jobs)
            this.$delete(this.jobForms, id)
        },
        onJobUpdate(idx, data) {
            this.$set(this.formData.jobs, idx, data)
        },
        onSubmit() {
            let errorCount = 0;
            (this.$refs.jobForms || []).forEach((form) => {
                if (!form.validate()) {
                    errorCount += 1
                }
            })

            if (!this.validate() || errorCount) {
                return
            }

            let promise
            if (this.employee) {
                promise = api.put(`/clients/${this.client.id}/onboarding-applications/${this.employee.id}`, this.getFormData())
            }
            else {
                promise = api.post(`/clients/${this.client.id}/onboarding-applications`, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            promise.then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('payroll_onboarding_applications_updated', resp)
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                if (errors.__status__ == 409) {
                    const c = confirm(errors.__all__[0])
                    if (c) {
                        this.formData.force = true
                        this.onSubmit()
                    }
                }
                else {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.formData.force = false

                    this.onError()
                }
            })
        },
        reset: function() {
            (this.$refs.jobForms || []).forEach((form) => {
                form.reset()
            })
            this.jobForms = {1: 1}
            this.formErrors = []

            FormMixin.methods.reset.call(this)
        },
    }
}
</script>
