<template>
    <div>
        <div class="flex-body pb-2">
            <div>
                <div class="container-centered my-4">
                    <div class="row">
                        <div class="col-12 col-md-10 offset-md-1">
                            <router-link class="btn btn-link mb-2" :to="{name: 'employer-home', params: {hashedClientId: $hasher.encode(client.id)}}">&laquo; Back to Dashboard</router-link>

                            <h3>Allocate Tips: <span class="text-thin">Calculate Allocation</span></h3>

                            <p class="">
                                Review the tip allocation below, and exclude employees as needed.
                            </p>

                            <form @submit.prevent="saveEmployeeTips" novalidate class="mt-4" v-if="dataLoaded">
                                <form-errors :errors="formErrors" :errorsHtml="formErrorsHtml" />

                                <div v-for="(store_detail, store_name) in taskDetail.store_data" class="card mb-4">
                                    <div class="card-header d-flex justify-content-between p-2">
                                        <h4 class="my-0">
                                            <span class="text-muted">Location:</span>
                                            {{ store_detail.label }}
                                        </h4>

                                        <h4 class="my-0">${{ store_detail.total_tips|formatCurrency }}</h4>
                                    </div>
                                    <div class="card-body py-1">
                                        <table class="table css-table">
                                            <thead>
                                                <tr>
                                                    <th>Exclude</th>
                                                    <th>Employee</th>
                                                    <th class="text-left pr-2">Type</th>
                                                    <th class="text-right pr-2">Total Hours</th>
                                                    <th class="text-right pr-2">Tips</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="emp in store_detail['employees']" :key="store_name + '.' + emp['idx_in_store']">
                                                    <td class="text-center">
                                                        <input type="checkbox" class="form-check-input" v-model="emp['excluded']" @change="calculateTipsForStore(store_name)" />
                                                    </td>
                                                    <td :class="{'text-very-muted': emp['excluded']}">
                                                        {{ emp['employee_full_name'] }}
                                                    </td>
                                                    <td class="text-left pr-2" :class="{'text-very-muted': emp['excluded']}">
                                                        {{ emp['pay_item_title'] }}
                                                    </td>
                                                    <td class="text-right pr-2" :class="{'text-very-muted': emp['excluded']}">
                                                        {{ emp['hours_display'] }}
                                                    </td>
                                                    <td class="text-right pr-2" :class="{'text-very-muted': emp['excluded']}">
                                                        <span v-if="emp['excluded']">
                                                            $ -
                                                        </span>
                                                        <span v-else>
                                                            ${{ emp['tips_display']|formatCurrency }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td>
                                                    </td>
                                                    <td>
                                                        <b>TOTAL</b>
                                                    </td>
                                                    <td>
                                                    </td>
                                                    <td class="text-right pr-2">
                                                        <b>{{ totalHoursByStore[store_name] }}</b>
                                                    </td>
                                                    <td class="text-right pr-2">
                                                        <b>${{ totalTipsByStore[store_name]|formatCurrency }}</b>

                                                        <more-info class="moreinfo-inline" v-if="totalTipsByStore[store_name] && (totalTipsByStore[store_name] != '0.00') && (totalTipsByStore[store_name] != store_detail.total_tips)">
                                                            Total tips may differ by a few cents due to rounding
                                                        </more-info>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>

                                <div class="row my-2 d-flex justify-content-between">
                                    <div class="col">
                                        <button type="button" class="btn btn-block btn-outline-primary" @click="goBack()">Back</button>
                                    </div>
                                    <div class="col">
                                        <button type="submit" class="btn btn-block btn-primary">Import to Payroll</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'

import moment from 'moment'

export default {
    props: ['client', ],
    computed: {
        taskId() {
            return this.$route.params.taskId
        },
    },
    data() {
        return {
            dataLoaded: false,
            taskDetail: null,
            formErrors: [],
            formErrorsHtml: [],
            formData: this.makeFormData(),
            totalHoursByStore: {},
            totalTipsByStore: {},
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Tips Allocator')
        this.getInitialData()
    },
    methods: {
        makeFormData() {
            return {
            }
        },

        calculateTipsForStore(store) {
            let totalHoursForStore = 0
            let totalTipsForStore = 0

            let actualTipsForStore = parseFloat(this.taskDetail.store_data[store]['total_tips'])

            // figure out the total hours, so we know what to divide by
            this.taskDetail.store_data[store]['employees'].forEach(emp => {
                // show everything to 2 decimal places
                emp['hours_display'] = parseFloat(emp['hours']).toFixed(2)
                if (! emp['excluded']) {
                    totalHoursForStore += parseFloat(emp['hours'])
                }
            })

            // then for each employee figure out their % of the total tips
            this.taskDetail.store_data[store]['employees'].forEach(emp => {
                if (! emp['excluded']) {
                    let pct_of_tips = parseFloat(emp['hours']) / totalHoursForStore
                    emp['pct_of_tips'] = pct_of_tips
                    emp['tips'] = (Math.round(pct_of_tips * actualTipsForStore * 100.0) / 100.0)
                    emp['tips_display'] = emp['tips'].toFixed(2)
                    // keep track of this, since it might be slightly higher
                    // or lower than the actual store total, depending on rounding
                    totalTipsForStore += emp['tips']
                }
            })

            this.totalHoursByStore[store] = totalHoursForStore.toFixed(2)
            this.totalTipsByStore[store] = totalTipsForStore.toFixed(2)
        },
        saveEmployeeTips() {
            const url = `/clients/${this.client.id}/tips-allocator/${this.$route.params.taskId}/employee-tips`

            this.$store.dispatch('START_LOADING')
            return this.$api.post(url, {'store_data': this.taskDetail.store_data}).then(resp => {
                let taskId = resp['task']['id']
                this.$router.push({name: 'tips-allocator-status', params: {hashedClientId: this.$route.params.hashedClientId, taskId: taskId}});
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        getInitialData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')

            const url = `/clients/${this.client.id}/tips-allocator/${this.taskId}/detail`

            this.$api.get(url).then(resp => {
                this.taskDetail = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')

                // if there were errors, to go end
                if (this.taskDetail.errors.length) {
                    this.$router.push({name: 'tips-allocator-error', params: {hashedClientId: this.$route.params.hashedClientId, taskId: this.taskId}});
                }

                // make sure to calculate/render the initial state
                for (let store in this.taskDetail.store_data) {
                    this.calculateTipsForStore(store)
                }
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        reset: function() {
            this.errors = {}
            this.formErrorsHtml = []
            this.formData = this.makeFormData()
        },
        goBack: function() {
            this.$router.push({name: 'tips-allocator-store-totals', params: {hashedClientId: this.$route.params.hashedClientId, taskId: this.taskId}});
        }
    },
}
</script>
