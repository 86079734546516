<template>
    <modal :autoshow="true" size="xl" ref="modal" @close="$emit('close')" :is-closeable="false" :close-warning-message="closeWarningMessage">

        <template slot="title">
            {{ stepTitle }}
        </template>

        <component
            :is="step"
            :prev="prev"
            ref="stepComponent"
            :employee="employee"
            :tax-withholding="taxWithholding"
            :employeeUpdate="employeeUpdate"
            @patch="onPatch"
            @next="onNextStep"
            @prev="onPrevStep"
            @updated="onUpdated"
        />

        <template slot="footer">
            <portal-target name="tax-withholding-edit-modal-footer"></portal-target>
        </template>
    </modal>
</template>

<script>
const STEP_ORDER = [
    'confirm-address',
    'form-selection',
    'complete-forms',
]

import ModalMixin from '@/mixins/ModalMixin'
import ConfirmAddress from './EmployeeTaxWithholdingAddressConfirmStep.vue'
import FormSelection from './EmployeeTaxWithholdingFormSelectionStep.vue'
import CompleteForms from './EmployeeTaxWithholdingFormFillStep.vue'
import api from '@/api'

export default {
    mixins: [ModalMixin, ],
    components: {ConfirmAddress, FormSelection, CompleteForms},
    props: ['employee', 'taxWithholding'],
    watch: {
        employee() {
            this.reset()
        },
        taxWithholding() {
            this.reset()
        },
    },
    computed: {
        stepTitle() {
            const STEP_TITLES = {
                'default': "Update Tax Form",
                'confirm-address': `Edit ${this.taxWithholding.jurisdiction_name} Taxes: Address Verification`,
                'form-selection': `Edit ${this.taxWithholding.jurisdiction_name} Taxes: Withholding Questionnaire`,
                'complete-forms': `Edit ${this.taxWithholding.jurisdiction_name} Taxes: Form Completion`,
            }
            if (this.step in STEP_TITLES) {
                return STEP_TITLES[this.step]
            } else {
                return STEP_TITLES['default']
            }
        },
    },
    data() {
        return {
            step: STEP_ORDER[0],
            employeeUpdate: null,
            prev: false,
            closeWarningMessage: "You have not saved your changes. Are you sure you want to discard them?"
        }
    },
    methods: {
        getNextStep(step) {
            return STEP_ORDER[STEP_ORDER.indexOf(step) + 1]
        },
        getPrevStep(step) {
            return STEP_ORDER[STEP_ORDER.indexOf(step) - 1]
        },
        onPrevStep() {
            this.prev = true
            let step = this.getPrevStep(this.step)

            if (!step) {
                this.$emit('close')
            }
            this.step = step
        },
        onNextStep() {
            this.prev = false
            let step = this.getNextStep(this.step)

            if (!step) {
                this.$emit('close')
            }
            this.step = step
        },
        onPatch(update) {
            if (update) {
                this.employeeUpdate = update
            } else {
                this.getEmployeeUpdate()
            }
        },
        getEmployeeUpdate() {
            this.$store.dispatch('START_LOADING')
            api.get(`/me/ess/companies/${this.employee.company_id}/updates/${this.employeeUpdate.id}`, {}).then((resp) => {
                this.employeeUpdate = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onUpdated() {
            this.$emit('updated')
            this.$emit('close')
        },
        reset() {
            this.employeeUpdate = null
            this.step = STEP_ORDER[0]
        },
    },
}
</script>
