<template>
    <div id="company" v-if="$store.state.user">
        <transition name="fade" mode="out-in">
            <router-view class="flex-page-wrapper flex-container flex-body view" v-if="dataLoaded" :company="company" />
        </transition>
    </div>
</template>

<script>
export default {
    computed: {
        companyId() {
            return this.$hasher.decode(this.$route.params.hashedCompanyId)
        },
        dataLoaded() {
            return (this.$store.state.user && this.$store.state.user.id) ? true : false
        },
        company() {
            let company = this.$store.state.user.employee_companies.find(c => c.id == this.companyId)
            if (!company) {
                const o = this.$store.state.user.onboarding_applications.find(o => o.company_id == this.companyId)
                company = o ? o.company : company
            }

            return company
        },
    },
    mounted() {
        this.$store.dispatch('GET_USER').then(() => {
            if (!['employee-tasks'].includes(this.$route.name)) {
                if (this.hasBlockingTask()) {
                    this.redirectToTaskPage()
                }
            }
        })
    },
    methods: {
        hasBlockingTask() {
            let user = this.$store.state.user
            if (user && this.company && user.employee_num_tasks_by_company_id && this.company.id in user.employee_num_tasks_by_company_id) {
                return user.employee_num_tasks_by_company_id[this.company.id]['has_blocking_task']
            }
            return false
        },
        redirectToTaskPage() {
            this.$router.push({name: 'employee-tasks', params: {hashedCompanyId: this.$route.params.hashedCompanyId}, query: {redirected: 1}})
        },
    }
}
</script>
