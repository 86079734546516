<template>
    <modal @close="$emit('close')" ref="modal" size="m" :autoshow="true">
        <template #title>
            <h3 class="modal-title">Edit Collection Settings</h3>
        </template>

        <template #subheading>
            <div class="p-1 unpad-x bg-150 rounded">
                <div><span class="text-muted">Employee:</span> {{ employee.full_name }}</div>
                <div><span class="text-muted">Document:</span> {{ docTask.name }}</div>
            </div>
            <p class="mt-2 mb-0">How should GetMyPaystub collect this document for this employee?</p>
        </template>

        <div v-if="canEditCollectionSettings" class="form-check-set mt-1 pb-1">
            <div class="form-check pb-1">
                <label>
                    <input name="collection_method" type="radio" @click="formData.collection_method = 'employee'" :checked="formData.collection_method == 'employee'" class="form-check-input">
                    <strong>Employee</strong> will be prompted to complete this document online
                </label>
            </div>
            <div class="form-check-set pl-3 pb-1" v-show="formData.collection_method == 'employee'">
                <div class="form-check">
                    <label>
                        <input name="block_ee_until_completion" type="radio" @click="formData.block_ee_until_completion = true; formData.collection_method = 'employee'" :checked="formData.block_ee_until_completion == true" class="form-check-input">
                        Block access to paystubs, W-2s, etc. until employee has completed this document.
                    </label>
                </div>
                <div class="form-check">
                    <label>
                        <input name="block_ee_until_completion" type="radio" @click="formData.block_ee_until_completion = false; formData.collection_method = 'employee'" :checked="formData.block_ee_until_completion == false" class="form-check-input">
                        Allow employee to access paystubs, W-2s, etc. before completing this document.
                    </label>
                </div>
                <div v-if="errors['block_ee_until_completion']" class="field-helper-text text-danger pt-1">
                    <i aria-hidden="true" title="Error" class="fas fa-fw fa-exclamation-circle"></i>
                    <span class="sr-only">Error:</span>
                    <span> {{ errors['block_ee_until_completion'][0]}}</span>
                </div>
            </div>
            <div class="form-check">
                <label>
                    <input name="collection_method" type="radio" @click="formData.collection_method = 'manager'; formData.block_ee_until_completion = false" :checked="formData.collection_method == 'manager'" class="form-check-input">
                    <span><strong>Manager</strong> will upload a completed hard copy</span>
                </label>
            </div>
            <div v-if="errors['collection_method']" class="field-helper-text text-danger pt-1">
                <i aria-hidden="true" title="Error" class="fas fa-fw fa-exclamation-circle"></i>
                <span class="sr-only">Error:</span>
                <span> {{ errors['collection_method'][0]}}</span>
            </div>
        </div>
        <div v-else>
            <alert-box type="info">Only your payroll admininstrator may change collection settings.</alert-box>
        </div>

        <template #footer>
            <form-errors :errors="formErrors"/>

            <button-row>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button v-if="canEditCollectionSettings" class="btn btn-primary" @click.prevent="onSubmit()">Save</button>
            </button-row>

        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    components: {},
    mixins: [ModalMixin, FormMixin, ],
    props: ['docTask', 'employee', 'client'],
    data() {
        return {
            formData: {
                collection_method: {
                    'needs-employee-response': 'employee',
                    'needs-manager-response': 'manager'
                }[this.docTask.status],
                block_ee_until_completion: this.docTask.block_ee_until_completion,
            },
        }
    },
    computed: {
        canEditCollectionSettings() {
            return this.$permissions.hasRolesInCompany(this.client.id, this.employee.company_id, this.$store.state.security_roles.SETTINGS_ROLES)
        }
    },
    methods: {
        validateFormData() {
            if (!this.formData.collection_method) {
                this.$set(this.errors, 'collection_method', ["Collection settings cannot be blank"])
                return false
            }
            if (this.formData.collection_method == 'employee' && typeof this.formData.block_ee_until_completion != 'boolean') {
                this.$set(this.errors, 'block_ee_until_completion', ["This selection cannot be blank"])
                return false
            }
            return true
        },
        onSubmit() {
            if (!this.validateFormData()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.patch(`/clients/${this.client.id}/employees/${this.employee.id}/document-tasks/${this.docTask.id}/collection-settings`, this.formData).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.$bus.showInfo('Collection settings successfully updated.')
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        }
    },
}
</script>

