<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div class="container-centered py-4">

                        <h1 class="text-center mb-4">
                            Account &amp; Security
                        </h1>

                        <!-- My Account widget -->
                        <div class="card mb-2">
                            <div class="card-header pr-1 d-flex align-items-center">
                                <span class="btnlike mr-auto">
                                    <h4 class="mb-0">
                                        My Account
                                    </h4>
                                </span>
                            </div>
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="col">
                                      <div class="mui-textfield" v-if="state.user">
                                        <span class="textfield">{{ state.user.email }}</span>
                                        <label>Email Address</label>
                                      </div>
                                    </div>
                                </div>
                                <div class="form-row mb-2">
                                    <button class="btn btn-outline-primary btn-collapse-none" @click.prevent="showChangePassword()">
                                      <i class="far fa-pen" aria-hidden="true"></i>
                                      <span class="btn-text">Change Password...</span>
                                    </button>
                                </div>
                                <alert-box type="info" class="mb-0">
                                    If you need to change the email address associated with
                                    your account, please contact your account administrator.
                                    To delete your account, see <b>Withdraw Consent</b> below.
                                </alert-box>
                            </div>
                        </div>

                        <!-- My Trusted Devices widget -->
                        <div class="card mb-2">
                            <div class="card-header pr-1 d-flex align-items-center">
                                <span class="btnlike mr-auto">
                                    <h4 class="mb-0">
                                      My Trusted Devices
                                    </h4>
                                </span>
                            </div>

                            <div class="card-body" v-if="mobile_devices.length">
                                <p class="">
                                    You have {{ mobile_devices.length }} trusted device<span v-if="mobile_devices.length > 1">s</span>.
                                    Trusted Devices can be used to log in to your GetMyPayStub account with biometric authentication.
                                </p>
                                <div class="row">
                                    <div class="col-md-4" v-for="device in mobile_devices">
                                        <div class="card card-body m-0 mb-2 text-center device-tile">
                                            <div class="mb-1">
                                                <i :class="['fal', device.icon, 'display-4', 'opacity-50']"></i>
                                            </div>

                                            <div class="mb-1 text-large text-semibold">
                                                <span v-if="device.user_label">
                                                    {{ device.user_label }}
                                                </span>
                                                <span v-else>
                                                    <i>No Nickname</i>
                                                </span>

                                                <a href="#" @click.prevent="editMobileDeviceNickname(device)" class="pl-1"><i class="far fa-pencil"></i></a>
                                            </div>

                                            <div class="mb-1">
                                                {{ device.device_model_formatted }}
                                            </div>

                                            <div class="mb-1 text-small" v-if="device.last_used">
                                                <i>Last sign in {{ device.last_used|fromNow }}</i>
                                            </div>

                                            <div>
                                                <a href="#" @click.prevent="deauthorizeDevice(device)"><i class="far fa-trash"></i> Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body" v-if="! mobile_devices.length">
                                <p class="mb-0">
                                    You do not have any Trusted Devices.

                                    <br><br>
                                    <a class="d-inline-block py-hf" href @click.prevent="showSendLinkModal()">Get the mobile app</a> for fast and convenient access to GetMyPayStub.
                                </p>
                            </div>

                        </div>

                        <!-- Security User Access widget -->
                        <div v-if="security_profiles.length > 0" class="card mb-2">
                            <div class="card-header pr-1 d-flex align-items-center">
                              <span class="btnlike mr-auto">
                                <h4 class="mb-0">
                                  My Security User Access
                                </h4>
                              </span>
                            </div>
                            <div class="card-body">
                                <p>
                                    You have Security User access at the organization<span v-if="security_profiles.length > 1">s</span> below.
                                    Employees can contact you to reset their account PIN, using the
                                    phone number<span v-if="security_profiles.length > 1">s</span> listed below.
                                </p>
                                <div class="pt-sm-1">
                                    <ul class="list-unstyled m-0">
                                        <!-- Header row -->
                                        <li class="d-none d-sm-block">
                                            <div class="form-row align-items-baseline pb-1">
                                                <div class="col-12 col-sm-5">
                                                    <h5 class="theading m-0">Organization</h5>
                                                </div>
                                                <div class="col-12 col-sm-4">
                                                    <h5 class="theading m-0">Name</h5>
                                                </div>
                                                <div class="col-12 col-sm-3">
                                                    <h5 class="theading m-0">Contact Number</h5>
                                                </div>
                                            </div>
                                        </li>
                                        <!-- Content row -->
                                        <li v-for="sec_profile in security_profiles" class="boxed-li-bottom">
                                            <div class="form-row py-1">
                                                <div class="col-12 col-sm-5">
                                                    <span class="btnlike-sm-alt text-semibold">
                                                        {{ sec_profile.client.name }}
                                                    </span>
                                                </div>
                                                <div class="col-12 col-sm-4 d-flex">
                                                    <span class="flex-fill">
                                                        <div class="label-like pt-hf d-md-none">
                                                            Name
                                                        </div>
                                                        <span class="btnlike-sm-alt">
                                                            {{ sec_profile.full_name }}
                                                        </span>
                                                    </span>
                                                    <span class="flex-0 pt-1 pt-md-0">
                                                        <button class="btn btn-sm btn-outline-primary btn-collapse-xl float-right mr-1" @click.prevent="showChangeName(sec_profile)">
                                                            <i class="far fa-pen" aria-hidden="true"></i>
                                                            <span class="btn-text">Edit</span>
                                                        </button>
                                                    </span>
                                                </div>
                                                <div class="col-12 col-sm-3 d-flex">
                                                    <span class="flex-fill">
                                                        <div class="label-like pt-hf d-md-none">
                                                            Contact Number
                                                        </div>
                                                        <span class="btnlike-sm-alt">
                                                            {{ sec_profile.phone_formatted }}
                                                        </span>
                                                    </span>
                                                    <span class="flex-0 pt-1 pt-md-0">
                                                        <button class="btn btn-sm btn-outline-primary btn-collapse-xl float-right mr-1" @click.prevent="showChangePhone(sec_profile)">
                                                            <i class="far fa-pen" aria-hidden="true"></i>
                                                            <span class="btn-text">Edit</span>
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <!-- /card content -->
                            </div>
                        </div>

                        <!-- Recent Logins widget -->
                        <div class="card mb-2">
                            <div class="card-header pr-1 d-flex align-items-center">
                                <span class="btnlike mr-auto">
                                    <h4 class="mb-0">
                                        Recent Logins
                                    </h4>
                                </span>
                            </div>
                            <div class="card-body">
                                <ul class="list-unstyled m-0">
                                    <!-- Header row -->
                                    <li class="d-none d-sm-block">
                                        <div class="form-row align-items-baseline pb-1">
                                            <div class="col-12 col-sm-5">
                                                <h5 class="theading m-0">Date &amp; Time</h5>
                                            </div>
                                            <div class="col-12 col-sm-4">
                                                <h5 class="theading m-0">Device</h5>
                                            </div>
                                            <div class="col-12 col-sm-3">
                                                <h5 class="theading m-0">IP Address</h5>
                                            </div>
                                        </div>
                                    </li>
                                    <!-- Content row -->
                                    <li class="boxed-li-bottom" v-for="login_detail in recent_logins">
                                        <div class="form-row align-items-baseline py-1">
                                            <div class="col-12 col-sm-5">
                                                <span>
                                                    <!-- Mon 09/15/2019 02:03 PM (EST) -->
                                                    {{ login_detail.login_completed_on|datetime }}
                                                </span>
                                            </div>
                                            <div class="col-12 col-sm-4">
                                                <span>
                                                    {{ login_detail.device_string }}
                                                </span>
                                            </div>
                                            <div class="col-12 col-sm-3">
                                                <span>
                                                    {{ login_detail.login_completed_from_ip }}
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- Active Sessions widget (Hidden for now) -->
                        <div class="card mb-2" v-show="false">
                            <div class="card-header pr-1 d-flex align-items-center">
                                <span class="btnlike mr-auto">
                                    <h4 class="mb-0">
                                        Active Sessions
                                    </h4>
                                </span>
                            </div>
                            <div class="card-body">
                                <ul class="list-unstyled m-0">
                                    <!-- Header row -->
                                    <li class="d-none d-sm-block">
                                        <div class="form-row align-items-baseline pb-1">
                                            <div class="col-12 col-sm-5">
                                                <h5 class="theading m-0">Expires</h5>
                                            </div>
                                            <div class="col-12 col-sm-4">
                                                <h5 class="theading m-0">Device</h5>
                                            </div>
                                            <div class="col-12 col-sm-3">
                                                <h5 class="theading m-0">IP Address</h5>
                                            </div>
                                        </div>
                                    </li>
                                    <!-- Content row -->
                                    <li class="boxed-li-bottom" v-for="session_detail in active_sessions">
                                        <div class="form-row align-items-baseline py-1">
                                            <div class="col-12 col-sm-5">
                                                <span>
                                                    <!-- Mon 09/15/19 02:03 PM (EST) -->
                                                    {{ session_detail.expire_date|datetime }}
                                                </span>
                                            </div>
                                            <div class="col-12 col-sm-4">
                                                <span>
                                                    {{ session_detail.device }}
                                                </span>
                                            </div>
                                            <div class="col-12 col-sm-3">
                                                <span>
                                                    {{ session_detail.ip }}
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- Terms & Privacy widget -->
                        <div class="card mb-2">
                            <div class="card-header pr-1 d-flex align-items-center">
                                <span class="btnlike mr-auto">
                                    <h4 class="mb-0">
                                        Terms &amp; Privacy
                                    </h4>
                                </span>
                            </div>
                            <div class="card-body">
                                <p class="mb-2">
                                    By using GetMyPayStub, you consent to our Terms and Conditions.
                                    You may withdraw this consent at any time. <strong class="text-semibold text-danger">
                                    Withdrawing your consent will permanently disable your access to GetMyPayStub and delete your account.</strong>
                                </p>
                                <p class="mb-2">
                                    <button class="btn btn-outline-primary btn-collapse-none" @click.prevent="showWithdrawConsent()">
                                        <i class="far fa-exclamation-circle" aria-hidden="true"></i>
                                        <span class="btn-text">Withdraw Consent &amp; Leave GetMyPayStub</span>
                                    </button>
                                </p>
                                <hr>
                                <p class="mb-1">
                                    View: <a href @click.prevent="showTerms()">Terms and Conditions</a> &nbsp;|&nbsp;
                                    <a href @click.prevent="showPrivacy()">Privacy Policy</a>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <terms-modal ref="termsModal" />
        <privacy-modal ref="privacyModal" />
        <withdraw-consent-modal ref="withdrawConsentModal" />
        <change-password-modal ref="changePasswordModal" />
        <change-phone-modal :sec_profile="currentSecProfilePhone" ref="changePhoneModal" v-if="currentSecProfilePhone" @close="currentSecProfilePhone=null" @updated="getUserAccountInfo" />
        <change-name-modal :sec_profile="currentSecProfileName" ref="changeNameModal" v-if="currentSecProfileName" @close="currentSecProfileName=null" @updated="getUserAccountInfo" />
        <send-link-modal ref="sendLinkModal" />
        <mobile-device-nickname-modal ref="mobileDeviceNicknameModal" :device="currentMobileDevice" v-if="currentMobileDevice" @updated="onMobileDevicesUpdated($event)" />
    </div>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import moment from 'moment'

import WithdrawConsentModal from './components/WithdrawConsentModal'
import ChangePasswordModal from './components/ChangePasswordModal'
import ChangePhoneModal from './components/ChangePhoneModal'
import ChangeNameModal from './components/ChangeNameModal'
import TermsModal from '@/components/Policies/TermsModal'
import PrivacyModal from '@/components/Policies/PrivacyModal'
import SendLinkModal from '@/components/SendLinkModal'
import MobileDeviceNicknameModal from './components/MobileDeviceNicknameModal'

export default {
    components: {WithdrawConsentModal, ChangePasswordModal, ChangePhoneModal, ChangeNameModal, TermsModal, PrivacyModal, SendLinkModal, MobileDeviceNicknameModal, },
    computed: {
        state() {return this.$store.state}
    },
    data() {
      return {
          recent_logins: [],
          active_sessions: [],
          mobile_devices: [],
          security_profiles: [],
          currentSecProfilePhone: null,
          currentSecProfileName: null,
          currentMobileDevice: null,
      }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Account & Security')
        this.getUserAccountInfo()
    },
    methods: {
        showTerms() {
            this.$refs.termsModal.open()
        },
        showPrivacy() {
            this.$refs.privacyModal.open()
        },
        showWithdrawConsent() {
            this.$refs.withdrawConsentModal.open()
        },
        showChangePassword() {
            this.$refs.changePasswordModal.open()
        },
        showChangePhone(sec_profile) {
            this.currentSecProfilePhone = sec_profile
            this.$nextTick(() => {
                this.$refs.changePhoneModal.open()
            })
        },
        showChangeName(sec_profile) {
            this.currentSecProfileName = sec_profile
            this.$nextTick(() => {
                this.$refs.changeNameModal.open()
            })
        },
        getUserAccountInfo() {
            this.$store.dispatch('START_LOADING')

            this.$store.dispatch('GET_USER').then(() => {
                api.get('/me/account-info').then((data) => {
                    this.$store.dispatch('STOP_LOADING')
                    this.recent_logins = data['recent_logins']
                    this.active_sessions = data['active_sessions']
                    this.mobile_devices = data['mobile_devices']
                    this.security_profiles = data['security_profiles']
                }).catch((errors) => {
                    this.$store.dispatch('STOP_LOADING')
                    bus.showError(errors.__all__)
                })
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                bus.showError(errors.__all__)
            })
        },
        showSendLinkModal() {
            this.$refs.sendLinkModal.open()
        },
        editMobileDeviceNickname(device) {
            this.currentMobileDevice = device
            this.$nextTick(() => {
                this.$refs.mobileDeviceNicknameModal.open()
            })
        },
        onMobileDevicesUpdated(mobile_devices) {
            this.mobile_devices = mobile_devices
        },
        deauthorizeDevice(device) {
          if (! confirm('Removing this device will turn off 1-button verification on that device.\n\n' + 'The next time you log in, we will confirm your identity via email or another Trusted Device (if you have one).\n\n' + 'Do you want to proceed?')) {
            return;
          }

          this.$store.dispatch('START_LOADING')
          api.post(`/mobile-device/${device.id}/deauthorize`, {}).then((data) => {
              this.$store.dispatch('STOP_LOADING')
              this.mobile_devices = data['mobile_devices']
              bus.showInfo('Trusted Device cleared.')
          }).catch((errors) => {
              this.$store.dispatch('STOP_LOADING')
              bus.showError(errors.__all__)
          })
        },
    }
}
</script>

<style>
.device-tile {
    border-radius: 12px !important;
}
</style>
