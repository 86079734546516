<template>
    <modal size="xl" @close="$emit('close')" ref="modal" :is-closeable="isCloseable" :autoshow="true">
        <template slot="title">
            <span v-if="reimbursementType">Update Reimbursement Type: </span><span v-if="reimbursementType" class="text-thin text-nowrap">{{ reimbursementType.name }}</span>
            <span v-if="!reimbursementType">Add Reimbursement Type</span>
        </template>
        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <div class="form-row mb-2">
                    <div class="col-6">
                        <form-input v-model="formData.starts_on" :errors="errors.starts_on" type="date" label="Starts On" />
                    </div>
                    <div class="col-6">
                        <form-input v-model="formData.inactive_on" :errors="errors.inactive_on" type="date" label="Expires On" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col">
                        <form-input v-model="formData.name" :errors="errors.name" type="text" label="Name" :readonly="!!reimbursementType" />
                    </div>
                </div>
            </form>
        </slot>

        <template slot="footer">
            <form-errors :errors="formErrors"/>

            <button-row>
                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['reimbursementType', ],
    mixins: [FormMixin, ModalMixin, ],
    data() {
        return {
            formData: this.makeFormData(),
        }
    },
    watch: {
    },
    methods: {
        makeFormData() {
            if (this.reimbursementType) {
                return {
                    name: this.reimbursementType.name,
                    starts_on: this.reimbursementType.current_version.starts_on,
                    inactive_on: this.reimbursementType.current_version.inactive_on,
                }
            } else {
                return {
                    name: '',
                    starts_on: '',
                    inactive_on: '',
                }
            }
        },
        getFormData() {
            return this.formData
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let promise
            if (this.reimbursementType) {
                promise = this.$api.put(`/admin/tax-engine/reimbursement-types/${this.reimbursementType.id}`, this.getFormData())
            }
            else {
                promise = this.$api.post(`/admin/tax-engine/reimbursement-types`, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>
