<template>
    <modal @close="reset(); $emit('close')" ref="modal">
        <template slot="title">
            Unlock &amp; Restore Access
        </template>
        <template slot="subheading">
            <div class="mt-hf bg-150 p-1 rounded mb-1">
                <span class="text-muted">User:</span> {{ user.email }}
            </div>
        </template>
        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <div v-if="!recoveryWord">
                    <p>
                        Verify this user's identity by asking them to repeat their secret recovery word.
                    </p>
                    <p class="mb-4">
                        Click below to see the word. <strong>Clicking this button will log your attempt to view the recovery word.</strong>
                    </p>
                    <button type="button" class="btn btn-outline-primary btn-block" @click.prevent="getRecoveryWord()">Reveal Recovery Word</button>
                </div>

                <div v-if="recoveryWord">
                    <p>
                        Confirm that the user has said this recovery word:
                    </p>
                    <p class="text-large text-center">
                        <code>{{ recoveryWord }}</code>
                    </p>
                    <div class="row mt-4">
                        <div class="col-12">
                            <button type="submit" class="btn btn-primary btn-block mb-1">Recovery Word is Correct</button>
                        </div>
                        <div class="col-12">
                            <button type="button" class="btn btn-outline-primary btn-block mb-1" @click.prevent="close">Recovery Word is Not Correct</button>
                        </div>
                    </div>
                </div>
            </form>
        </slot>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import FormMixin from '@/mixins/Form'

export default {
    mixins: [ModalMixin, FormMixin],
    props: ['user', 'client'],
    data() {
        return {
            recoveryWord: '',
        }
    },
    methods: {
        getRecoveryWord() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${this.client.id}/users/${this.user.id}/recovery-word`).then(resp => {
                this.recoveryWord = resp
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            if (!confirm("Are you sure?")) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/users/${this.user.id}/unlock`, {}).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('unlocked')
                this.reset()
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.reset()
                this.$bus.showError(errors.__all__)
            })
        },
        reset() {
            this.recoveryWord = ''
        },
    },
}
</script>
