<template>
<div v-if="dataLoaded && employee">
    <div class="flex-body body-scroll">
        <div class="container-fluid">
            <div class="container-centered-m py-4">
                <info-widget>
                    <template slot="title">
                        Personal Information
                    </template>
                    <template slot="headerbutton">
                        <button v-if="company.has_enhanced_ess" class="btn btn-outline-primary" type="button" @click.prevent="editIdentity">
                            <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                            Edit
                        </button>
                    </template>
                    <slot>
                        <div class="card-body py-2">
                            <div class="mui-textfield">
                                <span class="textfield">{{ employee.first_name }} {{ employee.middle_name }} {{ employee.last_name }} {{ employee.suffix }}</span>
                                <label>Name</label>
                            </div>

                            <div class="mui-textfield">
                                <span v-if="employee.preferred_name" class="textfield">{{ employee.preferred_name }}</span>
                                <span v-else class="textfield">{{ employee.first_name }} {{ employee.last_name }}</span>
                                <label>Display Name</label>
                            </div>

                            <div class="mui-textfield">
                                <span class="textfield">{{ employee.dob|date }}</span>
                                <label>Date of Birth</label>
                            </div>

                            <div class="mui-textfield">
                                <span class="textfield">XXX-XX-{{ employee.ssn_last_4 }}</span>
                                <label>Social Security Number</label>
                            </div>

                            <div class="mui-textfield">
                                <span class="textfield">{{ employee.phone_home_formatted }}</span>
                                <label>Phone Number</label>
                            </div>
                        </div>
                    </slot>
                </info-widget>

                <info-widget>
                    <template slot="title">
                        Address
                    </template>
                    <template slot="headerbutton">
                        <button v-if="company.has_enhanced_ess" class="btn btn-outline-primary" type="button" @click.prevent="editAddress">
                            <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                            Edit
                        </button>
                    </template>
                    <slot>
                        <div class="card-body py-2">
                            <div class="mui-textfield">
                                <span class="textfield">
                                    {{ employee.address_1 }}
                                    <br />
                                    {{ employee.address_2 }}
                                    <br v-if="employee.address_2" />
                                    {{ employee.city }}, {{ employee.state }} {{ employee.postal_code }}
                                </span>
                                <label>Residential Address</label>
                            </div>

                            <div class="mui-textfield">
                                <span v-if="employee.mailing_address_1" class="textfield">
                                    {{ employee.mailing_address_1 }}
                                    <br />
                                    {{ employee.mailing_address_2 }}
                                    <br v-if="employee.mailing_address_2" />
                                    {{ employee.mailing_city }}, {{ employee.mailing_state }} {{ employee.mailing_postal_code }}
                                </span>
                                <span v-else>Same as above</span>
                                <label>Mailing Address</label>
                            </div>
                        </div>
                    </slot>
                </info-widget>

                <info-widget v-for="contact in employee.emergency_contacts" :key=contact.id>
                    <template slot="title">Emergency Contact {{ contact.idx }}</template>
                    <template slot="headerbutton">
                        <a href v-if="company.has_enhanced_ess && employee.emergency_contacts.length > 1" class="btn btn-link px-2" type="button" @click.prevent="removeEmergencyContact(contact)">
                            Remove
                        </a>
                        <button v-if="company.has_enhanced_ess" class="btn btn-outline-primary" type="button" @click.stop.prevent="editEmergencyContact(contact)">
                            <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                            Edit
                        </button>
                    </template>
                    <slot>
                        <div class="card-body py-2">
                            <div class="mui-textfield">
                                <span class="textfield">{{ contact.first_name }} {{contact.last_name }}</span>
                                <label>Name</label>
                            </div>

                            <div class="mui-textfield">
                                <span class="textfield">{{ contact.relationship }}</span>
                                <label>Relationship</label>
                            </div>

                            <div class="mui-textfield">
                                <span class="textfield">{{ contact.phone_formatted}}</span>
                                <label>Phone</label>
                            </div>

                            <div class="mui-textfield">
                                <span class="textfield">{{ contact.email }}</span>
                                <label>Email</label>
                            </div>
                        </div>
                    </slot>
                </info-widget>
                <p class="mb-2">
                    <a href v-if="company.has_enhanced_ess" class="btn btn-link" @click.prevent="addEmergencyContact()"><i class="far fa-plus mr-hf"></i>Add Emergency Contact</a>
                </p>
                <identity-modal :employee="employee" @updated="onIdentityUpdate" ref="identityModal" v-if="identityModalOpen" @close="identityModalOpen = false"></identity-modal>
                <address-modal :employee="employee" @updated="onAddressUpdate" ref="addressModal" v-if="addressModalOpen" @close="addressModalOpen = false"></address-modal>
                <emergency-contact-modal :employee="employee" :contact="contactToEdit" @updated="onEmergencyContactUpdate" ref="emergencyContactModal" v-if="emergencyContactModalOpen" @close="emergencyContactModalOpen = false"></emergency-contact-modal>
            </div>
        </div>
    </div>
</div>
</template>


<script>

import InfoWidget from '@/components/InfoWidget'
import IdentityModal from './components/EmployeeIdentityEditModal.vue'
import AddressModal from './components/EmployeeAddressEditModal.vue'
import EmergencyContactModal from './components/EmergencyContactEditModal.vue'

export default {
    props: ['company'],
    components: {InfoWidget, IdentityModal, AddressModal, EmergencyContactModal, },
    computed: {},
    data() {
        return {
            employee: null,
            dataLoaded: false,
            contactToEdit: null,
            emergencyContactModalOpen: false,
            identityModalOpen: false,
            addressModalOpen: false,
        }
    },
    mounted() {
        this.getData()
        if (this.$route.query.editAddress) { this.addressModalOpen = true }
    },
    methods: {
        getData(silent) {
            if(!silent) {
                this.dataLoaded = false
                this.$store.dispatch('START_LOADING')
            }
            this.$api.get(`/me/ess/companies/${this.company.id}/personal-info`).then(resp => {
                this.employee = resp
                this.dataLoaded = true
                if (!silent) { this.$store.dispatch('STOP_LOADING') }
            }).catch((errors) => {
                if (!silent) { this.$store.dispatch('STOP_LOADING') }
                this.$bus.showError(errors.__all__)
            })
        },
        editIdentity() {
            this.getData(true)
            this.identityModalOpen = true
        },
        onIdentityUpdate() {
            this.identityModalOpen = false
            this.getData()
        },
        editAddress() {
            this.getData(true)
            this.addressModalOpen = true
        },
        onAddressUpdate() {
            this.addressModalOpen = false
            this.getData()
        },
        addEmergencyContact() {
            this.contactToEdit = null
            this.emergencyContactModalOpen = true
        },
        removeEmergencyContact(contact) {
            if (!confirm('Are you sure you want to remove this contact?')) {
                return
            }
            this.$store.dispatch('START_LOADING')
            this.$api.del(`/me/ess/companies/${this.company.id}/emergency-contacts/${contact.id}`).then((resp) => {
                this.getData()
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        editEmergencyContact(contact) {
            this.contactToEdit = contact
            this.emergencyContactModalOpen = true
        },
        onEmergencyContactUpdate() {
            this.emergencyContactModalOpen = false
            this.contactToEdit = null
            this.getData()
        },
    },
}

</script>
