<template>
    <page>
        <fast-table
            v-show="dataLoaded"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="currentPaygroup = $event"
        >

            <template slot="table-actions">
                <a class="btn btn-primary btn-collapse mr-2" :href="exportFileURL">
                    <i class="far fa-fw fa-file-excel"></i> <span class="btn-text">Export</span>
                </a>

                <button type="button" class="btn btn-primary btn-collapse mr-2" @click.prevent="$refs.importFileModal.open()">
                    <i class="far fa-fw fa-file-excel"></i> <span class="btn-text">Import</span>
                </button>
            </template>

        </fast-table>

        <import-file-modal :client="client" :import-file-type="'paygroups'" @updated="getData()" ref="importFileModal" />
    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import ImportFileModal from './components/ImportFileModal'

export default {
    components: {Page, FastTable, ImportFileModal, },
    props: ['client', ],
    computed: {
        tableData() {
            const tableData = []

            this.paygroups.forEach((o, idx) => {
                const row = {
                    id: idx,
                    isActive: this.currentPaygroup && o.id == this.currentPaygroup.id,
                    object: o,
                    cells: [
                        o.Name,
                        o.PaySchedule,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
        exportFileURL() {
            return `${this.$api.API_BASE}/admin/clients/${this.client.id}/config/paygroups/export`
        }
    },
    data() {
        return {
            paygroups: [],
            dataLoaded: false,
            currentPaygroup: null,

            headers: [
                {label: 'Name', classes: '', isSearchable: true},
                {label: 'Pay Schedule', classes: '', isSearchable: true, isFilterable: true},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', `Client ${this.client.client_code} - ${this.client.name} / Paygroups`)
        this.getData()
    },
    methods: {
        getData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/clients/${this.client.id}/config/paygroups`).then((resp) => {
                this.paygroups = resp['data']
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
    },
}

</script>
