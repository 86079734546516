<template>
<section class="item-details-section">
    <div>
        <span class="text-semibold">Date range: </span>
        <span>{{ version.starts_on|date("MM/DD/YYYY") }}</span>
        <span v-if="version.ends_on"> - {{ version.ends_on|date("MM/DD/YYYY") }}</span>
        <span v-else>+</span>
    </div>

    <table class="mt-1 ml-0 table">
        <thead>
            <tr>
                <th class="pl-0">Start</th>
                <th v-if="hasRate">Rate</th>
                <th v-if="hasNonResidentRate">NR. Rate</th>
                <th v-if="hasEmployerRate">Emp. Rate</th>
                <th v-if="hasFlatRate">Flat Amount</th>
                <th v-if="hasNonResidentFlatRate">NR. Flat Amount</th>
                <th v-if="hasEmployerFlatRate">Emp. Flat Amount</th>
                <th v-if="hasBracketAdjustment">Bracket Adj.</th>
            </tr>
        </thead>

        <tbody>
            <tr v-for="line in version.tax_table_lines">
                <td class="pl-0">${{ line.start|formatCurrency }}</td>
                <td v-if="hasRate">{{ line.rate|formatPercentage }}%{{ line.is_flat_rate ? "*" : '' }}</td>
                <td v-if="hasNonResidentRate">{{ line.non_resident_rate|formatPercentage }}%</td>
                <td v-if="hasEmployerRate">{{ line.employer_rate|formatPercentage }}%</td>
                <td v-if="hasFlatRate">${{ line.flat_amount|formatCurrency }}</td>
                <td v-if="hasNonResidentFlatRate">${{ line.non_resident_flat_amount|formatCurrency }}</td>
                <td v-if="hasEmployerFlatRate">${{ line.employer_flat_amount|formatCurrency }}</td>
                <td v-if="hasBracketAdjustment">${{ line.bracket_adjustment|formatCurrency }}</td>
            </tr>
        </tbody>
    </table>

    <p v-if="version.tax_table_lines.find(l => l.is_flat_rate)">* Indicates a flat rate line.</p>
</section>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'
export default {
    components: {InfoWidget, },
    props: ['version', ],
    computed: {
        hasRate() {
            return this.version.tax_table_lines.find(l => l.rate != '0.000000') ? true : false
        },
        hasNonResidentRate() {
            return this.version.tax_table_lines.find(l => l.non_resident_rate !== null) ? true : false
        },
        hasEmployerRate() {
            return this.version.tax_table_lines.find(l => l.employer_rate != '0.000000') ? true : false
        },
        hasFlatRate() {
            return this.version.tax_table_lines.find(l => l.flat_amount != '0.00') ? true : false
        },
        hasNonResidentFlatRate() {
            return this.version.tax_table_lines.find(l => l.non_resident_flat_amount !== null) ? true : false
        },
        hasEmployerFlatRate() {
            return this.version.tax_table_lines.find(l => l.employer_flat_amount != '0.00') ? true : false
        },
        hasBracketAdjustment() {
            return this.version.tax_table_lines.find(l => l.bracket_adjustment != '0.00') ? true : false
        },
    },
    data() {
        return {
            isExpanded: false,
        }
    },
    filters: {
        formatPercentage: (p) => {
            p = '' + p
            p = p.replace(/[0]*$/, '')
            p = p.replace(/[.]*$/, '')  // Make "0." become "0"
            return p
        },
    },
}
</script>
