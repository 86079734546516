import Vue from 'vue'
import toastr from 'toastr'
import openSocket from 'socket.io-client'
import mixpanel from 'mixpanel-browser'

const UPDATES_API_BASE = process.env.VUE_APP_UPDATES_API_BASE

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-top-right",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

export default new Vue({
    data() {
        return {
            updatesSocket: null,
            clientSocketConnected: false,
            clientRoomJoined: false,
            updatesClientId: null,
        }
    },
    methods: {
        noAccess() {
            this.$emit('no-access')
        },
        loggedOut() {
            this.$emit('logged-out')
        },
        onNavigation() {
            this.$emit('navigation')
        },
        showError(title, text) {
            if (title instanceof Array) {
                title.forEach(t => {
                    this.showError(t)
                })

                return
            }

            text = text ? text.trim() : ''
            title = title ? title.trim() : ''

            if (title && text) {
                toastr.error(text, title)
            }
            else if (title) {
                toastr.error(title)
            }
        },
        showInfo(title, text) {
            if (title instanceof Array) {
                title.forEach(t => {
                    this.showInfo(t)
                })

                return
            }

            text = text ? text.trim() : ''
            title = title ? title.trim() : ''

            if (title && text) {
                toastr.success(text, title)
            }
            else if (title) {
                toastr.success(title)
            }
        },
        showUpdatePrompt() {
            let title = "Please refresh"
            let text = "Your website version is a little out of date. Click here to refresh."

            toastr.info(text, title, {onclick: function() { window.location.reload() }})
        },
        connectUpdatesAPI() {
            if (!UPDATES_API_BASE) {return}
            if (!this.updatesSocket) {
                this.clientSocketConnected = false
                this.updatesSocket = openSocket(UPDATES_API_BASE)
                this.updatesSocket.on('connect', () => {
                    this.clientSocketConnected = true
                })

                this.updatesSocket.off('updated')
                this.updatesSocket.on('updated', (msg, cb) => {
                    this.$emit('client_data_updated', msg)
                    if (cb) {cb()}
                })
            }
        },
        disconnectUpdatesAPI() {
            if (!UPDATES_API_BASE) {return}
            if (this.updatesSocket) {
                this.updatesSocket.close()
                this.updatesSocket = null
            }
        },
        subscribeToClientUpdates(clientId) {
            if (!UPDATES_API_BASE) {return}
            clientId = `${process.env.VUE_APP_UPDATES_API_REALM}-${clientId}`
            if ((this.updatesClientId == clientId) && this.clientRoomJoined) {
                return
            }

            this.updatesClientId = clientId

            this.connectUpdatesAPI()
            if (this.clientSocketConnected) {
                this.updatesSocket.emit('join', {client_id: this.updatesClientId})
                this.clientRoomJoined = true
            }
            else {
                this.updatesSocket.on('connect', () => {
                    this.updatesSocket.emit('join', {client_id: this.updatesClientId})
                    this.clientRoomJoined = true
                })
            }
        },
        unsubscribeFromClientUpdates() {
            if (!UPDATES_API_BASE) {return}
            if (this.updatesSocket) {
                this.updatesSocket.emit('leave', {client_id: this.updatesClientId})
            }
        },
        trackEvent(event, eventData={}) {
            if (process.env.VUE_APP_MIXPANEL_ENABLED) {
                try {
                    mixpanel.track(event, eventData)
                }
                catch (ex) {
                    // ignore
                }
            }
        }
    },
})

