<template>
<section class="item-details-section">
    <div>
        <span class="text-semibold">Date range: </span>
        <span>{{ version.starts_on|date("MM/DD/YYYY") }}</span>
        <span v-if="version.ends_on"> - {{ version.ends_on|date("MM/DD/YYYY") }}</span>
        <span v-else>+</span>
    </div>

    <div>
        <span class="text-semibold">Status: </span>
        <span>{{ status }}</span>
    </div>

    <div>
        <span class="text-semibold">Annual Exemption Limit: </span>
        <span>${{ version.exemption_limit|formatCurrency }}</span>
    </div>

    <div>
        <span class="text-semibold">Monthly Exemption: </span>
        <span>${{ version.monthly_exemption_limit|formatCurrency }} {{ version.is_credit ? ' (Credit)' : '' }}</span>
    </div>

</section>
</template>

<script>
import InfoWidget from '@/components/InfoWidget'
export default {
    props: ['version', ],
    components: {InfoWidget, },
    computed: {
        status() {
            const STATUSES = {
                'taxable': 'Taxable',
                'subject': 'Subject',
                'exempt': 'Exempt',
            }
            return STATUSES[this.version.status] ? STATUSES[this.version.status] : this.version.status
        },
    },
    data() {
        return {
            isExpanded: false,
        }
    },
}
</script>
