<template>
<div>
<sidebar-widget
    @add="modalOpen = true"
    @delete="onDelete($event)"
    @edit="currentRowId = $event; modalOpen = true"
    @versions="currentRowId = $event; versionsModalIsOpen = true"
    :rows="rows"
    :headers="['Filing Status']"
>
    <template slot="title">Percent Personal Tax Credit</template>

    <template slot="row-details" scope="props">
        <percent-personal-tax-credit-version :version="props.row.current_version" />
    </template>
</sidebar-widget>

<add-edit-modal
    v-if="modalOpen"
    :tax="tax"
    :table="currentRow"
    @updated="$emit('updated')"
    @close="modalOpen = false; currentRowId = null"
/>

<versions-modal
    v-if="versionsModalIsOpen"
    :url="getURL(currentRowId)"
    @close="currentRowId = null; versionsModalIsOpen = false"
>
    <template slot="version" scope="props">
        <percent-personal-tax-credit-version :version="props.version" />
    </template>
</versions-modal>

</div>
</template>

<script>
import AddEditModal from './PercentPersonalTaxCreditTableModal'
import SidebarWidget from './SidebarWidget'
import PercentPersonalTaxCreditVersion from './PercentPersonalTaxCreditTableVersion'
import VersionsModal from './VersionsModal'

export default {
    props: ['tax', ],
    components: {AddEditModal, SidebarWidget, PercentPersonalTaxCreditVersion, VersionsModal, },
    computed: {
        currentRow() {
            return this.tax.percent_personal_tax_credit_tables.find(x => x.id == this.currentRowId)
        },
        rows() {
            const result = []
            this.tax.percent_personal_tax_credit_tables.forEach(row => {
                row = JSON.parse(JSON.stringify(row))
                row.cells = [
                    row.filing_status ? row.filing_status.name : 'N/A'
                ]
                result.push(row)
            })
            return result
        },
    },
    data() {
        return {
            modalOpen: false,
            versionsModalIsOpen: false,
            currentRowId: null,
        }
    },
    methods: {
        getURL(rowId) {
            if (!rowId) {return}
            return `/admin/tax-engine/taxes/${this.tax.id}/percent-personal-tax-credit-tables/${rowId}`
        },
        onDelete(rowId) {
            if (!confirm("Are you sure?")) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.del(this.getURL(rowId)).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>



