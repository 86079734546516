<template>
    <div class="flex-container flex-body">
        <div class="py-1">
            Select documents to include in this template. (Documents can be included in multiple templates.)
        </div>

        <!-- Tab links -->
        <div class="nav-tabs-container d-md-none">
            <div class="nav-tabs-scroll-helper">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link active" href="#tabsHrTstep2content1" id="tabsHrTstep2link1" data-toggle="tab" role="tab" aria-controls="tabsHrTstep2content1" aria-selected="true">
                            Available ({{ allDocuments.length }})
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#tabsHrTstep2content2" id="tabsHrTstep2link2" data-toggle="tab" role="tab" aria-controls="tabsHrTstep2content2" aria-selected="false">
                            Selected ({{ selectedDocuments.length }})
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="d-flex flex-column flex-grow-1">
            <!-- Tabs container -->
            <div class="tab-content tab-content-flex-md row flex-grow-1 mb-2">
                <div class="tab-pane col-12 col-md-7 flex-column active" id="tabsHrTstep2content1" role="tabpanel" aria-labelledby="tabsHrTstep2link1">

                    <!-- Nested vertical flexbox component (left) -->

                    <!-- Standalone container -->
                    <div class="flex-grow-1 d-flex flex-column">

                        <h5 class="d-none d-md-block pt-1">Available <span class="text-regular">({{ allDocuments.length }})</span></h5>

                        <!-- Card outer -->
                        <div class="card card-body flex-grow-1 d-flex flex-column p-0 mt-1 mt-md-0">
                            <!-- Sticky-top search bar -->
                            <div class="flex-grow-0 flex-shrink-0 d-flex p-1">
                                <page-search v-model="search" />
                            </div>

                            <!-- Scrollable content container -->
                            <div class="flex-grow-1 overflow-scroll-y position-relative">
                                <!-- Inner scrolling content -->
                                <div class="flex-grow-1 w-100 px-1 position-absolute">

                                    <div class="py-hf" v-for="doc in searchedDocuments" :key="doc.doc_key">
                                        <div class="form-check">
                                            <label class="form-check-label pl-qt" :for="'available-document-' + doc.doc_key">
                                                <input type="checkbox" class="form-check-input" :id="'available-document-' + doc.doc_key" :checked="checkedDocuments.indexOf(doc.doc_key) >= 0" @input="onInput(doc.doc_key)">
                                                {{ doc.name }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Sticky-bottom 'select all' button -->
                        <div class="flex-grow-0 pt-1">
                            <button class="btn btn-outline-primary btn-block" @click.prevent="selectAll()">
                                Select All ({{ allDocuments.length }})
                            </button>
                        </div>
                    </div>
                    <!-- /(left) -->
                </div>

                <div class="tab-pane col-12 col-md-5 flex-column" id="tabsHrTstep2content2" role="tabpanel" aria-labelledby="tabsHrTstep2link2">

                    <!-- Nested vertical flexbox component (right) -->

                    <!-- Standalone container -->
                    <div class="flex-grow-1 d-flex flex-column">
                        <h5 class="d-none d-md-block pt-1">Selected <span class="text-regular">({{ selectedDocuments.length }})</span></h5>

                        <!-- Card outer -->
                        <div class="card card-body d-flex flex-column bg-150 p-0 overflow-scroll-y pos-relative">

                            <!-- When empty, use this instead of the scrollable content container -->
                            <div class="px-4 align-self-center my-auto" v-if="!selectedDocuments.length">
                                <div class="text-center text-muted">
                                    <em>Select items under Available to add them here.</em>
                                </div>
                            </div>

                            <div class="flex-grow-1 w-100 px-1 py-hf pos-absolute" v-if="selectedDocuments.length">
                                <div class="list-item py-hf cell-truncate" v-for="doc in selectedDocuments" :key="doc.doc_key">
                                    <div class="form-check">
                                        <label class="form-check-label pl-qt" :for="'selected-document-' + doc.doc_key">
                                            <input type="checkbox" class="form-check-input" checked :id="'selected-document-' + doc.doc_key" @input="onInput(doc.doc_key)">
                                            {{ doc.name }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- /(right) -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as JsSearch from 'js-search'

export default {
    props: ['allDocuments', 'value', ],
    computed: {
        searchIndex() {
            const searchIndex = new JsSearch.Search('id')
            searchIndex.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
            searchIndex.addIndex('name')

            searchIndex.addDocuments(this.allDocuments || [])
            return searchIndex
        },
        selectedDocuments() {
            return this.allDocuments.filter(d => {return this.checkedDocuments.indexOf(d.doc_key) >= 0})
        },
        searchedDocuments() {
            if (!this.search.trim()) {
                return this.allDocuments
            }
            return this.searchIndex.search(this.search.trim())
        },
    },
    data() {
        return this.makeData()
    },
    methods: {
        selectAll() {
            this.checkedDocuments.splice(0, this.checkedDocuments.length)
            this.allDocuments.forEach(d => {
                this.checkedDocuments.push(d.doc_key)
            })
            this.$nextTick(() => {
                this.$emit('input', this.checkedDocuments)
            })
        },
        makeData() {
            const data = {
                search: '',
                checkedDocuments: [],
            }

            this.value.forEach(doc_key => {
                data.checkedDocuments.push(doc_key)
            })

            return data
        },
        reset() {
            const data = this.makeData()
            this.search = data.search
            this.checkedDocuments = data.checkedDocuments
        },
        onInput(doc_key) {
            const idx = this.checkedDocuments.indexOf(doc_key)
            if (idx >= 0) {
                this.checkedDocuments.splice(idx, 1)
            }
            else {
                this.checkedDocuments.push(doc_key)
            }

            this.$nextTick(() => {
                this.$emit('input', this.checkedDocuments)
            })
        },
    },
}
</script>
