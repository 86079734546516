<template>
    <div>
        <div class="mb-4" v-if="onboardingApplication.company.feature_i9_document_photos && step == 'alert'">
            <h3 class="mb-4">I-9 Employment Eligibility Verification</h3>

            <alert-box type="info">
                <p class="mb-1">
                    In this section, you <span v-if="onboardingApplication.company.i9_document_photos_ee_required">will need to</span> <span v-if="!onboardingApplication.company.i9_document_photos_ee_required">may</span> <strong>submit photos of your verification documents.</strong>
                </p>
                <p class="mb-0">
                    Have your documents ready before clicking Continue.
                </p>
            </alert-box>
        </div>

        <div class="mb-4" v-if="onboardingApplication.company.feature_i9_document_photos && step == 'info'">
            <h3 class="mb-4">I-9 Employment Eligibility Verification</h3>

            <p>
                Form I-9 verifies your eligibility to work in the United States. First, fill out the form on the next page. Then, <span v-if="!onboardingApplication.company.i9_document_photos_ee_required">you may</span> submit photos of 1 or more acceptable documents that demonstrate your eligibility.
            </p>
            <p>
                Review Page 3 of the <a target="_blank" href="https://www.uscis.gov/sites/default/files/document/forms/i-9instr.pdf">Form I-9 instructions (PDF)</a> for a list of all acceptable documents.
            </p>
            <p>
                <strong>You will still need to bring your documents</strong> on your first day of work.
            </p>
        </div>

        <div class="mb-4" v-if="step == 'survey'">
            <survey
                :yaml="form.yaml"
                :knowndata="knownData"
                @error="onPDFFormError"
                ref="survey"
                :hidesubmit="true"
                :pdf-url="form.instructions_pdf_file_url || form.pdf_file_url"
            />

            <form-errors :errors="formErrors" />
        </div>

        <div class="mb-4" v-if="onboardingApplication.company.feature_i9_document_photos && step == 'list-a'">
            <h3>I-9 Documents: List A</h3>

            <div class="mt-4">
                <div class="mb-2">
                    <p>The following documents, from I-9 List A, demonstrate both identity and eligibility to work.</p>
                    <form-input type="radio" label="Do you have one of the following documents?" :options="listAOptions" v-model="listADocSlug" />
                </div>

                <hr v-if="formErrors.length" />
                <form-errors :errors="formErrors" />
            </div>
        </div>

        <div class="mb-4" v-if="onboardingApplication.company.feature_i9_document_photos && step == 'list-a-1'">
            <h3>I-9 Documents: List A</h3>

            <div class="mt-4">
                <div class="mb-2">
                    <p>
                        Along with the document you selected, you are required to show one or more 
                        additional documents.
                    </p>
                    <form-input type="radio" label="Select the additional document that you have:" :options="listASecondaryOptions" v-model="listASecondaryDocSlug" />
                </div>

                <hr v-if="formErrors.length" />
                <form-errors :errors="formErrors" />
            </div>
        </div>

        <div class="mb-4" v-if="onboardingApplication.company.feature_i9_document_photos && step == 'list-a-2'">
            <h3>I-9 Documents: List A</h3>

            <div class="mt-4">
                <div class="mb-2">
                    <p>
                        Along with the document you selected, you are required to show one or more 
                        additional documents.
                    </p>
                    <form-input type="radio" label="Select the additional document that you have:" :options="listATertiaryOptions" v-model="listATertiaryDocSlug" />
                </div>

                <hr v-if="formErrors.length" />
                <form-errors :errors="formErrors" />
            </div>
        </div>

        <div class="mb-4" v-if="onboardingApplication.company.feature_i9_document_photos && step == 'list-bc'">
            <h3>I-9 Documents: Lists B and C</h3>

            <div class="mt-4">
                <div class="mb-2">
                    <p>
                        Documents from I-9 List B demonstrate your identity. Documents from I-9 List C demonstrate your eligibility to work.
                    </p>
                    <p class="text-semibold">
                        Select a document from List B that you have:
                    </p>
                    <form-input type="select" label="List B Document" :options="listBOptions" v-model="listBDocSlug" />
                    <p class="text-semibold pt-2">
                        Select a document from List C that you have:
                    </p>
                    <form-input type="select" label="List C Document" :options="listCOptions" v-model="listCDocSlug" />

                </div>

                <hr v-if="formErrors.length" />
                <form-errors :errors="formErrors" />
            </div>
        </div>

        <div class="mb-4" v-if="onboardingApplication.company.feature_i9_document_photos && step == 'list-a-upload'">
            <i9-upload
                :key="listADocSlug"
                :doc="listADoc"
                :form="form"
                list="list-a"
                @close="back"
                @uploads="onDocUpload('list-a', $event)"
            />
        </div>

        <div class="mb-4" v-if="onboardingApplication.company.feature_i9_document_photos && step == 'list-a-1-upload'">
            <i9-upload
                :key="listASecondaryDocSlug"
                :doc="listASecondaryDoc"
                :form="form"
                list="list-a-1"
                @close="back"
                @uploads="onDocUpload('list-a-1', $event)"
            />
        </div>

        <div class="mb-4" v-if="onboardingApplication.company.feature_i9_document_photos && step == 'list-a-2-upload'">
            <i9-upload
                :key="listATertiaryDocSlug"
                :doc="listATertiaryDoc"
                :form="form"
                list="list-a-2"
                @close="back"
                @uploads="onDocUpload('list-a-2', $event)"
            />
        </div>

        <div class="mb-4" v-if="onboardingApplication.company.feature_i9_document_photos && step == 'list-b-upload'">
            <i9-upload
                :key="listBDocSlug"
                :doc="listBDoc"
                :form="form"
                list="list-b"
                @close="back"
                @uploads="onDocUpload('list-b', $event)"
            />
        </div>

        <div class="mb-4" v-if="onboardingApplication.company.feature_i9_document_photos && step == 'list-c-upload'">
            <i9-upload
                :key="listCDocSlug"
                :doc="listCDoc"
                :form="form"
                list="list-c"
                @close="back"
                @uploads="onDocUpload('list-c', $event)"
            />
        </div>

        <div class="mb-4" v-if="onboardingApplication.company.feature_i9_document_photos && step == 'review'">

            <h3>I-9 Documents: Review</h3>

            <div class="mt-4">
                <div class="mb-2">
                    <p><i class="fas fa-check text-primary"></i>&nbsp; Your document photos are added.</p>

                    <alert-box type="info" class="mb-2">
                        Reminder: <strong>You must still bring these documents</strong> on your first day of work.
                    </alert-box>

                    <div class="card card-body p-1 my-1" v-if="listADoc">
                        <div class="d-flex mb-1">
                            <span class="flex-grow-1 text-bold">
                                {{ listADoc.name }}
                            </span>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <div class="docphoto-square">
                                    <div class="docphoto-square-content">
                                        <img :src="previewURLs.listAPhotos.front" alt="Document Photo" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-6" v-if="previewURLs.listAPhotos.back">
                                <div class="docphoto-square">
                                    <div class="docphoto-square-content">
                                        <img :src="previewURLs.listAPhotos.back" alt="Document Photo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card card-body p-1 my-1" v-if="listASecondaryDoc">
                        <div class="d-flex mb-1">
                            <span class="flex-grow-1 text-bold">
                                {{ listASecondaryDoc.name }}
                            </span>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <div class="docphoto-square">
                                    <div class="docphoto-square-content">
                                        <img :src="previewURLs.listASecondaryPhotos.front" alt="Document Photo" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-6" v-if="previewURLs.listASecondaryPhotos.back">
                                <div class="docphoto-square">
                                    <div class="docphoto-square-content">
                                        <img :src="previewURLs.listASecondaryPhotos.back" alt="Document Photo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card card-body p-1 my-1" v-if="listATertiaryDoc">
                        <div class="d-flex mb-1">
                            <span class="flex-grow-1 text-bold">
                                {{ listATertiaryDoc.name }}
                            </span>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <div class="docphoto-square">
                                    <div class="docphoto-square-content">
                                        <img :src="previewURLs.listATertiaryPhotos.front" alt="Document Photo" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-6" v-if="previewURLs.listATertiaryPhotos.back">
                                <div class="docphoto-square">
                                    <div class="docphoto-square-content">
                                        <img :src="previewURLs.listATertiaryPhotos.back" alt="Document Photo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card card-body p-1 my-1" v-if="listBDoc">
                        <div class="d-flex mb-1">
                            <span class="flex-grow-1 text-bold">
                                {{ listBDoc.name }}
                            </span>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <div class="docphoto-square">
                                    <div class="docphoto-square-content">
                                        <img :src="previewURLs.listBPhotos.front" alt="Document Photo" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="docphoto-square" v-if="previewURLs.listBPhotos.back">
                                    <div class="docphoto-square-content">
                                        <img :src="previewURLs.listBPhotos.back" alt="Document Photo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card card-body p-1 my-1" v-if="listCDoc">
                        <div class="d-flex mb-1">
                            <span class="flex-grow-1 text-bold">
                                {{ listCDoc.name }}
                            </span>
                        </div>
                        <div class="form-row">
                            <div class="col-6">
                                <div class="docphoto-square">
                                    <div class="docphoto-square-content">
                                        <img :src="previewURLs.listCPhotos.front" alt="Document Photo" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="docphoto-square" v-if="previewURLs.listCPhotos.back">
                                    <div class="docphoto-square-content">
                                        <img :src="previewURLs.listCPhotos.back" alt="Document Photo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p class="pt-2">
                        Next, review and sign the completed Form I-9.
                    </p>
                </div>

                <hr v-if="formErrors.length" />
                <form-errors :errors="formErrors"/>

            </div>
        </div>

        <button-row inline v-if="!['list-a-upload', 'list-a-1-upload', 'list-a-2-upload', 'list-b-upload', 'list-c-upload'].includes(this.step)">
            <a href @click.prevent="back">Back</a>
            <button class="btn btn-primary" @click.prevent="next">Continue</button>
        </button-row>

        <button-row inline v-if="!onboardingApplication.company.i9_document_photos_ee_required && ['list-a', 'list-a-1', 'list-a-2', 'list-bc', 'list-a-upload', 'list-a-1-upload', 'list-a-2-upload', 'list-b-upload', 'list-c-upload', 'review', ].includes(this.step)">
            <button class="btn btn-link mx-auto" @click.prevent="skip">Skip This Step</button>
        </button-row>

        <forms-pdf-modal
            v-if="currentPDF"
            :onboarding-application="onboardingApplication"
            :form="form"
            :pdf="currentPDF"
            :form-data="formData"
            :uploads="uploads"
            :known-data="knownData"
            :errors="serverFormErrors"
            :warnings="serverFormWarnings"
            @close="currentPDF = null"
            @updated="$emit('updated')"
        />

    </div>
</template>

<script>
import moment from 'moment'
import Survey from '@/components/PDFForm/Survey'
import PdfModal from '@/components/PdfModal'
import ButtonRow from '@/components/ButtonRow'
import i9Documents from '@/utils/i9'
import I9Upload from './I9Upload'
import YAML from 'js-yaml'
import FormsPdfModal from './FormsPDFModal'

export default {
    props: ['onboardingApplication', 'knownData', ],
    components: {Survey, PdfModal, ButtonRow, I9Upload, FormsPdfModal, },
    computed: {
        form() {
            return this.onboardingApplication.forms.find(f => f.form_designation == 'i9')
        },
        previewURLs() {
            return {
                listAPhotos: {
                    front: (this.listAPhotos && this.listAPhotos.front) ? this.makePreviewURL(this.listAPhotos.front) : '',
                    back: (this.listAPhotos && this.listAPhotos.back) ? this.makePreviewURL(this.listAPhotos.back) : '',
                },
                listASecondaryPhotos: {
                    front: (this.listASecondaryPhotos && this.listASecondaryPhotos.front) ? this.makePreviewURL(this.listASecondaryPhotos.front) : '',
                    back: (this.listASecondaryPhotos && this.listASecondaryPhotos.back) ? this.makePreviewURL(this.listASecondaryPhotos.back) : '',
                },
                listATertiaryPhotos: {
                    front: (this.listATertiaryPhotos && this.listATertiaryPhotos.front) ? this.makePreviewURL(this.listATertiaryPhotos.front) : '',
                    back: (this.listATertiaryPhotos && this.listATertiaryPhotos.back) ? this.makePreviewURL(this.listATertiaryPhotos.back) : '',
                },
                listBPhotos: {
                    front: (this.listBPhotos && this.listBPhotos.front) ? this.makePreviewURL(this.listBPhotos.front) : '',
                    back: (this.listBPhotos && this.listBPhotos.back) ? this.makePreviewURL(this.listBPhotos.back) : '',
                },
                listCPhotos: {
                    front: (this.listCPhotos && this.listCPhotos.front) ? this.makePreviewURL(this.listCPhotos.front) : '',
                    back: (this.listCPhotos && this.listCPhotos.back) ? this.makePreviewURL(this.listCPhotos.back) : '',
                },
            }
        },
        uploads() {
            const result = []

            const possiblePhotos = [
                this.listAPhotos,
                this.listASecondaryPhotos,
                this.listATertiaryPhotos,
                this.listBPhotos,
                this.listCPhotos,
            ]

            possiblePhotos.forEach(listPhotos => {
                if (!listPhotos) {return}
                result.push(listPhotos.front.id)
                if (listPhotos.back) {
                    result.push(listPhotos.back.id)
                }
            })

            return result
        },
        i9Documents() {return i9Documents},
        immigrationStatus() {
            if (!this.formData) {return ''}

            if (this.formData.designation__us_citizen) {
                return 'us_citizen'
            }
            else if (this.formData.designation__us_national) {
                return 'us_national'
            }
            else if (this.formData.designation__us_permanent_resident) {
                return 'us_permanent_resident'
            }
            else if (this.formData.designation__authorized_alien) {
                return 'authorized_alien'
            }
        },
        isAMinor() {
            if (!this.formData) {return true}  // Should not happen, but if it does, lets you use all documents
            return moment(this.formData.dob, 'MM/DD/YYYY').isAfter(moment().subtract(18, 'year').subtract(1, 'day').startOf('day'))
        },
        listADocs() {
            let docs = i9Documents.listA.filter(d => d.slug != 'list_a_na' && d.immigration_statuses.includes(this.immigrationStatus))
            if (!this.isAMinor) {
                docs = docs.filter(d => !d.is_under_18)
            }
            return docs
        },
        listBDocs() {
            let docs = i9Documents.listB.filter(d => d.slug != 'list_b_na' && d.immigration_statuses.includes(this.immigrationStatus))
            if (!this.isAMinor) {
                docs = docs.filter(d => !d.is_under_18)
            }
            return docs
        },
        listCDocs() {
            let docs = i9Documents.listC.filter(d => d.slug != 'list_c_na' && d.immigration_statuses.includes(this.immigrationStatus))
            if (!this.isAMinor) {
                docs = docs.filter(d => !d.is_under_18)
            }
            return docs
        },
        listAOptions() {
            const result = []
            this.listADocs.forEach(d => {
                result.push({
                    text: d.name,
                    value: d.slug,
                })
            })

            result.push({
                text: "I don't have any of these, or I want to use different documents",
                value: '',
            })

            return result
        },
        listASecondaryOptions() {
            const result = []
            if (this.listADoc && this.listADoc.secondary_documents) {
                this.listADoc.secondary_documents.forEach(d => {
                    result.push({
                        text: d.name,
                        value: d.slug,
                    })
                })
            }

            return result
        },
        listATertiaryOptions() {
            const result = []
            if (this.listADoc && this.listADoc.tertiary_documents) {
                this.listADoc.tertiary_documents.forEach(d => {
                    result.push({
                        text: d.name,
                        value: d.slug,
                    })
                })
            }

            return result
        },
        listBOptions() {
            const result = []
            this.listBDocs.forEach(d => {
                result.push({
                    text: d.name,
                    value: d.slug,
                })
            })

            return result
        },
        listCOptions() {
            const result = []
            this.listCDocs.forEach(d => {
                result.push({
                    text: d.name,
                    value: d.slug,
                })
            })

            return result
        },
        listADoc() {
            return this.listADocs.find(d => d.slug == this.listADocSlug)
        },
        listASecondaryDoc() {
            if (!this.listADoc || !this.listADoc.secondary_documents) {return null}
            return this.listADoc.secondary_documents.find(d => d.slug == this.listASecondaryDocSlug)
        },
        listATertiaryDoc() {
            if (!this.listADoc || !this.listADoc.tertiary_documents) {return null}
            if (['foreign_passport_work_auth_nonimmigrant_tertiary_na', 'receipt_fsm_passport_with_form_i94_na', 'receipt_rmi_passport_with_form_i94_na'].includes(this.listATertiaryDocSlug)) {
                return null
            }
            return this.listADoc.tertiary_documents.find(d => d.slug == this.listATertiaryDocSlug)
        },
        listBDoc() {
            return this.listBDocs.find(d => d.slug == this.listBDocSlug)
        },
        listCDoc() {
            return this.listCDocs.find(d => d.slug == this.listCDocSlug)
        },
    },
    data() {
        return {
            step: this.onboardingApplication.company.feature_i9_document_photos ? 'alert' : 'survey', // possible values: alert, info, survey, list-a, list-a-1, list-a-2, list-bc, list-a-upload, list-a-1-upload, list-a-2-upload, list-b-upload, list-c-upload
            formErrors: [],  // Global form errors
            formData: null,
            listADocSlug: null,
            listBDocSlug: null,
            listCDocSlug: null,
            listASecondaryDocSlug: null,
            listATertiaryDocSlug: null,

            listAPhotos: null,
            listASecondaryPhotos: null,
            listATertiaryPhotos: null,
            listBPhotos: null,
            listCPhotos: null,

            serverFormErrors: [],
            serverFormWarnings: [],

            currentPDF: null,
        }
    },
    methods: {
        next() {
            this.formErrors = []

            if (!this.onboardingApplication.company.feature_i9_document_photos) {
                if (this.onPDFFormSubmit()) {
                    this.onSubmit()
                }
                return
            }

            if (this.step == 'alert') {
                this.step = 'info'
            }
            else if (this.step == 'info') {
                this.step = 'survey'
            }
            else if (this.step == 'survey') {
                this.onPDFFormSubmit()
            }
            else if (this.step == 'list-a') {
                if (this.listADocSlug === null) {
                    this.formErrors.push('Please make a selection.')
                }
                else if (this.listADocSlug === '') {
                    this.step = 'list-bc'
                }
                else {
                    if (this.listASecondaryOptions.length) {
                        this.step = 'list-a-1'
                    }
                    else {
                        this.step = 'list-a-upload'
                    }
                }
            }
            else if (this.step == 'list-a-1') {
                if (this.listASecondaryDocSlug === null) {
                    this.formErrors.push('Please make a selection.')
                }
                else if (this.listATertiaryOptions.length) {
                    this.step = 'list-a-2'
                }
                else {
                    this.step = 'list-a-upload'
                }
            }
            else if (this.step == 'list-a-2') {
                if (this.listATertiaryDocSlug === null) {
                    this.formErrors.push('Please make a selection.')
                }
                else {
                    this.step = 'list-a-upload'
                }
            }
            else if (this.step == 'list-a-upload') {
                this.step = this.listASecondaryDocSlug ? 'list-a-1-upload' : 'review'
            }
            else if (this.step == 'list-a-1-upload') {
                this.step = this.listATertiaryDoc ? 'list-a-2-upload' : 'review'
            }
            else if (this.step == 'list-a-2-upload') {
                this.step = 'review'
            }
            else if (this.step == 'list-bc') {
                if ((this.listBDocSlug === null) || (this.listCDocSlug === null)) {
                    this.formErrors.push('Please make a selection.')
                }
                else {
                    this.step = 'list-b-upload'
                }
            }
            else if (this.step == 'list-b-upload') {
                this.step = 'list-c-upload'
            }
            else if (this.step == 'list-c-upload') {
                this.step = 'review'
            }
            else if (this.step == 'review') {
                this.onSubmit()
            }
        },
        back() {
            this.formErrors = []

            if (!this.onboardingApplication.company.feature_i9_document_photos) {
                this.$emit('close')
                return
            }

            if (this.step == 'review') {
                if (this.listADoc) {
                    this.step = 'list-a-upload'
                }

                else {
                    this.step = 'list-c-upload'
                }
            }
            else if (this.step == 'list-c-upload') {
                this.listCPhotos = null
                this.step = 'list-b-upload'
            }
            else if (this.step == 'list-b-upload') {
                this.listBPhotos = null
                this.step = 'list-bc'
            }
            else if (this.step == 'list-bc') {
                this.listBDocSlug = null
                this.listCDocSlug = null
                this.step = 'list-a'
            }

            else if (this.step == 'list-a-2-upload') {
                this.listATertiaryPhotos = null
                this.step = 'list-a-1-upload'
            }
            else if (this.step == 'list-a-1-upload') {
                this.listASecondaryPhotos = null
                this.step = 'list-a-upload'
            }
            else if (this.step == 'list-a-upload') {
                this.listAPhotos = null

                if (this.listATertiaryOptions.length) {
                    this.step = 'list-a-2'
                }
                else if (this.listASecondaryOptions.length) {
                    this.step = 'list-a-1'
                }
                else {
                    this.step = 'list-a'
                }
            }
            else if (this.step == 'list-a-2') {
                this.listATertiaryDocSlug = null
                this.step = 'list-a-1'
            }
            else if (this.step == 'list-a-1') {
                this.listASecondaryDocSlug = null
                this.step = 'list-a'
            }
            else if (this.step == 'list-a') {
                this.listADocSlug = null
                this.step = 'survey'
            }

            else if (this.step == 'survey') {
                this.step = 'info'
            }
            else if (this.step == 'info') {
                this.step = 'alert'
            }
            else if (this.step == 'alert') {
                this.$emit('close')
            }
        },
        skip() {
            this.onSubmit()
        },
        onDocUpload(list, uploads) {
            if (list == 'list-a') {
                this.listAPhotos = uploads
            }
            else if (list == 'list-a-1') {
                this.listASecondaryPhotos = uploads
            }
            else if (list == 'list-a-2') {
                this.listATertiaryPhotos = uploads
            }
            else if (list == 'list-b') {
                this.listBPhotos = uploads
            }
            else if (list == 'list-c') {
                this.listCPhotos = uploads
            }
            this.next()
        },
        onPDFFormError(errors) {
            this.formErrors = []
            if (errors.message) {
                this.formErrors = errors.message
            }
            else if (errors.__all__) {
                this.formErrors = errors.__all__
            }
            else {
                this.formErrors = [errors]
            }
            this.$bus.showError('Please check the form for errors, then try again.')
        },
        onPDFFormSubmit() {
            let data
            try {
                data = this.$refs.survey.getFormValues()
            }
            catch (ex) {
                this.onPDFFormError(ex)
                return false
            }

            delete data['signature']
            if (data['translator_signature']) {delete data['translator_signature']}
            if (data['translator_signature_2']) {delete data['translator_signature_2']}
            if (data['translator_signature_3']) {delete data['translator_signature_3']}
            if (data['translator_signature_4']) {delete data['translator_signature_4']}
            if (data['translator_signature_5']) {delete data['translator_signature_5']}

            this.$set(this, 'formData', data)
            if (this.onboardingApplication.company.feature_i9_document_photos) {
                this.step = 'list-a'
            }

            return true
        },
        onSubmit() {
            this.$store.dispatch('START_LOADING')

            this.$api.post(`/me/onboarding/${this.onboardingApplication.id}/onboarding-application/forms/${this.form.id}`, {
                'form_data': this.formData,
            }).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.serverFormErrors = resp['errors']
                this.serverFormWarnings = resp['warnings']
                this.previewPDFForm()
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        previewPDFForm() {
            this.currentPDF = {
                title: this.parseYAML(this.form.yaml).title,
                url: {
                    url: `${this.$api.API_BASE}/me/onboarding/${this.onboardingApplication.id}/onboarding-application/forms/${this.form.id}/pdf`,
                    withCredentials: true,
                }
            }
        },
        makePreviewURL(file) {
            return `${this.$api.API_BASE}/me/onboarding/${this.form.onboarding_application_id}/onboarding-application/forms/${this.form.id}/photos/${file.id}/preview`
        },
        parseYAML(yaml) {
            return YAML.safeLoad(yaml)
        },
    },
}
</script>
