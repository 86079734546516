<template>
    <div>
        <table-page
            :client="client"
            :warnings="warnings"
            :changelogs="changelogs"
            @warnings_updated="getWarnings"
            @changelog_date_filter_change="onChangelogDateRangeChange"
            :show-employee-columns-in-warnings="false"
            :hide-employee-ssn-column="true"
            :hide-email-column="true"
            :hide-tracked-object-name-column="true"
            @updated="getDocuments(); getChangelogs()"
        >
            <template v-if="documentModuleUpgradeAvailable">
                <portal to="upgrade-link">
                    <router-link target="_blank" :to="documentModuleUpgradeRoute" @click.native="trackUpgradeClick">
                        <i class="fas fa-fw fa-star mr-0"></i>
                        Upgrade
                    </router-link>
                </portal>
            </template>

            <fast-table
                :recordLabel="recordLabel"
                :sections="sections"
                :headers="headers"
                :data="tableData"
                @click="currentDocumentId = $event.id; currentDocumentType = $event.doc_type; currentDocumentVersionNumber = $event.version_number"
                :use-date-filters="true"
                @date_filter_change="onDateFilter"
            >
                <template slot="table-actions">
                    <router-link class="btn btn-primary btn-collapse" :to="{name: 'settings-hr-documents-add', params: {client: client.id} }">
                        <i class="far fa-fw fa-plus"></i> <span class="btn-text">Add Document to Library</span>
                    </router-link>
                </template>
            </fast-table>

            <add-hr-document-modal :client="client" ref="addModal" @paymasters_hr_documents_updated="getDocuments(); getChangelogs()"/>
        </table-page>

        <document-sidebar
            :document-id="currentDocument.id"
            :document-type="currentDocument.doc_type"
            :document-version-number="currentDocumentVersionNumber"
            :key="currentDocument.doc_key"
            v-if="currentDocument"
            ref="documentSidebar"
            :client="client"
            @updated="getDocuments(); getChangelogs()"
            @close="currentDocumentId = null; currentDocumentType = null; currentDocumentVersionNumber = null; sidebarTab = 'main'; currentChangelogId = null;"
            @change_version="currentDocumentVersionNumber = $event"
            :tab="sidebarTab"
            :item-id="currentChangelogId"
        />
    </div>
</template>

<script>
import FastTable from '@/components/FastTable'
import AddHrDocumentModal from './components/AddHRDocumentModal'
import DocumentSidebar from './components/DocumentSidebar'
import TablePage from '@/components/TablePage'

export default {
    components: {FastTable, AddHrDocumentModal, DocumentSidebar, TablePage, },
    props: ['client', ],
    computed: {
        currentDocument() {
            return this.documents.find(doc => (doc.id == this.currentDocumentId && doc.doc_type == this.currentDocumentType))
        },
        currentDocumentChangelogs() {
            if (!this.currentDocument) {return []}

            return this.changelogs.filter(c => {
                (c.hr_document && c.hr_document.id == this.currentDocumentId) ||
                (c.hr_form_document && c.hr_form_document.id == this.currentDocumentId)
            })
        },
        draftDocuments() {
            // Draft documents are unique in that we may combine multiple draft docs from one "batch" into a single table entry
            let draftDocs = []
            let batchIds = []
            this.documents.forEach(doc => {
                if (doc.is_active && doc.draft_version) {
                    if (doc.is_draft && doc.batch_key && batchIds.includes(doc.batch_key)) {
                        // If this is part of a batch of draft docs being created together,
                        // don't add an additional entry to draftDocs; instead augment existing entry
                        let existingDoc = draftDocs.find(dd => dd.batch_key == doc.batch_key)
                        existingDoc.company_name = 'MULTIPLE'
                        existingDoc.num_positions += doc.num_positions
                    } else {
                        // If this is a draft doc in a batch by itself, or the first seen of a batch
                        let draftDoc = JSON.parse(JSON.stringify(doc))
                        draftDoc.version_number = doc.draft_version.version_number
                        if (draftDoc.version_number > 1) {
                            draftDoc.updated_on = doc.draft_version.updated_on // give draft versions of active docs their own "last modified" date
                        }
                        draftDocs.push(draftDoc)
                        if (draftDoc.batch_key) {
                            batchIds.push(draftDoc.batch_key)
                        }
                    }
                }
            })
            return draftDocs
        },
        activeDocuments() {
            return this.documents.filter((doc) => {return doc.is_active && !doc.is_draft})
        },
        inactiveDocuments() {
            return this.documents.filter((doc) => {return !doc.is_active})
        },
        hrDocsSuiteReleased() {
            return (this.$store.state.system_flags && this.$store.state.system_flags['hr_docs_suite_released']) || this.client.preview_hr_docs_suite
        },
        sections() {
            let sections = [
                {title: 'Active Documents', defaultIsClosed: false, id: 'active', hasDateFilter: false},
                {title: 'Inactive Documents', defaultIsClosed: true, id: 'inactive', hasDateFilter: true},
            ]
            if (this.hrDocsSuiteReleased) {
                sections.unshift({title: 'Draft Documents', defaultIsClosed: false, id: 'draft', hasDateFilter: false},)
            }
            return sections
        },
        tableData() {
            const tableData = []
            const subsets = [
                this.activeDocuments,
                this.inactiveDocuments,
            ]

            if (this.hrDocsSuiteReleased) {
                subsets.unshift(this.draftDocuments)
            }

            subsets.forEach(docs => {
                const sectionData = []

                docs.forEach((d, idx) => {
                    sectionData.push({
                        id: idx,
                        isActive: this.currentDocumentId && d.id == this.currentDocumentId && d.doc_type == this.currentDocumentType && this.currentDocumentVersionNumber == d.version_number,
                        object: d,
                        cells: this.makeDocumentTableData(d),
                    })
                })
                tableData.push(sectionData)
            })
            return tableData
        },
        documentModuleUpgradeAvailable() {
            // If client or at least one company does not have all the document modules, then an upgrade is available
            if (!(this.$store.state.system_flags && this.$store.state.system_flags['hr_docs_suite_released'])) {
                return false
            }
            if (!this.client.has_company_docs_module || !this.client.has_uniform_module || !this.client.has_custom_forms_module) {
                return true
            }
            if (this.client.has_company_docs_module) {
                if (this.client.companies.some(c => c.has_company_docs_module == false)) {
                    return true
                }
            }
            if (this.client.has_uniform_module) {
                if (this.client.companies.some(c => c.has_uniform_module == false)) {
                    return true
                }
            }
            if (this.client.has_custom_forms_module) {
                if (this.client.companies.some(c => c.has_custom_forms_module == false)) {
                    return true
                }
            }
            return false
        },
        documentModuleUpgradeRoute() {
            return {name: 'client-features-document-modules', params: {client: this.client.id}}
        },
    },
    data() {
        return {
            documents: [],
            dataLoaded: false,
            currentDocumentId: null,
            currentDocumentType: null,
            currentDocumentVersionNumber: null,
            dateRanges: {},

            recordLabel: "HR documents",
            headers: [
                {label: 'Name', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Company', classes: 'cw-15', isSearchable: true, isFilterable: true},
                {label: 'Type', classes: 'cw-12', isSearchable: false, isFilterable: true},
                {label: 'Version', classes: 'cw-5', isSearchable: false},
                {label: 'Positions', classes: 'cw-6', isSearchable: false},
                {label: 'Individuals', classes: 'cw-8', isSearchable: false},
                {label: 'Last Modified', classes: 'cw-date', isSearchable: false, type: 'date'},
            ],
            warnings: null,
            changelogs: null,
            changelogDateRangeStart: null,
            changelogDateRangeEnd: null,
            currentChangelogId: null,
            sidebarTab: 'main',
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Settings / HR Document Library')
        this.getDocuments()
        this.getWarnings()
        this.getChangelogs()
    },
    methods: {
        getDocuments() {
            this.$store.dispatch('START_LOADING')

            let docsUrl = `/clients/${this.client.id}/hr-documents`
            let formDocsUrl = `/clients/${this.client.id}/hr-form-documents`
            let uniformsUrl = `/clients/${this.client.id}/uniforms`
            let customFormsUrl = `/clients/${this.client.id}/hr-custom-forms`
            const params = []
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (range) {
                    params.push(`date-filter.${s.id}.start=${encodeURIComponent(range.start)}`)
                    params.push(`date-filter.${s.id}.end=${encodeURIComponent(range.end)}`)
                }
            })

            docsUrl += '?' + params.join('&')
            formDocsUrl += '?' + params.join('&')
            uniformsUrl += '?' + params.join('&')
            customFormsUrl += '?' + params.join('&')

            const allDocs = []
            const promises = []
            promises.push(this.$api.get(docsUrl).then(docs => {
                allDocs.push(...docs)
            }))
            promises.push(this.$api.get(formDocsUrl).then(formDocs => {
                allDocs.push(...formDocs)
            }))
            if (this.client.has_uniform_module) {
                promises.push(this.$api.get(uniformsUrl).then(uniforms => {
                    allDocs.push(...uniforms)
                }))
            }
            if (this.client.has_custom_forms_module) {
                promises.push(this.$api.get(customFormsUrl).then(customForms => {
                    allDocs.push(...customForms)
                }))
            }
            Promise.all(promises).then(() => {
                this.documents = allDocs
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                if (errors.__status__ == 403) {
                    this.$bus.$emit('no-access')
                }
                else {
                    this.$bus.showError(errors.__all__)
                }
            })
        },
        getLastFilename(files) {
            if (!files || !files.length) {
                return ''
            }
            files = files.slice()  // Make a copy of the array
            files.sort((a, b) => {
                return a.version > b.version ? -1 : 1
            })

            return files[0] ? files[0].filename : ''
        },
        getWarnings() {
            this.warnings = []
        },
        getChangelogs() {
            let url = `/clients/${this.client.id}/history-logs`
            if (this.changelogDateRangeStart && this.changelogDateRangeEnd) {
                url = `${url}?date-filter.history_logs.start=${encodeURIComponent(this.changelogDateRangeStart)}&date-filter.history_logs.end=${encodeURIComponent(this.changelogDateRangeEnd)}`
            }

            this.$api.get(url).then(resp => {
                this.changelogs = resp.filter(c => c.hr_document || c.hr_form_document)
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getDocuments()
        },
        onChangelogDateRangeChange(range) {
            this.changelogDateRangeStart = range.start
            this.changelogDateRangeEnd = range.end
            this.getChangelogs()
        },
        makeDocumentTableData(doc) {
            let company_name = ""
            if (doc.company_name) { // draft doc may have a company_name of MULTIPLE for the purpose of table data
                company_name = doc.company_name
            } else {
                company_name = doc.company ? doc.company.name : '---'
            }
            let doc_type = {'hr-doc': 'Company Document', 'hr-form-doc': 'Fillable Form', 'uniform': 'Uniform', 'hr-custom-form': 'Custom Form'}[doc.doc_type]
            let version_number = doc.yaml_form ? doc.yaml_form.version_number : doc.version_number
            let num_individuals = doc.individuals ? doc.individuals.length : 0
            return [
                doc.name,
                company_name,
                doc_type,
                version_number,
                doc.num_positions,
                num_individuals,
                doc.updated_on
            ]
        },
        trackUpgradeClick() {
            // TODO KB enable once we have the marketing page
            // this.$bus.trackEvent('Document Module Upgrade Click', {
            //     'client_id': this.client.id,
            //     'client_business_type_rollup_primary': this.client.business_type_rollup_primary,
            //     'client_business_type_rollup_secondary': this.client.business_type_rollup_secondary,
            //     'client_business_type_rollup_brand': this.client.business_type_rollup_brand,
            // })
        }
    },
}

</script>
