<template>
    <div class="promo-tile" :class="">
        <div class="">
            <a :href="android_url">
                <img src="@/assets/img/google-play-badge.svg" width="100%">
            </a>

            <br><br>

            <a :href="ios_url">
                <img src="@/assets/img/apple-appstore-badge.svg" width="100%">
            </a>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            android_url: 'https://play.google.com/store/apps/details?id=com.deltahcm.getmypaystub',
            ios_url: 'https://apps.apple.com/us/app/getmypaystub/id1480874816',
        }
    },
    mounted() {
        const userAgent = window.navigator.userAgent

        if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iOS/i)) {
           window.location.href = this.ios_url
        }

        if (userAgent.match(/Android/i)) {
           window.location.href = this.android_url
        }
    }
}
</script>
