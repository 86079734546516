<template>
    <modal @close="$emit('close')" ref="modal">
        <template slot="title">New Hire Import Options</template>

        <slot>
            <form-errors :errors="formErrors"/>

            <div class="form-row pb-2">
                <div class="col">
                    <form-input label="New Hires Import Enabled" v-model="formData.pos_do_new_hires" :errors="errors.pos_do_new_hires" type="checkbox" />
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.pos_do_new_hires">
                <div class="col">
                    <form-input label="Default pay frequency (overriden by Paygroup)" v-model="formData.default_pay_frequency" :errors="errors.default_pay_frequency" type="select" :options="payFrequencyOptions">
                        <template #popover-helper>
                            <p>
                                When pushing new hires into Payroll, we need to set the default pay period.
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.pos_do_new_hires">
                <div class="col">
                    <form-input label="Default normal hours (overriden by Paygroup)" v-model="formData.default_normal_hours" :errors="errors.default_normal_hours" type="decimal" size="6" maxlength="6" :nocaps="true">
                        <template #popover-helper>
                            <p>
                                When pushing new hires into Payroll, we need to set the default 'normal hours' per pay period (typically 40 or 80).
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.pos_do_new_hires">
                <div class="col">
                    <form-input label="Pay rate warning threshold" v-model="formData.payrate_warning_threshold" :errors="errors.payrate_warning_threshold" type="decimal" size="6" maxlength="6" :nocaps="true">
                        <template #popover-helper>
                            <p>
                                Warn (but don't block) when pay rate is set to more than this.
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div class="form-row pb-2" v-if="formData.pos_do_new_hires">
                <div class="col">
                    <form-input label="Flag additional withholding elections" v-model="formData.pos_do_addl_withholding_check" :errors="errors.pos_do_addl_withholding_check" type="checkbox">
                        <template #popover-helper>
                            <p>
                                Flag new hires with additional withholdings
                            </p>
                        </template>
                    </form-input>
                </div>
            </div>

            <div v-if="formData.pos_do_new_hires">
                <h5 class="mt-2 mb-2">
                    Org Level Mappings

                    <more-info class="moreinfo-inline mt-0">
                        Field to use for org_levels during POS new hires import:
                        <ul>
                            <li> If it's a literal value, use that field from the POS file itself (so 'Store' means use the 'Store' column from the POS)</li>
                            <li> If it's the literal '%', look up in the Stores list</li>
                            <li> if it's '*', ask the user during the new hire review step (you can then provide an optional default value)</li>
                            <li> If it's quoted with "", use that actual, literal value (without the quotes)</li>
                        </ul>
                    </more-info>
                </h5>

                <form-input label="Org Level 1" v-model="formData.org_level_1_pos_employee_import_field" :errors="errors.org_level_1_pos_employee_import_field" :nocaps="true" type="text" />
                <form-input :class="'ml-4'" label="Org Level 1 Default"v-model="formData.org_level_1_pos_employee_import_field_default" :errors="errors.org_level_1_pos_employee_import_field_default" :nocaps="true" type="text" v-if="formData.org_level_1_pos_employee_import_field == '*'" />

                <form-input label="Org Level 2" v-model="formData.org_level_2_pos_employee_import_field" :errors="errors.org_level_2_pos_employee_import_field" :nocaps="true" type="text" />
                <form-input :class="'ml-4'" label="Org Level 2 Default" v-model="formData.org_level_2_pos_employee_import_field_default" :errors="errors.org_level_2_pos_employee_import_field_default" :nocaps="true" type="text" v-if="formData.org_level_2_pos_employee_import_field == '*'" />

                <form-input label="Org Level 3" v-model="formData.org_level_3_pos_employee_import_field" :errors="errors.org_level_3_pos_employee_import_field" :nocaps="true" type="text" />
                <form-input :class="'ml-4'" label="Org Level 3 Default" v-model="formData.org_level_3_pos_employee_import_field_default" :errors="errors.org_level_3_pos_employee_import_field_default" :nocaps="true" type="text" v-if="formData.org_level_3_pos_employee_import_field == '*'" />

                <form-input label="Org Level 4" v-model="formData.org_level_4_pos_employee_import_field" :errors="errors.org_level_4_pos_employee_import_field" :nocaps="true" type="text" />
                <form-input :class="'ml-4'" label="Org Level 4 Default" v-model="formData.org_level_4_pos_employee_import_field_default" :errors="errors.org_level_4_pos_employee_import_field_default" :nocaps="true" type="text" v-if="formData.org_level_4_pos_employee_import_field == '*'" />

                <form-input label="Org Level 5" v-model="formData.org_level_5_pos_employee_import_field" :errors="errors.org_level_5_pos_employee_import_field" :nocaps="true" type="text" />
                <form-input :class="'ml-4'" label="Org Level 5 Default" v-model="formData.org_level_5_pos_employee_import_field_default" :errors="errors.org_level_5_pos_employee_import_field_default" :nocaps="true" type="text" v-if="formData.org_level_5_pos_employee_import_field == '*'" />
            </div>

        </slot>

        <template slot="footer">
            <div class="form-row">
                <div class="col">
                    <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                </div>
                <div class="col">
                    <button type="submit" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [FormMixin, ModalMixin],
    props: ['client', 'integration', ],
    data() {
        const payFrequencyOptions = [
            {text: 'Weekly', value: '52'},
            {text: 'Bi-weekly', value: '26'},
            {text: 'Semi-monthly', value: '24'},
        ]

        return {
            dataLoaded: false,
            payFrequencyOptions: payFrequencyOptions,
            formData: this.makeFormData(),
        }
    },
    computed: {
        formRules() {
            let rules = {}

            if (this.formData.pos_do_new_hires) {
                rules['default_pay_frequency'] = {presence: {allowEmpty: false}, }
                rules['default_normal_hours'] = {presence: {allowEmpty: false}, }
            }

            return rules
        },
    },
    methods: {
        makeFormData() {
            let fields = [
                'pos_do_new_hires',
                'default_pay_frequency', 'default_normal_hours', 'payrate_warning_threshold', 'pos_do_addl_withholding_check',
                'org_level_1_pos_employee_import_field', 'org_level_2_pos_employee_import_field', 'org_level_3_pos_employee_import_field',
                'org_level_4_pos_employee_import_field', 'org_level_5_pos_employee_import_field',
                'org_level_1_pos_employee_import_field_default', 'org_level_2_pos_employee_import_field_default', 'org_level_3_pos_employee_import_field_default',
                'org_level_4_pos_employee_import_field_default', 'org_level_5_pos_employee_import_field_default',
            ]
            let formData = {}

            fields.forEach(f => {
                formData[f] = this.integration[f]
            })
            return formData
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            this.$store.dispatch('START_LOADING')
            let url = `/admin/clients/${this.client.id}/pos-integrations/${this.integration.id}/new-hire-import`
            api.put(url, this.formData).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch((errors) => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
                this.$store.dispatch('STOP_LOADING')
            })
        },
    }
}
</script>
