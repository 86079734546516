<template>
    <modal @close="$emit('close')" ref="modal" :is-closeable="true" size="xl" :autoshow="true">
        <template #title>
            {{ dialogTitle }}
        </template>
        <slot>
            <div v-if="dataLoaded && ['create-case', 'submit-case', 'confirm-fields', 'view-case'].includes(mode)">
                <p>
                    Company: {{ company.name }}
                </p>

                <div v-if="eVerifyCase" class="bg-light p-2 mb-2">
                    <b>Case #:</b> {{ eVerifyCase.case_number }}
                    <br>
                    <b>Status:</b> {{ eVerifyCase.case_status_pretty }}
                </div>

                <p v-if="mode == 'create-case'">
                    Review the information below and click "Create Case" to create an E-Verify case for this employee.
                </p>

                <p v-if="showNewCaseWarning" class="alert alert-danger">
                    This employee has already had an E-Verify case. Only open a new case if instructed to do so by E-Verify.
                </p>

                <p v-if="mode == 'submit-case'">
                    Review the information below and click "Submit Case" to submit this E-Verify case.
                </p>

                <div v-if="mode == 'confirm-fields'" class="mt-2 alert alert-danger">
                    Please confirm the highlighted fields and resubmit the case.
                </div>

                <form class="mui-form">

                    <h6 class="my-2">Employee Information and Attestation</h6>

                    <div class="form-row">
                        <div class="col-12 col-lg-6">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('last_name')" type="text" label="Last Name (Family Name)" v-model="formData.last_name" :errors="errors.last_name" />
                            <div v-if="case_fields_to_verify.includes('last_name')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12 col-lg-6">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('first_name')" type="text" label="First Name (Given Name)" v-model="formData.first_name" :errors="errors.first_name" />
                            <div v-if="case_fields_to_verify.includes('first_name')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12 col-lg-6">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('middle_initial')" type="text" label="Middle Initial" v-model="formData.middle_initial" maxlength="1" />
                            <div v-if="case_fields_to_verify.includes('middle_initial')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12 col-lg-6">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('other_last_names')" type="text" label="Other last names used (if any)" v-model="formData.other_last_names" />
                            <div v-if="case_fields_to_verify.includes('other_last_names')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-12 col-lg-6">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('date_of_birth')" type="date" label="Date of Birth" v-model="formData.date_of_birth" :errors="errors.date_of_birth" />
                            <div v-if="case_fields_to_verify.includes('date_of_birth')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12 col-lg-6">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('ssn')" type="text" label="SSN" v-model="formData.ssn" :errors="errors.ssn" />
                            <div v-if="case_fields_to_verify.includes('ssn')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12 col-lg-6">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('employee_email_address')" type="text" label="Employee Email" v-model="formData.employee_email_address" :errors="errors.employee_email_address" />
                            <div v-if="case_fields_to_verify.includes('employee_email_address')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12 col-lg-6">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('phone_number')" type="text" label="Employee Phone" v-model="formData.phone_number" :errors="errors.phone_number" />
                            <div v-if="case_fields_to_verify.includes('phone_number')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12 col-lg-6">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('citizenship_status_code')" label="Citizenship Status" v-model="formData.citizenship_status_code" type="radio" :options="citizenshipStatusOptions"/>
                            <div v-if="case_fields_to_verify.includes('citizenship_status_code')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>
                    </div>

                    <h6 class="mb-2">Employer or Authorized Representative Review and Verification</h6>

                    <div class="form-row">
                        <div class="col-12">
                            <form-input :disabled="isReadonly" label="What document types are on this employee's Form I-9?" v-model="formData.documents_list" type="radio" :options="documentListOptions" />
                        </div>

                        <div class="col-12" v-if="formData.documents_list == 'list_a'">
                            <form-input :disabled="isReadonly" type="select" label="List A Document" v-model="formData.document_a_type_code" :options="listADocumentOptions" />
                        </div>

                        <div class="col-12" v-if="formData.documents_list == 'list_bc'">
                            <form-input :disabled="isReadonly" type="select" label="List B Document" v-model="formData.document_b_type_code" :options="listBDocumentOptions" />
                        </div>

                        <div class="col-12" v-if="formData.documents_list == 'list_bc'">
                            <form-input :disabled="isReadonly" type="select" label="List C Document" v-model="formData.document_c_type_code" :options="listCDocumentOptions" />
                        </div>

                        <!-- Conditionals -->
                        <div class="col-12" v-if="docDependentFieldsToDisplay.includes('us_passport_number')">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('us_passport_number')" label="Document Number" v-model="formData.us_passport_number" type="text" :errors="errors.us_passport_number"  />
                             <div v-if="case_fields_to_verify.includes('us_passport_number')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12" v-if="docDependentFieldsToDisplay.includes('i9_alien_document_choice')">
                            <form-input :disabled="isReadonly" label="Select the document number provided on the Form I-9" v-model="formData.i9_alien_document_choice" type="radio" :options="i9AlienDocumentOptions" />
                        </div>

                        <div class="col-12" v-if="docDependentFieldsToDisplay.includes('i94_document_choice')">
                            <form-input :disabled="isReadonly" label="What document number is on the I-94?" v-model="formData.i94_document_choice" type="radio" :options="i94NumberOptions" />
                        </div>

                        <div class="col-12" v-if="docDependentFieldsToDisplay.includes('alien_number')">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('alien_number')" label="Alien/USCIS Number" v-model="formData.alien_number" type="text" :errors="errors.alien_number"  />
                             <div v-if="case_fields_to_verify.includes('alien_number')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12" v-if="docDependentFieldsToDisplay.includes('i94_number')">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('i94_number')" label="I-94 Number" v-model="formData.i94_number" type="text" :errors="errors.i94_number"  />
                             <div v-if="case_fields_to_verify.includes('i94_number')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12" v-if="docDependentFieldsToDisplay.includes('i551_number')">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('i551_number')" label="Document Number" v-model="formData.i551_number" type="text" :errors="errors.i551_number"  />
                             <div v-if="case_fields_to_verify.includes('i551_number')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12" v-if="docDependentFieldsToDisplay.includes('i766_number')">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('i766_number')" label="Document Number" v-model="formData.i766_number" type="text" :errors="errors.i766_number"  />
                             <div v-if="case_fields_to_verify.includes('i766_number')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12" v-if="docDependentFieldsToDisplay.includes('sevis_number')">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('sevis_number')" label="SEVIS Number" v-model="formData.sevis_number" type="text" :errors="errors.sevis_number"  />
                             <div v-if="case_fields_to_verify.includes('sevis_number')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12" v-if="docDependentFieldsToDisplay.includes('foreign_passport_number')">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('foreign_passport_number')" label="Document Number" v-model="formData.foreign_passport_number" type="text" :errors="errors.foreign_passport_number"  />
                             <div v-if="case_fields_to_verify.includes('foreign_passport_number')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12" v-if="docDependentFieldsToDisplay.includes('country_code')">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('country_code')" label="Country of Issuance" v-model="formData.country_code" type="select" :options="countryOptions" />
                             <div v-if="case_fields_to_verify.includes('country_code')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12" v-if="docDependentFieldsToDisplay.includes('document_sub_type_code')">
                            <form-input :disabled="isReadonly" label="Are you entering a Driver's License or a State Issued ID Card?" v-model="formData.document_sub_type_code" type="radio" :options="driversLicenseDocumentOptions" />
                        </div>

                        <div class="col-12" v-if="docDependentFieldsToDisplay.includes('us_state_code')">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('us_state_code')" label="State" v-model="formData.us_state_code" type="select" :options="stateOptions" />
                             <div v-if="case_fields_to_verify.includes('us_state_code')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12" v-if="docDependentFieldsToDisplay.includes('document_bc_number')">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('document_bc_number')" label="License Number" v-model="formData.document_bc_number" type="text" />
                             <div v-if="case_fields_to_verify.includes('document_bc_number')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                        <div class="col-12" v-if="docDependentFieldsToDisplay.includes('expiration_date')">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('expiration_date')" label="Expiration Date" v-model="formData.expiration_date" type="date" />
                             <div v-if="case_fields_to_verify.includes('expiration_date')">
                                <e-verify-confirm-field-warning />
                            </div>
                        </div>

                    </div>

                    <h6 class="mb-2">Additional Case Details</h6>

                    <div class="form-row">
                        <div class="col-12">
                            <form-input :disabled="isReadonly && !case_fields_to_verify.includes('date_of_hire')" label="Employee's First Day of Employment" v-model="formData.date_of_hire" type="date" />
                        </div>
                    </div>

                    <h6 class="mb-2">Submitter Information</h6>

                    <div class="form-row">
                        <div class="col-12">
                            <form-input label="Manager Name" :disabled="isEverythingReadonly" v-model="formData.case_creator_name" type="text" :errors="errors.case_creator_name" />
                        </div>

                        <div class="col-12">
                            <form-input label="Manager Email" :disabled="isEverythingReadonly" v-model="formData.case_creator_email_address" type="email" :errors="errors.case_creator_email_address" />
                        </div>

                        <div class="col-12">
                            <form-input label="Manager Phone Number" :disabled="isEverythingReadonly" v-model="formData.case_creator_phone_number" type="phone" :errors="errors.case_creator_phone_number" />
                        </div>
                    </div>

                    <div v-if="askReasonForDelay">
                        <div class="bg-info-light p-2 mb-2">
                            Select or enter the reason why the case has not been submitted within 3 days of start date.
                        </div>

                        <div class="form-row">
                            <div class="col-12">
                                <form-input type="select" :disabled="isEverythingReadonly" label="Reason for Delay" v-model="formData.reason_for_delay_code" :options="reasonForDelayOptions" :errors="errors.reason_for_delay_code" />
                            </div>

                            <div class="col-12">
                                <form-input label="Other Explanation" :disabled="isEverythingReadonly" v-model="formData.reason_for_delay_description" type="text" v-if="formData.reason_for_delay_code == 'OTHER'" :errors="errors.reason_for_delay_description" />
                            </div>
                        </div>
                    </div>

                    <div v-if="require_duplicate_continue_reason">
                        <div class="bg-info-light p-2 mb-2">
                            An employee with this SSN has an E-Verify case in the past 365 days.
                            Enter the reason for submitting a duplicate case:
                        </div>

                        <div class="form-row">
                            <div class="col-12">
                                <form-input label="Duplicate Reason" :disabled="isEverythingReadonly" v-model="formData.duplicate_continue_reason" type="text" :errors="errors.duplicate_continue_reason" />
                            </div>
                        </div>
                    </div>

                </form>
            </div>

            <div v-if="mode == 'create-case-errors'">
                <div class="alert alert-danger">
                    <b>Errors Creating Case</b>
                    <br><br>

                    <ul>
                        <li v-for="error in case_creation_errors.errors">
                            {{ error.message }}
                        </li>
                    </ul>
                </div>
            </div>
        </slot>

        <template #footer>
            <div class="modal-footer">
                <div v-if="mode == 'create-case'">
                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="onSubmit('create-case')">
                            Create Case
                        </button>
                    </button-row>
                </div>

                <div v-if="mode == 'submit-case'">
                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="onSubmit('submit-case')">
                            Submit Case
                        </button>
                    </button-row>
                </div>

                <div v-if="mode == 'confirm-fields'">
                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="onSubmit('confirm-fields')">
                            Confirm &amp; Submit
                        </button>
                    </button-row>
                </div>

                <div v-if="mode == 'view-case'">
                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                            Close
                        </button>
                    </button-row>
                </div>

                <div v-if="mode == 'create-case-errors'">
                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                            Close
                        </button>
                    </button-row>
                </div>
            </div>
        </template>
    </modal>

<!-- /Modal: Select Documents -->
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import i9Documents from '@/utils/i9'
import I9Doc from './I9Doc'
import STATES from '@/states'
import countries from '@/countries'
import EVerifyConfirmFieldWarning from './EVerifyConfirmFieldWarning'

const reasonForDelayOptions = [
    {text: 'Awaiting Social Security Number', value: 'AWAITING_SOCIAL_SECURITY_NUMBER'},
    {text: 'Technical Problems', value: 'TECHNICAL_PROBLEMS'},
    {text: 'Audit Revealed that New Hire Was Not Run', value: 'AUDIT_REVEALED_THAT_NEW_HIRE_WAS_NOT_RUN'},
    {text: 'Awaiting response on case created with incorrect information', value: 'AWAITING_RESPONSE_ON_CASE_WITH_INCORRECT_INFO'},
    {text: 'Federal Contractor with E-Verify Clause verifying an existing employee', value: 'FEDERAL_CONTRACTOR'},
    {text: 'Other', value: 'OTHER'},
]

const citizenshipStatusOptions = [
    {text: 'A citizen of the United States', value: 'US_CITIZEN'},
    {text: 'A noncitizen national of the United States', value: 'NONCITIZEN'},
    {text: 'A lawful permanent resident', value: 'LAWFUL_PERMANENT_RESIDENT'},
    {text: 'An noncitizen authorized to work', value: 'NONCITIZEN_AUTHORIZED_TO_WORK'},
]

const documentListOptions = [
    {text: 'List A Document', value: 'list_a'},
    {text: 'List B & C Document', value: 'list_bc'},
]

const i94NumberOptions = [
    {text: 'Alien/USCIS Number', value: 'alien_uscis_number'},
    {text: 'Form I-94', value: 'i94_number'},
]

const driversLicenseDocumentOptions = [
    {text: "Driver's License", value: 'DRIVERS_LICENSE'},
    {text: 'State Issued ID Card', value: 'STATE_ID_CARD'},
]

const i9AlienDocumentOptions = [
    {text: 'Alien Registration Number', value: 'alien_uscis_number'},
    {text: 'Form I-94', value: 'i94_number'},
    {text: 'Foreign Passport Number', value: 'foreign_passport_number'},
]


const allDocumentOptions = [
    // List A
    {
        'value': 'US_PASSPORT',
        'text': 'U.S. Passport or Passport Card',
        'list': 'list_a',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN']
    },
    {
        'value': 'FORM_I94_RECEIPT',
        'text': 'Arrival/Departure Record (Form I-94) with temporary I-551 stamp or refugee admission stamp (receipt)',
        'list': 'list_a',
        'citizenship_status_filter': ['LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK', ]
    },
    {
        'value': 'FORM_I551',
        'text': 'Permanent Resident Card or Alien Registration Receipt Card (Form I-551)',
        'list': 'list_a',
        'citizenship_status_filter': ['LAWFUL_PERMANENT_RESIDENT', ]
    },
    {
        'value': 'FOREIGN_PASSPORT_WITH_I551_STAMP',
        'text': 'Foreign passport with temporary I-551 stamp or printed notation on a MRIV',
        'list': 'list_a',
        'citizenship_status_filter': ['LAWFUL_PERMANENT_RESIDENT', ]
    },
    {
        'value': 'FORM_I766',
        'text': 'Employment Authorization Document (Form I-766)',
        'list': 'list_a',
        'citizenship_status_filter': ['ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },
    {
        'value': 'FOREIGN_PASSPORT_WITH_FORM_I94',
        'text': 'Foreign passport with Arrival/Departure Record (Form I-94)',
        'list': 'list_a',
        'citizenship_status_filter': ['ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },

    // List B
    {
        'value': 'DRIVERS_LICENSE',
        'text': "Driver's license or ID card issued by a U.S. state or outlying possession",
        'list': 'list_b',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN', 'LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },
    {
        'value': 'GOVERNMENT_ID_CARD',
        'text': 'ID card issued by a U.S. federal, state or local government agency',
        'list': 'list_b',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN', 'LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },
    {
        'value': 'SCHOOL_ID_CARD',
        'text': 'School ID card',
        'list': 'list_b',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN', 'LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },
    {
        'value': 'VOTER_REGISTRATION_CARD',
        'text': 'Voter registration card',
        'list': 'list_b',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN', 'LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },
    {
        'value': 'US_MILITARY_CARD',
        'text': 'U.S. military card or draft record',
        'list': 'list_b',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN', 'LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },
    {
        'value': 'MILITARY_DEPENDENT_ID_CARD',
        'text': "Military dependent's ID card",
        'list': 'list_b',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN', 'LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },
    {
        'value': 'US_COAST_GUARD_CARD',
        'text': 'U.S. Coast Guard Merchant Mariner Card',
        'list': 'list_b',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN', 'LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },
    {
        'value': 'CANADIAN_DRIVERS_LICENSE',
        'text': "Driver's license issued by a Canadian government authority",
        'list': 'list_b',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN', 'LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },
    {
        'value': 'SCHOOL_RECORD',
        'text': 'School record or report card (under age 18)',
        'list': 'list_b',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN', 'LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },
    {
        'value': 'HOSPITAL_RECORD',
        'text': 'Clinic, doctor or hospital record (under age 18)',
        'list': 'list_b',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN', 'LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },
    {
        'value': 'DAY_CARE_RECORD',
        'text': 'Day-care or nursery school record (under age 18)',
        'list': 'list_b',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN', 'LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },
    {
        'value': 'NATIVE_AMERICAN_TRIBAL_DOCUMENT',
        'text': 'Native American tribal document',
        'list': 'list_b',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN', 'LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },

    // List C
    {
        'value': 'SOCIAL_SECURITY_CARD',
        'text': 'Unrestricted Social Security Card',
        'list': 'list_c',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN', 'LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },
    {
        'value': 'FS_545',
        'text': 'Certification of Birth Abroad (FS 545)',
        'list': 'list_c',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN']
    },
    {
        'value': 'DS_1350',
        'text': 'Certificate of Report of Birth (DS 1350)',
        'list': 'list_c',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN']
    },
    {
        'value': 'FS_240',
        'text': 'Consular Report of Birth Abroad (FS 240)',
        'list': 'list_c',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN']
    },
    {
        'value': 'US_BIRTH_CERTIFICATE',
        'text': 'U.S. birth certificate (original or certified copy)',
        'list': 'list_c',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN']
    },
    // NB: this is repeated for list C
    {
        'value': 'NATIVE_AMERICAN_TRIBAL_DOCUMENT',
        'text': 'Native American tribal document',
        'list': 'list_c',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN', 'LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },
    {
        'value': 'FORM_I197',
        'text': 'U.S. Citizen ID Card (Form I-197)',
        'list': 'list_c',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN']
    },
    {
        'value': 'FORM_I179',
        'text': 'ID Card for Use of Resident Citizen in the United States (Form I-179)',
        'list': 'list_c',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN']
    },
    {
        'value': 'EMPLOYMENT_AUTHORIZATION_DOCUMENT',
        'text': 'Employment authorization document issued by the U.S. Department of Homeland Security',
        'list': 'list_c',
        'citizenship_status_filter': ['US_CITIZEN', 'NONCITIZEN', 'LAWFUL_PERMANENT_RESIDENT', 'ALIEN_AUTHORIZED_TO_WORK', 'NONCITIZEN_AUTHORIZED_TO_WORK']
    },
]

export default {
    components: {I9Doc, EVerifyConfirmFieldWarning, },
    mixins: [FormMixin, ModalMixin, ],
    props: ['client', 'company', 'employee', 'eVerifyCase', 'showNewCaseWarning'],
    mounted() {
        this.reset()
    },
    computed: {
        isReadonly() {
            return this.mode != 'create-case'
        },
        isEverythingReadonly() {
            return this.mode == 'view-case'
        },
        employeeObjectUrlPath() {
            return this.employee.is_employee ? 'employee' : 'onboarding-application'
        },
        dialogTitle() {
            switch (this.mode) {
                case 'create-case':
                    return "E-Verify Case Creation"
                case 'submit-case':
                    return "E-Verify Case Submission"
                case 'confirm-fields':
                    return "Confirm Case Details"
                case 'create-case-errors':
                    return "Errors Creating Case"
                default:
                    return "View E-Verify Case"
            }
        },
        askReasonForDelay() {
            let date_str = this.formData['date_of_hire'];
            if (! date_str) {
                return false;
            }

            if (this.isEverythingReadonly) {
                return (this.formData['reason_for_delay_code'] != '')
            }

            let today = moment().set({
                'hour': 0,
                'minute': 0,
                'second': 0,
                'millisecond': 0,
            })

            let inputformat = 'MM/DD/YYYY'
            if (date_str.includes('-')) {
                inputformat = 'YYYY-MM-DD'
            }
            let cutoff = moment(date_str, inputformat).add(3, 'days')
            let requires_explanation = (today > cutoff)

            return requires_explanation;
        },
        stateOptions() {
            const opts = []
            STATES.forEach(s => {
                opts.push({text: s.name.toUpperCase(), value: s.abbreviation})
            })
            return opts
        },
        countryOptions() {
            const opts = []
            countries.forEach(c => {
                opts.push({text: c.name, value: c.alpha3})
            })
            return opts
        },
        listADocumentOptions() {
            const opts = []

            allDocumentOptions.forEach(obj => {
                if (obj.list == 'list_a' && obj.citizenship_status_filter.includes(this.formData.citizenship_status_code)) {
                    opts.push({text: obj.text, value: obj.value})
                }
            })
            return opts
        },
        listBDocumentOptions() {
            const opts = []

            allDocumentOptions.forEach(obj => {
                if (obj.list == 'list_b' && obj.citizenship_status_filter.includes(this.formData.citizenship_status_code)) {
                    opts.push({text: obj.text, value: obj.value})
                }
            })
            return opts
        },
        listCDocumentOptions() {
            const opts = []

            allDocumentOptions.forEach(obj => {
                if (obj.list == 'list_c' && obj.citizenship_status_filter.includes(this.formData.citizenship_status_code)) {
                    opts.push({text: obj.text, value: obj.value})
                }
            })
            return opts
        },
        docDependentFieldsToDisplay() {
            let fields = []

            if (this.formData['document_a_type_code'] == 'US_PASSPORT') {
                fields.push('us_passport_number')
                fields.push('expiration_date')
            }

            if (this.formData['document_a_type_code'] == 'FORM_I94_RECEIPT') {
                fields.push('i94_document_choice')
                if (this.formData['i94_document_choice'] == 'alien_uscis_number') {
                    fields.push('alien_number')
                }
                if (this.formData['i94_document_choice'] == 'i94_number') {
                    fields.push('i94_number')
                }
                fields.push('foreign_passport_number')
                fields.push('expiration_date')
                fields.push('country_code')
            }

            if (this.formData['document_a_type_code'] == 'FORM_I551') {
                fields.push('alien_number')
                fields.push('i551_number')
            }

            if (this.formData['document_a_type_code'] == 'FOREIGN_PASSPORT_WITH_I551_STAMP') {
                fields.push('alien_number')
                fields.push('foreign_passport_number')
                fields.push('expiration_date')
                fields.push('country_code')
            }

            if (this.formData['document_a_type_code'] == 'FORM_I766') {
                fields.push('alien_number')
                fields.push('i766_number')
                fields.push('expiration_date')
            }

            if (this.formData['document_a_type_code'] == 'FOREIGN_PASSPORT_WITH_FORM_I94') {
                fields.push('i94_document_choice')
                if (this.formData['i94_document_choice'] == 'alien_uscis_number') {
                    fields.push('alien_number')
                }
                if (this.formData['i94_document_choice'] == 'i94_number') {
                    fields.push('i94_number')
                }

                fields.push('foreign_passport_number')
                fields.push('expiration_date')
                fields.push('country_code')
            }

            // List B
            if (this.formData['document_b_type_code'] == 'DRIVERS_LICENSE') {
                fields.push('document_sub_type_code')
                fields.push('us_state_code')
                fields.push('document_bc_number')
                fields.push('expiration_date')
            }

            // if they didn't provide an A list doc *and* they're not a citizen/permanent resident
            if ((this.formData['document_a_type_code'] == '') && ((this.formData['citizenship_status_code'] == 'ALIEN_AUTHORIZED_TO_WORK') || (this.formData['citizenship_status_code'] == 'NONCITIZEN_AUTHORIZED_TO_WORK'))) {
                fields.push('i9_alien_document_choice')
                if (this.formData['i9_alien_document_choice'] == 'alien_uscis_number') {
                    fields.push('alien_number')
                }
                if (this.formData['i9_alien_document_choice'] == 'i94_number') {
                    fields.push('i94_number')
                }
                if (this.formData['i9_alien_document_choice'] == 'foreign_passport_number') {
                    fields.push('foreign_passport_number')
                    fields.push('country_code')
                }
            }

            // if the backend returned a SEVIS number, show it
            if (this.formData['sevis_number']) {
                fields.push('sevis_number')
            }

            // if they have a foreign passport number, use that regardless of the document type
            if (this.formData['foreign_passport_number']) {
                fields.push('foreign_passport_number')
                fields.push('expiration_date')
                fields.push('country_code')
            }

            return fields
        },
        formRules() {
            let rules = {
                ssn: {
                    presence: {allowEmpty: false},
                    format: {
                        pattern: "[0-9]{3}-[0-9]{2}-[0-9]{4}",
                        message: "must be XXX-XX-XXXX"
                    }
                },
                phone_number: {
                    presence: {allowEmpty: false},
                    format: {
                        pattern: "[0-9]{3}-{0,1}[0-9]{3}-{0,1}[0-9]{4}",
                        message: "must be 10 digits"
                    }
                },
                case_creator_name: {presence: {allowEmpty: false} },
                case_creator_email_address: {
                    presence: {allowEmpty: false},
                    email: true,
                },
                case_creator_phone_number: {
                    presence: {allowEmpty: false},
                    format: {
                        pattern: "[0-9]{3}-{0,1}[0-9]{3}-{0,1}[0-9]{4}",
                        message: "must be 10 digits"
                    }
                },
            }

            if (this.formData['sevis_number']) {
                rules['sevis_number'] = {
                    format: {
                        pattern: "^N[0-9]{10}$",
                        message: "must be 'N' followed by 10 digits"
                    }
                }
            }

            if (this.formData['us_passport_number']) {
                rules['us_passport_number'] = {
                    format: {
                        pattern: "^[A-Za-z0-9]{1,9}$",
                        message: "must be 6-9 digits."
                    }
                }
            }

            if (this.formData['i551_number']) {
                rules['i551_number'] = {
                    format: {
                        pattern: "^[a-zA-Z]{3}[0-9]{10}$",
                        message: "must start with three letters followed by 10 digits. E.g.: QLC3856718663."
                    }
                }
            }

            if (this.formData['i766_number']) {
                rules['i766_number'] = {
                    format: {
                        pattern: "^[a-zA-Z]{3}[0-9]{10}$",
                        message: "must start with three letters followed by 10 digits. E.g.: MSC0902850001."
                    }
                }
            }

            if (this.formData['i94_number']) {
                rules['i94_number'] = {
                    format: {
                        pattern: "^[0-9A-Za-z]*$",
                        message: "can only contain letters and numbers."
                    }
                }
            }

            if (this.formData['document_bc_number']) {
                rules['document_bc_number'] = {
                    format: {
                        pattern: "^[a-zA-Z0-9*-]*$",
                        message: "must be up to 17 alphanumeric characters."
                    }
                }
            }

            if (this.askReasonForDelay) {
                rules['reason_for_delay_code'] = {presence: {allowEmpty: false} }
            }

            if (this.require_duplicate_continue_reason) {
                rules['duplicate_continue_reason'] = {presence: {allowEmpty: false} }
            }

            return rules
        },
    },
    data() {
        return {
            citizenshipStatusOptions: citizenshipStatusOptions,
            documentListOptions: documentListOptions,
            driversLicenseDocumentOptions: driversLicenseDocumentOptions,
            i9AlienDocumentOptions: i9AlienDocumentOptions,
            i94NumberOptions: i94NumberOptions,
            reasonForDelayOptions: reasonForDelayOptions,
            formData: this.makeFormData(),
            errors: [],
            require_reason_for_delay: false,
            require_duplicate_continue_reason: false,
            case_fields_to_verify: [],
            case_creation_errors: [],
            mode: 'create-case',
            dataLoaded: false,
        }
    },
    methods: {
        makeFormData() {
            // prefill data from I-9

            return {
                first_name: '',
                last_name: '',
                middle_initial: '',
                other_last_names: '',
                date_of_birth: '',
                date_of_hire: '',
                ssn: '',
                employee_email_address: '',
                phone_number: '',
                citizenship_status_code: '',

                document_a_type_code: '',
                document_b_type_code: '',
                document_bc_number: '',
                document_c_type_code: '',
                document_sub_type_code: '',
                expiration_date: '',
                no_expiration_date: false,

                us_passport_number: '',

                alien_number: '',
                foreign_passport_number: '',
                country_code: '', // ISO 3166-1 alpha-3
                i551_number: '',
                i766_number: '',
                i94_number: '',
                sevis_number: '',
                us_state_code: '',
                visa_number: '',

                case_creator_name: '',
                case_creator_email_address: '',
                case_creator_phone_number: '',

                reason_for_delay_code: '',
                reason_for_delay_description: '',
                duplicate_continue_reason: '',

                // doesn't get POSTed
                documents_list: '',
                i94_document_choice: '',
                i9_alien_document_choice: '',
            }
        },
        getInitialData() {
            this.$store.dispatch('START_LOADING')

            let url = `/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/create-case`
            if (this.eVerifyCase) {
                url = `/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCase.id}`
            }

            this.$api.get(url).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                Object.assign(this.formData, resp.case.case_data)
                this.isPristine = true

                if (this.formData['document_a_type_code']) {
                    this.formData.documents_list = 'list_a'
                } else {
                    this.formData.documents_list = 'list_bc'
                }
                if (this.formData['i94_number']) {
                    this.formData.i94_document_choice = 'i94_number'
                }
                if (this.formData['alien_number']) {
                    this.formData.i94_document_choice = 'alien_uscis_number'
                }

                // case submitter information (security user)
                this.formData.case_creator_name = resp.case_creator.name
                this.formData.case_creator_email_address = resp.case_creator.email
                this.formData.case_creator_phone_number = resp.case_creator.phone

                // do we need to ask for the reason for delay?
                this.require_reason_for_delay = resp.require_reason_for_delay
                this.require_duplicate_continue_reason = resp.require_duplicate_continue_reason

                // are there specific case fields we need to verify?
                if (resp.case.case_status == 'UNCONFIRMED_DATA') {
                    this.mode = 'confirm-fields'
                    // set specific case fields we need to verify
                    this.case_fields_to_verify = resp.case_fields_to_verify
                }

                // were there errors in case creation? if so, add them to the list of
                // fields to verify so they're editable
                this.case_creation_errors = resp.case.case_creation_errors
                if (this.case_creation_errors && this.case_creation_errors['errors']) {
                    this.case_creation_errors['errors'].forEach(err => {
                        this.case_fields_to_verify.push(err.attribute_name)
                    })
                }

                this.dataLoaded = true
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onSubmit(action) {
            const formData = {
                action: action,
                form_data: this.getFormData(),
            }

            if (!this.validate()) {
                this.$bus.showError("There are errors preventing your submission. Review the form and correct the errors.")
                return
            }

            this.$store.dispatch('START_LOADING')

            let url = `/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/create-case`
            if (this.eVerifyCase) {
                url = `/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCase.id}`
            }

            this.$api.post(url, formData).then((resp) => {
                this.$store.dispatch('STOP_LOADING')

                // after submitting, it either:
                // A) Has errors creating a case
                // B) Needs to confirm details
                // C) Created a case successfully (even if we need to photo-match or something, we don't do it here)

                if (resp.case.case_creation_errors && resp.case.case_creation_errors.length > 0) {
                    this.mode = 'create-case-errors'
                    this.case_creation_errors = resp.case.case_creation_errors
                } else {
                    if (resp.case.case_status == 'UNCONFIRMED_DATA') {
                        this.mode = 'confirm-fields'
                        // set specific case fields we need to verify
                        this.case_fields_to_verify = resp.case_fields_to_verify
                        return
                    }

                    this.$emit('updated', resp)
                    this.isPristine = true
                    this.$nextTick(() => {
                        this.close(true)
                    })
                }
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        reset() {
            FormMixin.methods.reset.call(this)
            this.dataLoaded = false
            this.$set(this, 'formData', this.makeFormData())
            this.getInitialData()

            this.mode = 'view-case'
            if (this.eVerifyCase) {
                switch(this.eVerifyCase.case_status) {
                    case 'PRE_DRAFT':
                        this.mode = 'create-case'
                        break;
                    case 'DRAFT':
                        this.mode = 'submit-case'
                        break;
                    case 'UNCONFIRMED_DATA':
                        this.mode = 'confirm-fields'
                        break;
                }
            } else {
                this.mode = 'create-case'
            }

            this.isPristine = true
            this.$store.dispatch('SET_CLOSEABLE', true)
        },
    }
}
</script>
