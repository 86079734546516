<template>
    <modal size="xl" ref="modal" @close="$emit('close')" :is-closeable="isCloseable" :autoshow="true">
        <template slot="title">
            <span v-if="minimumWage">Update Minimum Wage</span>
            <span v-if="!minimumWage">Add Minimum Wage</span>
        </template>
        <slot>

            <form class="mui-form" @submit.prevent="onSubmit">
                <div class="form-row mb-4">
                    <div class="col-6">
                        <form-input v-model="formData.starts_on" :errors="errors.starts_on" type="date" label="Starts On" />
                    </div>
                    <div class="col-6">
                        <form-input v-model="formData.inactive_on" :errors="errors.inactive_on" type="date" label="Expires On" />
                    </div>
                </div>

                <div class="form-row mb-2">
                    <div class="col-2">
                        <form-input v-model="formData.minimum_wage" :errors="errors.minimum_wage" type="currency" label="Minimum Hourly Wage" />
                    </div>
                    <div class="col-2">
                        <form-input v-model="formData.max_tip_credit_claimed" :errors="errors.tip_credit_claimed" type="currency" label="Max Tip Credit Claimed" />
                    </div>
                </div>

                <h4>Minimum Wage for Minors</h4>

                <p>If this jurisdiction has a special minimum wage for minors, specify it below. Otherwise, leave it blank.</p>
                <div class="form-row mb-2">
                    <div class="col-2">
                        <form-input v-model="formData.minor_minimum_wage" :errors="errors.minor_minimum_wage" type="currency" label="Minimum Hourly Wage" />
                    </div>
                    <div class="col-2">
                        <form-input v-model="formData.minor_max_tip_credit_claimed" :errors="errors.minor_tip_credit_claimed" type="currency" label="Max Tip Credit Claimed" />
                    </div>

                    <div class="col-6">
                        <form-input v-model="formData.minor_age" :errors="errors.minor_age" type="select" label="Minor Age" :options="minorAgeOptions" helper-text="Age at which a person is still considered a minor."/>
                    </div>
                </div>

            </form>
        </slot>

        <template slot="footer">
            <form-errors :errors="formErrors"/>

            <button-row>
                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['minimumWage', 'jurisdiction', ],
    mixins: [FormMixin, ModalMixin, ],
    computed: {
        minorAgeOptions() {
            const result = []
            for (let y = 0; y < 18; y++) {
                for (let m = 0; m < 12; m++) {
                    result.push({
                        value: `${y}.${m}`,
                        text: `${y} years, ${m} months`,
                    })
                }
            }
            result.reverse()
            return result
        },
    },
    data() {
        return {
            formRules: {
                starts_on: {presence: {allowEmpty: false}, date: true},
                minimum_wage: {presence: {allowEmpty: false}},
                max_tip_credit_claimed: {presence: {allowEmpty: false}},
            },
            formData: this.makeFormData(),
        }
    },
    methods: {
        getFormData() {
            const data = JSON.parse(JSON.stringify(this.formData))
            data.minor_age_years = null
            data.minor_age_months = null
            if (data.minor_age) {
                const minorAge = data.minor_age.split('.', 2).map(x => parseInt(x, 10))
                data.minor_age_years = minorAge[0]
                data.minor_age_months = minorAge[1]
            }
            delete data.minor_age
            return data
        },
        makeFormData() {
            if (this.minimumWage) {
                return {
                    starts_on: this.minimumWage.current_version.starts_on,
                    inactive_on: this.minimumWage.current_version.inactive_on,
                    minimum_wage: this.minimumWage.current_version.minimum_wage,
                    max_tip_credit_claimed: this.minimumWage.current_version.max_tip_credit_claimed,
                    minor_minimum_wage: this.minimumWage.current_version.minor_minimum_wage,
                    minor_max_tip_credit_claimed: this.minimumWage.current_version.minor_max_tip_credit_claimed,
                    minor_age: this.minimumWage.current_version.minor_age_years ? `${this.minimumWage.current_version.minor_age_years}.${this.minimumWage.current_version.minor_age_months}` : null,
                    jurisdiction: this.jurisdiction.id,
                }
            } else {
                return {
                    starts_on: '',
                    inactive_on: '',
                    minimum_wage: '',
                    max_tip_credit_claimed: '',
                    minor_minimum_wage: null,
                    minor_max_tip_credit_claimed: null,
                    minor_age: null,
                    jurisdiction: this.jurisdiction.id,
                }
            }
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let promise, url;
            if (this.minimumWage) {
                url = `/admin/tax-engine/jurisdictions/${this.jurisdiction.id}/minimum-wage/${this.minimumWage.id}`
                promise = this.$api.put(url, this.getFormData())
            }
            else {
                url = `/admin/tax-engine/jurisdictions/${this.jurisdiction.id}/minimum-wage`
                promise = this.$api.post(url, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.errors = errors
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>

