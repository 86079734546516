<template>
<div>
    <div>
        <div class="modal-active-filters mb-1">
            <span class="btnlike text-muted" v-show="activeFilterCount == 0 || onlyQuickFilterIsActive">
                Select 1 or more filters in these categories:
            </span>
            <active-filters-list
                :in-modal="true"
                :possible-filters="possibleFilters"
                :filter-values="currentFilterValues"
                :filter-value-counts="filterValueCounts"
                :quick-filter-on="currentQuickFilterOn"
                @remove="removeFilter"
                @clear="resetAll()"
                v-show="activeFilterCount > 0"
            />
        </div>
    </div>
    <!-- Filters selection area -->

    <div class="selectfilters-list-wrapper">
        <div class="selectfilters-list accordion" id="selectFiltersAccordion">
            <section v-for="filter in possibleFiltersWithMatches" :key="filter.id" class="selectfilters-section">
                <div class="selectfilters-section__header" :id="'category_' + filter.id">
                    <span class="flex-grow-1">
                        <tooltip
                            tooltipText="Turn off quick filter to select individual warning types"
                            :tooltipEnabled="filter.type == 'warning' && currentQuickFilterOn"
                            tooltipPlacement="right"
                            class="d-inline-block"
                        >
                            <button type="button" :disabled="filter.type == 'warning' && currentQuickFilterOn" class="selectfilters-section__toggle collapse-trigger" :class="{'collapsed': !expandedFilters[filter.id], 'disabled': filter.type == 'warning' && currentQuickFilterOn}" @click.prevent="toggleExpandedFilters(filter.id)" :aria-expanded="expandedFilters[filter.id]" :aria-controls="'categoryBody_' + filter.id">
                                <collapse-toggle-button/>
                                {{ filter.name }}
                            </button>
                        </tooltip>
                    </span>
                    <span class="flex-grow-0 selectfilters-section__status">
                        <span v-if="currentFilterValues[filter.id].length && (!hasQuickFilter || (hasQuickFilter && !currentQuickFilterOn))" class="selectfilters-section__counter">
                            {{ currentFilterValues[filter.id].length }}
                        </span>
                        <span v-if="hasQuickFilter && currentQuickFilterOn && filter.type == 'warning'" class="text-muted text-small d-inline-block mr-hf">
                            <em>Quick filter on</em>
                        </span>

                        <span v-if="hasQuickFilter && filter.type == 'warning'">
                            <button type="button" :class="{'btn-toggle-active': currentQuickFilterOn}" class="btn btn-icon btn-icon-outline btn-toggle btn-toggle--warnings ml-hf" aria-label="Quick Filter: Warnings" @click.prevent="toggleQuickFilter()">
                                <i aria-hidden="true" class="fas fa-exclamation-triangle" title="Quick Filter: Warnings"></i>
                            </button>
                        </span>
                    </span>
                </div>

                <div class="selectfilters-section__body collapse" :id="'categoryBody_' + filter.id" :aria-labelledby="'categoryHeader_' + filter.id" data-parent="#selectFiltersAccordion" :class="{'show': expandedFilters[filter.id]}">
                    <div v-if="!filter.filterValues.length" class="pl-4 py-hf text-empty-state selectfilters-section__item">
                        <div class="pl-2 mb-0 btnlike">No filter options in this category</div>
                    </div>

                    <ul class="list-unstyled m-0 p-0" v-if="filter.filterValues">
                        <li v-for="filterValue in filter.filterValues" :key="filter.id + '--' + filterValue.value" class="selectfilters-section__item" :class="{'is-active': currentFilterValues[filter.id].find(fv => fv.value == filterValue.value)}">
                            <span class="flex-grow-1">
                                <form-input type="checkbox" :label="filterValue.label" :value="currentFilterValues[filter.id].find(fv => fv.value == filterValue.value)" @input="toggleFilterValue(filter.id, filterValue, $event)" />
                            </span>
                            <span class="flex-grow-0">
                                <!-- Not currently needed, but here in the structure just in case -->
                            </span>
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    </div>

    <button-row class="mt-2">
        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">Cancel</button>
        <button type="button" class="btn btn-primary" @click.prevent="applyFilters" :disabled="filtersMatchInitialValues" :class="{'disabled': filtersMatchInitialValues}">
            {{ initialFilterValuesBlank ? 'Apply Filters' : 'Apply Changes' }}
        </button>
    </button-row>
</div>
</template>

<script>
import ButtonRow from '@/components/ButtonRow'
import ActiveFiltersList from './ActiveFiltersList'
import deepCompare from 'fast-deep-equal/es6'
import rowMatchesFilters from './filter-utils'
import Tooltip from '../Tooltip.vue'

export default {
    props: ['possibleFilters', 'filterValues', 'quickFilterOn', 'sections', 'headers', 'data', 'filteredData', 'managers', ],
    components: {ActiveFiltersList, ButtonRow, Tooltip, },
    computed: {
        hasQuickFilter() {
            return this.quickFilterOn && (this.possibleFilters.find(f => f.type == 'warning') ? true : false)
        },
        activeFilterCount() {
            return Object.values(this.currentFilterValues).filter(f => f.length > 0).length
        },
        onlyQuickFilterIsActive() {
            if (!this.hasQuickFilter) {return false}
            if (!this.currentQuickFilterOn) {return false}
            if (this.activeFilterCount != 1) {return false}
            const filter = this.possibleFilters.find(f => f.type == 'warning')
            return (this.currentFilterValues[filter.id].length)
        },
        initialFilterValues() {
            const currentFilterValues = {}
            this.possibleFilters.forEach(pf => {
                currentFilterValues[pf.id] = []
                if (pf.id in this.filterValues) {
                    currentFilterValues[pf.id] = JSON.parse(JSON.stringify(this.filterValues[pf.id]))
                }
            })

            return currentFilterValues
        },
        initialFilterValuesBlank() {
            let valuesCount = 0
            Object.keys(this.initialFilterValues).forEach(fId => {
                valuesCount += this.initialFilterValues[fId].length
            })

            return valuesCount == 0
        },
        filtersMatchInitialValues() {
            // true if the current status is the same as the initial filter values, false if something has changed
            return deepCompare(this.initialFilterValues, this.currentFilterValues) && this.quickFilterOn == this.currentQuickFilterOn
        },
        currentFilteredData() {
            if (!this.filters || !this.filters.length) {
                return this.data
            }

            const filteredData = []
            this.data.forEach(section => {
                filteredData.push(
                    section.filter(row => rowMatchesFilters(row, this.filters, this.managers))
                )
            })

            return filteredData
        },
        possibleFiltersWithMatches() {
            // Create the final result array and fill it with current filter values
            const result = []
            Object.keys(this.currentFilterValues).forEach(filterId => {
                const filter = this.possibleFilters.find(f => f.id == filterId)
                result.push({
                    name: filter.name,
                    filterValues: JSON.parse(JSON.stringify(this.currentFilterValues[filterId])),
                    idx: filter.idx,
                    id: filter.id,
                    type: filter.type,
                    filterValueEditor: filter.filterValueEditor ? filter.filterValueEditor : null,
                })
            })

            const fvPairs = []  // This array contains pairs of [filterId, filterValue] and will be our work queue
            this.possibleFilters.forEach(filter => {
                filter.filterValues.forEach(fv => {
                    if (this.currentFilterValues[filter.id] && this.currentFilterValues[filter.id].find(x => x.value == fv.value)) {
                        return
                    }
                    fvPairs.push([filter.id, fv])
                })
            })

            const addFilterValue = (filterId, filterValue) => {
                // This function effectively adds the passed in arguments to the current filters.
                // This allows us to construct a what-if scenario for the given filter value.
                const currentFilterValues = JSON.parse(JSON.stringify(this.currentFilterValues))

                // We consider what happens if this is the only value for this filter.
                // Otherwise if fvA matches some rows but fvB does not and we are testing fvB
                // We want to not allow fvB even if the whole set [fvA, fvB] would match some of
                // the rows
                currentFilterValues[filterId] = [filterValue]
                return currentFilterValues
            }

            const makeFilterArray = (filters) => {
                // Similar to the computed property in Filters.vue called activeFilters.
                // This function makes an array of filters as consumable by rowMatchesFilters()
                const result = []
                Object.keys(filters).forEach(filterId => {
                    if (!filters[filterId].length) {return}
                    const filter = this.possibleFilters.find(f => f.id == filterId)
                    result.push({
                        name: filter.name,
                        filterValues: filters[filterId],
                        idx: filter.idx,
                        id: filter.id,
                        type: filter.type,
                        filterValueEditor: filter.filterValueEditor ? filter.filterValueEditor : null,
                    })
                })

                return result
            }

            const filtersHaveMatch = (filters) => {
                // Checks if any section has at least one row that matches the given filters
                const filterArray = makeFilterArray(filters)
                return this.data.some(section => {
                    return section.some(row => rowMatchesFilters(row, filterArray, this.managers))
                })
            }

            // Check each filter value in our work queue and see if it'll remove all rows if applied.
            fvPairs.forEach(fvPair => {
                const filterId = fvPair[0]
                const filterValue = fvPair[1]
                const filters = addFilterValue(filterId, filterValue)

                if (filtersHaveMatch(filters)) {
                    const filter = result.find(f => f.id == filterId)
                    filter.filterValues.push(filterValue)
                }
            })

            result.forEach(filter => {
                filter.filterValues = filter.filterValues.sort((a, b) => {
                    if (a.label == b.label) {
                        return 0
                    }
                    return (a.label > b.label) ? 1 : -1
                })
            })

            return result
        },
        filterValueCounts() {
            const filters = []
            const counts = {}
            Object.keys(this.currentFilterValues).forEach(filterId => {
                if (!this.currentFilterValues[filterId].length) {return}
                const filter = this.possibleFilters.find(f => f.id == filterId)

                counts[filter.id] = {}

                this.currentFilterValues[filterId].forEach(filterValue => {
                    filters.push({
                        name: filter.name,
                        filterValues: [filterValue],
                        idx: filter.idx,
                        id: filter.id,
                        type: filter.type,
                        filterValueEditor: filter.filterValueEditor ? filter.filterValueEditor : null,
                    })

                    counts[filter.id][filterValue.value] = 0
                })
            })

            this.filteredData.forEach(section => {
                section.forEach(row => {
                    filters.forEach(filter => {
                        if (rowMatchesFilters(row, [filter], this.managers)) {
                            counts[filter.id][filter.filterValues[0].value] += 1
                        }
                    })
                })
            })

            return counts
        },
    },
    data() {
        const currentFilterValues = {}
        this.possibleFilters.forEach(pf => {
            currentFilterValues[pf.id] = []
            if (pf.id in this.filterValues) {
                currentFilterValues[pf.id] = JSON.parse(JSON.stringify(this.filterValues[pf.id]))
            }
        })

        return {
            currentFilterValues: currentFilterValues,
            currentQuickFilterOn: this.quickFilterOn,
            expandedFilters: {}
        }
    },
    methods: {
        // We need some sort of fix for Bootstrap's accordion behavior, which is bizarrely
        // broken in 1 key way. Section toggles have `.collapsed` by default, since they begin 
        // closed. When I toggle a section open, `.collapsed` gets removed from that toggle (good).
        // If I toggle the same section closed, `.collapsed` gets re-added to that toggle (good).
        // However, if I toggle a DIFFERENT section open – which should close the first section 
        // automatically, because accordion – `.collapsed` does NOT get re-added to the first 
        // section's toggle (bad). This means that after just a few clicks, the open/closed 
        // indicators all get out of sync. 
        // In the example in Bootstrap's docs, everything works fine, so it's probably just 
        // a bug in my implementation. Maybe Bootstrap's code expects there to be 1 expanded
        // section by default (which we don't have)?

        toggleFilterValue(filterId, filterValue, isActive) {
            this.currentFilterValues[filterId] = this.currentFilterValues[filterId].filter(fv => fv.value != filterValue.value)
            if (isActive) {
                this.currentFilterValues[filterId].push(filterValue)
            }
        },
        removeFilter({filterId, filterValue}) {
            this.currentFilterValues[filterId] = this.currentFilterValues[filterId].filter(fv => fv.value != filterValue)
        },
        applyFilters() {
            this.$emit('filter', {
                filterValues: this.currentFilterValues,
                quickFilterOn: this.currentQuickFilterOn
            })
            this.$nextTick(() => {
                this.close()
            })
        },
        toggleQuickFilter() {
            const filter = this.possibleFilters.find(f => f.type == 'warning')

            if (this.currentQuickFilterOn) {
                this.currentFilterValues[filter.id] = []
                this.currentQuickFilterOn = false
            } else {
                this.currentFilterValues[filter.id] = []
                filter.filterValues.forEach(fv => {
                    this.currentFilterValues[filter.id].push(fv)
                })
                this.currentQuickFilterOn = true
                this.$set(this.expandedFilters, filter.id, false)
            }
        },
        resetAll() {
            Object.keys(this.currentFilterValues).forEach(fId => {
                this.currentFilterValues[fId] = []
            })
            this.currentQuickFilterOn = false
        },
        toggleExpandedFilters(filterId) {
            if (!this.expandedFilters[filterId]) {
                Object.keys(this.expandedFilters).forEach(fId => {
                    this.expandedFilters[fId] = false
                })
            }

            this.$set(this.expandedFilters, filterId, !this.expandedFilters[filterId])
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>
