<template>
    <sidebar @close="$emit('close')" v-if="securityProfile">
        <template slot="title" v-if="securityProfile">
            {{ securityProfile.full_name }}
        </template>

        <template slot="header" v-if="securityProfile">
            <div class="mb-1">
                <div class="d-flex flex-wrap">
                    <div class="py-qt text-nowrap">
                        <!-- Type of object -->
                        <span class="text-muted">
                            Security User&nbsp;&middot;
                        </span>
                        <!-- Status -->
                        <span class="text-muted" v-if="isActive && !securityProfile.user_id">
                            Pending Activation
                        </span>
                        <span class="text-muted" v-if="isActive && securityProfile.user_id">
                            Active
                        </span>
                        <span class="text-danger-dark text-bold text-italic" v-if="!isActive">
                            Inactive
                        </span>
                    </div>
                </div>
            </div>

            <unlock-widget v-if="securityProfile.user_id && securityProfile.user_id && isLocked" :client="client" :user="{id: securityProfile.user_id}" :is-locked="isLocked" @unlocked="refresh" />

            <div class="nav-tabs-container">
                <div class="nav-tabs-scroll-helper">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'main'}" href="#tabSUContentMain" id="tabSULinkMain" data-toggle="tab" role="tab" aria-controls="tabSUContentMain" :aria-selected="activeTab == 'main'">Main</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'warnings'}" href="#tabSUContentWarnings" id="tabSULinkWarnings" data-toggle="tab" role="tab" aria-controls="tabSUContentWarnings" :aria-selected="activeTab == 'warnings'">Warnings</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'access'}" href="#tabSUUserAccess" id="tabLinkUserAccess" data-toggle="tab" role="tab" aria-controls="tabSUUserAccess" :aria-selected="activeTab == 'access'">User Access</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{active: activeTab == 'changelogs'}" href="#tabSUChangeHistory" id="tabSULinkHistory" data-toggle="tab" role="tab" aria-controls="tabSUChangeHistory" :aria-selected="activeTab == 'changelogs'">History</a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <slot v-if="securityProfile">
            <div class="tab-content">
                <div class="tab-pane" :class="{'show active': activeTab == 'main'}" id="tabSUContentMain" role="tabpanel" aria-labelledby="tabSULinkMain">

                    <!-- User Info widget -->
                    <info-widget :collapsible="true" :is-expanded="userInfoExpanded" @toggle_expand="userInfoExpanded = !userInfoExpanded">
                        <template slot="title">User Information</template>
                        <slot>
                            <div class="card-body py-2">

                                <div class="mui-textfield">
                                    <span class="textfield">{{ securityProfile.first_name }} {{ securityProfile.last_name }}</span>
                                    <label>Name</label>
                                </div>

                                <!-- Textfield with icon buttons to the right of it -->
                                <div class="d-flex align-items-start">
                                    <div class="flex-fill overflow-auto">
                                        <div class="mui-textfield">
                                            <span class="textfield">{{ securityProfile.email }}</span>
                                            <label>Email Address</label>
                                        </div>
                                    </div>
                                    <div class="flex-0">
                                        <a class="btn btn-outline-primary btn-collapse-xl" :href="'mailto:' + encodeURI(securityProfile.email)">
                                            <i class="far fa-envelope" aria-hidden="true" title="Send Email"></i>
                                            <span class="btn-text">Send Email</span>
                                        </a>
                                    </div>
                                </div>

                                <div class="mui-textfield">
                                    <span class="textfield">{{ securityProfile.phone_formatted }}</span>
                                    <label>Phone Number</label>
                                    <p class="text-small mt-1">This phone number is shown to employees when they need to contact a manager to reset their PIN.</p>
                                </div>

                                <button-row stacked class="pt-0">
                                    <button v-if="isActive && !securityProfile.user_id" class="btn btn-outline-primary" @click.prevent="resendInvitation()">
                                        Re-send Invitation
                                    </button>
                                    <button v-if="isActive && securityProfile.user && canManage" class="btn btn-outline-primary" @click.prevent="deactivate()">
                                        Deactivate
                                    </button>
                                    <button v-if="isActive && !securityProfile.user && canManage" class="btn btn-outline-primary" @click.prevent="deleteSecurityProfile()">
                                        Delete User
                                    </button>
                                    <button v-if="!isActive && canManage" class="btn btn-outline-primary" @click.prevent="reactivate()">
                                        Reactivate
                                    </button>
                                </button-row>
                            </div>
                        </slot>
                    </info-widget>

                    <!-- Roles widget -->
                    <info-widget v-if="isActive" :collapsible="true" :is-expanded="roleExpanded" @toggle_expand="roleExpanded = !roleExpanded">
                        <template slot="title">Role</template>
                        <template slot="headerbutton">
                            <button class="btn btn-outline-primary" @click.prevent="editModalOpen = true">
                                <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                                Edit
                            </button>
                        </template>
                        <slot>
                            <div class="py-1">
                                <section class="table-section">
                                    <table class="table css-table widget-table table-fixed">
                                        <thead>
                                            <tr>
                                                <th class="cw-toggle"></th>
                                                <th>Role</th>
                                                <th class="cw-date text-center">Companies</th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="role in profileRoles">
                                            <tr>
                                                <td class="cw-toggle position-relative">
                                                    <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': openCompanyPositions[role.role], 'collapsed': !openCompanyPositions[role.role]}" @click.prevent="openCompanyPositions[role.role] = !openCompanyPositions[role.role]">
                                                        <collapse-toggle-button />
                                                    </a>
                                                </td>
                                                <td class="truncate-wrapper">
                                                    <span>
                                                        {{ role.role_pretty }}
                                                    </span>
                                                </td>
                                                <td class="cw-date text-center">
                                                    <span>
                                                        {{ role.company_security_profiles.length }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr class="child no-transition" v-show="openCompanyPositions[role.role]">
                                                <td colspan="3">
                                                    <div class="card card-body item-details">
                                                        <div v-for="csp in role.company_security_profiles" class="mb-hf">
                                                            <div class="py-hf text-semibold">
                                                                {{ csp.company.name }}
                                                            </div>
                                                            <div class="pl-1" v-if="$store.state.security_roles.COMPANY_LEVEL_ROLES.includes(role.role)">
                                                                All positions.
                                                            </div>
                                                            <div v-if="$store.state.security_roles.LOCATION_LEVEL_ROLES.includes(role.role)">
                                                                <div v-if="csp.work_location_security_profiles.length < 1">
                                                                    No work locations.
                                                                </div>
                                                                <ul class="list-unstyled pl-1 m-0" v-if="csp.work_location_security_profiles.length">
                                                                    <li class="p-0 pb-qt" v-for="wlsp in csp.work_location_security_profiles">
                                                                        {{ wlsp.work_location.name }}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div v-if="$store.state.security_roles.POSITION_LEVEL_ROLES.includes(role.role)">
                                                                <div v-if="csp.security_profile_positions.length < 1">
                                                                    No positions.
                                                                </div>
                                                                <ul class="list-unstyled pl-1 m-0" v-if="csp.security_profile_positions.length">
                                                                    <li class="p-0 pb-qt" v-for="spp in csp.security_profile_positions">
                                                                        {{ spp.position.terminal_value }}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>
                            </div>
                        </slot>
                    </info-widget>

                    <!-- E-Verify access widget -->
                    <info-widget v-if="isActive && client.has_everify && securityProfile.everify_access" :collapsible="true" :is-expanded="eVerifyAccessExpanded" @toggle_expand="eVerifyAccessExpanded = !eVerifyAccessExpanded">
                        <template slot="title">E-Verify Access</template>
                        <template slot="title-detail">
                            <div v-if="securityProfile.everify_access == 'enabled'">
                                <h4 class="text-dark mb-0"><i>Enabled</i></h4>
                            </div>
                            <div v-if="securityProfile.everify_access == 'blocked'">
                                <h4 class="text-dark mb-0"><i>Training Required</i></h4>
                            </div>
                        </template>

                        <slot>
                            <div class="card-body py-2">
                                <div v-if="securityProfile.everify_access == 'enabled'">
                                    This user has passed the E-Verify Knowledge Test and may access E-Verify.
                                </div>

                                <div v-if="securityProfile.everify_access == 'blocked'">
                                    This user has not yet passed the E-Verify Knowledge Test and must do so before accessing E-Verify.
                                </div>

                                <div v-if="securityProfile.everify_access == ''">
                                    This user has not yet passed the E-Verify Knowledge Test and must do so before accessing E-Verify.
                                </div>

                                <div v-if="securityProfile.everify_latest_test && securityProfile.everify_latest_test.taken">
                                    <div class="card card-body item-details mt-2">
                                        <div class="mb-hf">
                                            <div class="py-hf text-semibold">
                                                Latest Knowledge Test Attempt
                                            </div>
                                            <div class="pt-1">
                                                Date: {{ securityProfile.everify_latest_test.latest_test.taken_on|date }}
                                                <br>
                                                Score: {{ securityProfile.everify_latest_test.latest_test.score }}
                                                <br>
                                                Result: <span v-if="securityProfile.everify_latest_test.latest_test.passed">Passed</span><span v-else>Failed</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </slot>
                    </info-widget>

                </div>

                <div class="tab-pane" :class="{'show active': activeTab == 'warnings'}" id="tabSUContentWarnings" role="tabpanel" aria-labelledby="tabSULinkWarnings">
                    <section class="table-section">
                        <warnings-header category="Dismissed" :expanded="dismissedWarningsExpanded" v-if="warnings" :warning-count="dismissedWarnings.length" @click="dismissedWarningsExpanded = !dismissedWarningsExpanded" />
                        <warnings :warnings="dismissedWarnings" :active-warning-id="activeWarningId" v-if="warnings" v-show="dismissedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>

                    <section class="table-section">
                        <warnings-header category="Unresolved" :expanded="unresolvedWarningsExpanded" v-if="warnings" :warning-count="unresolvedWarnings.length" @click="unresolvedWarningsExpanded = !unresolvedWarningsExpanded" />
                        <warnings :warnings="unresolvedWarnings" :active-warning-id="activeWarningId" v-if="warnings" v-show="unresolvedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>

                    <section class="table-section">
                        <warnings-header category="Resolved" :expanded="resolvedWarningsExpanded" v-if="warnings" :warning-count="resolvedWarnings.length" @click="resolvedWarningsExpanded = !resolvedWarningsExpanded" />
                        <warnings :warnings="resolvedWarnings" :active-warning-id="activeWarningId" v-if="warnings" v-show="resolvedWarningsExpanded" @updated="onWarningsUpdate" />
                    </section>

                </div>

                <div class="tab-pane" :class="{'show active': activeTab == 'access'}" id="tabSUUserAccess" role="tabpanel" aria-labelledby="tabLinkUserAccess">
                    <user-access
                        :client="client"
                        :user="securityProfile.user"
                        :email="securityProfile.email"
                        :login-sessions="loginSessions"
                        :allow-security-profile-pin-reset="true"
                        @pin_reset="resetPIN()"
                    />
                </div>

                <div class="tab-pane" :class="{'show active': activeTab == 'changelogs'}" id="tabSUChangeHistory" role="tabpanel" aria-labelledby="tabSULinkHistory">
                    <change-history :client="client" :changelogs="changelogs" :active-changelog-id="activeChangelogId" />
                </div>
            </div>
        </slot>

        <edit-modal v-if="editModalOpen" ref="editModal" :security-profile="securityProfile" :client="client" @updated="onUpdate" @close="editModalOpen = false" />
        <pin-reset-modal :user="securityProfile.user" :client="client" v-if="securityProfile.user_id" ref="pinResetModal" />
    </sidebar>
</template>

<script>
import Sidebar from '@/components/Sidebar'
import InfoWidget from '@/components/InfoWidget'
import ButtonRow from '@/components/ButtonRow'
import ChangeHistory from '@/components/ChangeHistory'
import EditModal from './EditSecurityUserModal'
import PinResetModal from '@/components/PINResetModal'
import UnlockWidget from '@/components/UnlockWidget'
import UserAccess from '@/components/UserAccess'
import Warnings from '@/components/WarningsWidget'
import WarningsHeader from '@/components/WarningsHeaderWidget'

export default {
    props: ['securityProfileId', 'client', 'tab', 'section', 'itemId', ],
    components: {Sidebar, InfoWidget, ButtonRow, ChangeHistory, EditModal, PinResetModal, UnlockWidget, UserAccess, Warnings, WarningsHeader, },
    computed: {
        isLocked() {
            return this.securityProfile.company_security_profiles.filter(csp => csp.is_locked).length > 0
        },
        isActive() {
            return this.securityProfile.company_security_profiles.filter(csp => csp.is_active).length > 0
        },
        unresolvedWarnings() {
            if (!this.warnings) {return []}
            return this.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return (!isDismissed && !w.is_resolved)
            })
        },
        resolvedWarnings() {
            if (!this.warnings) {return []}
            return this.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return (!isDismissed && w.is_resolved)
            })
        },
        dismissedWarnings() {
            if (!this.warnings) {return []}
            return this.warnings.filter(w => {
                const isDismissed = (this.$store.state.user.acknowledged_warning_ids.indexOf(w.id) >= 0)
                return isDismissed
            })
        },
        canManage() {
            if (!this.securityProfile) {
                return false
            }

            let canManageCount = 0
            this.securityProfile.company_security_profiles.forEach(csp => {
                if (!this.$permissions.hasRolesInCompany(this.client.id, csp.company_id, this.$store.state.security_roles.SETTINGS_ROLES)) {
                    return
                }

                // if the security user we're trying to manage is themselves an officer, nobody can 'manage' them;
                // if they're a PA, then their 'manager' has to be an officer;
                // otherwise being a PA is enough
                if (csp) {
                    // this is [ROLE_PAYROLL_ADMIN, ROLE_OFFICER, ROLE_OFFICER_PA]
                    let requiredRoles = this.$store.state.security_roles.SETTINGS_ROLES
                    if (csp.role == this.$store.state.security_roles.ROLE_PAYROLL_ADMIN) {
                        requiredRoles = this.$store.state.security_roles.OFFICER_ROLES
                    } else if (csp.role == this.$store.state.security_roles.ROLE_OFFICER) {
                        requiredRoles = []
                    }

                    if (!this.$permissions.hasRolesInCompany(this.client.id, csp.company_id, requiredRoles)) {
                        return
                    }
                }

                canManageCount += 1
            })

            return canManageCount == this.securityProfile.company_security_profiles.length
        },
        profileRoles() {
            const result = {}
            this.securityProfile.company_security_profiles.forEach(csp => {
                if (!csp.is_active) {return}

                if (!result.hasOwnProperty(csp.role)) {
                    result[csp.role] = {
                        role_numeric: csp.role_numeric,
                        role: csp.role,
                        role_pretty: csp.role_pretty,
                        company_security_profiles: [],
                    }
                }
                result[csp.role].company_security_profiles.push(csp)

            })

            const profileRoles = Object.values(result)
            profileRoles.sort((a, b) => a.role_numeric > b.role_numeric ? 1 : -1)

            return profileRoles
        },
    },
    data() {
        const openCompanyPositions = {}
        this.$store.state.security_roles.ALL_ROLES.forEach(roleId => {
            openCompanyPositions[roleId] = false
        })

        return {
            securityProfile: null,
            changelogs: null,
            warnings: null,
            openChangelogs: {},
            changeHistoryLoaded: false,
            openCompanyPositions: openCompanyPositions,
            loginSessions: [],

            activeTab: this.tab,

            activeWarningId: (this.tab == 'warnings') ? this.itemId : null,
            activeChangelogId: (this.tab == 'changelogs') ? this.itemId : null,

            unresolvedWarningsExpanded: true,
            resolvedWarningsExpanded: true,
            dismissedWarningsExpanded: true,
            editModalOpen: false,
            userInfoExpanded: true,
            roleExpanded: true,
            eVerifyAccessExpanded: true,
        }
    },
    mounted() {
        this.getSecurityProfile()
        this.getChangelogs()
        this.getWarnings()
    },
    methods: {
        openCompanyPosition(cpId) {
            this.$set(this.openCompanyPositions, cpId, !this.openCompanyPositions[cpId])
        },
        getSecurityProfile() {
            this.securityProfile = null
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${this.client.id}/security-profiles/${this.securityProfileId}`).then(resp => {
                this.securityProfile = resp
                this.$store.dispatch('STOP_LOADING')
                this.getLoginSessions()
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        getChangelogs() {
            this.changelogs = null
            this.$api.get(`/clients/${this.client.id}/security-profiles/${this.securityProfileId}/changelogs`).then(resp => {
                this.changelogs = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getWarnings() {
            this.changelogs = null
            this.$api.get(`/clients/${this.client.id}/security-profiles/${this.securityProfileId}/warnings`).then(resp => {
                this.warnings = resp
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
            })
        },
        getLoginSessions() {
            this.loginSessions = null
            if (this.securityProfile.user_id) {
                this.$api.get(`/clients/${this.client.id}/users/${this.securityProfile.user_id}/login-history`).then(resp => {
                    this.loginSessions = resp
                }).catch((errors) => {
                    this.$bus.showError(errors.__all__)
                })
            }
        },
        reset() {
            this.getSecurityProfiles()
            this.getChangelogs()
            this.getWarnings()
            this.openCompanyPositions = {}
            this.$store.state.security_roles.ALL_ROLES.forEach(roleId => {
                this.openCompanyPositions[roleId] = false
            })
        },
        resetPIN() {
            this.$refs.pinResetModal.open()
        },
        deleteSecurityProfile() {
            if (!confirm('Are you sure you want to delete this security user invitation?')) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.del(`/clients/${this.client.id}/security-profiles/${this.securityProfile.id}`).then(() => {
                this.$emit('deleted', {})
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        deactivate() {
            if (!confirm('Are you sure you want to deactivate this security user?')) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.patch(`/clients/${this.client.id}/security-profiles/${this.securityProfile.id}`, {is_active: false}).then(resp => {
                this.getChangelogs()
                this.getWarnings()
                Object.assign(this.securityProfile, resp)
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        reactivate() {
            this.editModalOpen = true
        },
        resendInvitation() {
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/security-profiles/${this.securityProfile.id}/invitation`, {}).then(resp => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showInfo(`Email sent to ${this.securityProfile.email}.`)
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        refresh() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${this.client.id}/security-profiles/${this.securityProfile.id}`).then(resp => {
                Object.assign(this.securityProfile, resp)
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
        onUpdate(data) {
            this.getChangelogs()
            this.getWarnings()
            Object.assign(this.securityProfile, data)
            this.$emit('updated', data)
        },
        updateSecurityProfile(sp) {
            // Similar to onUpdate() but does not emit an event. This is used by the parent component to overwrite this.securityProfile if an out of band update comes in
            Object.assign(this.securityProfile, sp)
        },
        onWarningsUpdate() {
            this.refresh()
        },
    },
}
</script>

