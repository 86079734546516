<template>
    <sidebar @close="$emit('close')">
        <template slot="title">
            {{ form.name }}
        </template>
        <slot>
            <div class="card mb-2">
                <div class="card-header pr-1 d-flex align-items-center">
                    <span class="btnlike mr-auto">
                        <h4 class="mb-0">Info</h4>
                    </span>

                    <button class="btn btn-outline-primary" type="button" @click.stop.prevent="openEditModalInfo()">
                        <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                        Edit
                    </button>
                </div>

                <div class="card-body">
                    <div class="mui-form">
                        <div class="mui-textfield">
                            <span class="textfield">{{ form.slug }}</span>
                            <label>Slug</label>
                        </div>

                        <div class="mui-textfield">
                            <span class="textfield">{{ form.form_type_label }}</span>
                            <label>Type</label>
                        </div>

                        <div class="mui-textfield">
                            <span class="textfield">{{ form.is_live ? 'Yes' : 'No' }}</span>
                            <label>Live</label>
                        </div>

                        <div class="mui-textfield">
                            <span class="textfield">{{ form.get_pdf_theme_display }}</span>
                            <label>PDF Theme</label>
                        </div>

                    </div>
                </div>
            </div>

            <info-widget :collapsible="true" :is-expanded="versionsExpanded" @toggle_expand="versionsExpanded = !versionsExpanded">
                <template slot="title">Versions</template>

                <template slot="headerbutton">
                    <button type="button" class="btn btn-outline-primary" @click.prevent="openEditModalVersions()">
                        <i class="far fa-pen" aria-hidden="true" title="Edit"></i>
                        Edit
                    </button>
                </template>

                <slot>
                    <table class="table css-table widget-table table-fixed">
                        <thead>
                            <tr>
                                <th class="cw-toggle"></th>
                                <th>PDF File</th>
                                <th class="cw-6 text-center">Status</th>
                                <th class="cw-6 text-center">Version</th>
                            </tr>
                        </thead>
                        <tbody v-for="version in sortedVersions" :key="version.id">
                            <tr>
                                <td class="cw-toggle position-relative">
                                    <a href class="widget-toggle-wrapper collapse-trigger" role="button" :class="{'is-expanded': shownVersionDetails[version.id]}" @click.prevent="toggleVersionDetails(version.id)">
                                        <collapse-toggle-button />
                                    </a>
                                </td>
                                <td class="truncate-wrapper">
                                    <a href @click.prevent="setCurrentPDF(version)" class="truncate-content">
                                        <i class="far fa-fw fa-file-pdf mr-hf"></i>
                                        <span>{{ version.pdf_file_real_name }}</span>
                                    </a>
                                </td>
                                <td class="text-center">
                                    <span v-if="version.is_draft">Draft</span>
                                    <span v-else-if="version.is_active_version">Active</span>
                                    <span v-else>Inactive</span>
                                </td>
                                <td class="text-center">
                                    <span>{{ version.version_number }}</span>
                                </td>
                            </tr>
                            <tr class="child no-transition" v-show="shownVersionDetails[version.id]">
                                <td colspan="4">
                                    <div class="card card-body item-details">
                                        <span>
                                            <strong>Activated On: </strong><br />
                                            <span v-if="version.activated_on">
                                                {{ version.activated_on|datetime }}
                                            </span>
                                            <span v-else>
                                                N/A
                                            </span>
                                        </span>
                                        <span>
                                            <strong>PDF Updated: </strong><br />
                                            {{ version.pdf_modified_on|datetime }}
                                        </span>
                                        <span>
                                            <strong>YAML Updated: </strong><br />
                                            {{ version.yaml_modified_on|datetime }}
                                        </span>
                                        <button class="btn btn-outline-primary mt-2" @click.prevent="openFormTestModal(version)">
                                            Test Version
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </slot>
            </info-widget>

            <form-test-modal v-if="currentTestVersion" ref="formTestModal" :form="form" :version="currentTestVersion" @updated="$emit('updated', $event);" />

            <edit-modal ref="editModal" :form="form" @updated="$emit('updated', $event);" />
        
            <pdf-modal :url="currentPDF.url" :title="currentPDF.title" v-if="currentPDF" @close="setCurrentPDF(null)" ref="pdfModal" />
        </slot>
    </sidebar>
</template>

<script>
import bus from '@/bus'
import api from '@/api'
import EditModal from './FormModal'
import Survey from '@/components/PDFForm/Survey'
import Sidebar from '@/components/Sidebar'
import InfoWidget from '@/components/InfoWidget'
import PdfModal from '@/components/PdfModal'
import FormTestModal from './FormTestModal'

export default {
    components: {EditModal, Survey, Sidebar, InfoWidget, PdfModal, FormTestModal, },
    props: ['form', ],
    computed: {
        sortedVersions() {
            if (!this.form) {return []}
            return this.sortVersions()
        }
    },
    watch: {},
    data() {
        return {
            shownVersionDetails: this.makeShownVersionDetails(),
            currentPDF: null,
            versionsExpanded: true,
            currentTestVersion: null,
        }
    },
    methods: {
        openEditModalInfo() {
            this.$refs.editModal.open()
            this.$refs.editModal.setMode("form-info")
        },
        openEditModalVersions() {
            this.$refs.editModal.open()
            this.$refs.editModal.setMode("form-version")
        },
        setCurrentPDF(version) {
            if (!version) {
                this.currentPDF = null
                return
            }

            this.currentPDF = {
                title: version.pdf_file_real_name,
                url: {
                    url: version.pdf_file_url,
                    withCredentials: true,
                }
            }
        },
        setCurrentTestVersion(version) {
            if (!version) {
                this.currentTestVersion = null
                return
            }
            this.currentTestVersion = version
        },
        sortVersions() {
            if (!this.form) {return []}
            const versions = this.form.form_versions.slice()  // Make a copy of the array
            versions.sort((a, b) => {
                return a.version_number < b.version_number ? 1 : -1
            })

            return versions
        },
        toggleVersionDetails(versionId) {
            this.$set(this.shownVersionDetails, versionId, !this.shownVersionDetails[versionId])
        },
        makeShownVersionDetails() {
            const versions = this.sortVersions()
            const shownVersionDetails = {}
            versions.forEach((v, idx) => {
                shownVersionDetails[v.id] = (idx == 0)
            })
            return shownVersionDetails
        },
        openFormTestModal(version) {
            this.setCurrentTestVersion(version)
            this.$nextTick(() => {
                this.$refs.formTestModal.open()
            })
        },
    },
}
</script>

