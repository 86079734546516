<template>
    <modal @close="$emit('close')" ref="modal" :is-closeable="true" size="xl" :autoshow="true">
        <template #title>
            <b>Action Required:</b> Final Nonconfirmation
        </template>
        <slot>
            <div v-show="['fnc'].includes(mode)">
                <p>
                    Company: {{ company.name }}
                </p>

                <div v-if="eVerifyCaseDetails" class="bg-light p-2 mb-2">
                    <b>Case #:</b> {{ eVerifyCase.case_number }}
                    <br>
                    <b>Status:</b> {{ eVerifyCase.case_status_pretty }}
                    <br>
                    <b>Employee:</b> {{ eVerifyCase.emp_full_name_sortable }}
                    <br>
                    <b>Start Date:</b> {{ eVerifyCase.emp_start_date|date }}
                </div>

                <p>
                    Unfortunately, E-Verify was unable to confirm {{ eVerifyCase.emp_full_name }}'s authorization for employment. Please close the case below to indicate whether or not you intend to continue {{ eVerifyCase.emp_first_name }}'s employment.
                </p>

                <div class="bg-light p-2">
                    <form-input v-model="formData.decision" type="radio" :options="decisionOptions" :errors="errors.decision" />
                </div>

                <div class="my-2 p-2" v-if="formData.decision == 'EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC'">
                    <form-input v-model="formData.employed_after_fnc" type="text" label="Please indicate why you are choosing to continue employment after a Final Nonconfirmation was received" :errors="errors.employed_after_fnc" />
                </div>

                <div class="my-2 p-2" v-if="formData.decision == 'FNC-OTHER'">
                    <form-input v-model="formData.other_close_reason" type="select" label="Indicate why you are closing this case:" :options="otherCloseReasonOptions" :errors="errors.other_close_reason" />

                    <div v-if="formData.other_close_reason == 'OTHER_FREE_TEXT'">
                        <form-input v-model="formData.other_close_reason_other_detail" type="text" label="Please indicate why you are closing this case:" :errors="errors.other_close_reason_other_detail" />
                    </div>
                </div>

            </div>
        </slot>

        <template #footer>
            <div class="modal-footer">
                <div v-if="mode == 'fnc'">
                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="onSubmit('fnc')">
                            Save
                        </button>
                    </button-row>
                </div>

                <div v-if="mode == 'view-case'">
                    <button-row>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close()">
                            Close
                        </button>
                    </button-row>
                </div>
            </div>
        </template>
    </modal>

<!-- /Modal: Select Documents -->
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'
import DownloadableMixin from '@/mixins/Downloadable'

export default {
    mixins: [FormMixin, ModalMixin, DownloadableMixin],
    props: ['client', 'company', 'employee', 'eVerifyCase'],
    mounted() {
        this.reset()
    },
    computed: {
        employeeObjectUrlPath() {
            return this.employee.is_employee ? 'employee' : 'onboarding-application'
        },
        decisionOptions() {
            return [
                {
                    html: `${this.company.name} will no longer employ ${this.eVerifyCase.emp_full_name}.`,
                    value: 'EMPLOYEE_TERMINATED_FOR_FNC_RESULT',
                },
                {
                    html: `${this.company.name} will continue to employ ${this.eVerifyCase.emp_full_name}.`,
                    value: 'EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC',
                },
                {
                    html: `Neither of the options above apply - I am closing this case for a different reason.`,
                    value: 'FNC-OTHER',
                },
            ]
        },
        otherCloseReasonOptions() {
            return [
                {
                    text: `SSA (Social Security Administration) asked me to re-run this case`,
                    value: 'EMPLOYER_INSTRUCTED_TO_CLOSE_BY_SSA',
                },
                {
                    text: `DHS (Department of Homeland Security) asked me to re-run this case`,
                    value: 'EMPLOYER_INSTRUCTED_TO_CLOSE_BY_DHS',
                },
                {
                    text: `The information entered was not correct.`,
                    value: 'INCORRECT_DATA',
                },
                {
                    text: `Other`,
                    value: 'OTHER_FREE_TEXT',
                },
            ]
        },
        formRules() {
            let rules = {}

            rules['decision'] = {presence: {allowEmpty: false} }

            if (this.formData.decision == 'EMPLOYEE_CONTINUES_TO_WORK_AFTER_FNC') {
                rules['employed_after_fnc'] = {presence: {allowEmpty: false} }
            }

            if (this.formData.decision == 'FNC-OTHER') {
                rules['other_close_reason'] = {presence: {allowEmpty: false} }

                if (this.formData.other_close_reason == 'OTHER_FREE_TEXT') {
                    rules['other_close_reason_other_detail'] = {presence: {allowEmpty: false} }
                }
            }

            return rules
        },
    },
    data() {
        return {
            formData: this.makeFormData(),
            eVerifyCaseDetails: null,
            errors: [],
            mode: 'fnc',
        }
    },
    methods: {
        makeFormData() {
            return {
                decision: '',
                employed_after_fnc: '',
                other_close_reason: '',
                other_close_reason_other_detail: '',
            }
        },
        getInitialData() {
            this.$store.dispatch('START_LOADING')

            let url = `/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCase.id}`

            this.$api.get(url).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                this.isPristine = true
                // avoid overwriting prop
                this.$set(this, 'eVerifyCaseDetails', resp.case)

            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onSubmit(action) {
            const formData = {
                action: action,
                form_data: this.getFormData(),
            }

            if (!this.validate()) {
                return
            }

            let url = `/clients/${this.client.id}/${this.employeeObjectUrlPath}s/${this.employee.id}/everify/case/${this.eVerifyCase.id}`

            this.$store.dispatch('START_LOADING')
            this.$api.post(url, formData).then((resp) => {
                this.$emit('updated', resp)
                this.$store.dispatch('STOP_LOADING')
                this.isPristine = true
                // avoid overwriting prop
                this.$set(this, 'eVerifyCaseDetails', resp.case)

                this.$nextTick(() => {
                    this.close(true)
                })
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        reset() {
            FormMixin.methods.reset.call(this)
            this.$set(this, 'formData', this.makeFormData())
            this.getInitialData()

            this.mode = 'fnc'

            this.isPristine = true
            this.$store.dispatch('SET_CLOSEABLE', true)
        },
    }
}
</script>
