<template>
    <modal @close="$emit('close')" ref="modal">
        <template slot="title">Withdraw Consent to Terms</template>

        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
              <p>
                <strong class="text-danger">Warning:</strong> Withdrawing your consent will
                <strong>permanently disable your access</strong> to the GetMyPayStub platform.
                This action cannot be undone.
              </p>

              <p>
                Check the boxes below to confirm that you want to proceed:
              </p>

              <hr>

              <div class="mui-checkbox mb-2">
                <label>
                  <input type="checkbox" v-model="formData.box1">
                  I understand that by withdrawing my consent, my GetMyPayStub account
                  will be deactivated forever, across all of my devices.
                </label>
              </div>

              <div class="mui-checkbox mb-2">
                <label>
                  <input type="checkbox" v-model="formData.box2">
                  I understand that this applies to ALL of my employers who use the
                  GetMyPayStub platform.
                </label>
              </div>

              <div class="mui-checkbox mb-2">
                <label>
                  <input type="checkbox" v-model="formData.box3">
                  I understand that my employers still retain all of my GetMyPayStub
                  data, including pay stubs and tax documents.
                </label>
              </div>

              <hr>

              <p>
                <strong>When you click the "Withdraw My Consent" button below,
            your account will be deactivated and you will be logged out.</strong>
              </p>

              <form-errors :errors="formErrors"/>
              <button-row>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close">Cancel</button>
                <button type="button" class="btn btn-primary" @click.prevent="onSubmit()">Withdraw My Consent</button>
              </button-row>
            </form>
        </slot>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import FormMixin from '@/mixins/Form'
import ButtonRow from '@/components/ButtonRow'

export default {
    mixins: [ModalMixin, FormMixin],
    components: {ButtonRow, },
    props: ['employee', 'user'],
    data() {
        return {
          formData: {
            box1: false,
            box2: false,
            box3: false,
          }
        }
    },
    methods: {
        onSubmit() {
            this.formErrors = []

            if (!this.formData.box1 || !this.formData.box2 || !this.formData.box3) {
              this.formErrors.push("Check all the boxes to proceed.")
              return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.post('/me/withdraw-consent', {}).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.$store.dispatch('LOGOUT', true)
                this.$router.push({name: 'account-closed'})
                this.close()
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>
