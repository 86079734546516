<template>
<div>
    <!-- Special handling for I-9 document photos -->
    <div v-if="aTrackedObject.slug == 'i9-document-photos'">
        <p v-if="['Modified', 'Removed'].includes(action)">
            <strong v-if="action == 'Modified'">Old:</strong>
            <button class="btn btn-reset btn-link d-flex justify-content-center align-items-center" @click.prevent="$emit('preview_photos', oldValues)">
                <div class="flex-grow-0 docphoto-thumb mr-1" :style="{ backgroundImage: 'url(' +  oldValues.front + ')' }"></div>
                <div class="flex-grow-1">{{ oldValues.document_title }}</div>
            </button>
        </p>

        <p v-if="['Modified', 'Added'].includes(action)">
            <strong v-if="action == 'Modified'">New:</strong>
            <button class="btn btn-reset btn-link d-flex justify-content-center align-items-center" @click.prevent="$emit('preview_photos', newValues)">
                <div class="flex-grow-0 docphoto-thumb mr-1" :style="{ backgroundImage: 'url(' +  newValues.front + ')' }"></div>
                <div class="flex-grow-1">{{ newValues.document_title }}</div>
            </button>
        </p>
    </div>

    <div class="item-details-section border-top pt-1" v-else-if="fields.length && action == 'Removed' && aTrackedObject.options.show_details_on_remove">
        <!-- String/single values fields -->
        <div v-for="field in fields">
            <div class="mb-hf">
                <span class="text-semibold text-700">{{ fieldLabels[field] }}</span>:
                <span>{{ oldValues[field] }}</span>
            </div>
        </div>

        <div v-for="field in listFields" :key="field">
            <div class="mb-hf" v-if="oldTrackedObject.data[field].length">
                <span class="text-semibold text-700">{{ fieldLabels[field] }}</span>
                <div class="pl-2 mb-1" v-for="to in oldTrackedObject.data[field]">
                    {{ to.natural_name }}
                </div>
            </div>
        </div>
    </div>

    <!-- Regular changes as long as it's not a 'status' -->
    <div class="item-details-section border-top pt-1" v-else-if="fields.filter(f => f != 'status').length && action != 'Removed' || listFields.length || aTrackedObject.options.show_details_on_remove">
        <!-- String/single values fields -->
        <div v-for="field in fields" v-if="field != 'status'">
            <div class="mb-hf">
                <span class="text-semibold text-700">{{ fieldLabels[field] }}</span>
                <span v-if="(newValues[field] == oldValues[field]) || oldValues[field] === null || oldValues[field] === ''">:
                    <span>{{ newValues[field] }}</span>
                </span>
            </div>

            <div class="pl-2 mb-1" v-if="newValues[field] != oldValues[field] && (oldValues[field] !== null && oldValues[field] !== '')">
                <div class="form-row mb-hf">
                    <div class="col-3">
                        <span>Old value:</span>
                    </div>

                    <div class="col-9">
                        <span>{{ oldValues[field] }}</span>
                    </div>

                </div>
                <div class="form-row mb-hf text-bold" v-if="action != 'Removed'">
                    <div class="col-3">
                        <span>New value:</span>
                    </div>
                    <div class="col-9">
                        <span>{{ newValues[field] }}</span>
                    </div>

                </div>
            </div>
        </div>
        <!-- End string/single values fields -->

        <!-- Sub-list fields -->
        <div v-for="field in listFields">

            <!-- Special handling for PDF fields -->
            <div v-if="isPdfField(field) && (removedListObjects[field].length || addedListObjects[field].length || (aTrackedObject.options.always_show_pdf_fields && newListObjects[field].length))">
                <div class="mb-hf">
                    <span class="text-semibold text-700">{{ fieldLabels[field] }}</span>
                </div>
                <div v-if="addedListObjects[field].length && !removedListObjects[field].length">
                    <div v-for="to in addedListObjects[field]" class="pl-2 mb-1">
                        <a v-if="to.data.pdf_file" href @click.prevent="$emit('open_pdf', {'to': 'new', 'field': field, 'filename': to.data.filename})">{{ to.natural_name }}</a>
                        <span v-else>{{ to.natural_name }}</span>
                    </div>
                </div>
                <div v-else-if="addedListObjects[field].length && removedListObjects[field].length" class="pl-2 mb-1">
                    <div class="form-row mb-hf" v-for="to in removedListObjects[field]">
                        <div class="col-3">
                            <span>Old file:</span>
                        </div>
                        <div class="col-9">
                            <a v-if="to.data.pdf_file" href @click.prevent="$emit('open_pdf', {'to': 'old', 'field': field, 'filename': to.data.filename})">{{ to.natural_name }}</a>
                            <span v-else>{{ to.natural_name }}</span>
                        </div>
                    </div>
                    <div class="form-row mb-hf text-bold" v-for="to in addedListObjects[field]">
                        <div class="col-3">
                            <span>New File:</span>
                        </div>
                        <div class="col-9">
                            <a v-if="to.data.pdf_file" href @click.prevent="$emit('open_pdf', {'to': 'new', 'field': field, 'filename': to.data.filename})">{{ to.natural_name }}</a>
                            <span v-else>{{ to.natural_name }}</span>
                        </div>
                    </div>
                </div>
                <div v-else-if="aTrackedObject.options.always_show_pdf_fields">
                    <div v-for="to in newListObjects[field]" class="pl-2 mb-1">
                        <a v-if="to.data.pdf_file" href @click.prevent="$emit('open_pdf', {'to': 'new', 'field': field, 'filename': to.data.filename})">{{ to.natural_name }}</a>
                        <span v-else>{{ to.natural_name }}</span>
                    </div>
                </div>
            </div>

            <template v-else-if="action == 'Added'">
            <div class="mb-hf" v-if="newTrackedObject.data[field].length">
                <span class="text-semibold text-700">{{ fieldLabels[field] }}</span>
                <div class="pl-2 mb-1" v-for="to in newTrackedObject.data[field]">
                    {{ to.natural_name }}
                </div>
            </div>
            </template>

            <template v-else-if="action == 'Modified'">
            <div class="mb-hf" v-if="addedListObjects[field].length">
                <span class="text-semibold text-700">{{ fieldLabels[field] }} Added</span>
                <div class="pl-2 mb-1" v-for="to in addedListObjects[field]">
                    {{ to.natural_name }}
                </div>
            </div>

            <div class="mb-hf" v-if="removedListObjects[field].length">
                <span class="text-semibold text-700">{{ fieldLabels[field] }} Removed</span>
                <div class="pl-2 mb-1" v-for="to in removedListObjects[field]">
                    {{ to.natural_name }}
                </div>
            </div>
            </template>
        </div>

        <!-- End sub-list fields -->
    </div>

</div>
</template>

<script>
export default {
    name: 'TrackedObject',
    props: ['oldTrackedObject', 'newTrackedObject', 'action', ],
    methods: {
        isPdfField(field) {
            return (this.fieldTypes[field] && this.fieldTypes[field] == 'pdf')
        }
    },
    computed: {
        aTrackedObject() {
            // Return just one of the tracked objects (old or new) to allow access to the TO metadata
            return (this.oldTrackedObject && this.oldTrackedObject.data) ? this.oldTrackedObject : this.newTrackedObject
        },
        fields() {
            return this.aTrackedObject.fields
        },
        listFields() {
            return this.aTrackedObject.list_fields
        },
        fieldLabels() {
            return this.aTrackedObject.field_labels
        },
        fieldTypes() {
            return this.aTrackedObject.field_types || {}
        },
        oldValues() {
            const result = {}
            this.fields.concat(this.listFields).forEach(f => {
                result[f] = null
                if (this.oldTrackedObject && this.oldTrackedObject.data && f in this.oldTrackedObject.data) {
                    let val = this.oldTrackedObject.data[f]
                    if (typeof val == 'boolean') {
                        val = val === true ? 'Yes' : 'No'
                    }
                    result[f] = val
                }
            })
            return result
        },
        newValues() {
            const result = {}
            this.fields.concat(this.listFields).forEach(f => {
                result[f] = null
                if (this.newTrackedObject && this.newTrackedObject.data && f in this.newTrackedObject.data) {
                    let val = this.newTrackedObject.data[f]
                    if (typeof val == 'boolean') {
                        val = val === true ? 'Yes' : 'No'
                    }
                    result[f] = val
                }
            })
            return result
        },
        newListObjects() {
            const result = {}

            this.listFields.forEach(field => {
                result[field] = this.newTrackedObject.data[field]
            })

            return result
        },
        addedListObjects() {
            // Objects added to a list, by field name
            const result = {}
            this.listFields.forEach(field => {
                const oldIDs = new Set()
                if (!this.newTrackedObject || !this.newTrackedObject.data) {
                    result[field] = []
                    return
                }

                if (this.oldTrackedObject && this.oldTrackedObject.data) {
                    this.oldTrackedObject.data[field].forEach(to => {
                        oldIDs.add(to.unique_id)
                    })
                }

                const newIDs = new Set()
                if (this.newTrackedObject && this.newTrackedObject.data) {
                    this.newTrackedObject.data[field].forEach(to => {
                        newIDs.add(to.unique_id)
                    })
                }

                result[field] = this.newTrackedObject.data[field].filter(to => {
                    return newIDs.has(to.unique_id) && !oldIDs.has(to.unique_id)
                })
            })

            return result
        },
        removedListObjects() {
            // Objects added to a list, by field name
            const result = {}
            this.listFields.forEach(field => {
                const oldIDs = new Set()
                if (!this.oldTrackedObject || !this.oldTrackedObject.data) {
                    result[field] = []
                    return
                }

                if (this.oldTrackedObject && this.oldTrackedObject.data) {
                    this.oldTrackedObject.data[field].forEach(to => {
                        oldIDs.add(to.unique_id)
                    })
                }

                const newIDs = new Set()
                if (this.newTrackedObject && this.newTrackedObject.data) {
                    this.newTrackedObject.data[field].forEach(to => {
                        newIDs.add(to.unique_id)
                    })
                }

                result[field] = this.oldTrackedObject.data[field].filter(to => {
                    return !newIDs.has(to.unique_id) && oldIDs.has(to.unique_id)
                })
            })

            return result
        },
    },
}
</script>
