<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div class="container-centered-s py-4 text-center">

                        <p>
                            We cannot find the page you’re looking for.
                        </p>
                        <p class="mb-4">
                            Please double-check that the address you entered is correct.
                        </p>
                        <p>
                            <router-link :to="logoLinkRoute" class="btn btn-primary mb-1">Go to Home</router-link>
                        </p>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
      logoLinkRoute() { // Borrowed from MainNavbar
            switch (this.$route.meta.mode) {
                case 'ess':
                    return {name: 'employee-home'}
                case 'client':
                    return {name: 'employer-home', params: this.$route.params.hashedClientId}
                case 'admin':
                    return {name: 'admin-home'}
            }
            return '/'
        },
    },
    mounted() { this.$store.dispatch('SET_PAGE_TITLE', 'Page Not Found')
    },
}
</script>
