<template>
    <div class="px-2 pb-1 pt-0 pt-md-1">
        <table class="table table-sm">
            <thead>
                <tr>
                    <th class="cell-name col-sortable">
                        Employee
                        <span class="sort-icon"></span>
                    </th>
                    <th class="cw-date text-right">
                        {{ dateLabel }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="task in tasks" :key="task.id">
                    <td class="cell-name">
                        <span class="btnlike-sm-alt text-semibold" v-if="task.case_status">
                            <router-link class="btnlike-sm-alt text-semibold" :to="{name: 'e-verify'}">{{ task.emp_full_name }}</router-link>
                        </span>
                        <span class="btnlike-sm-alt text-semibold" v-else-if="task.employee">
                            <router-link class="btnlike-sm-alt text-semibold" :to="{name: 'hr-documents-incomplete', query: {doc: task.id}}">{{ task.employee.full_name }}</router-link>
                        </span>
                        <span class="btnlike-sm-alt text-semibold" v-else-if="task.onboarding_application">
                            <router-link class="btnlike-sm-alt text-semibold" :to="{name: 'onboarding', query: {id: task.onboarding_application.id}}">{{ task.onboarding_application.full_name }}</router-link>
                        </span>
                        <!-- <span class="btnlike-sm-alt text-semibold" v-else-if="task.company_security_profile">
                            {{ task.company_security_profile.security_profile.full_name }}
                        </span> -->
                    </td>
                    <td class="cw-date text-right">
                        <span class="btnlike-sm-alt">
                            <span v-if="taskType == 'doc_needs_manager_approval'">{{ task.responded_on|dateshort }}</span>
                            <span v-else-if="taskType == 'doc_needs_manager_response'">{{ task.assigned_on|dateshort }}</span>
                            <span v-else-if="taskType == 'onboarding_app_approval'">{{ task.onboarding_application.date_hired|fromNow }}</span>
                            <span v-else-if="taskType == 'everify_action_required'">{{ task.next_action_due_on|dateshort }}</span>
                            <span v-else-if="taskType == 'everify_cases_to_create'">{{ task.next_action_due_on|dateshort }}</span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: ['tasks', 'taskType'],
        computed: {
            dateLabel() {
                if (this.taskType) {
                    return {
                        'onboarding_app_approval': 'Expected Start Date',
                        'doc_needs_manager_approval': 'Submitted On',
                        'doc_needs_manager_response': 'Required On',
                        'everify_action_required': 'Due On',
                        'everify_cases_to_create': 'Due On',
                    }[this.taskType]
                }
                return ""
            },
            linkTo() {
                if (this.taskType) {
                    return {
                        'onboarding_app_approval': {name: 'onboarding', params: {}},
                        'doc_needs_manager_approval': {name: 'hr-documents-incomplete'},
                        'doc_needs_manager_response': {name: 'hr-documents-incomplete'},
                        'everify_action_required': {name: 'e-verify'},
                        'everify_cases_to_create': {name: 'e-verify'},
                    }[this.taskType]
                }
                return {}
            }
        }
    }
</script>