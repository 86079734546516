<template>
    <modal size="xl" @close="$emit('close')" ref="modal" :is-closeable="true" :autoshow="true">
        <template slot="title">
            Versions
        </template>
        <slot>
            <version-widget :version="version" v-for="version in versions" v-if="versions">
                <slot name="version" :version="version"></slot>
            </version-widget>
        </slot>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import VersionWidget from './VersionWidget'

export default {
    mixins: [ModalMixin, ],
    components: {VersionWidget, },
    props: ['url', ],
    data() {
        return {
            versions: null,
        }
    },
    mounted() {
        this.getVersions()
    },
    methods: {
        getVersions() {
            this.$api.get(this.url).then(resp => {
                this.versions = resp.versions.sort((a, b) => a.created_on < b.created_on ? 1 : -1)
            }).catch(errors => {
                this.$bus.showError(errors.__all__)
            })
        },
    },
}
</script>
