<template>
    <modal @close="reset(); $emit('close')" ref="modal">
        <template slot="title">Reset PIN for {{ user.email }}</template>

        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">

                <div v-if="mode == 'initial'">
                    <p>Verify the identity of the user by asking them to repeat their secret code word. You can view their code word by tapping the button below. <strong>Please note that the system will log your attempt to view the secret code word.</strong></p>
                    <button type="button" class="btn btn-primary btn-block" @click.prevent="getRecoveryWord()">Reveal Recovery Word</button>
                </div>

                <div v-if="mode == 'confirm-recovery-word'">
                    <p>Please verify the identity of the user by asking them to repeat their secret code word:</p>
                    <p class="text-large text-center"><code>{{ recoveryWord }}</code></p>

                    <p class="mt-4">
                        <button type="submit" class="btn btn-primary btn-block">They said all the right things to me</button>
                        <button type="button" class="btn btn-outline-primary btn-block" @click.prevent="close">They said the wrong thing</button>
                    </p>
                </div>

                <div v-if="mode == 'confirmed'">
                    <p>
                        The user will receive an email with a link to select a new PIN. They must click this link within 24 hours.
                    </p>

                    <p>
                        They will then be able to use their new PIN to reset their password.
                    </p>

                    <button-row center>
                        <button type="button" class="btn btn-outline-primary" @click.prevent="close">Close</button>
                    </button-row>
                </div>

            </form>
        </slot>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import FormMixin from '@/mixins/Form'

export default {
    mixins: [ModalMixin, FormMixin],
    props: ['user', 'client'],
    data() {
        return {
            mode: 'initial',
            recoveryWord: '',
        }
    },
    methods: {
        getRecoveryWord() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/clients/${this.client.id}/users/${this.user.id}/recovery-word`).then(resp => {
                this.recoveryWord = resp
                this.mode = 'confirm-recovery-word'
                this.$store.dispatch('STOP_LOADING')
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            if (!confirm("Are you sure? This action will lock this user's other accounts in the system.")) {
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${this.client.id}/users/${this.user.id}/pin-reset`, {}).then(() => {
                this.$store.dispatch('STOP_LOADING')
                this.mode = 'confirmed'
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        reset() {
            this.recoveryWord = ''
            this.mode = 'initial'
        },
    },
}
</script>
