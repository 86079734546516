<script>
import Worker from 'worker-loader!../search.worker.js'


export default {
    data() {
        return {
            searchIndexes: [],
            foundDataIds: null,
            searchWorker: null,
        }
    },
    watch: {
        search() {
            this.performSearch()
        },
        data() {
            this.createSearchIndexes()
        },
    },
    created() {
        this.searchWorker = new Worker()
        this.searchWorker.addEventListener('message', (m) => {
            if (m.data.action == 'search') {
                this.$set(this, 'foundDataIds', m.data.result)
            }
        })
    },
    mounted() {
        this.createSearchIndexes()
    },
    destroyed() {
        if (this.searchWorker) {
            this.searchWorker.terminate()
            this.searchWorker = null
        }
    },
    computed: {
        searchedData() {
            if (this.search.trim() && this.foundDataIds) {
                const result = []
                this.foundDataIds.forEach((sectionIds, sectionIdx) => {
                    sectionIds = new Set(sectionIds)
                    result[sectionIdx] = this.data[sectionIdx].filter(r => sectionIds.has(r.id))
                })
                return result
            }
            return this.data
        },
    },
    methods: {
        createSearchIndexes() {
            const headers = JSON.parse(JSON.stringify(this.headers))  // May contain methods
            this.searchWorker.postMessage({
                action: 'create',
                headers: headers,
                sections: this.sections,
                data: this.data,
            })
        },
        performSearch() {
            if (this.search.trim()) {
                this.searchWorker.postMessage({
                    action: 'search',
                    search: this.search,
                })
            }
            else {
                this.foundDataIds = null
            }
        },
    },
}
</script>
