<template>
    <modal @close="$emit('close')" ref="modal">
        <template slot="title">Unlock Access to Company</template>

        <slot>
            <p>
                Your access to <span class="text-semibold">{{ company.name }}</span> has been locked
                because your PIN was reset at another organization.
            </p>

            <div v-if="managers.length">
                <p>
                    To unlock it, contact one of the following administrators:
                </p>
                <div class="card card-body mb-2">
                    <ul class="m-0 pl-2">
                        <li v-for="m in managers">{{ m.full_name }}: {{ m.phone_formatted }}</li>
                    </ul>
                </div>
            </div>
            <p v-else>
                To unlock it, contact your account administrator.
            </p>

            <p>
                Your administrator will prompt you to say your recovery word before
                restoring your access. Please have this word ready.
            </p>
        </slot>

        <template slot="footer">
            <div class="row">
                <div class="col-12 col-rs-6 offset-rs-3">
                    <button type="button" class="btn btn-block btn-primary mb-1" @click.prevent="close()">Done</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [ModalMixin, ],
    props: ['company'],
    data() {
        return {
            managers: [],
            dataLoaded: false,
        }
    },
    methods: {
        open() {
            ModalMixin.methods.open.call(this)
            this.$nextTick(() => {
                this.getManagers()
            })
        },
        getManagers() {
            this.$store.dispatch('START_LOADING')
            return this.$api.get(`/me/managers/client/${this.company.client}/company/${this.company.id}`).then((resp) => {
                this.managers = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.dataLoaded = true
                this.$bus.showError(errors.__all__)
                this.$store.dispatch('STOP_LOADING')
            })
        },
    },
}
</script>
