<template>
    <!-- Scoreboard container -->
    <div v-if="warningsLoaded && tasksLoaded">
        <!-- Errors -->
        <div v-if="errors.length" class="scoreboard-group">
            <alert-box type="error" class="mb-0">
                <p class="mb-1">Failed to load warnings:</p>
                <ul>
                    <li v-for="error in errors">{{ error }}</li>
                </ul>
            </alert-box>
        </div>

        <!-- Warnings -->
        <div v-if="warningTypes.length > 0" class="scoreboard-group">
            <h4 class="mb-1">
                <!-- TODO: Is the total count below correct?? -->
                Warnings to Review <span class="text-regular">({{ warnings.length }})</span>
            </h4>
            <div class="scoreboard scoreboard--warnings box-shadow-tile accordion" id="scoreboard_warnings">
                <div v-for="warningType in warningTypes" :key="warningType.type" class="scoreboard-item" v-if="errors.length < 1">
                    <div class="scoreboard-item-main" data-toggle="collapse" :data-target="'#warnings-table-' + warningType.type" role="button" aria-expanded="false" :aria-controls="'warnings-table-' + warningType.type">
                        <span class="scoreboard-item-icon">
                            <span class="counter counter-warnings">
                                <i class="counter-icon fas fa-fw fa-exclamation-triangle" aria-label="Warnings"></i>
                                <span class="counter-label">{{ warningsByType[warningType.type].length }}</span>
                            </span>
                        </span>
                        <span class="scoreboard-item-type">
                            {{ warningType.label }}
                        </span>
                        <span class="scoreboard-item-count">
                            <more-info>
                                <vue-markdown>{{ warningType.warning_info }}</vue-markdown>
                            </more-info>
                        </span>
                    </div>
                    <!-- Warning Detail table -->
                    <div class="scoreboard-item-detail collapse" :id="'warnings-table-' + warningType.type" data-parent="#scoreboard_warnings">
                        <div class="px-2 pb-1 pt-0 pt-md-1">
                            <table class="table table-sm table-flush-x table-rearrange-sm">
                                <thead>
                                    <tr>
                                        <th class="cell-date col-sortable col-sort-desc">
                                            Date
                                            <span class="sort-icon"></span>
                                        </th>
                                        <th class="cell-name col-sortable">
                                            <span v-if="warningsByType[warningType.type][0].employee">Employee</span>
                                            <span v-if="!warningsByType[warningType.type][0].employee && warningsByType[warningType.type][0].onboarding_application">Employee</span>
                                            <span v-if="warningsByType[warningType.type][0].company_security_profile">Security User</span>
                                            <span class="sort-icon"></span>
                                        </th>
                                        <th class="col-sortable cell-employee-num text-md-right">
                                            Employee Num
                                            <span class="sort-icon"></span>
                                        </th>
                                        <th class="col-sortable">
                                            Company
                                            <span class="sort-icon"></span>
                                        </th>
                                        <th class="col-sortable text-md-center" v-if="warningsByType[warningType.type][0].is_resolvable">
                                            Resolved
                                            <span class="sort-icon"></span>
                                        </th>
                                        <th class="cell-actions text-md-right">
                                            <span class="sr-only">Actions</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="warning in warningsByType[warningType.type]" :key="warning.id">
                                        <td class="cell-date">
                                            <span class="btnlike-sm-alt">
                                                {{ warning.created_on|dateshort }}
                                            </span>
                                        </td>
                                        <td class="cell-name">
                                            <span class="btnlike-sm-alt text-semibold" v-if="warning.employee_id">
                                                {{ warning.employee.full_name }}
                                            </span>
                                            <span class="btnlike-sm-alt text-semibold" v-if="!warning.employee_id && warning.onboarding_application_id">
                                                {{ warning.onboarding_application.full_name }}
                                            </span>
                                            <span class="btnlike-sm-alt text-semibold" v-if="warning.company_security_profile_id">
                                                {{ warning.company_security_profile.security_profile.full_name }}
                                            </span>
                                            <!-- Not a link (for now)
                                            <router-link class="btnlike-sm-alt text-semibold" v-if="warning.employee_id" :to="{name: '', params: {}}">{{ warning.employee.full_name }}</router-link>
                                            <router-link class="btnlike-sm-alt text-semibold" v-if="warning.onboarding_application_id" :to="{name: '', params: {}}">{{ warning.onboarding_application.full_name }}</router-link>
                                            -->
                                        </td>
                                        <td class="cell-employee-num text-md-right">
                                            <span class="btnlike-sm-alt">
                                                <span class="employee-num-prefix">Employee Number: </span>
                                                <template v-if="warning.employee_id">
                                                    {{ warning.employee.employee_number }}
                                                </template>
                                                <template v-else>--</template>
                                            </span>
                                        </td>
                                        <td>
                                            <span class="btnlike-sm-alt">
                                                {{ warning.company.name }}
                                            </span>
                                        </td>
                                        <td class="text-md-center" v-if="warning.is_resolvable">
                                            <span class="btnlike-sm-alt text-muted" v-if="warning.resolved_on">
                                                <i class="far fa-fw fa-check"></i>
                                                <span class="d-md-none">Resolved</span>
                                            </span>

                                            <span class="date-value-overdue btnlike-sm-alt mt-0" v-if="!warning.resolved_on">
                                                No<span class="d-md-none">t Resolved</span>
                                            </span>
                                        </td>
                                        <td class="cell-actions text-md-right">
                                            <tooltip
                                                tooltipText="Superusers cannot dismiss warnings"
                                                :tooltipEnabled="$store.state.user.is_superuser"
                                            >
                                                <button type="button" class="btn btn-sm btn-outline-primary my-hf my-md-0" :disabled="$store.state.user.is_superuser" @click.prevent="dismiss(warning)">Dismiss</button>
                                            </tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Tasks -->
        <!-- <pre>{{ tasksTableData | pretty }}</pre> -->
        <div v-if="numTasks > 0" class="scoreboard-group">
            <h4 class="mb-1">
                Tasks <span class="text-regular">({{ numTasks }})</span>
            </h4>
            <div class="scoreboard scoreboard--tasks box-shadow-tile accordion" id="scoreboard_tasks">
                <div v-for="taskType in tasksTableData" :key="taskType.type" class="scoreboard-item">
                    <div class="scoreboard-item-main" data-toggle="collapse" :data-target="'#tasks-table-' + taskType.type" role="button" aria-expanded="false" :aria-controls="'tasks-table-' + taskType.type" @click="toggleTasksWidgetExpanded">
                        <span class="scoreboard-item-icon">
                            <span class="scoreboard-item-icon">
                                <span class="counter counter-tasks">
                                    <i v-if="['doc_needs_manager_approval', 'doc_needs_manager_response'].includes(taskType.type)" class="counter-icon fas fa-fw fa-file" aria-label="tasks"></i>
                                    <i v-if="taskType.type == 'onboarding_app_approval'" class="counter-icon fas fa-fw fa-user" aria-label="tasks"></i>
                                    <i v-if="taskType.type == 'everify_action_required'" class="counter-icon fas fa-fw fa-id-badge" aria-label="tasks"></i>
                                    <i v-if="taskType.type == 'everify_cases_to_create'" class="counter-icon fas fa-fw fa-id-badge" aria-label="tasks"></i>
                                    <span class="counter-label">{{ taskType.numTasks }}</span>
                                </span>
                            </span>
                            <!-- <a class="collapse-trigger" role="button" :class="{'collapsed': !tasksWidgetExpanded}">
                                <collapse-toggle-button class="p-0"/>
                            </a> -->
                        </span>
                        <span class="scoreboard-item-type">
                            {{ taskType.label }}
                        </span>
                        <span class="scoreboard-item-count">
                            <!-- <span class="counter counter-tasks">
                                <i class="counter-icon fas fa-fw fa-file" aria-label="tasks"></i>
                                <span class="counter-label">{{ taskType.numTasks }}</span>
                            </span> -->
                        </span>
                    </div>
                    <!-- Expanded UI -->
                    <div class="scoreboard-item-detail collapse" :id="'tasks-table-' + taskType.type" data-parent="#scoreboard_tasks">
                        <!-- Company section -->
                        <section template v-if="taskType.companies.length">
                            <div v-for="company in taskType.companies" :key="company.id" class="table-section" :class="{'pb-0': !company.isExpanded}">
                                <a href v-if="company.id" class="table-section-titlebar collapse-trigger" role="button" :class="{'collapsed': !company.isExpanded}" @click.prevent="$set(company, 'isExpanded', !company.isExpanded)">
                                    <collapse-toggle-button />
                                    <h5 class="table-section-title">{{ company.name }} <span class="text-regular">({{ company.numTasks }})</span></h5>
                                </a>
                                <section v-show="company.isExpanded">
                                    <!-- Document section -->
                                    <section v-if="company.documents.length">
                                        <div v-for="doc in company.documents" :key="doc.key" class="scoreboard-document-section">
                                            <a href v-if="company.id" class="table-section-titlebar collapse-trigger" role="button" :class="{'collapsed': !doc.isExpanded}" @click.prevent="$set(doc, 'isExpanded', !doc.isExpanded)">
                                                <collapse-toggle-button />
                                                <h5 class="table-section-title">{{ doc.name }} <span class="text-regular">({{ doc.numTasks }})</span></h5>
                                            </a>
                                            <employer-scoreboard-tasks-table v-show="doc.isExpanded" :tasks="doc.tasks" :task-type="taskType.type"/>
                                        </div>
                                    </section>
                                    <!-- Tasks not associated with a document -->
                                    <section v-else>
                                        <employer-scoreboard-tasks-table :tasks="company.tasks" :task-type="taskType.type"/>
                                    </section>
                                </section>
                            </div>
                        </section>
                        <!-- Tasks not associated with a company -->
                        <section v-else>
                            <employer-scoreboard-tasks-table :tasks="taskType.tasks" :task-type="taskType.type"/>
                        </section>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import Tooltip from '@/components/Tooltip'
import EmployerScoreboardTasksTable from './EmployerScoreboardTasksTable'

export default {
    props: ['client', ],
    components: {VueMarkdown, Tooltip, EmployerScoreboardTasksTable, },
    computed: {
        user() {return this.$store.state.user},
        warningTypes() {
            const types = []
            const seen = new Set()
            this.warnings.forEach(w => {
                if (seen.has(w.warning_type)) {
                    return
                }
                seen.add(w.warning_type)
                types.push({
                    type: w.warning_type,
                    label: w.warning_type_pretty,
                    warning_info: w.warning_info,
                })
            })

            return types
        },
        warningsByType() {
            const result = {}
            this.warningTypes.forEach(t => {
                result[t.type] = []
            })

            this.warnings.forEach(w => {
                result[w.warning_type].push(w)
            })

            this.warningTypes.forEach(t => {
                result[t.type].sort((a, b) => {
                    return a.created_on > b.created_on ? 1 : -1
                })
            })

            return result
        },
    },
    data() {
        return {
            warnings: [],
            tasksByType: {},
            numTasks: 0,
            tasksTableData: [],
            tasksWidgetExpanded: false,
            errors: [],
            warningsLoaded: true,
            tasksLoaded: true,
        }
    },
    mounted() {
        this.getWarnings()
        this.getTasks()
    },
    methods: {
        getWarnings() {
            const url = this.client ? `/clients/${this.client.id}/warnings/active` : '/warnings/active'

            return this.$api.get(url).then(resp => {
                this.warnings = resp
                this.warningsLoaded = true
            }).catch(errors => {
                this.errors = errors
                this.warningsLoaded = true
            })
        },
        getTasks() {
            const url = `/clients/${this.client.id}/tasks/active`

            return this.$api.get(url).then(resp => {
                this.tasksByType = resp.tasks_by_type
                this.numTasks = resp.num_tasks
                if (this.numTasks > 0) {
                    this.tasksTableData = this.makeTasksTableData(resp.tasks_by_type)
                }
                this.tasksLoaded = true
            }).catch(errors => {
                this.errors = errors
                this.tasksLoaded = true
            })
        },
        makeTasksTableData(tasksByType) {
            let tableData = []
            let taskTypeLabels = {
                'onboarding_app_approval': 'Onboarding Ready for Approval',
                'doc_needs_manager_approval': 'Document Ready for Approval',
                'doc_needs_manager_response': 'Document Ready to Complete',
                'everify_action_required': 'E-Verify: Action Required',
                'everify_cases_to_create': 'E-Verify: Cases to Create',
            }
            Object.keys(tasksByType).forEach(key => {
                if (tasksByType[key].length > 0) {

                    let tasksList = tasksByType[key]

                    let taskTypeData = {
                        type: key,
                        label: taskTypeLabels[key],
                        tasks: [],
                        numTasks: tasksList.length,
                        companies: [],
                    }

                    tasksList.forEach(t => {
                        if (t.company_id) {
                            let existingCompanyIdx = taskTypeData.companies.findIndex(company => company.id == t.company_id)

                            if (existingCompanyIdx == -1) {
                                let newCompany = {
                                    id: t.company_id,
                                    name: t.company.name,
                                    documents: [],
                                    numTasks: 1,
                                    tasks: [],
                                    isExpanded: false,
                                }
                                if (t.doc_key) {
                                    let newDoc = {
                                        key: t.doc_key,
                                        name: t.document_name,
                                        tasks: [t],
                                        numTasks: 1,
                                        isExpanded: false,
                                    }
                                    newCompany.documents.push(newDoc)
                                } else {
                                    newCompany.tasks.push(t)
                                }
                                taskTypeData.companies.push(newCompany);
                            } else {
                                if (t.doc_key) {
                                    let existingDocIdx = taskTypeData.companies[existingCompanyIdx].documents.findIndex(doc => doc.key == t.doc_key)

                                    if (existingDocIdx == -1) {
                                        let newDoc = {
                                            key: t.doc_key,
                                            name: t.document_name,
                                            tasks: [t],
                                            numTasks: 1,
                                            isExpanded: false,
                                        }
                                        taskTypeData.companies[existingCompanyIdx].documents.push(newDoc)
                                    } else {
                                        taskTypeData.companies[existingCompanyIdx].documents[existingDocIdx].tasks.push(t)
                                        taskTypeData.companies[existingCompanyIdx].documents[existingDocIdx].numTasks += 1
                                    }
                                } else {
                                    taskTypeData.companies[existingCompanyIdx].tasks.push(t)
                                }
                                taskTypeData.companies[existingCompanyIdx].numTasks += 1
                            }
                        } else {
                            taskTypeData.tasks.push(t)
                        }
                    })

                    // special case display for better experience: if there is only one company / document, expand it
                    if (taskTypeData.companies.length == 1) {
                        taskTypeData.companies[0].isExpanded = true
                        if (taskTypeData.companies[0].documents.length == 1) {
                            taskTypeData.companies[0].documents[0].isExpanded = true
                        }
                    }

                    tableData.push(taskTypeData)
                }
            })
            return tableData
        },
        dismiss(warning) {
            if (!confirm('Are you sure?')) {return}

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/clients/${warning.client_id}/warnings/${warning.id}`, {acknowledge: true}).then(resp => {
                this.getWarnings().then(() => {
                    this.$store.dispatch('STOP_LOADING')
                })
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors[0])
            })
        },
        toggleTasksWidgetExpanded() {
            this.tasksWidgetExpanded = !this.tasksWidgetExpanded
        },
    },
}

</script>
