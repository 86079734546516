<template>
    <modal @close="$emit('close')" ref="modal" :autoshow="true">
        <template slot="title"><span class="text-muted">Security User: </span> {{ securityProfileName }}</template>

        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <p>This document contains sensitive employee information.</p>

                <p>
                    <strong>
                        If you proceed, your attempt to view this document will be logged and a notification sent to the appropriate company leadership.
                    </strong>
                </p>
            </form>
        </slot>

        <template slot="footer">
            <button-row inline>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="submit" class="btn btn-primary" @click.prevent="onSubmit()">View Document</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
// this component is for having a security user to consent to viewing a protected HR document (I-9, tax forms, etc.)
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['clientId', 'doc'],
    mixins: [ModalMixin],
    computed: {
        securityProfileName() {
            const sp = this.$store.state.user.security_profiles.find(sp => sp.client_id == this.clientId)
            if (sp) {
                return sp.full_name
            }

            return ''
        },
    },
    methods: {
        onSubmit() {
            this.$emit('submit')
        },
    },
}
</script>

