<template>
    <div id="company">
        <div class="flex-page-content flex-container body-gray">
            <div class="flex-body body-scroll">

                <div class="container mx-auto">

                    <div class="py-4">
                        <h1 class="mb-2">My Employee Dashboard</h1>

                        <employee-scoreboard :show-onboarding-modal="true" v-if="company" :company="company"/>

                        <div class="row" v-if="state.user">
                            <div class="col-12 col-lg-9">
                                <h4 class="mb-2">Account Features</h4>
                                <div class="form-row">
                                    <div class="col-12 col-ss-6 col-sm-4">
                                        <tooltip
                                            tooltipText="Unavailable because you are not connected to this company yet."
                                            :tooltipEnabled="!isCompanyLinked"
                                            class="d-block"
                                        >
                                            <router-link :to="{name: 'employee-paychecks', params: {hashedCompanyId: $route.params.hashedCompanyId}}" :class="{'module-tile': true, 'module-tile--disabled': !isCompanyLinked}" :disabled="!isCompanyLinked" :event="isCompanyLinked ? 'click' : ''">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">

                                                        <span class="module-tile-icon">
                                                            <i class="fal fa-fw fa-money-check-alt"></i>
                                                        </span>

                                                        <div class="module-tile-label">
                                                            Pay Stubs
                                                        </div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </tooltip>
                                    </div>

                                    <div class="col-12 col-ss-6 col-sm-4">
                                        <tooltip
                                            tooltipText="Unavailable because you are not connected to this company yet."
                                            :tooltipEnabled="!isCompanyLinked"
                                            class="d-block"
                                        >
                                            <router-link :to="{name: 'employee-tax-forms', params: {hashedCompanyId: $route.params.hashedCompanyId}}" :class="{'module-tile': true, 'module-tile--disabled': !isCompanyLinked}" :disabled="!isCompanyLinked" :event="isCompanyLinked ? 'click' : ''">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">

                                                        <span class="module-tile-icon">
                                                            <i class="fal fa-fw fa-file-contract"></i>
                                                        </span>

                                                        <div class="module-tile-label">
                                                            Tax Documents
                                                        </div>

                                                    </div>
                                                </div>
                                            </router-link>
                                        </tooltip>
                                    </div>

                                    <div class="col-12 col-ss-6 col-sm-4">
                                        <tooltip
                                            tooltipText="Unavailable because you are not connected to this company yet."
                                            :tooltipEnabled="!isCompanyLinked"
                                            class="d-block"
                                        >
                                            <router-link :to="{name: 'employee-hr-docs', params: {hashedCompanyId: $route.params.hashedCompanyId}}" :class="{'module-tile': true, 'module-tile--disabled': !isCompanyLinked}" :disabled="!isCompanyLinked" :event="isCompanyLinked ? 'click' : ''">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">

                                                        <span class="module-tile-icon">
                                                            <i class="fal fa-fw fa-copy"></i>
                                                        </span>

                                                        <div class="module-tile-label">
                                                            HR Documents
                                                        </div>

                                                    </div>
                                                </div>
                                            </router-link>
                                        </tooltip>
                                    </div>

                                    <div v-if="company && company.has_enhanced_ess" class="col-12 col-ss-6 col-sm-4">
                                        <tooltip
                                            tooltipText="Unavailable because you are not connected to this company yet."
                                            :tooltipEnabled="!isCompanyLinked"
                                            class="d-block"
                                        >
                                            <router-link :to="{name: 'employee-profile', params: {hashedCompanyId: $route.params.hashedCompanyId}}" :class="{'module-tile': true, 'module-tile--new': true, 'module-tile--disabled': !isCompanyLinked}" :disabled="!isCompanyLinked" :event="isCompanyLinked ? 'click' : ''">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">

                                                        <span class="module-tile-icon">
                                                            <i class="fal fa-fw fa-user-alt"></i>
                                                        </span>

                                                        <div class="module-tile-label">
                                                            Profile
                                                        </div>

                                                        <div class="module-tile-meta">
                                                            <span>New!</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </router-link>
                                        </tooltip>
                                    </div>

                                    <div class="col-12 col-ss-6 col-sm-4" v-if="company && company.feature_covid_vaccination_status_reporting">
                                        <tooltip
                                            tooltipText="Unavailable because you are not connected to this company yet."
                                            :tooltipEnabled="!isCompanyLinked"
                                            class="d-block"
                                        >
                                            <router-link :to="{name: 'employee-covid', params: {hashedCompanyId: $route.params.hashedCompanyId}}" :class="{'module-tile': true, 'module-tile--disabled': !isCompanyLinked}" :disabled="!isCompanyLinked" :event="isCompanyLinked ? 'click' : ''">
                                                <div class="module-tile-inner">
                                                    <div class="module-tile-content">

                                                        <span class="module-tile-icon">
                                                            <i class="fal fa-fw fa-virus"></i>
                                                        </span>

                                                        <div class="module-tile-label">
                                                            COVID
                                                        </div>

                                                    </div>
                                                </div>
                                            </router-link>
                                        </tooltip>
                                    </div>

                                </div>
                            </div>

                            <div class="col-12 col-lg-3">
                                <div class="pt-2 pt-lg-0">
                                    <!-- Promo Tile -->
                                    <div class="hide-in-native">
                                        <promo-tile promo="get-the-app" class="promo-tile-tall-lg"></promo-tile>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import EmployeeScoreboard from '@/components/EmployeeScoreboard'
import PromoTile from '@/components/PromoTile'
import Tooltip from '@/components/Tooltip'

export default {
    props: [],
    components: {EmployeeScoreboard, PromoTile, Tooltip, },
    computed: {
        state() {return this.$store.state},
        companies() {
            let result = []
            if (!this.$store.state.user) {
                return result
            }
            result = result.concat(this.$store.state.user.employee_companies)
            result = result.concat(this.$store.state.user.employee_onboarding_companies)
            result = result.concat(this.$store.state.user.employee_pending_approval_companies)
            result = result.concat(this.$store.state.user.employee_pending_link_companies)

            let seen = new Set()
            result = result.filter((c) => {
                if (seen.has(c.id)) {
                    return false
                }

                seen.add(c.id)
                return true
            })

            return result
        },
        companyId() {
            return this.$hasher.decode(this.$route.params.hashedCompanyId)
        },
        company() {
            return this.companies.find(c => c.id == this.companyId)
        },
        isCompanyLinked() {
            if (!this.$store.state.user) {
                return false
            }
            return this.$store.state.user.employee_companies.find(c => c.id == this.companyId) || this.$store.state.user.employee_onboarding_companies.find(c => c.id == this.companyId)
        },
        isCompanyPendingLink() {
            if (!this.$store.state.user) {
                return false
            }
            return this.$store.state.user.employee_pending_link_companies.find(c => c.id == this.companyId)
        },
        pageTitle() {
            return this.company ? `${this.company.name} / Home` : "Home"
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', this.pageTitle)

        this.$store.dispatch('GET_USER').then(() => {
            this.$store.dispatch('SET_PAGE_TITLE', this.pageTitle)
            this.$api.post('/me/last-viewed', {ess: true})
            this.$api.get(`/me/ess?company_id=${encodeURIComponent(this.company.id)}`) // record a LoginSession and kick off employee refresh in the bg

            if (this.isCompanyPendingLink) {
                this.$router.push({name: 'employee-link-record', params: {hashedCompanyId: this.$hasher.encode(this.company.id)}})
                return
            }

            if (this.hasBlockingTask()) {
                this.redirectToTaskPage()
                return
            }
        }).catch((errors) => {
            this.$bus.showError(errors.__all__)
        })
    },
    methods: {
        hasBlockingTask() {
            let user = this.$store.state.user
            if (user && this.company && user.employee_num_tasks_by_company_id && this.company.id in user.employee_num_tasks_by_company_id) {
                return user.employee_num_tasks_by_company_id[this.company.id]['has_blocking_task']
            }
            return false
        },
        redirectToTaskPage() {
            this.$router.push({name: 'employee-tasks', params: {hashedCompanyId: this.$route.params.hashedCompanyId}, query: {redirected: 1}})
        },
    }
}
</script>
