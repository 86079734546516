<template>
    <div class="card mb-1">
        <div class="card-body">
            <form @submit.prevent="onSubmit">
                <form-input @input="onInput" label="First Name" type="text" v-model="formData.first_name" :errors="errors.first_name" />
                <form-input @input="onInput" label="Last Name" type="text" v-model="formData.last_name" :errors="errors.last_name" />
                <div class="form-row">
                    <div class="col-7">
                        <form-input @input="onInput" label="Relationship" type="select" v-model="formData.relationship" :errors="errors.relationship" :options="relationshipOptions" />
                    </div>
                    <div class="col-5">
                        <form-input @input="onInput" label="Phone Number" type="phone" v-model="formData.phone" :errors="errors.phone" />
                    </div>
                </div>
                <form-input @input="onInput" label="Email Address (Optional)" type="email" v-model="formData.email" :errors="errors.email" />
            </form>
        </div>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'

import relationships from '@/contact-relationships'

export default {
    mixins: [FormMixin],
    props: ['instance'],
    computed: {
        relationshipOptions() {
            const result = []
            relationships.forEach((s) => {
                result.push({text: s.name.toUpperCase(), value: s.name.toUpperCase()})
            })
            return result
        },
    },
    data() {
        return {
            formRules: {
                first_name: {presence: {allowEmpty: false}},
                last_name: {presence: {allowEmpty: false}},
                relationship: {presence: {allowEmpty: false}},
                phone: {presence: {allowEmpty: false}},
                email: {optionalEmail: true},
            },
            formData: this.makeFormData()
        }
    },
    watch: {
        instance() {
            this.reset()
        },
    },
    methods: {
        makeFormData() {
            if (this.instance) {
                return {
                    id: this.instance.id,
                    first_name: this.instance.first_name,
                    last_name: this.instance.last_name,
                    relationship: this.instance.relationship,
                    phone: this.instance.phone,
                    email: this.instance.email,
                }
            }
            else {
                return {
                    first_name: '',
                    last_name: '',
                    relationship: '',
                    phone: '',
                    email: '',
                }
            }
        },
        getFormData() {
            return this.formData
        },
        onInput() {
            this.$nextTick(() => {
                this.$emit('update', this.getFormData())
            })
        },
    },
}
</script>
