<template>
    <span ref="tooltip" class="tooltip-wrapper" tabindex="0" :title="tooltip" :data-placement="tooltipPlacement">
        <slot/>
    </span>
</template>

<script>
import $ from 'jquery'

export default {
    props: ['tooltipText', 'tooltipEnabled', 'tooltipPlacement'],
    computed: {
        tooltip() {return this.tooltipEnabled ? this.tooltipText : ''},
    },
    methods: {
        updateTooltip() {
            this.$nextTick(() => {
                if (this.tooltip) {
                    $(this.$refs.tooltip).tooltip()
                } else {
                    $(this.$refs.tooltip).tooltip('dispose')
                }
            })
        }
    },
    watch: {
        tooltip: 'updateTooltip'
    },
    mounted() {
        if (this.tooltip) {
            $(this.$refs.tooltip).tooltip()
        }
    },
    destroyed() {
        if (this.tooltip) {
            $(this.$refs.tooltip).tooltip('dispose')
        }
    },
}
</script>

<style scoped lang="scss">
    button:disabled {
        pointer-events: none;
    }
</style>
