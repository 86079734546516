<template>
    <div class="card mb-2">
        <div class="card-body">
            <form-errors :errors="formErrors"/>

            <div class="form-row">
                <div class="col">
                    <form-input :nocaps="true" v-model="formData.slug" :errors="allErrors.slug" type="text" label="Slug" @input="onInput"/>
                </div>
            </div>

            <div class="form-row">
                <div class="col">
                    <form-input v-model="formData.text" :errors="allErrors.text" type="textarea" label="Text" helper-text="You can use Markdown syntax." @input="onInput"/>
                </div>
            </div>

            <div class="form-row">
                <div class="col">
                    <form-input v-model="responseType" type="radio" label="Type" :options="responseTypeOptions" @input="onInput"/>
                </div>
            </div>

            <div class="form-row" v-if="responseType == 'form'">
                <div class="col">
                    <form-input v-model="formData.yaml_form" :errors="allErrors.yaml_form" type="select" label="PDF Form" :options="formOptions" @input="onInput"/>
                </div>
            </div>

            <div class="form-row" v-if="responseType == 'explicit'">
                <div class="col">
                    <form-input v-model="formData.tax_data" :errors="allErrors.tax_data" type="select" :options="JSONOptions" label="Explicit Tax Value" @input="onInput"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'

export default {
    mixins: [FormMixin],
    props: ['value', 'forms', 'upstreamErrors', ],
    computed: {
        allErrors() {
            return Object.assign({}, this.errors, this.upstreamErrors)
        },
        responseTypeOptions() {
            return [
                {text: "Form", value: 'form'},
                {text: "Explicit Value", value: 'explicit'},
            ]
        },
        JSONOptions() {
            return [
                {text: "Not blocked", value: '{"isBlocked":false}'},
                {text: "Blocked", value: '{"isBlocked":true}'},
            ]
        },
        formOptions() {
            const VALID_TYPES = [
                'fed-w4',
                'fed-exempt',
                'state-w4',
                'non-resident',
                'exempt-cert',
            ]

            const opts = []
            this.forms.forEach(f => {
                if (VALID_TYPES.indexOf(f.form_type) >= 0) {
                    opts.push({text: f.name, value: f.id})
                }
            })
            return opts
        },
    },
    data() {
        return {
            formRules: {
                slug: {presence: {allowEmpty: false},},
                text: {presence: {allowEmpty: false},},
            },
            formData: this.makeFormData(),
            responseType: (!this.value || this.value.yaml_form) ? 'form' : 'explicit',
        }
    },
    watch: {
        'value.id'() {
            this.reset()
        },
        responseType() {
            this.formData.tax_data = null
            this.formData.yaml_form = null
        },
    },
    methods: {
        validate(fields) {
            let isValid = FormMixin.methods.validate.call(this, fields)
            if (this.responseType == 'explicit') {
                if (!this.JSONOptions.find(o => o.value == this.formData.tax_data)) {
                    this.errors['tax_data'] = ['Please select a value.']
                    isValid = false
                }
            }
            else {
                if (!this.formData.yaml_form) {
                    this.errors['yaml_form'] = ['Please select a form.']
                    isValid = false
                }
            }
            return isValid
        },
        getFormData() {
            return {
                id: this.formData.id,
                text: this.formData.text,
                slug: this.formData.slug,
                yaml_form: this.formData.yaml_form,
                tax_data: JSON.parse(this.formData.tax_data),
            }
        },
        makeFormData() {
            if (this.value) {
                return {
                    id: this.value.id,
                    text: this.value.text,
                    slug: this.value.slug,
                    yaml_form: this.value.yaml_form,
                    tax_data: this.value.tax_data ? JSON.stringify(this.value.tax_data) : null,
                }
            }
            else {
                return {
                    id: null,
                    text: '',
                    slug: '',
                    yaml_form: null,
                    tax_data: null,
                }
            }
        },
        onInput() {
            this.$emit('input', this.getFormData())
        },
    },
}
</script>
