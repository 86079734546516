<template>
    <modal size="xl" @close="$emit('close')" ref="modal" :is-closeable="isCloseable" :autoshow="true">
        <template slot="title">
            <span v-if="taxLowIncomeExemption">Update Low Income Exemption: {{ taxLowIncomeExemption.filing_status ? taxLowIncomeExemption.filing_status.name : 'N/A' }}</span>
            <span v-if="!taxLowIncomeExemption">Add New Low Income Exemption</span>
        </template>
        <slot>
            <form class="mui-form" @submit.prevent="onSubmit()">
                <div class="form-row mb-2">
                    <div class="col-6">
                        <form-input v-model="formData.starts_on" :errors="errors.starts_on" type="date" label="Starts On" />
                    </div>
                    <div class="col-6">
                        <form-input v-model="formData.inactive_on" :errors="errors.inactive_on" type="date" label="Expires On" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-6">
                        <form-input v-model="formData.filing_status" :errors="errors.filing_status" type="select" label="Filing Status" :readonly="!!taxLowIncomeExemption" :options="filingStatusOptions" />
                    </div>

                    <div class="col-6">
                        <form-input v-model="formData.formula_id" :errors="errors.formula_id" type="select" label="Formula" :readonly="!!taxLowIncomeExemption" :options="formulaOptions" />
                    </div>
                </div>

                <div class="form-row" v-if="currentFormula">
                    <div class="col-6" v-for="field in currentFormula.constants">
                        <form-input v-model="formData.formula_constants[field[0]]" :errors="errors[field[0]]" type="currency" :label="field[1]" />
                    </div>
                </div>

            </form>
        </slot>

        <template slot="footer">
            <form-errors :errors="formErrors"/>

            <button-row>
                <button type="button" class="btn btn-block btn-outline-primary" @click.prevent="close()">Cancel</button>
                <button type="button" class="btn btn-block btn-primary" @click.prevent="onSubmit()">Save</button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import FormMixin from '@/mixins/Form'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    props: ['tax', 'taxLowIncomeExemption', 'lowIncomeExemptionFormulas', ],
    mixins: [FormMixin, ModalMixin, ],
    computed: {
        filingStatusOptions() {
            const result = []

            this.tax.jurisdiction.filing_statuses.forEach(s => {
                result.push({text: s.name, value: s.id})
            })

            if (result.length < 1) {
                result.push({text: 'NO FILING STATUS', value: '-'})
            }
            return result
        },
        formulaOptions() {
            const result = []

            this.lowIncomeExemptionFormulas.forEach(x => {
                result.push({text: x.name, value: x.id})
            })

            return result
        },
        currentFormula() {
            return this.lowIncomeExemptionFormulas.find(x => x.id == this.formData.formula_id)
        },
    },
    data() {
        return {
            formData: this.makeFormData(),
        }
    },
    methods: {
        makeFormData() {
            if (this.taxLowIncomeExemption) {
                return {
                    starts_on: this.taxLowIncomeExemption.current_version.starts_on,
                    inactive_on: this.taxLowIncomeExemption.current_version.inactive_on,
                    filing_status: this.taxLowIncomeExemption.filing_status_id,
                    formula_id: this.taxLowIncomeExemption.current_version.formula_id,
                    formula_constants: JSON.parse(JSON.stringify(this.taxLowIncomeExemption.current_version.formula_constants)),
                }
            } else {
                return {
                    starts_on: '',
                    inactive_on: '',
                    filing_status: '',
                    formual_id: '',
                    formula_constants: {},
                }
            }
        },
        getFormData() {
            const data = JSON.parse(JSON.stringify(this.formData))
            data.filing_status = data.filing_status == '-' ? null : data.filing_status
            return data
        },
        onSubmit() {
            if (!this.validate()) {
                return
            }

            let promise
            if (this.taxLowIncomeExemption) {
                promise = this.$api.put(`/admin/tax-engine/taxes/${this.tax.id}/tax-low-income-exemptions/${this.taxLowIncomeExemption.id}`, this.getFormData())
            }
            else {
                promise = this.$api.post(`/admin/tax-engine/taxes/${this.tax.id}/tax-low-income-exemptions`, this.getFormData())
            }

            this.$store.dispatch('START_LOADING')
            return promise.then(resp => {
                this.$store.dispatch('STOP_LOADING')
                this.$emit('updated', resp)
                this.close()
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                Object.assign(this.errors, errors)
                this.formErrors = errors.__all__
                this.onError()
            })
        },
    }
}
</script>
